import styled from 'styled-components'
import { Paper, TableContainer } from '@mui/material'

export const StyledPaper = styled(Paper)`
  box-shadow: none !important;
  border-radius: 12px !important;
`

export const StyledTableContainer = styled(TableContainer)`
  border-radius: 12px 12px 0 0;
`
