import React from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { Control, Controller } from 'react-hook-form'
import { BenefitProgramFormValues } from 'Pages/JOONAdmin/EmployerDetailsPage/Views/BenefitPrograms/CreateBenefitProgramModal/useBenefitProgramForm'
import { InputContainer } from 'Components/Inputs/ControlledSelect'

type ControlledChipsProps = {
  options: { id: number; name: string; description: string }[]
  control: Control<BenefitProgramFormValues>
  name: string
  defaultSelected?: number[]
}

export const CategoryMultiselect = ({
  options,
  control,
  name,
  defaultSelected
}: ControlledChipsProps) => {
  return (
    <InputContainer>
      <Controller
        name={name as keyof BenefitProgramFormValues}
        control={control}
        defaultValue={defaultSelected}
        render={({ field }) => (
          <Autocomplete
            multiple
            id="category-multi-selecte"
            options={options}
            getOptionLabel={(option) => option.name}
            value={options.filter((option) => (field.value as number[]).includes(option.id))}
            onChange={(_, newValue) => {
              field.onChange(newValue.map((item) => item.id))
            }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Program Categories" />
            )}
          />
        )}
      />
    </InputContainer>
  )
}
