import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { UserInfoActionTypes, useUserInfoContext } from 'Contexts/UserInfoContext'
import { useEmployerInfoContext, EmployerInfoActionTypes } from 'Contexts/EmployerInfoContext'
import { JoonAdminActionTypes, useJoonAdminContext } from 'Contexts/JOONAdmin/JOONAdminContext'

import { useResetContexts } from 'Hooks/useResetContexts'
import { QueryNames } from 'Hooks/api/queryNames'

import { PageRoutes } from 'Utils/types/pageRoutes'

export const useSwitchActiveUser = () => {
  const {
    state: { userRoles },
    dispatch
  } = useUserInfoContext()
  const employerInfoContext = useEmployerInfoContext()
  const { employerId } = employerInfoContext.state
  const joonAdminContext = useJoonAdminContext()
  const { joonAdminUserId } = joonAdminContext.state

  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { resetContexts } = useResetContexts()

  const switchActiveUsers = (selectedUserId?: number) => {
    // Save the user roles so that we can set them after resetting the contexts
    // This is because roles aren't currently returned anywhere except for the login route.
    // That means we'd have no way to get these back for the original JOON admin.
    const saveAdminRoles = userRoles

    // Invalidate queries so we can fetch the proper data since we are resetting the contexts
    queryClient.invalidateQueries([QueryNames.GetInvoices, employerId])
    queryClient.invalidateQueries([QueryNames.EmployerDashboard, employerId])
    queryClient.invalidateQueries([QueryNames.Dashboard, selectedUserId])
    queryClient.invalidateQueries([QueryNames.GetUserReimbursements, selectedUserId])
    queryClient.invalidateQueries([QueryNames.GetPurchaseAccounts, selectedUserId])
    queryClient.invalidateQueries([QueryNames.GetDepositAccount, selectedUserId])
    queryClient.invalidateQueries([QueryNames.EmployerBenefitPrograms, employerId])

    resetContexts()
    dispatch({ type: UserInfoActionTypes.SetUserRoles, value: saveAdminRoles })

    // Switch users
    dispatch({ type: UserInfoActionTypes.SetUserId, value: selectedUserId })
  }

  const exitGhostMode = () => {
    joonAdminContext.dispatch({
      type: JoonAdminActionTypes.SetGhostModeActive,
      value: false
    })
    switchActiveUsers(joonAdminUserId)

    navigate(`${PageRoutes.AdminEmployerDetails}${employerId}`)
  }

  const enterGhostMode = (ghostUserId: number) => {
    const savedEmployerId = employerId
    if (savedEmployerId) {
      joonAdminContext.dispatch({
        type: JoonAdminActionTypes.SetGhostModeActive,
        value: true
      })
      switchActiveUsers(ghostUserId)
      employerInfoContext.dispatch({
        type: EmployerInfoActionTypes.SetEmployerId,
        value: savedEmployerId
      })
      // Go to dashboard
      navigate(PageRoutes.UserDashboard)
    }
  }

  return { switchActiveUsers, exitGhostMode, enterGhostMode }
}
