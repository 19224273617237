import { CSSProperties, ReactNode } from 'react'

import styled from 'styled-components'
import { Colors, elfGreen, white } from 'Utils/styles/colors'
import { spacingLG, spacingSM, SpacingUnits } from 'Utils/styles/spacing'

const boxShadow = '6px 6px 0px rgba(172, 189, 189, 0.4)'
const StyledCard = styled.div<{
  enableShadow: boolean
  backgroundColor: Colors
  maxHeight?: number
  maxWidth?: number
  includeBorder?: boolean
  padding?: SpacingUnits
}>`
  display: flex;
  flex: 2;
  flex-direction: column;
  border-radius: 12px;
  background-color: ${(props) => props.backgroundColor};
  padding: ${(props) => (props.padding ? props.padding : spacingLG)};
  ${(props) => (props.includeBorder ? `border: 2px solid ${elfGreen};` : '')}
  ${(props) => (props.enableShadow ? `box-shadow: ${boxShadow};` : '')}
  ${(props) => (props.maxHeight ? `max-height: ${props.maxHeight}px;` : '')}
  ${(props) => (props.maxWidth ? `max-width: ${props.maxWidth}px;` : '')}
  height: fit-content;
  gap: ${spacingSM};
`

type CardProps = {
  children: ReactNode
  backgroundColor?: Colors
  includeBorder?: boolean
  enableShadow?: boolean
  maxHeight?: number
  maxWidth?: number
  padding?: SpacingUnits
  style?: CSSProperties
  onClick?: () => void
}
export const Card = ({
  children,
  maxHeight,
  maxWidth,
  padding,
  style,
  onClick,
  enableShadow = false,
  backgroundColor = white,
  includeBorder = false
}: CardProps) => {
  return (
    <StyledCard
      style={style}
      enableShadow={enableShadow}
      backgroundColor={backgroundColor}
      includeBorder={includeBorder}
      maxHeight={maxHeight}
      maxWidth={maxWidth}
      padding={padding}
      onClick={onClick}
    >
      {children}
    </StyledCard>
  )
}
