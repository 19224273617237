import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { QueryNames } from 'Hooks/api/queryNames'
import { AppNotification } from 'Utils/types/appNotification'
import { resetConnectionRenewDate } from 'Services/JOONAdmin/joonAdminUsersService'

export const useResetRenewDateQuery = () => {
  return useMutation([QueryNames.ResetRenewDate], resetConnectionRenewDate, {
    onSuccess: (response) => {
      if (response.success) {
        toast.success(AppNotification.ResetRenewDateSuccess)
      } else {
        toast.error(`${AppNotification.ResetRenewDateFailure} ${response.message}`)
      }
    },
    onError: () => {
      toast.error(`${AppNotification.ResetRenewDateFailure}`)
    }
  })
}
