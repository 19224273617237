import { useEffect, useMemo, useState } from 'react'
import debounce from 'lodash.debounce'

export const useDebouncedSearchInput = (onUpdate: (value: string) => void, timeout = 500) => {
  const [value, setValue] = useState('')
  const debouncedUpdateQuery = useMemo(() => debounce(onUpdate, timeout), [onUpdate, timeout])
  useEffect(() => {
    debouncedUpdateQuery(value)
  }, [debouncedUpdateQuery, value])
  // on component unmount
  useEffect(() => () => debouncedUpdateQuery.cancel())

  return useMemo(
    () => ({ value, setValue, flush: debouncedUpdateQuery.flush }),
    [debouncedUpdateQuery.flush, value]
  )
}
