import { FC } from 'react'

import { PeopleTable } from 'Pages/Employer/PeoplePage/PeopleTable/PeopleTable'
import { InviteUserModal } from 'Components/Modal/InviteUserModal/InviteUserModal'
import { ManageUserProgramsModal } from 'Components/Modal/ManageUserProgramsModal/ManageUserProgramsModal'
import { ManageUserPermissionsModal } from 'Components/Modal/ManageUserPermissionsModal/ManageUserPermissionsModal'
import { RemoveUserModal } from 'Components/Modal/RemoveUserModal/RemoveUserModal'
import { ReactivateUserModal } from 'Components/Modal/ReactivateUserModal/ReactivateUserModal'
import { ManageUserAllowancesModal } from 'Pages/JOONAdmin/EmployerDetailsPage/ManageUserAllowances/ManageUserAllowancesModal'
import { UpdateUserCountryModal } from 'Components/Modal/UpdateUserCountryModal/UpdateUserCountryModal'
import { BulkInviteModal } from 'Components/Modal/BulkModal/BulkInviteModal'
import { BulkUpdateModal } from 'Components/Modal/BulkModal/BulkUpdateModal'

import { useEmployerInfoContext } from 'Contexts/EmployerInfoContext'

import { useGetInvoicesQuery } from 'Hooks/api/employer/invoices/useGetInvoicesQuery'
import { ViewUserMembershipsModal } from 'Components/Modal/ViewUserMembershipsModal/ViewUserMembershipsModal'

export const JoonAdminUsersTable: FC = () => {
  const {
    state: { employerId }
  } = useEmployerInfoContext()

  useGetInvoicesQuery({
    pageNumber: 1,
    employerId,
    perPage: 1
  })

  return (
    <>
      <PeopleTable isJoonAdmin />
      {/* Modals for managing users in various ways */}
      <InviteUserModal />
      <ManageUserProgramsModal />
      <ViewUserMembershipsModal />
      <ManageUserPermissionsModal />
      <BulkInviteModal />
      <BulkUpdateModal />
      <RemoveUserModal />
      <ReactivateUserModal />
      <UpdateUserCountryModal />
      <ManageUserAllowancesModal />
    </>
  )
}
