import { useQuery } from 'react-query'
import { QueryNames } from 'Hooks/api/queryNames'
import { useErrorReporter } from 'Hooks/useErrorReporter'
import { getMerchants } from 'Services/JOONAdmin/merchantService'
import { Merchant } from 'Utils/types/JOONAdmin/merchant'

export const useGetMerchantsQuery = (searchTerm?: string) => {
  const reportError = useErrorReporter()

  return useQuery<Merchant[]>({
    queryKey: [QueryNames.GetMerchants, searchTerm],
    queryFn: async () => {
      const response = await getMerchants(searchTerm)
      if (!response.data) {
        reportError('useGetMerchantsQuery failure', response)
      }

      return response.data?.merchants ?? []
    }
  })
}
