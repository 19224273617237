import * as Switch from '@radix-ui/react-switch'
import styled from 'styled-components'
import { spacingSM } from 'Utils/styles/spacing'

import {
  elfGreen,
  elfGreenHover,
  frostedGlass,
  towerGray,
  white,
  zirconGray
} from 'Utils/styles/colors'

const StyledSwitchRoot = styled(Switch.Root)`
  width: 42px;
  padding: 1px;
  margin: ${spacingSM} 0;
  background-color: ${zirconGray};
  border-radius: 9999px;
  position: relative;
  box-shadow: none;
  border: 1px solid ${frostedGlass};

  &[data-state='checked'] {
    background-color: ${elfGreen};
  }
`

const StyledSwitchThumb = styled(Switch.Thumb)`
  display: block;
  width: 21px;
  height: 21px;
  background-color: ${white};
  border-radius: 9999px;
  transition: transform 200ms;

  &[data-state='checked'] {
    transform: translateX(17px);
  }
`

export const StyledSwitch = styled(({ className, defaultChecked, disabled = false, ...props }) => (
  <StyledSwitchRoot
    className={`${className} SwitchRoot`}
    defaultChecked={defaultChecked}
    disabled={disabled}
    {...props}
  >
    <StyledSwitchThumb className="SwitchThumb" />
  </StyledSwitchRoot>
))<{ disabled?: boolean }>`
  cursor: pointer;

  &:hover {
    background-color: ${towerGray};
    &[data-state='checked'] {
      background-color: ${elfGreenHover};
    }
  }

  ${(props) =>
    props.disabled &&
    `
    cursor: not-allowed;
    opacity: 0.6;

    &:hover {
      background-color: ${zirconGray};
      &[data-state='checked'] {
        background-color: ${zirconGray};
      }
    }
  `}
`
