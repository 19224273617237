import { ChangeEvent, FC, useState, DragEvent, useMemo } from 'react'
import styled from 'styled-components'
import { elfGreen, frostedGlass, white } from 'Utils/styles/colors'
import { spacingMD } from 'Utils/styles/spacing'
import { useHover } from 'Hooks/useHover'
import UploadArrow from 'Assets/upload-arrow.svg'
import { toast } from 'react-toastify'

const FileUploadButton = styled.button<{
  filePicked: boolean
  isDragging: boolean
}>`
  cursor: pointer;
  box-sizing: border-box;
  padding: ${spacingMD};
  background: ${({ filePicked, isDragging }) => (filePicked || isDragging ? white : frostedGlass)};
  border: 1.5px ${({ filePicked }) => (filePicked ? 'solid' : 'dashed')} ${elfGreen};
  border-radius: 8px;

  font-family: 'BasisGrotesqueProRegular', sans-serif;
  color: ${elfGreen};

  &:hover,
  &:focus {
    background-color: ${white};
    outline: none;
  }
`

const UploadArrowImg = styled.img`
  position: relative;
  top: 2px;
  right: 5px;
  height: 15px;
  width: 15px;
`

export enum FileType {
  Images = 'images',
  Csv = 'csv'
}

const FileFormats: Record<FileType, string[]> = {
  images: ['jpg', 'jpeg', 'pdf', 'gif', 'png'],
  csv: ['csv']
}

const MAX_FILE_SIZE = 10240 // 10MB

type FileUploadProps = {
  setFileValue: (file: File | null) => void
  fileType: FileType
}
export const FileUpload: FC<FileUploadProps> = ({ setFileValue, fileType }) => {
  const [file, setFile] = useState<File | null>(null)
  const [isDragging, setIsDragging] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  // Hooks
  const [hoverRef, isHovered] = useHover<HTMLButtonElement>()

  const handleDrag = (e: DragEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setIsDragging(true)
    } else if (e.type === 'dragleave') {
      setIsDragging(false)
    }
  }

  const handleDrop = function (event: DragEvent<HTMLButtonElement>) {
    event.preventDefault()
    event.stopPropagation()

    setIsDragging(false)

    const fileValue = event.dataTransfer.files ? event.dataTransfer.files[0] : null

    if (!fileValue) return

    verifyAndSetFile(fileValue)
  }

  const handleFileSelected = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    event.stopPropagation()

    const fileValue = event.target.files ? event.target.files[0] : null

    if (!fileValue) return

    verifyAndSetFile(fileValue)
  }

  const verifyAndSetFile = (file: File) => {
    // Verify file type
    const invalidFileFormat = !FileFormats[fileType].some((format) =>
      file.name.toLowerCase().endsWith(format.toLowerCase())
    )
    if (invalidFileFormat) {
      toast.error(
        `Only the following file format(s) are acceptable: ${FileFormats[fileType].join(', ')}`
      )
      return
    }

    const fileSize = file.size / 1024
    if (fileSize > MAX_FILE_SIZE) {
      toast.error('File size is greater than the allowed limit.')
      return
    }

    setFile(file)
    setFileValue(file)
  }

  const handleClickUploadButton = () => {
    document.getElementById('uploader')?.click()
  }

  const buttonText = useMemo(() => {
    if (file?.name) return file.name
    if (isHovered || isFocused) return 'Upload a file'
    if (isDragging) return 'Drop to upload'
    return `Files can be up to 10mb / Format(s): ${FileFormats[fileType].join(', ')}`
  }, [file, fileType, isFocused, isHovered, isDragging])

  return (
    <FileUploadButton
      ref={hoverRef}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
      type="button"
      filePicked={!!file}
      isDragging={isDragging}
      onClick={handleClickUploadButton}
    >
      <UploadArrowImg src={UploadArrow} alt="Upload a receipt" />
      {buttonText}
      <input id="uploader" onChange={handleFileSelected} type="file" hidden />
    </FileUploadButton>
  )
}
