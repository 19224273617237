import { useMemo } from 'react'

import { Modal } from 'Components/Modal/Modal'
import { ModalHeader } from 'Components/Modal/ModalHeader'
import { ModalType, usePeoplePageContext } from 'Contexts/PeoplePageContext'
import { useEmployerUsersContext } from 'Contexts/EmployerUsersContext'
import { BenefitProgramAllowances } from 'Pages/JOONAdmin/EmployerDetailsPage/ManageUserAllowances/BenefitProgramAllowances'
import { HeaderText } from 'Components/Header/HeaderStyles'

export const ManageUserAllowancesModal = () => {
  const {
    state: { modal, actionableUserId },
    setModal
  } = usePeoplePageContext()

  const setShowModal = (show: boolean) => {
    setModal(show ? ModalType.ManageUserAllowances : undefined)
  }

  const {
    state: { users }
  } = useEmployerUsersContext()

  const user = useMemo(() => {
    return users.find((user) => user.id == actionableUserId)
  }, [users, actionableUserId])

  // Filter out expired memberships that are active currently or in the future
  const filteredExpiredmemberships = useMemo(() => {
    if (user?.expiredMemberships === undefined) return []

    return user.expiredMemberships.filter((expiredMembership) => {
      const { programId } = expiredMembership
      const hasActiveMembership =
        user?.activeMemberships === undefined
          ? false
          : user.activeMemberships.filter(
              (activeMembership) => activeMembership.programId === programId
            ).length > 0

      const hasFutureMembership =
        user?.futureMemberships === undefined
          ? false
          : user.futureMemberships.filter(
              (futureMembership) => futureMembership.programId === programId
            ).length > 0

      return !hasActiveMembership && !hasFutureMembership
    })
  }, [user?.expiredMemberships, user?.activeMemberships, user?.futureMemberships])

  // Filter out future memberships that are currently active
  const filteredFutureMemberships = useMemo(() => {
    if (user?.activeMemberships === undefined || user?.futureMemberships === undefined) return []

    return user.futureMemberships.filter((futureMembership) => {
      const { programId } = futureMembership

      const hasActiveMembership =
        user?.activeMemberships === undefined
          ? false
          : user.activeMemberships.filter(
              (activeMembership) => activeMembership.programId === programId
            ).length > 0

      return !hasActiveMembership
    })
  }, [user?.futureMemberships, user?.activeMemberships])

  return (
    <>
      <Modal
        showModal={modal === ModalType.ManageUserAllowances}
        setShowModal={setShowModal}
        overflow
      >
        <ModalHeader>
          Allowances for User {user?.id} ({user?.userInfo.email})
        </ModalHeader>
        {user?.activeMemberships !== undefined
          ? user.activeMemberships.map((membership) => (
              <BenefitProgramAllowances
                key={membership.programId}
                userId={user.id}
                membership={membership}
                benefitProgramId={membership.programId}
                currencyCode={user.currencyCode}
              />
            ))
          : null}
        {user !== undefined && (
          <>
            {filteredExpiredmemberships.length > 0 ? (
              <>
                <HeaderText>Expired Memberships</HeaderText>
                {filteredExpiredmemberships.map((membership) => (
                  <BenefitProgramAllowances
                    key={membership.programId}
                    userId={user.id}
                    membership={membership}
                    benefitProgramId={membership.programId}
                    currencyCode={user.currencyCode}
                    isActiveMembership={false}
                  />
                ))}
              </>
            ) : null}
            {filteredFutureMemberships.length > 0 ? (
              <>
                <HeaderText>Future Memberships</HeaderText>
                {filteredFutureMemberships.map((membership) => (
                  <BenefitProgramAllowances
                    key={membership.programId}
                    userId={user.id}
                    membership={membership}
                    benefitProgramId={membership.programId}
                    currencyCode={user.currencyCode}
                    isActiveMembership={false}
                  />
                ))}
              </>
            ) : null}
          </>
        )}
      </Modal>
    </>
  )
}
