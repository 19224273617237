import { CurrencyCode, CurrencySymbol } from 'Utils/types/currencies'

export const numToCurrency = (
  amount: number,
  currencyCode: CurrencyCode,
  locale = 'en-US',
  showMinorUnit = true
) => {
  const hasMinorUnit = amount - Math.floor(amount) !== 0

  return (
    new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: showMinorUnit || hasMinorUnit ? 2 : 0,
      maximumFractionDigits: showMinorUnit || hasMinorUnit ? 2 : 0
    })
      .format(amount)
      // If the locale doesn't use the currency, convert the code to the symbol
      .replace(currencyCode, CurrencySymbol[currencyCode])
  )
}

export const roundAmount = (amount: number) => {
  // Note the plus sign that drops any "extra" zeroes at the end.
  // https://stackoverflow.com/a/12830454
  return +amount.toFixed(2)
}

export const nonNumberRegex = /[^\d.-]/g
export const nonNumberRegexNoNegative = /[^\d.]/g
export const intercedingNegativeSign = /(.)-/g
export const singleDecimalRegex = /^(-?\d*\.\d*)\./g
export const doubleDecimalRegex = /^(-?\d*\.\d{2})\d+$/g
