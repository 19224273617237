import { FC } from 'react'
import { Table, TableBody, TableRow } from '@mui/material'
import { StyledPaper, StyledTableContainer } from 'Components/Table/StyledTableComponents'
import { StyledTableCell } from 'Components/Table/StyledTableCell'
import { SortableTableHeader, TableHeaderCell } from 'Components/Table/SortableTableHeader'
import { Pagination } from 'Components/Table/Pagination'

import { usePagination } from 'Hooks/usePagination'
import { useTableOrder } from 'Hooks/useTableOrder'
import { getComparator } from 'Utils/helpers/tables'
import { Memberships } from 'Utils/types/benefitProgram'
import { displayDate } from 'Utils/helpers/dateUtils'
import { FlexColumn, spacingXS } from 'Utils/styles/spacing'

const headerCells: readonly TableHeaderCell<Memberships>[] = [
  { id: 'programId', numeric: true, label: 'Program Id' },
  { id: 'programName', numeric: false, label: 'Program Name' },
  { id: 'membershipStartDate', numeric: false, label: 'Enrollment Start Date' },
  { id: 'membershipEndDate', numeric: false, label: 'Enrollment End Date' }
]

type UserMembershipsTableProps = {
  memberships: Memberships[]
}

export const UserMembershipsTable: FC<UserMembershipsTableProps> = ({ memberships }) => {
  const { order, orderBy, handleRequestSort } = useTableOrder<Memberships>()
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = usePagination()

  const sortedMemberships = [...memberships].sort(getComparator(order, orderBy))
  const displayedMemberships = sortedMemberships.slice(page * rowsPerPage, (page + 1) * rowsPerPage)

  return (
    <>
      <StyledPaper style={{ height: 'fit-content' }}>
        <FlexColumn gap={spacingXS}>
          <StyledTableContainer>
            <Table>
              <SortableTableHeader
                headerCells={headerCells}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                hideActionColumn={true}
              />
              <TableBody>
                {displayedMemberships.map((membership) => (
                  <TableRow
                    key={`${membership.programId}-${displayDate(membership.membershipStartDate)}`}
                  >
                    <StyledTableCell>{membership.programId}</StyledTableCell>
                    <StyledTableCell>{membership.programName}</StyledTableCell>
                    <StyledTableCell>{displayDate(membership.membershipStartDate)}</StyledTableCell>
                    <StyledTableCell>
                      {membership.membershipEndDate
                        ? displayDate(membership.membershipEndDate)
                        : 'None'}
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </FlexColumn>
      </StyledPaper>
      <Pagination
        optionText="records"
        totalRows={memberships.length}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  )
}
