import { createContext, useContext, useReducer } from 'react'
import { propEq, reject } from 'ramda'

import { EmployerInfo } from 'Utils/types/employerInfo'
import { EmployerAdminInvoice } from 'Utils/types/invoice'
import { ContextProviderProps } from 'Contexts/ContextProvider'
import { PaymentMethod } from 'Utils/types/paymentMethod'
import { CountryCode } from 'Utils/types/countries'

export enum EmployerInfoActionTypes {
  SetEmployerInfo = 'SET_EMPLOYER_INFO',
  SetEmployerId = 'SET_EMPLOYER_ID',
  SetInvoices = 'SET_INVOICES',
  ToggleAutopay = 'TOGGLE_AUTO_PAY',
  SetPaymentMethods = 'SET_PAYMENT_METHODS',
  RemovePaymentMethod = 'REMOVE_PAYMENT_METHOD',
  SetLoading = 'SET_LOADING',
  ResetState = 'RESET_STATE'
}
type Action =
  | { type: EmployerInfoActionTypes.SetEmployerInfo; value: EmployerInfo }
  | { type: EmployerInfoActionTypes.SetEmployerId; value: number }
  | { type: EmployerInfoActionTypes.SetInvoices; value: EmployerAdminInvoice[] }
  | { type: EmployerInfoActionTypes.SetPaymentMethods; value: PaymentMethod[] }
  | { type: EmployerInfoActionTypes.RemovePaymentMethod; value: PaymentMethod['id'] }
  | { type: EmployerInfoActionTypes.SetLoading; value: boolean }
  | {
      type: EmployerInfoActionTypes.ToggleAutopay
      value: {
        paymentMethodId: PaymentMethod['id']
        autopayEnabled: boolean
        countryCode: CountryCode
      }
    }
  | { type: EmployerInfoActionTypes.ResetState }
type Dispatch = (action: Action) => void
type State = {
  isLoading: boolean
  paymentMethods: PaymentMethod[]
  invoices: EmployerAdminInvoice[]
  employerInfo?: EmployerInfo
  employerId?: number
}

const DEFAULT_STATE: State = {
  invoices: [],
  paymentMethods: [],
  isLoading: false
}

const EmployerInfoStateContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(
  undefined
)

const userInfoReducer = (state: State, action: Action): State => {
  switch (action.type) {
    // Employer info
    case EmployerInfoActionTypes.SetEmployerInfo: {
      return { ...state, employerInfo: action.value }
    }
    case EmployerInfoActionTypes.SetEmployerId: {
      return { ...state, employerId: action.value }
    }
    // Invoices
    case EmployerInfoActionTypes.SetInvoices: {
      return { ...state, invoices: action.value }
    }
    // Payment methods
    case EmployerInfoActionTypes.SetPaymentMethods: {
      return { ...state, paymentMethods: action.value }
    }
    case EmployerInfoActionTypes.ToggleAutopay: {
      if (state.employerInfo === undefined) return state

      const { countryCode, autopayEnabled, paymentMethodId } = action.value

      return {
        ...state,
        employerInfo: {
          ...state.employerInfo,
          countryConfigs: {
            ...state.employerInfo.countryConfigs,
            [countryCode]: {
              autoPaymentMethodId: autopayEnabled ? paymentMethodId : null
            }
          }
        }
      }
    }
    case EmployerInfoActionTypes.RemovePaymentMethod: {
      const updatedPaymentMethods = reject(propEq(action.value, 'id'), state.paymentMethods)
      return { ...state, paymentMethods: updatedPaymentMethods }
    }
    // Loading states
    case EmployerInfoActionTypes.SetLoading: {
      return { ...state, isLoading: action.value }
    }
    case EmployerInfoActionTypes.ResetState: {
      return DEFAULT_STATE
    }
    default: {
      throw new Error(`Invalid action type`)
    }
  }
}

const EmployerInfoProvider = ({ children }: ContextProviderProps) => {
  const [state, dispatch] = useReducer(userInfoReducer, DEFAULT_STATE)
  const value = { state, dispatch }
  return (
    <EmployerInfoStateContext.Provider value={value}>{children}</EmployerInfoStateContext.Provider>
  )
}

const useEmployerInfoContext = () => {
  const context = useContext(EmployerInfoStateContext)
  if (context === undefined) {
    throw new Error('useEmployerInfo must be used within an EmployerInfoProvider')
  }
  return context
}

export { EmployerInfoProvider, useEmployerInfoContext }
