import { FC } from 'react'
import styled from 'styled-components'

import { UserAllowancesTable } from 'Pages/JOONAdmin/EmployerDetailsPage/ManageUserAllowances/UserAllowancesTable'
import { AddAllowanceForm } from 'Pages/JOONAdmin/EmployerDetailsPage/ManageUserAllowances/AddAllowanceForm'
import { EligibleSpendBanner } from './EligibleSpendBanner'

import { Memberships } from 'Utils/types/benefitProgram'
import { CurrencyCode } from 'Utils/types/currencies'
import { spacingMD, spacingSM } from 'Utils/styles/spacing'

const ProgramTitle = styled.h3`
  align-content: center;
  justify-content: center;
  margin: ${spacingMD} ${spacingSM};
`

type BenefitProgramAllowancesProps = {
  userId: number
  benefitProgramId: number
  membership: Memberships
  currencyCode: CurrencyCode
  isActiveMembership?: boolean
}

export const BenefitProgramAllowances: FC<BenefitProgramAllowancesProps> = ({
  userId,
  benefitProgramId,
  membership,
  currencyCode,
  isActiveMembership = true
}) => {
  return (
    <div>
      <ProgramTitle>
        Program {membership.programId} ({membership.programName})
      </ProgramTitle>
      {isActiveMembership && (
        <EligibleSpendBanner
          userId={userId}
          benefitProgramId={benefitProgramId}
          currencyCode={currencyCode}
        />
      )}
      <UserAllowancesTable
        key={membership.programId}
        userId={userId}
        benefitProgramId={benefitProgramId}
      />
      <AddAllowanceForm
        userId={userId}
        benefitProgramId={benefitProgramId}
        currencyCode={currencyCode}
      />
    </div>
  )
}
