import { FC } from 'react'
import { ThinDarkGreenText } from 'Utils/styles/text'
import { FlexColumn, spacingSM, spacingXS } from 'Utils/styles/spacing'

type RemoveUserFormProps = {
  firstName?: string
  permanentRecommendation?: boolean
}
export const ScheduleRemovalMessage: FC<RemoveUserFormProps> = ({
  firstName,
  permanentRecommendation
}) => {
  const firstDuration = permanentRecommendation ? 'permanently' : 'temporarily'
  const secondDuration = permanentRecommendation ? 'temporary' : 'permanent'
  const locationSuggestion = permanentRecommendation ? '"Manage Benefits"' : '"Terminate Benefits"'

  return (
    <FlexColumn gap={spacingSM} style={{ padding: spacingXS, paddingBottom: 0 }}>
      <ThinDarkGreenText style={{ maxWidth: 500 }}>
        Any eligible purchases made on or before the selected date will be counted toward{' '}
        {firstName}’s allowance and be reimbursable.
      </ThinDarkGreenText>
      <ThinDarkGreenText style={{ maxWidth: 500 }}>
        {firstName} will still be able to login to see past purchases and reimbursements, but will
        not be eligible to receive reimbursements for following months. Any connected cards and
        direct deposit accounts will be deleted within 60 days from our systems.
      </ThinDarkGreenText>
      <ThinDarkGreenText style={{ maxWidth: 500 }}>
        <b>Recommended for:</b> {firstDuration} removing {firstName} from receiving benefits. For{' '}
        {secondDuration} removal, instead select {locationSuggestion}.
      </ThinDarkGreenText>
    </FlexColumn>
  )
}
