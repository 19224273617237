import { TextSpan } from 'Components/TextSpan'
import { elfGreen } from 'Utils/styles/colors'

export const AlertReload = ({ onClick }: { onClick: () => void }) => {
  return (
    <div>
      A new version of JOON is available. Please save your changes and{' '}
      <span onClick={onClick}>
        <TextSpan color={elfGreen}>reload the page</TextSpan>
      </span>
      .
    </div>
  )
}
