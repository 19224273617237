import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { QueryNames } from 'Hooks/api/queryNames'
import { AppNotification } from 'Utils/types/appNotification'
import { deleteUserStripeCustomer } from 'Services/JOONAdmin/joonAdminUsersService'

export const useDeleteUserStripeCustomerQuery = () => {
  const queryClient = useQueryClient()

  return useMutation([QueryNames.DeleteUserStripeCustomer], deleteUserStripeCustomer, {
    onSuccess: (response) => {
      if (response.success) {
        queryClient.invalidateQueries([QueryNames.UsersForEmployer])
        toast.success(AppNotification.DeleteStripeAccountSuccess)
      } else {
        toast.error(`${AppNotification.DeleteStripeAccountFailure} ${response.message}`)
      }
    },
    onError: () => {
      toast.error(`${AppNotification.DeleteStripeAccountFailure}`)
    }
  })
}
