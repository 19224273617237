import { PageRoutes } from 'Utils/types/pageRoutes'

export const addAxiosInterceptors = (api) => {
  api.interceptors.request.use((config) => {
    // Don't send headers out to others
    if (config.url && config.url[0] != '/' && !config.url.includes(config.baseURL)) {
      delete config.headers['User-Timezone']
    }

    return config
  })

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401 || error.response?.status === 403) {
        window.location.replace(window.location.origin + PageRoutes.ForcedLogout)
      }
      return Promise.reject(error)
    }
  )
}
