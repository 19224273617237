import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { ModalBody } from '../ModalBody'
import { ButtonContainer } from 'Utils/styles/spacing'
import { StyledButton } from 'Components/Buttons/StyledButton'
import { StyledSelect } from 'Components/Inputs/StyledSelect'

import { usePeoplePageContext } from 'Contexts/PeoplePageContext'
import { useCountries } from 'Hooks/useCountries'
import { useUpdateUserCountryQuery } from 'Hooks/api/JOONAdmin/useUpdateUserCountryQuery'
import { UserDetailsForEmployer } from 'Utils/types/user'

type UpdateUserCountryFormProps = {
  user?: UserDetailsForEmployer
}
export const UpdateUserCountryForm: FC<UpdateUserCountryFormProps> = ({ user }) => {
  const { countryOptions } = useCountries()
  const { setModal } = usePeoplePageContext()
  const { mutateAsync, isLoading } = useUpdateUserCountryQuery()

  const { handleSubmit } = useForm()
  const handleCountryUpdate = async () => {
    if (selectedCountryChange === user?.countryCode) return

    if (user?.id && selectedCountryChange) {
      await mutateAsync({ userId: user.id, countryCode: selectedCountryChange })
      setModal(undefined)
    }
  }

  const [selectedCountryChange, setSelectedCountryChange] = useState(user?.countryCode)

  return (
    <form onSubmit={handleSubmit(handleCountryUpdate)}>
      <ModalBody>
        <StyledSelect
          disabled={isLoading}
          options={countryOptions}
          defaultValue={selectedCountryChange}
          handleChange={(value) => setSelectedCountryChange(value)}
        />
      </ModalBody>
      <ButtonContainer>
        <StyledButton primary id="users.updateCountry" disabled={isLoading} />
      </ButtonContainer>
    </form>
  )
}
