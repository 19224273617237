import React, { CSSProperties } from 'react'
import { CheckboxContainer } from './CheckboxContainer'
import { StyledCheckbox } from './StyledCheckbox'
import styled, { css } from 'styled-components'

export type CheckboxProps = {
  labelText: string
  defaultValue?: boolean
  containerStyle?: CSSProperties
  value?: boolean
  onClick?: () => void
  disabled?: boolean
}

const Label = styled.label<{ $clickable?: boolean }>`
  ${({ $clickable }) =>
    $clickable &&
    css`
      cursor: pointer;
    `}
`

export const Checkbox = ({
  labelText,
  containerStyle,
  value = false,
  onClick,
  disabled = false
}: CheckboxProps) => {
  const handleToggle = () => {
    if (disabled) return
    onClick && onClick()
  }

  return (
    <CheckboxContainer
      style={containerStyle}
      onClick={handleToggle}
      $clickable={!!onClick && !disabled}
    >
      <StyledCheckbox
        key={Math.random()}
        type="checkbox"
        defaultChecked={value}
        errorPresent={false}
        disabled={disabled}
      />
      <Label $clickable={!!onClick && !disabled}>{labelText}</Label>
    </CheckboxContainer>
  )
}
