import styled from 'styled-components'
import { useMemo } from 'react'

import { Modal } from '../Modal'
import { RemoveUserForm } from 'Components/Modal/RemoveUserModal/RemoveUserForm'
import { ModalHeader } from 'Components/Modal/ModalHeader'

import { ModalType, usePeoplePageContext } from 'Contexts/PeoplePageContext'
import { useEmployerUsersContext } from 'Contexts/EmployerUsersContext'

import { FlexColumn, ScrollableFormContainer, spacingMD, spacingSM } from 'Utils/styles/spacing'
import { MediumBoldText, ThinDarkGreenText } from 'Utils/styles/text'
import { isEmployerAdminOnly } from 'Utils/helpers/user'

const StyledHeader = styled(ModalHeader)`
  display: flex;
  gap: ${spacingSM};
`

export const RemoveUserModal = () => {
  const {
    state: { modal, actionableUserId },
    setModal
  } = usePeoplePageContext()

  const {
    state: { users }
  } = useEmployerUsersContext()

  const user = useMemo(() => {
    return users.find((user) => user.id == actionableUserId)
  }, [users, actionableUserId])

  const setShowModal = (show: boolean) => setModal(show ? ModalType.RemoveUser : undefined)
  const headerText = isEmployerAdminOnly(user?.roles)
    ? 'Terminate Benefits/Admin'
    : 'Terminate Benefits'

  return (
    <Modal showModal={modal === ModalType.RemoveUser} setShowModal={setShowModal}>
      <ScrollableFormContainer gap={spacingMD}>
        <StyledHeader>{headerText}</StyledHeader>

        <FlexColumn>
          <MediumBoldText>
            {user?.userInfo.firstName} {user?.userInfo.lastName}
          </MediumBoldText>
          <ThinDarkGreenText>{user?.userInfo.email}</ThinDarkGreenText>
        </FlexColumn>

        <RemoveUserForm user={user} />
      </ScrollableFormContainer>
    </Modal>
  )
}
