import styled from 'styled-components'
import { UserRole } from 'Utils/types/roles'
import {
  electricViolet,
  electricVioletFaded,
  elfGreen,
  elfGreenFaded,
  frostedGlass,
  towerGray
} from 'Utils/styles/colors'
import { spacingXS, spacingXXS } from 'Utils/styles/spacing'
import { useMemo } from 'react'
import { getGlobalAdminRole } from 'Utils/helpers/user'

const displayConfig = {
  [UserRole.EMPLOYERADMIN]: {
    label: 'Admin',
    backgroundColor: elfGreenFaded,
    textColor: elfGreen
  },
  [UserRole.PROGRAMADMIN]: {
    label: 'Admin',
    backgroundColor: electricVioletFaded,
    textColor: electricViolet
  },
  [UserRole.FINANCEADMIN]: {
    label: 'Admin',
    backgroundColor: electricVioletFaded,
    textColor: electricViolet
  },
  [UserRole.EMPLOYEE]: {
    label: 'None',
    backgroundColor: frostedGlass,
    textColor: towerGray
  },
  [UserRole.JOONENGINEER]: {
    label: 'Joon Engineer',
    backgroundColor: electricVioletFaded,
    textColor: electricViolet
  }
}

const TagList = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacingXS};
  flex-wrap: wrap;
`

const Tag = styled.span`
  border-radius: ${spacingXXS};
  padding: ${spacingXXS} ${spacingXS};
  line-height: 110%;
  background-color: ${({ role }: { role: UserRole }) => displayConfig[role].backgroundColor};
  color: ${({ role }: { role: UserRole }) => displayConfig[role].textColor};
`

export const PermissionsTags = ({ userRoles = [] }: { userRoles: UserRole[] }) => {
  const displayRoles = useMemo(() => {
    const globalAdmin = getGlobalAdminRole(userRoles)
    if (globalAdmin) return [globalAdmin]

    return userRoles.length === 1
      ? userRoles
      : userRoles.filter((r) => r !== UserRole.EMPLOYEE && r !== UserRole.JOONENGINEER)
  }, [userRoles])

  return (
    <TagList>
      {displayRoles.length ? (
        displayRoles.map((role) => (
          <Tag key={role} role={role}>
            {displayConfig[role].label}
          </Tag>
        ))
      ) : (
        <Tag key={UserRole.EMPLOYEE} role={UserRole.EMPLOYEE}>
          {displayConfig[UserRole.EMPLOYEE].label}
        </Tag>
      )}
    </TagList>
  )
}
