import { useMutation, useQueryClient } from 'react-query'
import { useErrorReporter } from 'Hooks/useErrorReporter'
import { QueryNames } from 'Hooks/api/queryNames'
import { usePeoplePageContext } from 'Contexts/PeoplePageContext'
import { inviteUser } from 'Services/users/userService'
import { toast } from 'react-toastify'
import { AppNotification } from 'Utils/types/appNotification'

export const useInviteUserQuery = () => {
  const reportError = useErrorReporter()
  const queryClient = useQueryClient()

  const { setModal } = usePeoplePageContext()

  return useMutation([QueryNames.InviteUser], inviteUser, {
    onSuccess: (response) => {
      if (response.success) {
        // TODO: Ideally we'd like to update the user list here. We don't know the user ID though.
        setModal(undefined)
        toast.success(AppNotification.UserInviteSuccess)
      } else {
        toast.error(
          `${AppNotification.UserInviteFailure} ${response.message ? response.message + '.' : ''}`
        )
      }
    },
    onError: (err: string) => {
      reportError(`useInviteUserQuery error: ${err}`)
    },
    onSettled: () => {
      // Invalidate so that we re-fetch when necessary
      queryClient.invalidateQueries([QueryNames.UsersForEmployer])
      queryClient.invalidateQueries([QueryNames.EmployerDashboard])
    }
  })
}
