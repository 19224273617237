import { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { StyledSelect } from 'Components/Inputs/StyledSelect'
import { PolicyPeriodDuration } from 'Utils/types/benefitProgramPolicy'
import { FlexColumn, spacingXXS } from 'Utils/styles/spacing'

import { getSelectOptions } from 'Utils/helpers/dateSelection'
import { parseServerDate } from 'Utils/helpers/dateUtils'

const DateSelectorContainer = styled(FlexColumn)`
  width: 100%;
  gap: ${spacingXXS};
`

type BenefitProgramEndDateSelectorProps = {
  onChange: (date: Date) => void
}

export const BenefitProgramEndDateSelector = ({ onChange }: BenefitProgramEndDateSelectorProps) => {
  const placeholderText = 'Select a month'

  const updateSelectedEndDate = (selectedOption: string) => {
    const selectedDate = parseServerDate(selectedOption)

    onChange(selectedDate)
  }

  const selectOptions = useMemo(() => getSelectOptions(PolicyPeriodDuration.Monthly, false), [])

  return (
    <DateSelectorContainer>
      <FormattedMessage id="createBenefitProgram.scheduledEndDate.label" />
      <StyledSelect
        options={selectOptions}
        handleChange={updateSelectedEndDate}
        placeholderText={placeholderText}
      />
    </DateSelectorContainer>
  )
}
