import { useState } from 'react'

export enum LocalStorageKeys {
  UserId = 'userId',
  EmployerId = 'employerId',
  Roles = 'roles',
  VerificationCode = 'verificationCode',
  RegistrationInfo = 'registrationInfo',
  PurchaseAccountProvider = 'purchaseAccountProvider'
}
export const useLocalStorage = <T>(key: LocalStorageKeys, initialValue: T) => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === 'undefined') {
      return initialValue
    }
    try {
      const item = window.localStorage.getItem(key)
      return item && item !== 'undefined' ? JSON.parse(item) : initialValue
    } catch (error) {
      console.log(error)
      return initialValue
    }
  })

  const setValue = (value: T | ((val: T) => T)) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value

      setStoredValue(valueToStore)

      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(valueToStore))
      }
    } catch (error) {
      // TODO: Handle error
    }
  }
  return [storedValue, setValue] as const
}
