import { useMutation } from 'react-query'
import { useErrorReporter } from 'Hooks/useErrorReporter'

import { QueryNames } from 'Hooks/api/queryNames'

import { removeUser } from 'Services/users/userService'
import { toast } from 'react-toastify'
import { AppNotification } from 'Utils/types/appNotification'
import { useEmployerUsersContext, EmployerActionTypes } from 'Contexts/EmployerUsersContext'
import { usePeoplePageContext } from 'Contexts/PeoplePageContext'

export const useRemoveUserQuery = () => {
  const reportError = useErrorReporter()

  const { dispatch } = useEmployerUsersContext()

  const { setModal, setActionableUserId } = usePeoplePageContext()

  return useMutation([QueryNames.RemoveUser], removeUser, {
    onSuccess: (response) => {
      if (response.userId) {
        dispatch({ type: EmployerActionTypes.RemoveUser, value: response.userId })

        setModal(undefined)
        setActionableUserId(undefined)
        toast.success(AppNotification.UserRemovalSuccess)
      } else {
        toast.error(AppNotification.UserRemovalFailed)
      }
    },
    onError: (err: string) => {
      reportError(`useRemoveUserQuery error: ${err}`)
    }
  })
}
