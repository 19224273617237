import React, { ChangeEvent, FC } from 'react'
import { TablePagination } from '@mui/material'

type PaginationProps = {
  optionText: string
  totalRows: number
  rowsPerPage: number
  page: number
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void
  handleChangeRowsPerPage: (event: ChangeEvent<HTMLInputElement>) => void
}
export const Pagination: FC<PaginationProps> = ({
  optionText,
  totalRows,
  rowsPerPage,
  page,
  handleChangeRowsPerPage,
  handleChangePage
}) => (
  <TablePagination
    rowsPerPageOptions={[
      { value: 10, label: `10 ${optionText}` },
      { value: 25, label: `25 ${optionText}` },
      { value: 50, label: `50 ${optionText}` }
    ]}
    component="div"
    labelRowsPerPage={''}
    labelDisplayedRows={({ from, to, count }) =>
      `Showing ${from} to ${to} of ${count} ${optionText}`
    }
    count={totalRows}
    rowsPerPage={rowsPerPage}
    page={totalRows === 0 ? 0 : page}
    onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
  />
)
