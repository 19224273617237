import { FC } from 'react'
import styled from 'styled-components'
import { Controller } from 'react-hook-form'

import { FormTextInput } from 'Components/Inputs/FormTextInput'
import { StyledButton } from 'Components/Buttons/StyledButton'
import { FormDatePicker } from 'Components/Inputs/Calendar/FormDatePicker'

import { useEmployerInfoContext } from 'Contexts/EmployerInfoContext'
import { FlexRow, spacingLG, spacingSM } from 'Utils/styles/spacing'
import { CurrencyCode } from 'Utils/types/currencies'

import { useAllowanceForm } from 'Pages/JOONAdmin/EmployerDetailsPage/ManageUserAllowances/useAllowanceForm'

const FormContainer = styled.div`
  display: flex;
  margin: ${spacingLG} ${spacingSM};
  gap: ${spacingSM};
`

const FormFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;
  gap: ${spacingSM};
`

const FormFieldsFirstRow = styled.div`
  display: flex;
  gap: ${spacingSM};
`

const FormFieldsSecondRow = styled.div``

const FormButtonContainer = styled(FlexRow)`
  flex: 1;
  align-items: flex-end;
`

type AddAllowanceFormProps = {
  userId: number
  benefitProgramId: number
  currencyCode: CurrencyCode
}

export const AddAllowanceForm: FC<AddAllowanceFormProps> = ({
  userId,
  benefitProgramId,
  currencyCode
}) => {
  const {
    state: { invoices }
  } = useEmployerInfoContext()

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    errors,
    earliestAllowanceStartDate,
    registerOptions,
    currencySymbol,
    handleSubmission,
    handleError,
    isLoading
  } = useAllowanceForm({ currencyCode, invoices, userId, benefitProgramId })

  const startDate = watch('start')
  const endDate = watch('end')

  return (
    <form onSubmit={handleSubmit(handleSubmission, handleError)}>
      <FormContainer>
        <FormFieldsContainer>
          <FormFieldsFirstRow>
            <FormTextInput
              fieldName="amount"
              labelId="manageUserAllowancesModal.amount.label"
              errorPresent={!!errors?.amount?.message}
              register={register}
              registerOptions={registerOptions.amount}
              defaultValue={`${currencySymbol} `}
            />
            <Controller
              control={control}
              name="start"
              rules={registerOptions.start}
              render={({ field: { onChange, value } }) => (
                <FormDatePicker
                  labelId="manageUserAllowancesModal.start.label"
                  minDate={earliestAllowanceStartDate}
                  maxDate={endDate}
                  date={value}
                  onChange={onChange}
                  disabled={false}
                  hasError={!!errors.start}
                />
              )}
            />
            <FormDatePicker
              labelId="manageUserAllowancesModal.end.label"
              minDate={startDate || earliestAllowanceStartDate}
              date={endDate}
              onChange={(date) => {
                if (date.getTime() === endDate?.getTime()) {
                  setValue('end', undefined)
                  return
                }
                setValue('end', date)
              }}
              disabled={false}
            />
          </FormFieldsFirstRow>
          <FormFieldsSecondRow>
            <FormTextInput
              fieldName="reason"
              labelId="manageUserAllowancesModal.reason.label"
              errorPresent={!!errors?.reason?.message}
              register={register}
              registerOptions={registerOptions.reason}
            />
          </FormFieldsSecondRow>
        </FormFieldsContainer>
        <FormButtonContainer>
          <StyledButton primary id="manageUserAllowancesModal.submit.label" isLoading={isLoading} />
        </FormButtonContainer>
      </FormContainer>
    </form>
  )
}
