import styled from 'styled-components'
import { elfGreen, elfGreenHover, red, towerGray, white, zirconGray } from 'Utils/styles/colors'
import checkMark from 'Assets/white-checkmark.svg'

const CheckboxCheckedImage = `
  background-image: url(${checkMark});
  background-position: center;
  background-repeat: no-repeat;
  background-origin: content-box;
`

export const StyledCheckbox = styled.input<{ errorPresent: boolean }>`
  cursor: pointer;
  appearance: none;
  background-color: ${white};
  margin: 0;

  border: 1.5px solid ${(props) => (props.errorPresent ? red : zirconGray)};
  height: 24px;
  min-width: 24px;
  border-radius: 4px;

  &:hover {
    border: 1.5px solid ${towerGray};
  }

  &:checked {
    border: 1.5px solid ${elfGreen};
    background-color: ${elfGreen};
    ${CheckboxCheckedImage}

    &:hover {
      background-color: ${elfGreenHover};
    }
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${zirconGray};
    border-color: ${towerGray};

    &:hover {
      border: 1.5px solid ${towerGray};
    }

    &:checked {
      background-color: ${zirconGray};
      border-color: ${towerGray};
      background-image: none;
    }
  }
`
