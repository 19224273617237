import { FormattedNumberParts } from 'react-intl'
import { CurrencyCode, CurrencySymbol, isValidCurrencyCode } from 'Utils/types/currencies'

type CurrencyPartsProps = {
  value: number
  currencyCode: CurrencyCode
  fractionalDigitCount: number
}

export const CurrencyFormattedParts = ({
  value,
  currencyCode,
  fractionalDigitCount
}: CurrencyPartsProps) => (
  <FormattedNumberParts
    value={value}
    style="currency"
    currency={currencyCode}
    maximumFractionDigits={fractionalDigitCount}
    minimumFractionDigits={fractionalDigitCount}
  >
    {(parts) => (
      <span>
        {parts.map((part) => {
          if (part.type === 'currency' && isValidCurrencyCode(part.value)) {
            // Plug in symbol if locale doesn't support currency
            return CurrencySymbol[part.value as CurrencyCode]
          }
          return part.value
        })}
      </span>
    )}
  </FormattedNumberParts>
)
