import { useState, useMemo } from 'react'
import { toast } from 'react-toastify'
import { useForm, RegisterOptions, SubmitErrorHandler, SubmitHandler } from 'react-hook-form'

import { FormTextInput } from 'Components/Inputs/FormTextInput'
import { StyledButton } from 'Components/Buttons/StyledButton'
import {
  ButtonContainer,
  FlexColumn,
  spacingMD,
  spacingSM,
  FormContainer
} from 'Utils/styles/spacing'
import { Checkbox } from 'Components/Inputs/Checkbox/Checkbox'
import { FormDatePicker } from 'Components/Inputs/Calendar/FormDatePicker'
import { InviteUserCountrySelect } from './InviteUserCountrySelect'
import { InviteUserProgramSelect } from './InviteUserProgramSelect'

import { useEmployerProgramsContext } from 'Contexts/EmployerProgramsContext'
import { useEmployerInfoContext } from 'Contexts/EmployerInfoContext'

import { useInviteUserQuery } from 'Hooks/api/employer/userManagement/useInviteUserQuery'
import { useUpdateUserProgram } from 'Hooks/useUpdateUserProgram'
import { useCountries } from 'Hooks/useCountries'

import { UserRole } from 'Utils/types/roles'
import { emailRegex } from 'Utils/helpers/userInfo'
import { EmployerBenefitProgram } from 'Utils/types/benefitProgram'
import { endOfToday } from 'date-fns'
import { formatDateForServer, createDate } from 'Utils/helpers/dateUtils'
import { ModalBody } from '../ModalBody'
import { ModalHeader } from 'Components/Modal/ModalHeader'

type ProgramStartDatesById = {
  [key: EmployerBenefitProgram['id']]: {
    scheduledStartDate: Date
  }
}

type InviteUserValues = {
  email: string
  firstName: string
  lastName: string
  inviteDate: Date
  hasAdminRole: boolean
  programStartDates: ProgramStartDatesById
}

export const InviteUserForm = () => {
  const {
    state: { programs }
  } = useEmployerProgramsContext()

  const {
    state: { employerId, employerInfo }
  } = useEmployerInfoContext()

  const { hasMultipleCountries, countryOptions } = useCountries()

  // Sets a default state ONLY if user has ONE (1) country
  const [selectedCountry, setSelectedCountry] = useState(
    countryOptions.length === 1 ? countryOptions[0].value : undefined
  )

  const {
    programChanges,
    filteredPrograms,
    toggleProgram,
    setProgramChangeDate,
    programStartDate,
    programMinStartDate,
    isSelected
  } = useUpdateUserProgram(programs, selectedCountry)

  const inviteInfo = useMemo(() => {
    return {
      hasAdminRole: false,
      inviteDate: createDate(),
      programStartDates: programs.reduce<ProgramStartDatesById>((programStartDates, program) => {
        return { ...programStartDates, [program.id]: { scheduledStartDate: createDate() } }
      }, {}),
      countryCode: employerInfo?.countries[0] || null
    }
  }, [programs, employerInfo])

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors }
  } = useForm<InviteUserValues>({
    defaultValues: inviteInfo
  })

  const registerOptions: { [key: string]: RegisterOptions } = {
    email: {
      required: 'Email is required',
      pattern: {
        value: emailRegex,
        message: 'Email does not meet requirements.'
      }
    },
    firstName: { required: 'First name is required' },
    lastName: { required: 'Last name is required' }
  }

  const inviteUserQuery = useInviteUserQuery()

  const handleInvite: SubmitHandler<InviteUserValues> = (data) => {
    const benefitPrograms = programChanges
      .filter((p) => p.enabled)
      .map((p) => ({
        id: p.id,
        scheduledStartDate: formatDateForServer(p.dateOfChange)
      }))

    if (Object.keys(benefitPrograms).length === 0 && !data.hasAdminRole) {
      toast.error('Changes must be made in order to save.')
      return
    }
    if (!selectedCountry) {
      toast.error('You must select a country.')
      return
    }

    const user = {
      name: `${data.firstName} ${data.lastName}`,
      email: data.email,
      inviteDate: formatDateForServer(data.inviteDate),
      benefitPrograms,
      roleName: data.hasAdminRole ? UserRole.EMPLOYERADMIN : undefined,
      countryCode: selectedCountry
    }

    if (employerId) inviteUserQuery.mutate({ employerId: employerId, users: [user] })
  }

  const handleError: SubmitErrorHandler<InviteUserValues> = (error) => {
    toast.error(error.email?.message ?? error.firstName?.message ?? error.lastName?.message)
  }

  const inviteDate = watch('inviteDate')

  const inviteText =
    inviteDate < endOfToday() ? 'users.schedule.inviteNow' : 'users.schedule.invite'

  return (
    <FormContainer onSubmit={handleSubmit(handleInvite, handleError)} gap={spacingMD}>
      <ModalHeader>Invite User</ModalHeader>
      <ModalBody maxHeight="calc(80vh - 150px)">
        <FormTextInput
          fieldName="email"
          errorPresent={!!errors?.email?.message}
          labelId="email.label"
          register={register}
          registerOptions={registerOptions.email}
        />
        <FormTextInput
          fieldName="firstName"
          errorPresent={!!errors?.firstName?.message}
          labelId="firstName.label"
          register={register}
          registerOptions={registerOptions.firstName}
        />
        <FormTextInput
          fieldName="lastName"
          errorPresent={!!errors?.lastName?.message}
          labelId="lastName.label"
          register={register}
          registerOptions={registerOptions.lastName}
        />
        <FormDatePicker
          labelId="users.invite.inviteDate"
          date={inviteDate}
          disabled={false}
          placeholder="Invite date"
          onChange={(date) => setValue('inviteDate', date)}
          inputInformation={{
            cardText:
              'Users will receive the invite to set up their account on the selected date at 2:00 UTC.'
          }}
        />
        {hasMultipleCountries && (
          <InviteUserCountrySelect
            countryOptions={countryOptions}
            handleChange={(value) => {
              setSelectedCountry(value)
            }}
          />
        )}
        <InviteUserProgramSelect
          programs={filteredPrograms}
          isCountrySelected={!!selectedCountry}
          toggleProgram={toggleProgram}
          setProgramChangeDate={setProgramChangeDate}
          programStartDate={programStartDate}
          programMinStartDate={programMinStartDate}
          isSelected={isSelected}
        />
        {/* Select admin permissions section */}
        <FlexColumn gap={spacingSM}>
          Admin Permissions
          <Checkbox
            labelText="Allow management of users, benefits, and billing information."
            value={getValues('hasAdminRole')}
            onClick={() => setValue('hasAdminRole', !getValues('hasAdminRole'))}
          />
        </FlexColumn>
      </ModalBody>

      <ButtonContainer>
        <StyledButton primary id={inviteText} isLoading={inviteUserQuery.isLoading} />
      </ButtonContainer>
    </FormContainer>
  )
}
