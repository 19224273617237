import { postRequest } from 'Services/baseRequests/postRequest'
import { RequestResponse } from 'Services/apiResponseTypes'

export type BenefitProgramReceiptDownloadPayload = {
  benefitProgramId: number
  userId: number
  startDate: string
  endDate: string
}

type BenefitProgramReceiptDownloadApiPayload = {
  user_id: string
  start_date: string
  end_date: string
}

export const benefitProgramReceiptDownload = async ({
  benefitProgramId,
  userId,
  startDate,
  endDate
}: BenefitProgramReceiptDownloadPayload): Promise<RequestResponse> => {
  const response = await postRequest<RequestResponse, BenefitProgramReceiptDownloadApiPayload>(
    `/admin/benefit_programs/${benefitProgramId}/fetch_program_receipts`,
    { user_id: userId.toString(), start_date: startDate, end_date: endDate }
  )

  return {
    success: response?.success ?? false,
    message: ''
  }
}
