import { FC } from 'react'
import {
  GearIcon,
  CardStackPlusIcon,
  LockClosedIcon,
  PaperPlaneIcon,
  Pencil2Icon,
  PersonIcon,
  TrashIcon,
  GlobeIcon,
  ReloadIcon,
  LockOpen1Icon
} from '@radix-ui/react-icons'
import Ghost from 'Assets/ghost.svg'

export enum PeopleAction {
  ManagePermissions = 'Manage Permissions',
  ManageBenefits = 'Manage Benefits',
  TerminateBenfits = 'Terminate Benefits',
  UnlockAccount = 'Unlock Account',
  ReEnableBenefits = 'Re-enable User',
  ResendInvite = 'Resend Invite',
  CancelInvite = 'Cancel Invite',
  ManageUserAllowances = 'Manage Allowances',
  ImpersonateUser = 'Impersonate User',
  StripeAccountDelete = 'Delete Stripe Account',
  UpdateCountry = 'Update Country',
  TerminateBenfitsAndAdmin = 'Terminate Benefits/Admin',
  ResetPurchaseAccount = 'Reset Renew Date (Truelayer)',
  ViewEnrollmentHistory = 'View Enrollment History'
}

export enum ActionType {
  UserModifications = 'User Modifications',
  ProgramModifications = 'Program Modifications'
}

export const getActionIcon = (action: PeopleAction | ActionType) => {
  switch (action) {
    case ActionType.UserModifications:
    case ActionType.ProgramModifications:
      return <GearIcon />
    case PeopleAction.ManagePermissions:
      return <LockClosedIcon />
    case PeopleAction.ManageBenefits:
      return <Pencil2Icon />
    case PeopleAction.StripeAccountDelete:
    case PeopleAction.TerminateBenfits:
    case PeopleAction.TerminateBenfitsAndAdmin:
    case PeopleAction.CancelInvite:
      return <TrashIcon />
    case PeopleAction.ReEnableBenefits:
    case PeopleAction.ViewEnrollmentHistory:
      return <PersonIcon />
    case PeopleAction.ResendInvite:
      return <PaperPlaneIcon />
    case PeopleAction.ManageUserAllowances:
      return <CardStackPlusIcon />
    case PeopleAction.UpdateCountry:
      return <GlobeIcon />
    case PeopleAction.ResetPurchaseAccount:
      return <ReloadIcon />
    case PeopleAction.UnlockAccount:
      return <LockOpen1Icon />
    case PeopleAction.ImpersonateUser:
      return <img src={Ghost} alt={'spooky'} />
    default:
      return null
  }
}

type ActionLabelProps = {
  action: PeopleAction | ActionType
}
export const ActionLabel: FC<ActionLabelProps> = ({ action }) => {
  return (
    <>
      {getActionIcon(action)}
      {action}
    </>
  )
}
