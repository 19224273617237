import styled from 'styled-components'

import { TextSpan } from 'Components/TextSpan'

import {
  fontSizeSM,
  fontSizeXL,
  fontWeightBold,
  fontWeightNormal,
  telegrafFont
} from 'Utils/styles/text'
import { PolicyPeriodDurationDisplay } from 'Utils/types/benefitProgramPolicy'
import { electricViolet } from 'Utils/styles/colors'
import { spacingXXS } from 'Utils/styles/spacing'
import { CurrencyText } from './Text/CurrencyText'
import {
  EmployerBenefitProgram,
  UserBenefitProgram,
  isEmployerBenefitProgram
} from 'Utils/types/benefitProgram'

const AmountContainer = styled.div`
  color: ${electricViolet};
  font-size: ${fontSizeXL};
  font-weight: ${fontWeightBold};
  display: flex;
  align-items: center;
  gap: ${spacingXXS};
`

const Allowance = styled.span`
  ${telegrafFont};
`

export const ProgramAmount = ({
  program
}: {
  program: UserBenefitProgram | EmployerBenefitProgram
}) => {
  const isEmployerBP = isEmployerBenefitProgram(program)
  const policy = isEmployerBP ? program.policy : program.membership

  const allowance = isEmployerBP ? policy.expenditureAllowance : program.currentPeriodAllowance

  return (
    <AmountContainer>
      <Allowance>
        <CurrencyText value={allowance} currencyCode={program.currencyCode} showMinorUnit={false} />
      </Allowance>
      <TextSpan fontWeight={fontWeightNormal} fontSize={fontSizeSM}>
        {' '}
        / {PolicyPeriodDurationDisplay.get(policy.policyPeriodDuration)}
      </TextSpan>
    </AmountContainer>
  )
}
