import React, { FC, useCallback } from 'react'
import styled from 'styled-components'
import { DownloadIcon } from '@radix-ui/react-icons'

import {
  DropdownContent,
  DropdownItem,
  DropdownRoot,
  DropdownTrigger
} from 'Components/DropdownMenu/DropdownMenu'

import { useDownloadBenefitRecipientsOverviewReport } from 'Hooks/api/JOONAdmin/useDownloadBenefitRecipientsOverviewReport'

import { spacingLG, spacingXXS } from 'Utils/styles/spacing'
import { useJoonAdminContext } from 'Contexts/JOONAdmin/JOONAdminContext'
import { useDownloadPurchasesReport } from 'Hooks/api/JOONAdmin/useDownloadPurchasesReport'
import { BaseText } from 'Utils/styles/text'
import { elfGreen, elfGreenActive, elfGreenHover, white } from 'Utils/styles/colors'
import { useDateSelectionModal } from 'Hooks/modals/useDateSelectionModal'

const Container = styled.div`
  display: flex;
  justify-content: center;
  gap: ${spacingXXS};
`

const Trigger = styled.div`
  ${BaseText};
  position: relative;
  height: 48px;
  padding: 0 ${spacingLG};

  border-radius: 8px;

  cursor: pointer;
  background-color: ${elfGreen};
  color: ${white};
  &:hover,
  &:focus {
    outline: none;
    background-color: ${elfGreenHover};
  }
  &:active {
    background-color: ${elfGreenActive};
  }

  display: flex;
  justify-content: center;
  align-items: center;
`

export const EmployerReportsMenuButton: FC = () => {
  const {
    state: { selectedEmployer }
  } = useJoonAdminContext()

  const { showModal } = useDateSelectionModal()

  const downloadBenefitsRecipientsOverviewReport = useDownloadBenefitRecipientsOverviewReport()
  const downloadPurchasesReport = useDownloadPurchasesReport()

  const handleDownloadBenefitRecipientsOverview = useCallback(async () => {
    if (!selectedEmployer) return

    const selectedDate = await showModal('Benefits Recipients Overview - Select Month', '')

    if (!selectedDate) return

    const day = selectedDate.getDate()
    const month = selectedDate.getUTCMonth() + 1
    const year = selectedDate.getUTCFullYear()

    downloadBenefitsRecipientsOverviewReport.mutate({
      employerId: selectedEmployer.id,
      day,
      month,
      year
    })
  }, [selectedEmployer, showModal, downloadBenefitsRecipientsOverviewReport])

  const handleDownloadPurchasesReport = useCallback(async () => {
    if (!selectedEmployer) return

    const selectedDate = await showModal('Purchase Report - Select Date', '')

    if (!selectedDate) return

    downloadPurchasesReport.mutate({ employerId: selectedEmployer.id, reportDate: selectedDate })
  }, [selectedEmployer, showModal, downloadPurchasesReport])

  return (
    <Container>
      <DropdownRoot>
        <DropdownTrigger>
          <Trigger>Reports</Trigger>
        </DropdownTrigger>

        <DropdownContent>
          <DropdownItem onSelect={() => handleDownloadBenefitRecipientsOverview()}>
            <DownloadIcon />
            Benefit Recipients Overview
          </DropdownItem>
          <DropdownItem onSelect={() => handleDownloadPurchasesReport()}>
            <DownloadIcon />
            Purchases Report
          </DropdownItem>
        </DropdownContent>
      </DropdownRoot>
    </Container>
  )
}
