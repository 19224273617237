import React, { useMemo, useState } from 'react'
import { Controller, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form'
import { Autocomplete, TextField } from '@mui/material'
import { Merchant } from 'Utils/types/JOONAdmin/merchant'
import { toast } from 'react-toastify'
import { FlexRow, spacingLG, spacingMD, spacingSM } from 'Utils/styles/spacing'
import { StyledButton } from 'Components/Buttons/StyledButton'
import { ModalBody } from 'Components/Modal/ModalBody'
import { ControlledSelect } from 'Components/Inputs/ControlledSelect'
import styled from 'styled-components'
import { DarkGreenText, fontSizeXXS, MediumBoldText, telegrafFont } from 'Utils/styles/text'
import { frostedGlass } from 'Utils/styles/colors'
import { useConfirmationModalContext } from 'Hooks/modals/useConfirmationModal'
import { useAddMerchantToProgramQuery } from 'Hooks/api/JOONAdmin/useAddMerchantToProgramQuery'
import { EmployerBenefitProgram } from 'Utils/types/benefitProgram'
import { useRemoveMerchantFromAllowListQuery } from 'Hooks/api/JOONAdmin/useRemoveMerchantFromAllowListQuery'
import { useRemoveMerchantFromBlockListQuery } from 'Hooks/api/JOONAdmin/useRemoveMerchantFromBlockListQuery'
import { useGetMerchantsQuery } from 'Hooks/api/JOONAdmin/useGetMerchantsQuery'
import { useGetBenefitProgramMerchantsQuery } from 'Hooks/api/JOONAdmin/useGetBenefitProgramMerchantsQuery'
import { ComponentLoading } from 'Components/CircularProgress/ComponentLoading'
import debounce from 'lodash.debounce'

type MerchantFormProps = {
  programId: EmployerBenefitProgram['id']
  allowList?: Merchant[]
  blockList?: Merchant[]
}

enum ProgramAction {
  Allow = 'Allow',
  Block = 'Block'
}

const actionOptions = [
  { label: ProgramAction.Allow, value: ProgramAction.Allow },
  { label: ProgramAction.Block, value: ProgramAction.Block }
]

export type MerchantFormValues = {
  selectedMerchant?: Merchant['id']
  merchantName?: string
}

const ListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  gap: 1%;
`

const Column = styled.div`
  flex: 1;
  width: 49%;
  background-color: ${frostedGlass};
  padding: ${spacingSM};
  border-radius: 8px;
`

const RemoveButton = styled.div`
  margin-top: 3px;
  margin-left: ${spacingMD};
  font-size: ${fontSizeXXS};
  font-weight: bold;
  &:hover {
    cursor: pointer;
  }
`

const Header = styled(MediumBoldText)`
  ${telegrafFont};
`

export const MerchantForm: React.FC<MerchantFormProps> = ({ programId }) => {
  const [action, setAction] = useState<ProgramAction>(ProgramAction.Block)
  const [searchTerm, setSearchTerm] = useState<string>('')

  const debouncedSetSearchTerm = debounce((newTerm) => {
    setSearchTerm(newTerm)
  }, 300)

  const { data: merchants } = useGetMerchantsQuery(searchTerm)
  const { data: benefitProgramMerchants, isLoading: benefitProgramMerchantsLoading } =
    useGetBenefitProgramMerchantsQuery(programId)

  const sortedMerchants = useMemo(() => {
    return merchants?.sort((a, b) => -b.name[0].localeCompare(a.name[0]))
  }, [merchants])

  const { mutate: addMerchantToProgramMutation } = useAddMerchantToProgramQuery()
  const { mutate: removeMerchantFromAllowListMutation } = useRemoveMerchantFromAllowListQuery()
  const { mutate: removeMerchantFromBlockListMutation } = useRemoveMerchantFromBlockListQuery()

  const { showModal } = useConfirmationModalContext()

  const { control, handleSubmit, setValue } = useForm<MerchantFormValues>()

  const handleSubmission: SubmitHandler<MerchantFormValues> = async (data) => {
    const result = await showModal('Create Merchant', 'Are you sure you want add this merchant?')

    if (result) {
      addMerchantToProgramMutation({
        ...data,
        merchantName: data.selectedMerchant ? undefined : searchTerm,
        action,
        programId
      })
    }
  }

  const handleRemoveFromAllowList = (merchantId: Merchant['id']) => {
    removeMerchantFromAllowListMutation({ programId, merchantId })
  }

  const handleRemoveFromBlockList = (merchantId: Merchant['id']) => {
    removeMerchantFromBlockListMutation({ programId, merchantId })
  }

  const handleError: SubmitErrorHandler<MerchantFormValues> = (error) => {
    toast.error(error.selectedMerchant?.message)
  }

  return (
    <ModalBody style={{ padding: `${spacingMD} 0` }}>
      <form onSubmit={handleSubmit(handleSubmission, handleError)}>
        <FlexRow gap={spacingLG}>
          <Controller
            control={control}
            name="selectedMerchant"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Autocomplete
                id="merchant-auto-complete"
                options={sortedMerchants ?? []}
                freeSolo
                groupBy={(option) => option.name[0].toUpperCase()}
                getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                value={merchants?.find((merchant) => merchant.id === value)}
                onChange={(_, newValue) => {
                  if (typeof newValue === 'string') {
                    setValue('merchantName', newValue)
                    setValue('selectedMerchant', undefined)
                  } else {
                    setValue('merchantName', undefined)
                    onChange(newValue?.id)
                  }
                }}
                onInputChange={(_, newInputValue) => {
                  debouncedSetSearchTerm(newInputValue)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Merchants"
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
                sx={{ minWidth: '500px' }}
              />
            )}
          />
          <ControlledSelect
            label=""
            options={actionOptions}
            defaultValue={action}
            setControlValue={setAction}
          />
          <StyledButton primary id="save.label" isLoading={false} />
        </FlexRow>
      </form>
      <ListContainer>
        <Column>
          <Header>Allow List</Header>
          <ul>
            {benefitProgramMerchantsLoading ? (
              <ComponentLoading />
            ) : (
              (benefitProgramMerchants?.allowList || []).map((merchant) => (
                <li key={merchant.id}>
                  <FlexRow>
                    <DarkGreenText>{merchant.name}</DarkGreenText>
                    <RemoveButton onClick={() => handleRemoveFromAllowList(merchant.id)}>
                      REMOVE
                    </RemoveButton>
                  </FlexRow>
                </li>
              ))
            )}
          </ul>
        </Column>

        <Column>
          <Header>Block List</Header>
          <ul>
            {benefitProgramMerchantsLoading ? (
              <ComponentLoading />
            ) : (
              (benefitProgramMerchants?.blockList || []).map((merchant) => (
                <li key={merchant.id}>
                  <FlexRow>
                    <DarkGreenText>{merchant.name}</DarkGreenText>
                    <RemoveButton onClick={() => handleRemoveFromBlockList(merchant.id)}>
                      REMOVE
                    </RemoveButton>
                  </FlexRow>
                </li>
              ))
            )}
          </ul>
        </Column>
      </ListContainer>
    </ModalBody>
  )
}
