import { FC } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import { black, elfGreen } from 'Utils/styles/colors'
import { ExternalLinkRoutes, PageRoutes } from 'Utils/types/pageRoutes'
import { spacingLG } from 'Utils/styles/spacing'

const MainMenu = styled.span`
  display: flex;
  align-items: center;
  gap: ${spacingLG};
`

const MenuLink = styled.a<{ disabled?: boolean }>`
  color: ${black};
  text-decoration: none;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    text-decoration: underline;
  }
  &.current {
    color: ${elfGreen};
  }
`

type MenuItem = {
  title: string
  url?: string
  isExternal?: boolean
  id?: string
}

export const employerMenuItems: MenuItem[] = [
  { title: 'Benefits', url: PageRoutes.EmployerDashboard },
  { title: 'Users', url: PageRoutes.EmployerPeople },
  { title: 'Billing', url: PageRoutes.EmployerBilling },
  { title: 'Resources', url: ExternalLinkRoutes.Resources, isExternal: true }
]

export const joonAdminMenuItems: MenuItem[] = [
  { title: 'Employers', url: PageRoutes.AdminEmployersList },
  { title: 'Reported Purchases', url: PageRoutes.AdminReportedPurchases },
  { title: 'Invoices', url: PageRoutes.AdminInvoicesList }
]

type MenuProps = {
  menuItems: MenuItem[]
}
export const InternalNavigationLinks: FC<MenuProps> = ({ menuItems }) => {
  const navigate = useNavigate()

  const curPath = window.location.pathname

  const handleClick = (item: MenuItem) => {
    if (item.isExternal) {
      window.open(item.url, '_blank')
    } else if (item.url) {
      navigate(item.url)
    }
  }

  return (
    <MainMenu>
      {menuItems.map((item) => (
        <MenuLink
          id={item.id ?? item.title}
          className={item.url === curPath ? 'current' : ''}
          onClick={() => handleClick(item)}
          key={item.title}
        >
          {item.title}
        </MenuLink>
      ))}
    </MainMenu>
  )
}
