import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import { ExternalLink } from 'Components/ExternalLink/ExternalLink'
import { ExternalLinkRoutes } from 'Utils/types/pageRoutes'
import { Subtext, fontSizeXXXS } from 'Utils/styles/text'
import { pagePadding, spacingMD, FlexColumn, spacingXXS } from 'Utils/styles/spacing'
import { screenSizes } from 'Utils/styles/screenSizes'

const FooterContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  align-items: end;

  @media ${screenSizes.mobile} {
    align-items: center;
    grid-template-rows: auto ${spacingMD};
    grid-template-columns: auto;
    gap: ${spacingXXS};
  }

  ${Subtext}
  ${pagePadding}

  padding-top: 0;
`
const LinkContainer = styled.div`
  display: flex;
  justify-content: end;
  @media ${screenSizes.mobile} {
    justify-content: center;
  }
`

const CopyrightContainer = styled(FlexColumn)`
  max-width: 75%;

  @media ${screenSizes.mobile} {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
  }
`

const TruelayerDisclaimer = styled.p`
  margin: 0;
  padding: 0;
  font-size: ${fontSizeXXXS};

  @media ${screenSizes.mobile} {
    text-align: center;
  }
`

export const TrademarkFooter = () => {
  return (
    <FooterContainer>
      <CopyrightContainer>
        <FormattedMessage id="copyright.message" values={{ year: new Date().getFullYear() }} />
        <TruelayerDisclaimer>
          JOON is acting as an agent of TrueLayer, who is providing the regulated Account
          Information Service, and is Authorised and Regulated by the Financial Conduct Authority
          under the Payment Services Regulations 2017 and the Electronic Money Regulations 2011
          (Firm Reference Number: 901096)
        </TruelayerDisclaimer>
      </CopyrightContainer>
      <LinkContainer>
        <ExternalLink href={ExternalLinkRoutes.HelpCenter} linkId="externalLink.helpCenter" />
        /
        <ExternalLink href={ExternalLinkRoutes.PrivacyPolicy} linkId="externalLink.privacyPolicy" />
        /
        <ExternalLink
          href="https://help.joon.io/data-storage-security-privacy"
          linkId="externalLink.security"
        />
      </LinkContainer>
    </FooterContainer>
  )
}
