import {
  GetAllowancesResponse,
  AddAllowanceResponse,
  AddAllowancePayload,
  AddAllowanceApiPayload,
  GetAllowancesPayload,
  GetAllowancesApiResponse
} from './apiResponseTypes'
import { getRequest } from 'Services/baseRequests/getRequest'
import { postRequest } from 'Services/baseRequests/postRequest'

export const getUserAllowances = async ({
  perPage,
  pageNumber,
  benefitProgramId,
  userId
}: GetAllowancesPayload): Promise<GetAllowancesResponse> => {
  const response = await getRequest<GetAllowancesApiResponse>(
    `/allowances?user_id=${userId}&benefit_program_id=${benefitProgramId}&per_page=${perPage}&page=${pageNumber}`
  )

  return {
    statusCode: response.statusCode,
    success: response.success ?? false,
    message: response.message ?? '',
    data:
      response.success && response.data
        ? { allowances: response.data.allowances, totalAllowances: response.data.total_records }
        : undefined
  }
}

export const addAllowance = async ({
  userId,
  benefitProgramId,
  amount,
  start,
  end,
  reason
}: AddAllowancePayload): Promise<AddAllowanceResponse> => {
  const response = await postRequest<AddAllowanceResponse, AddAllowanceApiPayload>(`/allowances`, {
    user_id: userId.toString(),
    benefit_program_id: benefitProgramId.toString(),
    amount,
    range_start: start,
    range_end: end,
    reason
  })

  return {
    statusCode: response.statusCode,
    success: response?.success ?? false,
    message: response?.message ?? ''
  }
}
