import { FC } from 'react'
import styled from 'styled-components'

import JoonLogo from 'Assets/JOONLogo.svg'

import { HeaderDropdown } from 'Components/Header/HeaderDropdown'
import {
  CenteredColumn,
  Column,
  HeaderContainer,
  HeaderText,
  JOONLogo,
  Row
} from 'Components/Header/HeaderStyles'
import {
  InternalNavigationLinks,
  joonAdminMenuItems
} from 'Components/Header/InternalNavigationLinks'

import { spacingLG } from 'Utils/styles/spacing'
import { telegrafFont } from 'Utils/styles/text'

type LoggedInJoonAdminHeaderProps = {
  headerText: string
}

const EmployerHeaderText = styled(HeaderText)`
  ${telegrafFont};
`

export const LoggedInJoonAdminHeader: FC<LoggedInJoonAdminHeaderProps> = ({ headerText }) => {
  return (
    <HeaderContainer style={{ gap: spacingLG }}>
      <Row style={{ maxHeight: 35, alignItems: 'center' }}>
        <Column style={{ alignItems: 'start' }}>
          <JOONLogo src={JoonLogo} alt="JOON Logo" />
        </Column>

        <Column style={{ flexDirection: 'row', justifyContent: 'end', gap: spacingLG }}>
          <InternalNavigationLinks menuItems={joonAdminMenuItems} />
          <HeaderDropdown />
        </Column>
      </Row>

      <Row>
        <CenteredColumn>
          {/* Main text */}
          <EmployerHeaderText>{headerText}</EmployerHeaderText>
        </CenteredColumn>
      </Row>
    </HeaderContainer>
  )
}
