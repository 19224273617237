import styled from 'styled-components'
import { createContext, FC, useContext, useRef, useState } from 'react'

import { Modal } from 'Components/Modal/Modal'
import { StyledButton } from 'Components/Buttons/StyledButton'
import { ModalHeader } from 'Components/Modal/ModalHeader'

import { spacingSM } from 'Utils/styles/spacing'
import { ThinDarkGreenText } from 'Utils/styles/text'
import {
  SelectModalContent,
  ConfirmationModalContextProviderProps,
  SelectModalContextType,
  useModalShow,
  SelectModalPayload
} from 'Hooks/modals/useModalShow'

import { StyledSelect, SelectOption } from 'Components/Inputs/StyledSelect'

const ButtonContainer = styled.div`
  display: flex;
  gap: ${spacingSM};
  justify-content: end;
`

const UseSelectModal = createContext<SelectModalContextType<SelectOption['value'] | undefined>>(
  {} as SelectModalContextType<SelectOption['value'] | undefined>
)

const SelectModalProvider: FC<ConfirmationModalContextProviderProps> = (props) => {
  const { setShow, show, onHide } = useModalShow()

  const [selectedOption, setSelectedOption] = useState<string | undefined>(undefined)
  const [content, setContent] = useState<SelectModalContent | null>()
  const resolver =
    useRef<
      (
        value: SelectOption['value'] | undefined | PromiseLike<SelectOption['value'] | undefined>
      ) => void
    >()

  const showModal = (payload: SelectModalPayload): Promise<SelectOption['value'] | undefined> => {
    const { title, message, selectOptions, placeholderText, confirmButtonId, cancelButtonId } =
      payload

    setContent({
      title,
      message,
      selectOptions,
      placeholderText: placeholderText ? placeholderText : 'Select an option',
      confirmButtonId: confirmButtonId ? confirmButtonId : 'continue.label',
      cancelButtonId
    })
    setShow(true)
    return new Promise((resolve) => {
      resolver.current = resolve
    })
  }

  const handleOk = () => {
    resolver.current && resolver.current(selectedOption)
    onHide()
  }

  const handleCancel = () => {
    resolver.current && resolver.current(undefined)
    onHide()
  }

  return (
    <UseSelectModal.Provider
      value={{
        showModal
      }}
    >
      {props.children}

      {content && (
        <Modal showModal={show} setShowModal={onHide} maxWidth={500}>
          <ModalHeader>{content.title}</ModalHeader>

          {content.message && <ThinDarkGreenText>{content.message}</ThinDarkGreenText>}

          <StyledSelect
            options={content.selectOptions}
            handleChange={setSelectedOption}
            placeholderText={content.placeholderText}
          />

          <ButtonContainer>
            {content.cancelButtonId && (
              <StyledButton id={content.cancelButtonId} onClick={handleCancel} />
            )}
            <StyledButton id={content.confirmButtonId} primary onClick={handleOk} />
          </ButtonContainer>
        </Modal>
      )}
    </UseSelectModal.Provider>
  )
}

const useSelectModal = (): SelectModalContextType<SelectOption['value'] | undefined> =>
  useContext(UseSelectModal)

export { useModalShow, useSelectModal }

export default SelectModalProvider
