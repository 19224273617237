import { useState, useCallback } from 'react'

export const useTableFilters = <T>(emptyFilters: T) => {
  const [filters, setFilters] = useState(emptyFilters)

  const updateFilters = useCallback((newFilters: Partial<T>) => {
    setFilters((currentFilters) => ({ ...currentFilters, ...newFilters }))
  }, [])

  const clearFilters = useCallback(() => setFilters(emptyFilters), [emptyFilters])

  return { filters, setFilters, updateFilters, clearFilters }
}
