import styled from 'styled-components'
import React from 'react'
import Flags from 'country-flag-icons/react/3x2'
import * as HoverCardPrimitive from '@radix-ui/react-hover-card'

import { CountryCode, CountryNames } from 'Utils/types/countries'
import { spacingXS, spacingSM } from 'Utils/styles/spacing'
import { fontSizeXS, fontWeightThin } from 'Utils/styles/text'
import { black, white, blackTint } from 'Utils/styles/colors'
import { Layer3 } from 'Utils/styles/zIndex'

const HoverCard = HoverCardPrimitive.Root
const HoverCardPortal = HoverCardPrimitive.Portal
const HoverCardArrow = HoverCardPrimitive.Arrow

const IconContainer = styled.span<{ size: IconSize }>`
  display: flex;
  align-items: center;
  ${({ size }) => size === 'small' && `font-size ${fontSizeXS}`}
`

const StyledHoverCardTrigger = styled(HoverCardPrimitive.Trigger)`
  display: flex;
`

const StyledContent = styled(HoverCardPrimitive.Content)`
  border-radius: 6px;
  padding: ${spacingSM};
  background-color: ${black};
  font-size: ${fontSizeXS};
  font-weight: ${fontWeightThin};
  color: ${white};
  ${Layer3};
`

type IconSize = 'default' | 'small'

const DEFAULT_SIZE = {
  width: '22.5px',
  height: '15px'
}

const SMALL_SIZE = {
  width: '16.5px',
  height: '11px'
}

const customIconStyles = {
  default: DEFAULT_SIZE,
  small: SMALL_SIZE
}

const iconStyles = (hasText: boolean, iconSize: IconSize) => ({
  marginRight: `${hasText ? spacingXS : '0'}`,
  border: `0.5px solid ${blackTint}`,
  ...customIconStyles[iconSize]
})

type CountryFlagIconProps = {
  text?: string
  iconSize?: IconSize
  countryCode: CountryCode
}

export const CountryFlagIcon = ({
  countryCode,
  text,
  iconSize = 'default'
}: CountryFlagIconProps) => {
  const Flag = Flags[countryCode]

  return (
    <IconContainer size={iconSize}>
      <HoverCard openDelay={0}>
        <StyledHoverCardTrigger>
          <Flag style={iconStyles(!!text, iconSize)} />
        </StyledHoverCardTrigger>
        <HoverCardPortal>
          <StyledContent style={{ marginRight: text ? spacingXS : '' }}>
            {CountryNames[countryCode]}
            <HoverCardArrow style={{ marginLeft: text ? spacingXS : '' }} />
          </StyledContent>
        </HoverCardPortal>
      </HoverCard>
      {text}
    </IconContainer>
  )
}
