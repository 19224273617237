import styled, { css } from 'styled-components'

import { elfGreen, elfGreenHover, towerGray } from 'Utils/styles/colors'

const Root = styled.div`
  color: ${towerGray};
  display: flex;
  justify-content: end;
`

const Text = styled.span<{ clickable: boolean }>`
  cursor: not-allowed;

  ${({ clickable }) =>
    clickable
      ? css`
          cursor: pointer;
          color: ${elfGreen};

          &:hover {
            text-decoration: underline;
            color: ${elfGreenHover};
          }
        `
      : ''};
`

export const ClearFiltersButton = ({ onClear }: { onClear?: () => void }) => (
  <Root>
    <Text onClick={onClear} clickable={!!onClear}>
      Reset Filters
    </Text>
  </Root>
)
