import { FC } from 'react'
import styled from 'styled-components'

import { CardLabel, DarkGreenText, OrangeText } from 'Utils/styles/text'
import { displayDate } from 'Utils/helpers/dateUtils'
import { UserStripeAccount } from 'Utils/types/user'
import { FlexColumn, spacingMD } from 'Utils/styles/spacing'
import { StyledAnchor } from 'Components/ExternalLink/ExternalLink'

const StyledContainer = styled(FlexColumn)`
  flex: 1;
`

const StripeDashboardLink = styled(StyledAnchor)`
  padding-top: ${spacingMD};
`

type StripeAccountInfoProps = {
  stripeAccountInfo?: UserStripeAccount
}
export const StripeAccountInfo: FC<StripeAccountInfoProps> = ({ stripeAccountInfo }) => {
  return (
    <StyledContainer>
      <CardLabel>Stripe Account Info</CardLabel>
      {stripeAccountInfo ? (
        <>
          <DarkGreenText>ID: {stripeAccountInfo.id}</DarkGreenText>
          <DarkGreenText>Stripe account ID: {stripeAccountInfo.stripeCustomerId}</DarkGreenText>
          <DarkGreenText>Created date: {displayDate(stripeAccountInfo.createdAt)}</DarkGreenText>
          <StripeDashboardLink
            href={`https://dashboard.stripe.com/connect/accounts/${stripeAccountInfo.stripeCustomerId}/activity`}
            target="_blank"
            padding={'0'}
          >
            View In Stripe Dashboard
          </StripeDashboardLink>
        </>
      ) : (
        <OrangeText>Stripe account does not exist</OrangeText>
      )}
    </StyledContainer>
  )
}
