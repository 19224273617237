export const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL || 'http://localhost:3000'
export const REACT_APP_APP_NAME = process.env.REACT_APP_APP_NAME || 'dev-couscous'

// Stripe connect variables used for setting up deposit accounts
export const REACT_APP_STRIPE_CONNECT_CLIENTID = process.env.REACT_APP_STRIPE_CONNECT_CLIENTID
export const REACT_APP_STRIPE_CONNECT_ACTIVATE_RETURN_URI =
  process.env.REACT_APP_STRIPE_CONNECT_ACTIVATE_RETURN_URI
export const REACT_APP_STRIPE_CONNECT_DASHBOARD_RETURN_URI =
  process.env.REACT_APP_STRIPE_CONNECT_DASHBOARD_RETURN_URI

// Datadog - error logging
export const REACT_APP_DATADOG_CLIENT_TOKEN = process.env.REACT_APP_DATADOG_CLIENT_TOKEN
export const REACT_APP_DATADOG_SITE = process.env.REACT_APP_DATADOG_SITE || 'us5.datadoghq.com'

// Sentry - error monitoring
export const REACT_APP_SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN

// Used when adding CARD payment methods for employers
export const REACT_APP_STRIPE_CLIENT_SECRET =
  process.env.REACT_APP_STRIPE_CLIENT_SECRET || 'pk_test_hkrEvUV9aiJsGT5bhfQyQd8c006ZEccXXR'

// Use when adding BANK payment methods for employers
export const REACT_APP_PLAID_ENVIRONMENT = process.env.REACT_APP_PLAID_ENVIRONMENT || 'sandbox'
export const REACT_APP_PLAID_API_KEY =
  process.env.REACT_APP_PLAID_API_KEY || 'cf9c115b6cd5e350c5ed3764793624'

export const REACT_APP_VERSION = process.env.REACT_APP_VERSION || 'no-build-version'
export const REACT_APP_CLIENT_SERVER_URL = process.env.REACT_APP_CLIENT_SERVER_URL || '/' // root url

export const REACT_APP_STRIPE_CONFIRM_PAYMENT_SETUP_RETURN_URL =
  process.env.REACT_APP_STRIPE_CONFIRM_PAYMENT_SETUP_RETURN_URL ||
  'http://localhost:8080/employer/billing' // root url

export const REACT_APP_JOON_EMPLOYER_ID = parseInt(process.env.REACT_APP_JOON_EMPLOYER_ID || '210')
