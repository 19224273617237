import { FC } from 'react'
import styled from 'styled-components'
import { Card } from 'Components/Card/Card'
import { CountryFlagIcon } from 'Components/Icons/CountryFlagIcon'
import { StyledButton } from 'Components/Buttons/StyledButton'
import { useConfirmationModalContext } from 'Hooks/modals/useConfirmationModal'
import { useDeleteEmployerCountryQuery } from 'Hooks/api/JOONAdmin/useDeleteEmployerCountryQuery'
import { useEmployerInfoContext } from 'Contexts/EmployerInfoContext'
import {
  FlexColumn,
  FlexRow,
  noPadding,
  spacingSM,
  spacingMD,
  spacingXXS
} from 'Utils/styles/spacing'
import { DarkGreenText, fontSizeSM, fontSizeMD, fontWeightBold } from 'Utils/styles/text'
import {
  HeaderSection,
  ProgramName,
  ButtonSection
} from '../BenefitPrograms/JoonAdminProgramDetailsCard'
import { EmployerCountry } from 'Utils/types/employerInfo'
import { CountryNames } from 'Utils/types/countries'

const InfoSection = styled(FlexColumn)`
  padding: ${spacingSM} ${spacingMD};
  gap: ${spacingSM};
`

const InfoContainer = styled(FlexRow)`
  gap: ${spacingXXS};
`

const Header = styled(HeaderSection)`
  gap: ${spacingSM};
`

const InfoLabel = styled.span`
  font-size: ${fontSizeSM};
  line-height: ${fontSizeMD};
  font-weight: ${fontWeightBold};
  min-width: fit-content;
`

const InfoValue = styled(DarkGreenText)`
  font-size: ${fontSizeSM};
  line-height: ${fontSizeMD};
`

type JoonAdminCountryCardProps = {
  countryConfig: EmployerCountry
  onClick?: () => void
}

export const JoonAdminCountryCard: FC<JoonAdminCountryCardProps> = ({ countryConfig, onClick }) => {
  const {
    state: { employerInfo }
  } = useEmployerInfoContext()

  const { countryCode, chargeFeePer, processingFee, pricingDescription, alternativeReimbursement } =
    countryConfig

  const { showModal } = useConfirmationModalContext()
  const { mutate } = useDeleteEmployerCountryQuery()

  const handleRemoveCountry = async () => {
    if (!employerInfo?.id) return

    const result = await showModal(
      `Remove Country: ${CountryNames[countryCode]}`,
      `Are you sure you want to remove this country from ${employerInfo?.name} (${countryConfig.employerId})?`
    )

    if (result) {
      mutate({ employerId: employerInfo.id, countryCode: countryCode })
    }
  }

  return (
    <Card style={{ minHeight: 220 }} padding={noPadding} onClick={onClick}>
      <Header>
        <ProgramName>{CountryNames[countryCode]}</ProgramName>
        <CountryFlagIcon countryCode={countryCode} />
      </Header>
      <InfoSection>
        <InfoContainer>
          <InfoLabel>Charge per type:</InfoLabel>
          <InfoValue>{chargeFeePer}</InfoValue>
        </InfoContainer>
        <InfoContainer>
          <InfoLabel>Processing fee per:</InfoLabel>
          <InfoValue>${processingFee}</InfoValue>
        </InfoContainer>
        <InfoContainer>
          <InfoLabel>Alternative reimbursement:</InfoLabel>
          <InfoValue>{alternativeReimbursement ? 'enabled' : 'disabled'}</InfoValue>
        </InfoContainer>
        <InfoContainer>
          <InfoLabel>Pricing description:</InfoLabel>
          <InfoValue>{pricingDescription}</InfoValue>
        </InfoContainer>
      </InfoSection>
      <ButtonSection>
        <StyledButton
          onClick={(e) => {
            e.stopPropagation()
            handleRemoveCountry()
          }}
          primary
          id="country.remove.button.label"
        />
      </ButtonSection>
    </Card>
  )
}
