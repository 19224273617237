import { useQuery } from 'react-query'
import { getEmployerDashboardData } from 'Services/dashboardService'
import { QueryNames } from 'Hooks/api/queryNames'
import { EmployerInfoActionTypes, useEmployerInfoContext } from 'Contexts/EmployerInfoContext'
import { useErrorReporter } from 'Hooks/useErrorReporter'

export const useEmployerDashboardQuery = (employerId?: number) => {
  const reportError = useErrorReporter()
  const employerInfoContext = useEmployerInfoContext()
  return useQuery({
    cacheTime: 1,
    queryKey: [QueryNames.EmployerDashboard, employerId],
    queryFn: async () => {
      if (employerId === undefined) return

      employerInfoContext.dispatch({ type: EmployerInfoActionTypes.SetLoading, value: true })
      const response = await getEmployerDashboardData(employerId)
      employerInfoContext.dispatch({ type: EmployerInfoActionTypes.SetLoading, value: false })

      if (response.data) {
        employerInfoContext.dispatch({
          type: EmployerInfoActionTypes.SetEmployerInfo,
          value: response.data.employerInfo
        })
        return response
      } else {
        reportError('useEmployerDashboardQuery failure', response)
      }
    },
    onError: (error: { message: string }) => {
      employerInfoContext.dispatch({ type: EmployerInfoActionTypes.SetLoading, value: false })
      reportError(`useGetUsersForEmployerQuery error: ${error.message}`)
      return undefined
    }
  })
}
