import { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import isSameDay from 'date-fns/isSameDay'

import { Checkbox } from 'Components/Inputs/Checkbox/Checkbox'
import { TextSpan } from 'Components/TextSpan'
import { FormDatePicker } from 'Components/Inputs/Calendar/FormDatePicker'
import { ScheduleRemovalMessage } from 'Components/Modal/ScheduleRemovalMessage'

import { bloodOrange, elfGreen, towerGray } from 'Utils/styles/colors'
import { FlexColumn, spacingMD, spacingSM, spacingXS, spacingXXS } from 'Utils/styles/spacing'
import { EmployerBenefitProgram } from 'Utils/types/benefitProgram'
import { ThinDarkGreenText } from 'Utils/styles/text'
import { displayDate } from 'Utils/helpers/dateUtils'

import { useEmployerInfoContext } from 'Contexts/EmployerInfoContext'

import { useGetInvoicesQuery } from 'Hooks/api/employer/invoices/useGetInvoicesQuery'

const ProgramContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  gap: ${spacingSM};
`

const ScrollingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: ${spacingMD};
  height: 100%;
`

const ProgramBoxStatus = styled.div`
  margin-top: ${spacingSM};
  gap: ${spacingSM};
  display: flex;
  flex-direction: row;
`

export type ProgramSelectionFormProps = {
  employerPrograms: EmployerBenefitProgram[]
  userName?: string
  removingFromAllPrograms?: boolean
  userPrograms?: { id: number; membershipStartDate: Date; membershipEndDate?: Date }[]
  toggleProgram: (id: number) => void
  setProgramChangeDate: (id: number, date: Date) => void
  programStartDate: (id: number) => Date
  programMinStartDate: (id: number) => Date
  isSelected: (id: number) => boolean
}

export const ProgramSelectionForm = ({
  employerPrograms,
  userPrograms,
  toggleProgram,
  programStartDate,
  programMinStartDate,
  setProgramChangeDate,
  isSelected,
  removingFromAllPrograms,
  userName
}: ProgramSelectionFormProps) => {
  const {
    state: { employerId }
  } = useEmployerInfoContext()

  const { isFetching } = useGetInvoicesQuery({
    pageNumber: 1,
    employerId,
    perPage: 1
  })

  const localToggleProgram = (programId: number) => {
    toggleProgram(programId)
  }

  const localIsSelected = (programId: number) => {
    return isSelected(programId)
  }

  const dateOfChange = (programId: number) => {
    return programStartDate(programId)
  }

  const minDateOfChange = (programId: number) => {
    return programMinStartDate(programId)
  }

  const onDateChange = (programId: number, date: Date) => {
    setProgramChangeDate(programId, date)
  }

  const membershipRangeByProgramId = useMemo(() => {
    return (
      userPrograms?.reduce<Record<string, { start: Date; end?: Date }>>(
        (map, program) => ({
          ...map,
          [program.id.toString()]: {
            start: program.membershipStartDate,
            end: program.membershipEndDate
          }
        }),
        {}
      ) ?? {}
    )
  }, [userPrograms])

  const now = new Date()

  const membershipStatus = (programId: number) => {
    if (!membershipRangeByProgramId[programId.toString()]) return

    const { start, end } = membershipRangeByProgramId[programId.toString()]

    if (end && isSameDay(end, now)) {
      return <TextSpan color={bloodOrange}>(Ending Today)</TextSpan>
    } else if (start > now) {
      return <TextSpan color={towerGray}>(Effective {displayDate(start)})</TextSpan>
    } else if (start < now && !end) {
      return <TextSpan color={elfGreen}>(Current)</TextSpan>
    } else if (end && start <= now && now <= end) {
      return <TextSpan color={bloodOrange}>(Ending {displayDate(end)})</TextSpan>
    }
  }

  const inProgram = useCallback(
    (programId: number) =>
      membershipRangeByProgramId && membershipRangeByProgramId[programId.toString()],
    [membershipRangeByProgramId]
  )

  return (
    <ScrollingContainer>
      {employerPrograms.map((program) => {
        const addingToProgram = !inProgram(program.id) && localIsSelected(program.id)
        const removingFromProgram = inProgram(program.id) && !localIsSelected(program.id)
        const removalText = addingToProgram ? 'after' : 'before'

        return (
          <ProgramContainer key={program.id}>
            <ProgramBoxStatus>
              <Checkbox
                labelText={program.name}
                value={localIsSelected(program.id)}
                onClick={() => localToggleProgram(program.id)}
              />

              {membershipStatus(program.id)}
            </ProgramBoxStatus>

            {(addingToProgram || removingFromProgram) && (
              <FlexColumn gap={spacingXXS}>
                <ThinDarkGreenText>
                  {removingFromProgram ? 'Eligibility ends on' : 'Eligibility begins on'}
                </ThinDarkGreenText>
                <FormDatePicker
                  disabled={isFetching}
                  minDate={minDateOfChange(program.id)}
                  onChange={(date) => onDateChange(program.id, date)}
                  date={dateOfChange(program.id)}
                />
                {removingFromProgram && removingFromAllPrograms ? (
                  <ScheduleRemovalMessage firstName={userName} />
                ) : (
                  <ThinDarkGreenText style={{ padding: spacingXS, paddingBottom: 0 }}>
                    Any eligible purchases made on or {removalText} the selected date will be
                    counted toward {userName}’s allowance and be reimbursable.
                  </ThinDarkGreenText>
                )}
              </FlexColumn>
            )}
          </ProgramContainer>
        )
      })}
    </ScrollingContainer>
  )
}
