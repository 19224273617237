import React from 'react'
import styled from 'styled-components'
import { fontSizeXS } from 'Utils/styles/text'
import { elfGreen } from 'Utils/styles/colors'

const ShowMoreText = styled.span`
  display: flex;
  font-size: ${fontSizeXS};
  color: ${elfGreen};
  cursor: pointer;
  align-items: center;
`

type ShowMoreChipsProps = {
  numExtraChips: number
  handleToggle: () => void
}
export const ShowMoreChips = ({ numExtraChips, handleToggle }: ShowMoreChipsProps) => (
  <ShowMoreText onClick={handleToggle}>+{numExtraChips} more</ShowMoreText>
)
