export enum PageRoutes {
  Login = '/login',
  ForgotPassword = '/forgot-password',
  ResetPassword = '/verify-reset-password',
  MicrosoftCallbackLogin = '/sign-in',
  SsoRedirect = '/ssoredirect',
  Logout = '/logout',
  ForcedLogout = '/force_logout',
  OldActivate = '/employees/onboarding/welcome', // This is what the API currently puts in emails
  OldActivateAdmin = '/employees/onboarding/step1', // API return for employer admins
  UserActivate = '/activate',
  UserDashboard = '/dashboard',
  UserPurchases = '/purchases',
  UserReimbursements = '/reimbursements',
  UserSettings = '/settings',
  EmployerActivate = '/employer/activate',
  EmployerDashboard = '/employer/dashboard',
  EmployerPeople = '/employer/people',
  EmployerBilling = '/employer/billing',
  AdminEmployersList = '/admin/employers',
  AdminReportedPurchases = '/admin/reported_purchases',
  AdminInvoicesList = '/admin/invoices',
  AdminInvoiceDetails = '/admin/invoice/',
  AdminEmployerDetails = '/admin/employer/'
}

export const PageNames = {
  [PageRoutes.Login]: 'Login',
  [PageRoutes.SsoRedirect]: 'SSO Redirect',
  [PageRoutes.ForgotPassword]: 'Forgot Password',
  [PageRoutes.ResetPassword]: 'Password Reset',
  [PageRoutes.MicrosoftCallbackLogin]: 'Login',
  [PageRoutes.Logout]: 'Logout',
  [PageRoutes.ForcedLogout]: 'Logout',
  [PageRoutes.OldActivate]: 'Activate',
  [PageRoutes.OldActivateAdmin]: 'Activate',
  [PageRoutes.UserActivate]: 'Activate',
  [PageRoutes.UserDashboard]: 'Dashboard',
  [PageRoutes.UserPurchases]: 'Purchases',
  [PageRoutes.UserReimbursements]: 'Reimbursements',
  [PageRoutes.UserSettings]: 'Settings',
  [PageRoutes.EmployerActivate]: 'Activate',
  [PageRoutes.EmployerDashboard]: 'Admin Dashboard',
  [PageRoutes.EmployerPeople]: 'Users',
  [PageRoutes.EmployerBilling]: 'Billing',
  [PageRoutes.AdminEmployersList]: 'JOON Admin',
  [PageRoutes.AdminEmployerDetails]: 'JOON Admin',
  [PageRoutes.AdminReportedPurchases]: 'JOON Admin',
  [PageRoutes.AdminInvoicesList]: 'JOON Admin: Invoices',
  [PageRoutes.AdminInvoiceDetails]: 'JOON Admin: Invoice'
}

export enum ExternalLinkRoutes {
  Discounts = 'https://www.joon.io/partners',
  InternationalDiscounts = 'https://joon.io/partners/#filter=.international',
  Resources = 'https://joonio.notion.site/Resources-for-Employers-4c492f0cc0f840a69f55b9c509d38865',
  EmployerTerms = 'https://joon.io/enterprise-agreement/',
  PrivacyPolicy = 'https://joon.io/privacy-policy/',
  HelpCenter = 'https://help.joon.io',
  WhatIsRolloverArticle = 'https://help.joon.io/rollover-allowances',
  HowToReportLargePurchases = 'https://help.joon.io/request-rollover-reimbursements',
  ApproximateReimbursements = 'https://help.joon.io/approximate-reimbursements',
  OngoingReimbursements = 'https://help.joon.io/request-ongoing-reimbursements-for-large-purchase',
  MultipleCards = 'https://help.joon.io/limits-with-multiple-cards-at-the-same-financial-institution'
}
