import { BenefitProgramPolicy, UserProgramMembership } from './benefitProgramPolicy'
import { BenefitProgramCategory } from './benefitProgramCategory'
import { AppIcons } from 'Utils/types/appIcons'
import { Purchase } from 'Utils/types/purchase'
import { DollarAmount } from 'Utils/types/common'
import { CountryCode } from './countries'
import { CurrencyCode } from './currencies'
import { Merchant } from 'Utils/types/JOONAdmin/merchant'

type BenefitProgramUtilization = {
  programId: number
  name: string
}

export const isEmployerBenefitProgram = (
  program: UserBenefitProgram | EmployerBenefitProgram
): program is EmployerBenefitProgram =>
  Object.prototype.hasOwnProperty.call(program, 'countries') ||
  !Object.prototype.hasOwnProperty.call(program, 'membership')

export type UserBenefitProgramUtilization = BenefitProgramUtilization & {
  membershipStartDate: Date
  membershipEndDate?: Date
}

export type Memberships = {
  programId: number
  programName?: string
  membershipStartDate: Date
  membershipEndDate?: Date
}

/**
 * @type {BenefitProgram}
 *
 * @property {DollarAmount} currentMonthAllowanceModification - Sum of all allowances for current month
 * @property {DollarAmount} currentPeriodAllowance - Allowance provided by benefit program for current period (inclusive of quarterly & annual programs)
 * @property {DollarAmount} currentTotalAllowance - Sum of all allowances eligible in current month including allowance mods & rollover
 * @property {DollarAmount} previousPeriodAllowance - Allowance provided by benefit program for previous period (inclusive of quarterly & annual programs)
 * @property {DollarAmount} previousTotalAllowance - Sum of all allowances eligible in previous month including allowance mods & rollover
 */
export type BenefitProgram = {
  id: number
  name: string
  icon: AppIcons
  categories: BenefitProgramCategory[]
  programDescriptionLink: string
  currentMonthReimbursement: DollarAmount
  // Current period; allowance provided from mods
  currentMonthAllowanceModification: DollarAmount
  // Current period; allowance provided from benefit program
  currentPeriodAllowance: DollarAmount
  // Current period; total allowance, including mods + rollover
  currentTotalAllowance: DollarAmount
  currentMonthPurchases: Purchase[]
  previousMonthReimbursement: DollarAmount
  // Last period; allowance provided from benefit program
  previousPeriodAllowance: DollarAmount
  // Last period; total allowance, including allowance mods + rollover
  previousTotalAllowance: DollarAmount
  previousMonthPurchases: Purchase[]
  currencyCode: CurrencyCode
  createdAt: Date
}

export type MonthlyUtilization = {
  currentMonth: {
    eligibleEmployees: number
    signedUpEmployees: number
    reimbursedEmployees: number
    reimbursedAmount: DollarAmount
    allowance: DollarAmount
  }
  previousMonth: {
    eligibleEmployees: number
    signedUpEmployees: number
    reimbursedEmployees: number
    reimbursedAmount: DollarAmount
  }
}

export type MonthlyUtilizationData = {
  benefitProgramId: number
  monthlyUtilization: MonthlyUtilization | null
}

export type EmployerBenefitProgram = BenefitProgram & {
  setupFee: DollarAmount
  processingFee: DollarAmount
  policy: BenefitProgramPolicy
  countries: CountryCode[]
  monthlyUtilization?: MonthlyUtilization
  activeEmployees: number
}

export type BenefitProgramMerchants = {
  allowList: Merchant[]
  blockList: Merchant[]
}

export type UserBenefitProgram = BenefitProgram & {
  currentPeriodUsage: DollarAmount
  membership: UserProgramMembership
}
