export const white = '#FFF'
export const azure = '#F4F7F7'
export const frostedGlass = '#EAF0F0'
export const zirconGray = '#E0E5E6'
export const towerGray = '#ACBDBD'
export const black = '#002321'
export const blackTint = 'rgba(0, 0, 0, 0.5)'
export const electricViolet = '#852EFF'
export const electricVioletFaded = 'rgba(133, 46, 255, 0.12)'
export const mintGreen = '#5ED7BF'
export const elfGreen = '#06917B'
export const elfGreenActive = '#17A28C'
export const elfGreenHover = '#058470'
export const elfGreenFaded = 'rgba(6, 145, 123, 0.12)'
export const mineralGreen = '#3E605A'
export const red = '#EC4329'
export const bloodOrange = '#FE5F55'
export const bloodOrangeFaded = 'rgba(254, 95, 85, 0.12)'
export const darkLemon = '#BAB700'
export const bronzeYellow = '#727000'
export const bronzeYellowFaded = 'rgba(186, 183, 0, 0.24)'
export const invisible = 'rgb(255 255 255 / 0%)'

export type Colors =
  | typeof white
  | typeof black
  | typeof red
  | typeof azure
  | typeof frostedGlass
  | typeof zirconGray
  | typeof towerGray
  | typeof mineralGreen
  | typeof elfGreen
  | typeof elfGreenFaded
  | typeof elfGreenHover
  | typeof elfGreenActive
  | typeof electricViolet
  | typeof electricVioletFaded
  | typeof bloodOrange
  | typeof bloodOrangeFaded
  | typeof darkLemon
  | typeof bronzeYellow
  | typeof bronzeYellowFaded
  | typeof invisible
  | typeof mintGreen
