import {
  ApiDetailedInvoice,
  ApiLatestInvoiceData,
  ApiPayment,
  EmployerAdminApiInvoice,
  JoonAdminApiInvoice
} from 'Services/apiResponseTypes'
import { Payment } from 'Utils/types/payment'
import { DetailedInvoice, EmployerAdminInvoice, JoonAdminInvoice } from 'Utils/types/invoice'
import { isPaymentMethod } from 'Utils/types/paymentMethod'
import { parseServerDate } from 'Utils/helpers/dateUtils'

export const parseEmployerAdminInvoiceApiResponseData = (
  employerAdminApiInvoice: EmployerAdminApiInvoice
): EmployerAdminInvoice => ({
  ...employerAdminApiInvoice,
  dateCreated: parseServerDate(employerAdminApiInvoice.dateCreated),
  dueDate: parseServerDate(employerAdminApiInvoice.dueDate),
  isInAutoPayment: employerAdminApiInvoice.autoPayment,
  numberOfUsersReimbursed: employerAdminApiInvoice.employeeCountWithAmount,
  totalAmount: employerAdminApiInvoice.amount,
  joonFees: employerAdminApiInvoice.joonFees ?? 0,
  payments: parseApiPaymentList(employerAdminApiInvoice.payments),

  benefitTransfersTotal: employerAdminApiInvoice.benefitTransferAmount,
  numberOfUsersChargedFor: employerAdminApiInvoice.chargeFeeCount
})

export const parseJoonAdminInvoiceApiResponseData = (
  joonAdminApiInvoice: JoonAdminApiInvoice
): JoonAdminInvoice => ({
  ...joonAdminApiInvoice,
  dateCreated: parseServerDate(joonAdminApiInvoice.dateCreated),
  dueDate: parseServerDate(joonAdminApiInvoice.dueDate),
  isInAutoPayment: joonAdminApiInvoice.autoPayment,
  numberOfUsersReimbursed: joonAdminApiInvoice.employeeCountWithAmount,
  totalAmount: joonAdminApiInvoice.amount,
  joonFees: joonAdminApiInvoice.joonFees ?? 0,
  payments: parseApiPaymentList(joonAdminApiInvoice.payments),

  employerBusinessName:
    joonAdminApiInvoice.employer.business_name ?? joonAdminApiInvoice.employer.name ?? '',
  employerId: joonAdminApiInvoice.employer.id,
  rosterSize: joonAdminApiInvoice.stripeMetadata?.rosterSize || 0
})

export const parseDetailedInvoiceApiResponseData = (
  apiDetailedInvoice: ApiDetailedInvoice
): DetailedInvoice => ({
  ...apiDetailedInvoice,
  dateCreated: parseServerDate(apiDetailedInvoice.dateCreated),
  dueDate: parseServerDate(apiDetailedInvoice.dueDate),
  numberOfUsersReimbursed: apiDetailedInvoice.employeeCountWithAmount,
  isInAutoPayment: apiDetailedInvoice.autoPayment,
  numberOfUsersChargedFor: apiDetailedInvoice.chargeFeeCount,
  benefitTransfersTotal: apiDetailedInvoice.benefitTransferAmount,
  benefitTransferFees: apiDetailedInvoice.benefitTransferFees,
  totalAmount: apiDetailedInvoice.amount,
  invoiceBenefitPrograms: apiDetailedInvoice.invoiceBenefitPrograms,
  joonFees: apiDetailedInvoice.joonFees ?? 0,
  payments: parseApiPaymentList(apiDetailedInvoice.payments),
  userReimbursements: parseApiReimbursements(apiDetailedInvoice.benefitTransfers),
  rosterSize: apiDetailedInvoice.stripeMetadata?.rosterSize || 0,
  employerBusinessName:
    apiDetailedInvoice.employer.business_name ?? apiDetailedInvoice.employer.name ?? '',
  employerId: apiDetailedInvoice.employer.id,
  currencyCode: apiDetailedInvoice.currencyCode,
  exchangeRate: apiDetailedInvoice.exchangeRate,
  calculatedProcessingFee: apiDetailedInvoice.calculatedProcessingFee,
  createdAt: parseServerDate(apiDetailedInvoice.createdAt),
  updatedAt: parseServerDate(apiDetailedInvoice.updatedAt)
})

export const parseInvoice = (invoice?: ApiLatestInvoiceData) => {
  if (!invoice) return undefined

  return {
    id: invoice.id,
    dateCreated: parseServerDate(invoice.dateCreated),
    state: invoice.state,
    dueDate: parseServerDate(invoice.dueDate)
  }
}

const parseApiReimbursements = (reimbursements?: ApiDetailedInvoice['benefitTransfers']) => {
  if (!reimbursements) return []

  return reimbursements.map(
    ({ id, amount, transferUrl, status, user, depositAccount, amountReceived }) => ({
      id,
      amount,
      transferUrl,
      status,
      employeeName: user.title,
      depositAccountPresent: depositAccount?.id !== undefined,
      amountReceived
    })
  )
}

const parseApiPaymentList = (paymentList?: ApiPayment[]): Payment[] => {
  if (!paymentList) return []

  return paymentList.map(
    ({ id, amount, createdAt, autoPayment, paymentMethodJson: paymentInfo }) => {
      const parsedPaymentInfo = isPaymentMethod(paymentInfo)
        ? {
            ...paymentInfo
          }
        : paymentInfo
        ? {
            stripeChargeId: paymentInfo.stripeChargeId,
            stripeCustomerId: paymentInfo.stripeCustomerId
          }
        : null

      return {
        id,
        amount,
        autoPayment,
        createdAt: parseServerDate(createdAt),
        paymentInfo: parsedPaymentInfo
      }
    }
  )
}
