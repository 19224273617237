import styled from 'styled-components'
import { elfGreen, elfGreenHover } from 'Utils/styles/colors'
import { FormattedMessage } from 'react-intl'
import React from 'react'
import { PageRoutes } from 'Utils/types/pageRoutes'
import { useNavigate } from 'react-router-dom'

export const StyledAnchor = styled.a`
  color: ${elfGreen};
  &:link,
  &:visited {
    text-decoration: none;
  }
  &:link {
    text-decoration: none;
  }
  &:hover {
    color: ${elfGreenHover};
    text-decoration: none;
    cursor: pointer;
  }
`

export type TextLinkProps = (
  | {
      href: string
      target?: string
    }
  | { route: PageRoutes }
) &
  ({ textId: string } | { text: string })

export const TextLink = (props: TextLinkProps) => {
  const navigate = useNavigate()

  const text = 'text' in props ? <span>{props.text}</span> : <FormattedMessage id={props.textId} />

  return 'href' in props ? (
    <StyledAnchor href={props.href} target={props.target}>
      {text}
    </StyledAnchor>
  ) : (
    <StyledAnchor onClick={() => navigate(props.route)}>{text}</StyledAnchor>
  )
}
