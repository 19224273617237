import React, { FC } from 'react'
import styled from 'styled-components'

import { StyledSelect } from 'Components/Inputs/StyledSelect'
import { PaymentMethodInfo } from 'Pages/Employer/BillingPage/PaymentMethods/PaymentMethodInfo'
import { InputContainer } from 'Pages/Employer/BillingPage/AutopayMenu/AutopayForm/styles'

import { PaymentMethod } from 'Utils/types/paymentMethod'
import { fontSizeLG, fontWeightThin } from 'Utils/styles/text'
import { black } from 'Utils/styles/colors'

const AddPaymentMethodText = styled.p`
  color: ${black};
  font-weight: ${fontWeightThin};
  line-height: ${fontSizeLG};
`

type PaymentMethodSelectorProps = {
  alternativeReimbursement: boolean
  setSelectedPaymentMethod: (value: string) => void
  paymentMethodOptions: { value: string; label: string }[]
  selectedAutopayMethod?: PaymentMethod
  editMode: boolean
  defaultValue?: boolean
  placeholderText?: string
}
export const PaymentMethodSelector: FC<PaymentMethodSelectorProps> = ({
  alternativeReimbursement,
  setSelectedPaymentMethod,
  paymentMethodOptions,
  selectedAutopayMethod,
  editMode,
  defaultValue,
  placeholderText
}) => {
  if (paymentMethodOptions.length === 0) {
    return (
      <InputContainer>
        <AddPaymentMethodText>Please add a payment method for this country.</AddPaymentMethodText>
      </InputContainer>
    )
  }

  return (
    <InputContainer>
      {!selectedAutopayMethod || editMode ? (
        <>
          <StyledSelect
            options={paymentMethodOptions}
            defaultValue={defaultValue ? paymentMethodOptions[0]?.value : undefined}
            placeholderText={placeholderText}
            handleChange={setSelectedPaymentMethod}
          />
        </>
      ) : (
        <PaymentMethodInfo
          paymentMethod={selectedAutopayMethod}
          hideText={alternativeReimbursement}
        />
      )}
    </InputContainer>
  )
}
