import { useMutation, useQueryClient } from 'react-query'
import { QueryNames } from 'Hooks/api/queryNames'
import { toast } from 'react-toastify'
import { AppNotification } from 'Utils/types/appNotification'
import { addAllowance } from 'Services/allowances'

export const useAddUserAllowanceMutation = () => {
  const queryClient = useQueryClient()

  return useMutation([QueryNames.AddUserAllowance], addAllowance, {
    onSuccess: (response, parameters) => {
      const { userId, benefitProgramId } = parameters

      if (response.success) {
        queryClient.invalidateQueries([QueryNames.GetUserAllowances, userId, benefitProgramId])
        toast.success(AppNotification.AllowanceModificationUpdateSuccess)
      } else {
        toast.error(`${AppNotification.AllowanceModificationUpdateFailure} ${response.message}`)
      }
    },
    onError: () => {
      toast.error(AppNotification.AllowanceModificationUpdateFailure)
    }
  })
}
