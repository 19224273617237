import styled from 'styled-components'
import { GridRow, spacingSM, spacingXXS } from 'Utils/styles/spacing'

export const AutopayFormFormContainer = styled.div`
  display: flex;
  flex-flow: column;
  max-width: calc(50% - ${spacingSM});
  gap: ${spacingSM};
`

export const ButtonContainer = styled.div`
  display: flex;
  gap: ${spacingSM};
  justify-content: end;
`

export const InputContainer = styled(GridRow)`
  grid-gap: ${spacingXXS};
`
