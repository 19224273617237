import { FC } from 'react'
import { InputContainer, StyledInputLabel } from 'Components/Inputs/StyledInputLabel'
import { Control, Controller } from 'react-hook-form'
import { StyledSwitch } from 'Components/Inputs/Switch/StyledSwitch'

export const ControlledSwitch: FC<{
  label: string
  name: string
  control: Control<any>
  defaultChecked?: boolean
  disabled?: boolean
}> = ({ label, name, control, disabled, defaultChecked = false }) => (
  <InputContainer>
    <StyledInputLabel>{label}</StyledInputLabel>
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <StyledSwitch
          onCheckedChange={() => {
            onChange(!value)
          }}
          disabled={disabled}
          defaultChecked={defaultChecked}
          checked={value}
        />
      )}
    />
  </InputContainer>
)
