import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import FileSaver from 'file-saver'

import { downloadPurchasesReport } from 'Services/JOONAdmin/joonAdminReportsService'

import { QueryNames } from 'Hooks/api/queryNames'

import { AppNotification } from 'Utils/types/appNotification'
import { buildExcelFileBlob } from 'Utils/helpers/files'
import { Employer } from 'Utils/types/JOONAdmin/employer'
import { formatDateForServer } from 'Utils/helpers/dateUtils'

const purchasesReportFilename = (employerId: number, date: string) => {
  return `employer_${employerId}_purchases_report_${date}.xls`
}

export const useDownloadPurchasesReport = () =>
  useMutation(
    [QueryNames.DownloadPurchasesReport],
    async ({ employerId, reportDate }: { employerId: Employer['id']; reportDate: Date }) => {
      const dateString = formatDateForServer(reportDate)
      const response = await downloadPurchasesReport(employerId, dateString)
      if (response.data) {
        FileSaver.saveAs(
          buildExcelFileBlob(response.data),
          purchasesReportFilename(employerId, dateString)
        )
        toast.success(AppNotification.ReportDownloadSuccess)
      } else {
        toast.error(response.message || AppNotification.ReportDownloadFailure)
      }
    },
    {
      onError: () => {
        toast.error(AppNotification.ReportDownloadSuccess)
        return undefined
      }
    }
  )
