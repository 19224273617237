import { useMutation, useQueryClient } from 'react-query'
import { QueryNames } from 'Hooks/api/queryNames'
import { toast } from 'react-toastify'
import { AppNotification } from 'Utils/types/appNotification'
import { terminateEmployer } from 'Services/JOONAdmin/joonAdminEmployersService'

export const useTerminateEmployerQuery = () => {
  const queryClient = useQueryClient()

  return useMutation([QueryNames.TerminateEmployer], terminateEmployer, {
    onSuccess: (response) => {
      if (response.success) {
        queryClient.invalidateQueries([
          QueryNames.EmployerDashboard,
          QueryNames.GetEmployerDetails,
          QueryNames.EmployerBenefitPrograms
        ])
        toast.success(AppNotification.TerminateEmployerSuccess)
      } else {
        toast.error(`${AppNotification.TerminateEmployerFailure} ${response.message}`)
      }
    },
    onError: (error: { message: string }) => {
      toast.error(`${AppNotification.TerminateEmployerFailure} ${error.message}`)
    }
  })
}
