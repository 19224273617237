import { FC } from 'react'
import styled from 'styled-components'

import { CurrencyText } from 'Components/Text/CurrencyText'
import { useGetUserEligibleSpendQuery } from 'Hooks/api/JOONAdmin/useGetUserEligibleSpendQuery'

import { CurrencyCode } from 'Utils/types/currencies'
import { ThinDarkGreenText } from 'Utils/styles/text'
import { FlexColumn, spacingSM } from 'Utils/styles/spacing'
import { frostedGlass } from 'Utils/styles/colors'

const EligibleSpendContainer = styled(FlexColumn)`
  background-color: ${frostedGlass};
  padding: ${spacingSM};
  margin: ${spacingSM} 0;
  border-radius: ${spacingSM};
`

type EligibleSpendBannerProps = {
  userId: number
  benefitProgramId: number
  currencyCode: CurrencyCode
}

export const EligibleSpendBanner: FC<EligibleSpendBannerProps> = ({
  userId,
  benefitProgramId,
  currencyCode
}) => {
  const { data } = useGetUserEligibleSpendQuery({
    userId,
    benefitProgramId
  })

  if (!data) return null

  return (
    <EligibleSpendContainer>
      <ThinDarkGreenText>
        Eligible spend this month:{' '}
        <CurrencyText value={data.currentEligibleSpend} currencyCode={currencyCode} />
      </ThinDarkGreenText>
      <ThinDarkGreenText>
        Eligible spend last month:{' '}
        <CurrencyText value={data.previousEligibleSpend} currencyCode={currencyCode} />
      </ThinDarkGreenText>
    </EligibleSpendContainer>
  )
}
