import React, { CSSProperties, ReactNode, useEffect, useRef } from 'react'
import { useSpring, animated } from '@react-spring/web'
import styled from 'styled-components'

import { Background } from 'Components/Background'

import { useCloseOnEscape } from 'Hooks/useCloseOnEscape'

import { spacingLG, spacingMD } from 'Utils/styles/spacing'
import { white } from 'Utils/styles/colors'
import { screenSizes } from 'Utils/styles/screenSizes'

import Close from 'Assets/close.svg'

const CloseModalButton = styled.img`
  cursor: pointer;
  position: absolute;
  top: ${spacingMD};
  right: ${spacingMD};
`

// Using snake case here because we get a warning when we use camel case
export const ModalContainer = styled(animated.div)<{
  overflow: 'auto' | 'hidden'
  min_width: number
  max_width?: number
}>`
  display: flex;
  flex-direction: column;
  gap: ${spacingMD};
  padding: ${spacingLG};
  max-height: calc(80vh);
  background-color: ${white};
  border-radius: 12px;
  overflow-y: ${({ overflow }) => overflow};
  max-width: ${({ max_width }) => (max_width ? `${max_width}px` : '')};
  min-width: ${({ min_width }) => `${min_width}px`};

  @media ${screenSizes.mobile} {
    min-width: inherit;
    width: calc(98vw - ${spacingLG}*2);
  }
`

type ModalProps = {
  showModal: boolean
  setShowModal: (a: boolean) => void
  children: ReactNode
  dataTestId?: string
  overflow?: boolean
  minWidth?: number
  maxWidth?: number
  style?: CSSProperties
}
export const Modal = ({
  showModal,
  setShowModal,
  children,
  maxWidth,
  dataTestId,
  style,
  minWidth = 500,
  overflow = false
}: ModalProps) => {
  const animation = useSpring({
    config: {
      duration: 250
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`
  })

  useCloseOnEscape(showModal, setShowModal)

  const ref = useRef<HTMLDivElement>(null)

  // Remove ability to close modal clicking outside
  // Make sure all modals have a button in the UI to close
  // useCloseOnClickOutside(ref, setShowModal)

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [showModal])

  return (
    <>
      {showModal && (
        <Background>
          <ModalContainer
            data-testid={dataTestId}
            overflow={overflow ? 'auto' : 'hidden'}
            min_width={minWidth}
            max_width={maxWidth}
            style={{ ...animation, ...style }}
            ref={ref}
          >
            {children}
            <CloseModalButton
              src={Close}
              alt="close"
              aria-label="Close modal"
              onClick={() => setShowModal(false)}
            />
          </ModalContainer>
        </Background>
      )}
    </>
  )
}
