import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import FileSaver from 'file-saver'
import moment from 'moment-business-days'

import { QueryNames } from 'Hooks/api/queryNames'
import { downloadInvoice } from 'Services/invoices/invoiceService'
import { AppNotification } from 'Utils/types/appNotification'
import { EmployerInfo } from 'Utils/types/employerInfo'
import { JoonAdminInvoice, EmployerAdminInvoice } from 'Utils/types/invoice'
import { buildExcelFileBlob } from 'Utils/helpers/files'

const invoiceFilename = (
  employerId: EmployerInfo['id'],
  invoice: JoonAdminInvoice | EmployerAdminInvoice
) => {
  const timestamp = moment(invoice.dateCreated).format('MM-DD-YYYY')
  return `${employerId}_invoice_${timestamp}.xls`
}

export const useDownloadInvoice = () =>
  useMutation(
    [QueryNames.DownloadInvoice],
    async ({
      employerId,
      invoice
    }: {
      employerId: EmployerInfo['id']
      invoice: JoonAdminInvoice | EmployerAdminInvoice
    }) => {
      const response = await downloadInvoice(employerId, invoice.id)
      if (response.data) {
        FileSaver.saveAs(buildExcelFileBlob(response.data), invoiceFilename(employerId, invoice))
        toast.success(AppNotification.InvoiceDownloadSuccess)
      } else {
        toast.error(response.message || AppNotification.InvoiceDownloadFailure)
      }
    },
    {
      onError: () => {
        toast.error(AppNotification.InvoiceDownloadFailure)
        return undefined
      }
    }
  )
