import { useQuery } from 'react-query'
import { useErrorReporter } from 'Hooks/useErrorReporter'
import { QueryNames } from 'Hooks/api/queryNames'
import { GetUserForEmployerPayload, getUsersForEmployer } from 'Services/users/userService'
import { EmployerActionTypes, useEmployerUsersContext } from 'Contexts/EmployerUsersContext'

export const useGetUsersForEmployerQuery = (
  payload: Omit<GetUserForEmployerPayload, 'employerId'> & {
    employerId?: GetUserForEmployerPayload['employerId']
  }
) => {
  const reportError = useErrorReporter()

  const { dispatch } = useEmployerUsersContext()

  return useQuery({
    cacheTime: 1,
    queryKey: [QueryNames.UsersForEmployer, payload],
    queryFn: async () => {
      if (payload.employerId === undefined) return

      dispatch({ type: EmployerActionTypes.SetLoading, value: true })
      const response = await getUsersForEmployer({ ...payload, employerId: payload.employerId })
      dispatch({ type: EmployerActionTypes.SetLoading, value: false })

      if (response.data) {
        dispatch({ type: EmployerActionTypes.SetUsers, value: response.data.users })
      } else {
        reportError('useGetUsersForEmployerQuery failure', response)
      }

      return response
    },
    onError: (err: string) => {
      dispatch({ type: EmployerActionTypes.SetLoading, value: false })
      reportError(`useGetUsersForEmployerQuery error: ${err}`)
      return undefined
    }
  })
}
