import { useQuery } from 'react-query'
import { QueryNames } from 'Hooks/api/queryNames'
import { useErrorReporter } from 'Hooks/useErrorReporter'
import { getBenefitProgramCategories } from 'Services/JOONAdmin/benefitProgramCategoriesService'

export const useGetBenefitProgramCategoriesQuery = () => {
  const reportError = useErrorReporter()

  return useQuery({
    queryKey: [QueryNames.GetBenefitProgramCategories],
    queryFn: async () => {
      const response = await getBenefitProgramCategories()
      if (!response.data) {
        reportError('useGetBenefitProgramCategoriesQuery failure', response)
      }

      return response.data?.programCategories ?? []
    }
  })
}
