import React from 'react'
import styled from 'styled-components'

import { ThinDarkGreenText } from 'Utils/styles/text'

const InfoContainer = styled.ul``
const InfoItem = styled.li``

export const UpdateBenefitProgramInfo = () => (
  <InfoContainer>
    <InfoItem>
      <ThinDarkGreenText>
        If the current policy does not have an end date, creating a new policy will end the current
        policy at the end of the previous period from the selected start date. For example, if you
        create a new policy starting January 2024, the end of the current policy will be December
        31, 2023.
      </ThinDarkGreenText>
    </InfoItem>
    <InfoItem>
      <ThinDarkGreenText>
        If you need to make multiple future policies, please DO NOT use this feature. It’s not
        designed for that.
      </ThinDarkGreenText>
    </InfoItem>
    <InfoItem>
      <ThinDarkGreenText>
        If there were any future policies that already exist for this benefit program, they will all
        be deleted.
      </ThinDarkGreenText>
    </InfoItem>
    <InfoItem>
      <ThinDarkGreenText>
        If you enable rollover, you will not be able to disable it without a support request.
      </ThinDarkGreenText>
    </InfoItem>
  </InfoContainer>
)

export const EndBenefitProgramPolicyInfo = () => (
  <InfoContainer>
    <InfoItem>
      <ThinDarkGreenText>
        Since there is no active policy, the only option is to delete all future policies.
      </ThinDarkGreenText>
    </InfoItem>
  </InfoContainer>
)
