import { LocalStorageKeys, useLocalStorage } from 'Hooks/useLocalStorage'

export const useIsLoggedIn = () => {
  const [userId] = useLocalStorage<number | undefined>(LocalStorageKeys.UserId, undefined)

  return {
    isLoggedIn: userId !== undefined,
    userId
  }
}
