import { useMemo } from 'react'
import styled from 'styled-components'

import { CountryFlagIcon } from 'Components/Icons/CountryFlagIcon'
import { ShowMoreChips } from 'Components/Chip/ShowMoreChips'
import { spacingSM, spacingXS, FlexRow } from 'Utils/styles/spacing'
import { CountryCode } from 'Utils/types/countries'
import { frostedGlass, white, black, Colors } from 'Utils/styles/colors'

const CountryChipsContainer = styled(FlexRow)`
  margin-left: ${spacingXS};
`

const CountryChipWrapper = styled.div`
  margin-left: calc(-1 * ${spacingXS});
`

const CountryChip = styled.span<{
  borderColor: Colors
  fontColor: Colors
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  padding: ${spacingXS} ${spacingSM};
  background: ${frostedGlass};
  color: ${({ fontColor }) => fontColor};
  border: 3px solid ${({ borderColor }) => borderColor};
`

type CountryChipsProps = {
  id: number | string
  countries: CountryCode[]
  handleToggle?: () => void
  borderColor?: Colors
  fontColor?: Colors
  showCountryCode?: boolean
  maxCountriesShown?: number
  showAll?: boolean
}
export const CountryChips = ({
  id,
  countries,
  handleToggle,
  borderColor = white,
  fontColor = black,
  showCountryCode = true,
  maxCountriesShown = 3,
  showAll = false
}: CountryChipsProps) => {
  const extraCountryCount = useMemo(
    () => (showAll ? 0 : countries.length - maxCountriesShown),
    [countries, maxCountriesShown, showAll]
  )
  const displayedCountries = useMemo(
    () => (showAll ? countries : countries.slice(0, maxCountriesShown)),
    [countries, maxCountriesShown, showAll]
  )

  return (
    <CountryChipsContainer>
      {displayedCountries.map((country) => (
        <CountryChipWrapper key={`${id}-${country}`}>
          <CountryChip borderColor={borderColor} fontColor={fontColor}>
            <CountryFlagIcon
              countryCode={country}
              text={showCountryCode ? country : ''}
              iconSize="small"
            />
          </CountryChip>
        </CountryChipWrapper>
      ))}
      {
        // Button opens modal showing more benefit program info
        extraCountryCount > 0 && handleToggle && (
          <ShowMoreChips handleToggle={handleToggle} numExtraChips={extraCountryCount} />
        )
      }
    </CountryChipsContainer>
  )
}
