import { createContext, useContext, useReducer } from 'react'
import { Purchase } from 'Utils/types/purchase'
import { find, propEq } from 'ramda'
import { ContextProviderProps } from 'Contexts/ContextProvider'

export enum UserPurchaseActionTypes {
  SetPurchases = 'SET_PURCHASES',
  InvalidatePurchase = 'INVALIDATE_PURCHASE',
  ResetState = 'RESET_STATE'
}
type Action =
  | { type: UserPurchaseActionTypes.SetPurchases; value: Purchase[] }
  | { type: UserPurchaseActionTypes.InvalidatePurchase; value: number }
  | { type: UserPurchaseActionTypes.ResetState }
type Dispatch = (action: Action) => void
type State = { purchases: Purchase[]; approvedPurchasesByProgram: Record<number, Purchase[]> }

const DEFAULT_STATE = {
  purchases: [],
  approvedPurchasesByProgram: {}
}

const UserPurchasesStateContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(
  undefined
)

const userPurchasesReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case UserPurchaseActionTypes.SetPurchases: {
      return { ...state, purchases: action.value }
    }

    case UserPurchaseActionTypes.InvalidatePurchase: {
      const purchaseToUpdate = find(propEq(action.value, 'id'), state.purchases)

      if (!purchaseToUpdate) {
        return state
      }

      const updatedPurchase = {
        ...purchaseToUpdate,
        pending: false,
        approved: false,
        approvedProgramId: undefined,
        approvedProgramName: undefined
      }
      const updatedPurchases = state.purchases.map((p) =>
        p.id === action.value ? updatedPurchase : p
      )

      return { ...state, purchases: updatedPurchases }
    }

    case UserPurchaseActionTypes.ResetState: {
      return DEFAULT_STATE
    }

    default: {
      throw new Error(`Invalid action type`)
    }
  }
}

const UserPurchasesProvider = ({ children }: ContextProviderProps) => {
  const [state, dispatch] = useReducer(userPurchasesReducer, DEFAULT_STATE)
  const value = { state, dispatch }
  return (
    <UserPurchasesStateContext.Provider value={value}>
      {children}
    </UserPurchasesStateContext.Provider>
  )
}

const useUserPurchasesContext = () => {
  const context = useContext(UserPurchasesStateContext)
  if (context === undefined) {
    throw new Error('useUserPurchases must be used within an UserPurchasesProvider')
  }
  return context
}

export { UserPurchasesProvider, useUserPurchasesContext }
