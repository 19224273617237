import { useMutation, useQueryClient } from 'react-query'
import { useErrorReporter } from 'Hooks/useErrorReporter'

import { QueryNames } from 'Hooks/api/queryNames'

import { resetLogin } from 'Services/users/userService'
import { toast } from 'react-toastify'
import { AppNotification } from 'Utils/types/appNotification'
import { EmployerActionTypes, useEmployerUsersContext } from 'Contexts/EmployerUsersContext'

export const useResetLoginQuery = () => {
  const queryClient = useQueryClient()
  const reportError = useErrorReporter()

  const {
    dispatch,
    state: { users }
  } = useEmployerUsersContext()

  return useMutation([QueryNames.ResetLogin], resetLogin, {
    onSuccess: (response, payload) => {
      queryClient.invalidateQueries([QueryNames.ResetLogin])
      if (response.success) {
        const updateUsers = users.map((user) => {
          const failedLoginCount = user.id === payload.userId ? 0 : user.failedLoginCount
          return { ...user, failedLoginCount: failedLoginCount }
        })

        dispatch({ type: EmployerActionTypes.SetUsers, value: updateUsers })
        toast.success(AppNotification.AccountUnlockedSuccess)
      } else {
        toast.error(AppNotification.AccountUnlockedFailure)
      }
    },
    onError: (err: string) => {
      reportError(`useResetLoginQuery error: ${err}`)
    }
  })
}
