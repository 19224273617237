import { CountryCode } from './countries'
import { PaymentMethod } from 'Utils/types/paymentMethod'
import { SelectOption } from 'Components/Inputs/StyledSelect'

export type EmployerInfo = {
  id: number
  name: string
  activeEmployees: number // Count of active employees for an employer
  countries: CountryCode[]
  // Registration / status info
  businessInfoSuccessSeen: boolean
  onboardingComplete: boolean
  onboardingStep: number
  // Billing info
  activeUsersCount: number
  earliestInvoiceDate: Date | null
  countryConfigs: CountryConfigs
}

export type CountryConfigs = Record<CountryCode, EmployerCountry>

export type EmployerCountry = {
  id: number
  countryCode: CountryCode
  employerId: number
  chargeFeePer: ChargeFeeType
  processingFee: number
  alternativeReimbursement: boolean
  autoPaymentMethodId: PaymentMethod['id'] | null
  pricingDescription?: string
}

export enum ChargeFeeType {
  Flat = 'flat',
  Invite = 'invite',
  Signup = 'signup',
  Reimbursement = 'reimbursement'
}

export const chargeFeeTypeOptions: SelectOption<ChargeFeeType>[] = [
  { label: 'Flat', value: ChargeFeeType.Flat },
  { label: 'Invite', value: ChargeFeeType.Invite },
  { label: 'Signup', value: ChargeFeeType.Signup },
  { label: 'Reimbursement', value: ChargeFeeType.Reimbursement }
]
