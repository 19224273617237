import { CSSProperties, MouseEvent, ReactNode } from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import EastIcon from '@mui/icons-material/East'

import { elfGreen, elfGreenHover } from 'Utils/styles/colors'
import { spacingXS } from 'Utils/styles/spacing'
import { fontWeightSlightlyBold } from 'Utils/styles/text'

const LinkContainer = styled.a`
  display: flex;
  align-items: end;
  justify-content: flex-start;
  cursor: pointer;
  gap: ${spacingXS};
  font-weight: ${fontWeightSlightlyBold};
  color: ${elfGreen};
  white-space: nowrap;
  &:link,
  &:visited {
    color: ${elfGreenHover};
    text-decoration: none;
  }
  &:link {
    color: ${elfGreenHover};
    text-decoration: none;
  }
  &:hover {
    color: ${elfGreenHover};
    text-decoration: none;
  }
`

type SharedLinkButtonProps = {
  labelId?: string
  text?: string
  href?: string
  onClick?: () => void
  style?: CSSProperties
  iconPosition?: 'left' | 'right'
  icon?: ReactNode
  isLoading?: boolean
  disabled?: boolean
}

export type LinkButtonProps = SharedLinkButtonProps & ({ labelId: string } | { text: string })

export const LinkButton = ({
  labelId,
  text,
  href,
  style,
  onClick,
  iconPosition = 'right',
  isLoading = false,
  disabled = false,
  icon = <EastIcon fontSize="small" />
}: LinkButtonProps) => {
  const handleClick = (e: MouseEvent, callback?: () => void) => {
    if (callback) {
      e.preventDefault()
      callback()
    }
  }

  return (
    <LinkContainer
      href={href}
      target="_blank"
      style={style}
      onClick={isLoading || disabled ? undefined : (e) => handleClick(e, onClick)}
    >
      {iconPosition === 'left' && icon}
      {labelId ? <FormattedMessage id={labelId} /> : text}
      {iconPosition === 'right' && icon}
    </LinkContainer>
  )
}
