import { FC } from 'react'
import { Modal } from 'Components/Modal/Modal'
import { CreateCountryForm } from './CreateCountryForm'
import { EmployerCountry } from 'Utils/types/employerInfo'

type CreateCountryModalProps = {
  showModal: boolean
  setShowModal: (a: boolean) => void
  countryConfig?: EmployerCountry
}

export const CreateCountryModal: FC<CreateCountryModalProps> = ({
  showModal,
  setShowModal,
  countryConfig
}) => {
  return (
    <Modal showModal={showModal} setShowModal={setShowModal} maxWidth={800} minWidth={800} overflow>
      <CreateCountryForm closeModal={() => setShowModal(false)} countryConfig={countryConfig} />
    </Modal>
  )
}
