import { useMutation, useQueryClient } from 'react-query'
import { useErrorReporter } from 'Hooks/useErrorReporter'

import { QueryNames } from 'Hooks/api/queryNames'

import { cancelUserInvite } from 'Services/users/userService'

export const useCancelUserInviteQuery = () => {
  const queryClient = useQueryClient()
  const reportError = useErrorReporter()

  return useMutation([QueryNames.CancelUserInvite], cancelUserInvite, {
    onSuccess: (response) => {
      if (response.success) {
        // TODO: ideally we would avoid re-fetching users, but there's useful logic performed when fetching
        queryClient.invalidateQueries([QueryNames.UsersForEmployer])
      }
    },
    onError: (err: string) => {
      reportError(`useCancelUserInviteQuery error: ${err}`)
    }
  })
}
