import { Dispatch, FC, SetStateAction } from 'react'
import { StyledInputLabel } from 'Components/Inputs/StyledInputLabel'
import { StyledSelect } from 'Components/Inputs/StyledSelect'
import styled from 'styled-components'
import { spacingXXS } from 'Utils/styles/spacing'

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacingXXS};
  flex: 1;
`

export const ControlledSelect: FC<{
  label: string
  options: any[]
  setControlValue: Dispatch<SetStateAction<any>>
  disabled?: boolean
  defaultValue?: any
}> = ({ label, options, setControlValue, disabled, defaultValue }) => {
  return (
    <InputContainer>
      <StyledInputLabel>{label}</StyledInputLabel>
      <StyledSelect
        options={options}
        handleChange={setControlValue}
        defaultValue={defaultValue}
        placeholderText={'Select an option...'}
        disabled={disabled}
      />
    </InputContainer>
  )
}
