import { FC, useMemo } from 'react'
import styled from 'styled-components'

import { FormTextInput } from 'Components/Inputs/FormTextInput'
import { StyledButton } from 'Components/Buttons/StyledButton'
import { StyledInputLabel } from 'Components/Inputs/StyledInputLabel'
import { FileType, FileUpload } from 'Components/Inputs/FileUpload'
import { FormDatePicker } from 'Components/Inputs/Calendar/FormDatePicker'
import { ModalBody } from 'Components/Modal/ModalBody'
import { ControlledStyledSelect } from 'Components/Inputs/ControlledStyledSelect'

import { ButtonContainer, GridRow, spacingMD, spacingXXS } from 'Utils/styles/spacing'
import { createDate, sameMonth } from 'Utils/helpers/dateUtils'
import { Purchase } from 'Utils/types/purchase'
import { useAddPurchaseForm } from 'Components/Modal/AddPurchaseModal/useAddPurchaseForm'
import { OngoingPurchaseCheckbox } from 'Components/Modal/AddPurchaseModal/OngoingPurchaseCheckbox'

const InputContainer = styled(GridRow)`
  grid-gap: ${spacingXXS};
`

export type AddPurchaseFormValues = {
  userId: number
  name: string
  amount: string
  benefitProgramId: string
  benefitProgramCategoryId: string
  description: string
  date: string
  ongoingPurchase: boolean
}

type AddPurchaseFormProps = {
  purchase?: Purchase
}
export const AddPurchaseForm: FC<AddPurchaseFormProps> = ({ purchase }) => {
  const {
    control,
    register,
    getValues,
    setValue,
    registerOptions,
    currencySymbol,
    programOptions,
    handleSubmit,
    errors,
    selectedProgram,
    categoryOptions,
    date,
    updateSelectedDate,
    setFile,
    handleSubmission,
    handleError,
    earliestReportableProgramDate,
    earliestDateInfo,
    addPurchaseQueryLoading,
    requestPurchaseEligibility,
    ongoingPurchases,
    amountMeetsThreshold,
    activeOngoingPurchaseForProgram,
    hasPendingOngoingPurchase,
    purchaseAmountRequired
  } = useAddPurchaseForm({ purchase })

  const isGracePeriod = useMemo(() => {
    if (!earliestReportableProgramDate) return false

    return !sameMonth(earliestReportableProgramDate.date, new Date())
  }, [earliestReportableProgramDate])

  return (
    <form onSubmit={handleSubmit(handleSubmission, handleError)}>
      <ModalBody maxHeight="calc(80vh - 150px)">
        <FormTextInput
          fieldName="name"
          disabled={!!purchase}
          errorPresent={!!errors?.name?.message}
          labelId="merchant.label"
          register={register}
          registerOptions={registerOptions.name}
        />
        <FormTextInput
          fieldName="description"
          errorPresent={!!errors?.description?.message}
          labelId="description.label"
          register={register}
          registerOptions={registerOptions.description}
        />
        <FormTextInput
          fieldName="amount"
          disabled={!!purchase}
          defaultValue={`${currencySymbol} `}
          errorPresent={!!errors?.amount?.message}
          labelId="amount.label"
          register={register}
          registerOptions={registerOptions.amount}
        />
        {programOptions.length > 1 && (
          <ControlledStyledSelect
            control={control}
            name={'benefitProgramId'}
            label={'Benefits Program'}
            options={programOptions}
            errorPresent={!!errors?.benefitProgramId?.message}
            placeholder={'Select a program...'}
            rules={registerOptions.selectedProgram}
          />
        )}
        {ongoingPurchases && (
          <OngoingPurchaseCheckbox
            getValues={getValues}
            setValue={setValue}
            hasPendingOngoingPurchase={hasPendingOngoingPurchase}
            activeOngoingPurchaseForProgram={activeOngoingPurchaseForProgram}
            amountMeetsThreshold={amountMeetsThreshold}
            purchaseAmountRequired={purchaseAmountRequired}
            currencySymbol={currencySymbol}
          />
        )}
        <ControlledStyledSelect
          control={control}
          name={'benefitProgramCategoryId'}
          label={'Benefits Category'}
          options={categoryOptions}
          errorPresent={!!errors?.benefitProgramCategoryId?.message}
          disabled={!selectedProgram || categoryOptions.length < 2}
          defaultValue={categoryOptions.length === 1 ? categoryOptions[0].value : undefined}
          placeholder={'Select a category...'}
          rules={registerOptions.selectedCategory}
        />

        <InputContainer>
          <StyledInputLabel>Purchase Date</StyledInputLabel>
          <FormDatePicker
            minDate={earliestReportableProgramDate?.date}
            maxDate={createDate()}
            date={date}
            onChange={updateSelectedDate}
            disabled={earliestReportableProgramDate && !!purchase}
            calenderSubText={earliestDateInfo}
            shownDate={isGracePeriod ? earliestReportableProgramDate?.date : undefined}
          />
        </InputContainer>
        {!purchase?.approved && <FileUpload setFileValue={setFile} fileType={FileType.Images} />}
      </ModalBody>

      <ButtonContainer marginTop={spacingMD}>
        <StyledButton
          primary
          id={purchase ? 'requestEligibility.label' : 'addPurchase.label'}
          isLoading={addPurchaseQueryLoading || requestPurchaseEligibility}
        />
      </ButtonContainer>
    </form>
  )
}
