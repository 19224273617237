import { useMemo } from 'react'

import { Modal } from '../Modal'
import { ModalHeader } from 'Components/Modal/ModalHeader'

import { ModalType, usePeoplePageContext } from 'Contexts/PeoplePageContext'
import { useEmployerUsersContext } from 'Contexts/EmployerUsersContext'

import { fontSizeLG, fontWeightThin, MediumBoldText, ThinDarkGreenText } from 'Utils/styles/text'
import { FlexColumn } from 'Utils/styles/spacing'
import { UserMembershipsTable } from 'Components/Modal/ViewUserMembershipsModal/UserMembershipsTable'
import styled from 'styled-components'
import { black } from 'Utils/styles/colors'

const HeaderSubtext = styled.span`
  color: ${black};
  font-weight: ${fontWeightThin};
  line-height: ${fontSizeLG};
  max-width: 700px;
`

export const ViewUserMembershipsModal = () => {
  const {
    state: { modal, actionableUserId },
    setModal
  } = usePeoplePageContext()

  const {
    state: { users }
  } = useEmployerUsersContext()

  const user = useMemo(() => {
    return users.find((user) => user.id == actionableUserId)
  }, [users, actionableUserId])

  const setShowModal = (show: boolean) => setModal(show ? ModalType.ViewUserMemberships : undefined)

  return (
    <Modal showModal={modal == ModalType.ViewUserMemberships} setShowModal={setShowModal}>
      <ModalHeader>Enrollment History</ModalHeader>
      <HeaderSubtext>
        Enrollments represent the benefits recipient&apos;s eligibility to participate in each
        benefits program.
      </HeaderSubtext>

      <FlexColumn>
        <MediumBoldText>
          {user?.userInfo.firstName} {user?.userInfo.lastName}
        </MediumBoldText>
        <ThinDarkGreenText>{user?.userInfo.email}</ThinDarkGreenText>
      </FlexColumn>

      <UserMembershipsTable
        memberships={[
          ...(user?.activeMemberships ?? []),
          ...(user?.futureMemberships ?? []),
          ...(user?.expiredMemberships ?? [])
        ]}
      />
    </Modal>
  )
}
