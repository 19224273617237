import { compact, max } from 'lodash'
import { RequestResponse } from 'Services/apiResponseTypes'
import { postRequest } from 'Services/baseRequests/postRequest'
import { putRequest } from 'Services/baseRequests/putRequest'
import { formatDateForServer } from 'Utils/helpers/dateUtils'

/**
 * Adds the given user to the list of given programs
 * @param payload The id of the user we are adding and a list of the program ids
 */

export type ChangeUserProgramPayload = { id: number; dateOfChange: Date }
type AddUsersToProgramPayloadApiPayload = {
  added_programs: { id: number; scheduled_start_date: string }[]
}

export const addUserToPrograms = async (payload: {
  userId: number
  programs: ChangeUserProgramPayload[]
}): Promise<RequestResponse> => {
  const response = await postRequest<RequestResponse, AddUsersToProgramPayloadApiPayload>(
    `/users/${payload.userId}/program_memberships`,
    {
      added_programs: payload.programs.map((p) => ({
        id: p.id,
        scheduled_start_date: formatDateForServer(p.dateOfChange)
      }))
    }
  )

  return {
    statusCode: response.statusCode,
    success: response?.success ?? false,
    message: response?.message ?? ''
  }
}

type RemovedProgramApiPayload = { scheduled_end_date: string }
/**
 * Removes the given user from the given program
 * @param payload The id of the user we are removing and the program to remove them from
 */
export const removeUserFromPrograms = async (payload: {
  userId: number
  programs: ChangeUserProgramPayload[]
}): Promise<RequestResponse> => {
  const requests = payload.programs.map((removedProgram) => {
    return putRequest<RequestResponse, RemovedProgramApiPayload>(
      `/users/${payload.userId}/program_memberships/${removedProgram.id}`,
      { scheduled_end_date: formatDateForServer(removedProgram.dateOfChange) }
    )
  })

  const responses = await Promise.all(requests)

  return {
    statusCode: max(compact(responses.map((r) => r.statusCode))),
    success: responses.every((r) => r?.success),
    message: responses.map((r) => r?.message).join(',')
  }
}
