import styled from 'styled-components'
import { InputLabelText } from 'Utils/styles/text'
import { black } from 'Utils/styles/colors'
import { GridRow, spacingSM, spacingXXS } from 'Utils/styles/spacing'

export const StyledInputLabel = styled.label`
  ${InputLabelText};
  color: ${black};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${spacingSM};
  padding-left: ${spacingXXS};
`

export const InputContainer = styled(GridRow)`
  grid-gap: ${spacingXXS};
`
