import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { QueryNames } from 'Hooks/api/queryNames'
import { AppNotification } from 'Utils/types/appNotification'
import { updateUserCountry } from 'Services/JOONAdmin/joonAdminUsersService'

export const useUpdateUserCountryQuery = () => {
  const queryClient = useQueryClient()

  return useMutation([QueryNames.UpdateUserCountry], updateUserCountry, {
    onSuccess: (response) => {
      if (response.success) {
        queryClient.invalidateQueries([QueryNames.UsersForEmployer])
        toast.success(AppNotification.UpdateUserCountrySuccess)
      } else {
        toast.error(`${AppNotification.UpdateUserCountryFailure} ${response.message}`)
      }
    },
    onError: () => {
      toast.error(`${AppNotification.UpdateUserCountryFailure}`)
    }
  })
}
