import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import styled from 'styled-components'

import { elfGreen, mineralGreen, white, zirconGray, Colors } from 'Utils/styles/colors'
import { fontSizeXS } from 'Utils/styles/text'
import { spacingSM, spacingXS, spacingLG } from 'Utils/styles/spacing'
import { Layer2 } from 'Utils/styles/zIndex'

export const DropdownTrigger = styled(DropdownMenu.Trigger)<{
  backgroundcolor?: Colors
  $wide?: boolean
}>`
  border: none;
  outline: none;
  background-color: ${(props) => (props.backgroundcolor ? props.backgroundcolor : white)};
  gap: ${spacingSM};
  align-items: center;
  justify-content: end;
  cursor: pointer;
  padding: 0;
  ${({ $wide }) => ($wide ? 'width: 100%;' : '')}
`

export const DropdownContent = styled(DropdownMenu.Content)<{ $absolute?: boolean }>`
  ${(props) => (props.$absolute ? 'position: absolute;' : '')}
  right: 0;
  min-width: 120px;
  background-color: ${white};
  margin: ${spacingXS} 0 0 ${spacingLG};
  padding: ${spacingSM} ${spacingSM};
  border-radius: 8px;
  border: 1px solid ${zirconGray};
  box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
  ${Layer2};
`

export const DropdownLabel = styled(DropdownMenu.Label)`
  display: flex;
  align-items: center;
  justify-content: start;
  vertical-align: middle;
  padding: ${spacingXS};
  gap: ${spacingSM};
  font-size: ${fontSizeXS};
`

export const DropdownItem = styled(DropdownMenu.Item)`
  display: flex;
  align-items: center;
  justify-content: start;
  vertical-align: middle;
  padding: 0 ${spacingXS};
  cursor: pointer;
  gap: ${spacingSM};

  font-size: ${fontSizeXS};
  height: 32px;
  min-width: 120px;
  color: ${mineralGreen};
  background-color: ${white};
  &:focus {
    outline: none;
    background-color: ${elfGreen};
    color: ${white};
    border-radius: 6px;
  }
  &[data-disabled] {
    color: ${zirconGray};
    cursor: initial;
  }
`

export const DropdownRoot = styled(DropdownMenu.Root)``
