import { useMutation, useQueryClient } from 'react-query'
import { QueryNames } from 'Hooks/api/queryNames'
import { toast } from 'react-toastify'
import { AppNotification } from 'Utils/types/appNotification'
import { createEmployerCountry } from 'Services/JOONAdmin/joonAdminEmployersService'

export const useCreateEmployerCountryQuery = () => {
  const queryClient = useQueryClient()

  return useMutation([QueryNames.CreateEmployerCountry], createEmployerCountry, {
    onSuccess: (response) => {
      if (response.success) {
        queryClient.invalidateQueries([QueryNames.EmployerDashboard])
        toast.success(AppNotification.CreateEmployerCountrySuccess)
      } else {
        toast.error(`${AppNotification.CreateEmployerCountryFailure} ${response.message}`)
      }
    },
    onError: () => {
      toast.error(AppNotification.CreateEmployerCountryFailure)
    }
  })
}
