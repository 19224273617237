import { useMutation } from 'react-query'
import { toast } from 'react-toastify'

import {
  benefitProgramReceiptDownload,
  BenefitProgramReceiptDownloadPayload
} from 'Services/JOONAdmin/joonAdminReceiptsService'

import { QueryNames } from 'Hooks/api/queryNames'

import { AppNotification } from 'Utils/types/appNotification'

export const useDownloadBenefitProgramReceipts = () =>
  useMutation(
    [QueryNames.BenefitProgramReceiptsDownload],
    async (payload: BenefitProgramReceiptDownloadPayload) => {
      const response = await benefitProgramReceiptDownload(payload)
      if (response.success) {
        toast.success(AppNotification.BenefitProgramReceiptDownloadSuccess)
      } else {
        toast.error(response.message || AppNotification.BenefitProgramReceiptDownloadFailure)
      }
    },
    {
      onError: () => {
        toast.error(AppNotification.BenefitProgramReceiptDownloadFailure)
        return undefined
      }
    }
  )
