import { useRef, useEffect, useState, MutableRefObject } from 'react'

export const useHover = <T>(): [MutableRefObject<T | null>, boolean] => {
  const [value, setValue] = useState(false)

  const ref: MutableRefObject<T | null> = useRef<T | null>(null)

  const handleMouseOver = (): void => setValue(true)
  const handleMouseOut = (): void => setValue(false)

  useEffect(
    () => {
      const node: any = ref.current
      if (node) {
        node.addEventListener('mouseover', handleMouseOver)
        node.addEventListener('mouseout', handleMouseOut)
        return () => {
          node.removeEventListener('mouseover', handleMouseOver)
          node.removeEventListener('mouseout', handleMouseOut)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ref.current] // Recall only if ref changes
  )
  return [ref, value]
}
