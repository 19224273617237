import { useQuery } from 'react-query'
import { useErrorReporter } from 'Hooks/useErrorReporter'
import { QueryNames } from 'Hooks/api/queryNames'
import {
  EmployerProgramsActionTypes,
  useEmployerProgramsContext
} from 'Contexts/EmployerProgramsContext'
import { getEmployerBenefitProgramDetails } from 'Services/employerBenefitPrograms'

export const useEmployerBenefitProgramsQuery = (
  monthlyUtilization: boolean,
  employerId?: number
) => {
  const reportError = useErrorReporter()
  const { dispatch } = useEmployerProgramsContext()
  return useQuery({
    cacheTime: 1,
    queryKey: [QueryNames.EmployerBenefitPrograms, employerId],
    queryFn: async () => {
      if (employerId === undefined) return

      const response = await getEmployerBenefitProgramDetails(employerId, monthlyUtilization)

      if (response.data?.benefitPrograms) {
        // Update monthly utilization stats for employer benefit programs
        dispatch({
          type: EmployerProgramsActionTypes.SetPrograms,
          value: response.data.benefitPrograms.filter(
            (bp) => !bp.policy.scheduledEndDate || bp.policy.scheduledEndDate > new Date()
          )
        })

        dispatch({
          type: EmployerProgramsActionTypes.SetProgramsIncludingExpired,
          value: response.data.benefitPrograms
        })

        return response
      } else {
        reportError('useEmployerBenefitProgramsQuery failure', response)
      }
    },
    onError: (error: { message: string }) => {
      reportError(`useEmployerBenefitProgramsQuery error: ${error.message}`)
    }
  })
}
