import { createContext, useContext, useReducer } from 'react'

import { ContextProviderProps } from 'Contexts/ContextProvider'

import { UserDetailsForEmployer } from 'Utils/types/user'
import { UserStatus } from 'Utils/types/userStatus'

export enum EmployerActionTypes {
  SetUsers = 'SET_USERS',
  SetLoading = 'SET_LOADING',
  RemoveUser = 'REMOVE_USER',
  ActivateUser = 'ACTIVATE_USER',
  AddRoles = 'ADD_ROLES',
  RemoveRoles = 'REMOVE_ROLES',
  ResetState = 'RESET_STATE'
}
type Action =
  | { type: EmployerActionTypes.SetUsers; value: UserDetailsForEmployer[] }
  | { type: EmployerActionTypes.SetLoading; value: boolean }
  | { type: EmployerActionTypes.RemoveUser; value: UserDetailsForEmployer['id'] }
  | { type: EmployerActionTypes.ActivateUser; value: UserDetailsForEmployer['id'] }
  | {
      type: EmployerActionTypes.AddRoles
      value: Required<Pick<UserDetailsForEmployer, 'id' | 'roles'>>
    }
  | {
      type: EmployerActionTypes.RemoveRoles
      value: Required<Pick<UserDetailsForEmployer, 'id' | 'roles'>>
    }
  | { type: EmployerActionTypes.ResetState }

type Dispatch = (action: Action) => void
type State = {
  users: UserDetailsForEmployer[]
  isLoading: boolean
}

const DEFAULT_STATE = {
  users: [],
  isLoading: false
}

const EmployerUsersStateContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(
  undefined
)

const userInfoReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case EmployerActionTypes.SetUsers: {
      return { ...state, users: [...action.value] }
    }
    case EmployerActionTypes.SetLoading: {
      return { ...state, isLoading: action.value }
    }
    case EmployerActionTypes.RemoveUser: {
      const index = state.users.findIndex((user) => user.id === action.value)
      const updatedUsers = [...state.users]
      if (index !== -1) {
        updatedUsers[index].status = UserStatus.Removed
      }
      return { ...state, users: updatedUsers }
    }
    case EmployerActionTypes.ActivateUser: {
      const index = state.users.findIndex((user) => user.id === action.value)
      const updatedUsers = [...state.users]
      if (index !== -1) {
        updatedUsers[index].status = UserStatus.Enabled
      }
      return { ...state, users: updatedUsers }
    }
    case EmployerActionTypes.AddRoles: {
      const index = state.users.findIndex((user) => user.id === action.value.id)
      const updatedUsers = [...state.users]
      if (index !== -1) {
        updatedUsers[index].roles = Array.from(
          new Set(action.value.roles.concat(updatedUsers[index].roles || []))
        )
      }
      return { ...state, users: updatedUsers }
    }
    case EmployerActionTypes.RemoveRoles: {
      const index = state.users.findIndex((user) => user.id === action.value.id)
      const updatedUsers = [...state.users]
      if (index !== -1) {
        const roles = new Set(updatedUsers[index].roles)
        action.value.roles.forEach((role) => roles.delete(role))
        updatedUsers[index].roles = Array.from(roles)
      }
      return { ...state, users: updatedUsers }
    }
    case EmployerActionTypes.ResetState: {
      return DEFAULT_STATE
    }
    default: {
      throw new Error(`Invalid EmployerActionType`)
    }
  }
}

const EmployerUsersProvider = ({ children }: ContextProviderProps) => {
  const [state, dispatch] = useReducer(userInfoReducer, DEFAULT_STATE)
  const value = { state, dispatch }
  return (
    <EmployerUsersStateContext.Provider value={value}>
      {children}
    </EmployerUsersStateContext.Provider>
  )
}

const useEmployerUsersContext = () => {
  const context = useContext(EmployerUsersStateContext)
  if (context === undefined) {
    throw new Error('useEmployerUsers must be used within an EmployerUsersProvider')
  }
  return context
}

export { EmployerUsersProvider, useEmployerUsersContext }
