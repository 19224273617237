import { useMutation, useQueryClient } from 'react-query'
import { QueryNames } from 'Hooks/api/queryNames'
import { toast } from 'react-toastify'
import { AppNotification } from 'Utils/types/appNotification'
import { updateEmployercountry } from 'Services/JOONAdmin/joonAdminEmployersService'

export const useUpdateEmployerCountryQuery = () => {
  const queryClient = useQueryClient()

  return useMutation([QueryNames.UpdateEmployerCountry], updateEmployercountry, {
    onSuccess: (response) => {
      if (response.success) {
        queryClient.invalidateQueries([QueryNames.EmployerDashboard])
        toast.success(AppNotification.UpdateEmployerCountrySuccess)
      } else {
        toast.error(`${AppNotification.UpdateEmployerCountryFailure} ${response.message}`)
      }
    },
    onError: () => {
      toast.error(AppNotification.UpdateEmployerCountryFailure)
    }
  })
}
