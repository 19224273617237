import { useMutation, useQueryClient } from 'react-query'
import { requestPurchaseEligibility } from 'Services/purchases/purchasesService'
import { QueryNames } from 'Hooks/api/queryNames'
import { ModalActionTypes, useModalContext } from 'Contexts/ModalContext'
import { toast } from 'react-toastify'
import { AppNotification } from 'Utils/types/appNotification'

export const useRequestPurchaseEligibilityQuery = () => {
  const { dispatch } = useModalContext()
  const queryClient = useQueryClient()

  return useMutation([QueryNames.RequestPurchaseEligibility], requestPurchaseEligibility, {
    onSuccess: (response) => {
      if (response.success) {
        dispatch({ type: ModalActionTypes.SetShowRequestPurchaseEligibilityModal, value: false })
        queryClient.invalidateQueries([QueryNames.GetUserPurchases])
        queryClient.invalidateQueries([QueryNames.GetUserOngoingPurchases])
        queryClient.invalidateQueries([QueryNames.GetUserPendingOPRPurchases])
        toast.success(AppNotification.RequestPurchaseEligibilitySuccess)
      } else {
        toast.error(AppNotification.RequestPurchaseEligibilityFailure)
      }
    },
    onError: () => {
      toast.error(AppNotification.RequestPurchaseEligibilityFailure)
    }
  })
}
