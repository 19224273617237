import { useMemo } from 'react'
import { useEmployerInfoContext } from 'Contexts/EmployerInfoContext'
import { paymentInfoWithStars } from 'Utils/helpers/paymentTypes'
import { CountryCode } from 'Utils/types/countries'
import { PaymentMethodType, isCardPayment } from 'Utils/types/paymentMethod'

export function useInvoicePaymentMethodOptions(
  countryCode: CountryCode,
  alternativeReimbursement: boolean
) {
  const {
    state: { paymentMethods }
  } = useEmployerInfoContext()

  const filteredPaymentMethods = useMemo(() => {
    return paymentMethods.filter((paymentMethod) => {
      // Credit card payments can always be used
      if (isCardPayment(paymentMethod)) return true

      const isCountryMatch = paymentMethod.countryCode === countryCode
      const isUSBank = paymentMethod.type === PaymentMethodType.USBank

      if (alternativeReimbursement) {
        return isCountryMatch || isUSBank || paymentMethod.countryCode === 'US'
      } else {
        return isCountryMatch || (countryCode === 'US' && isUSBank)
      }
    })
  }, [paymentMethods, countryCode, alternativeReimbursement])

  const paymentMethodOptions = useMemo(() => {
    return filteredPaymentMethods.map((paymentMethod) => ({
      value: paymentMethod.id.toString(),
      label: paymentInfoWithStars(paymentMethod)
    }))
  }, [filteredPaymentMethods])

  return { paymentMethods, paymentMethodOptions }
}
