import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { CheckIcon, ChevronDownIcon } from '@radix-ui/react-icons'
import * as SelectPrimitive from '@radix-ui/react-select'
import {
  black,
  elfGreen,
  frostedGlass,
  mineralGreen,
  towerGray,
  white,
  zirconGray,
  red
} from 'Utils/styles/colors'
import styled from 'styled-components'
import { BaseText } from 'Utils/styles/text'
import { spacingSM } from 'Utils/styles/spacing'
import { Layer3 } from 'Utils/styles/zIndex'

const StyledTrigger = styled(SelectPrimitive.SelectTrigger)<{
  error: 'y' | 'n'
}>`
  all: unset;
  ${BaseText};
  display: grid;
  grid-template-columns: auto 15px;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;

  height: 50px;
  box-sizing: border-box;

  padding: 0 ${spacingSM};
  background-color: ${white};

  border-radius: 8px;
  border: 1.5px solid ${(props) => (props.error === 'y' ? red : zirconGray)};
  color: ${(props) => (props.error === 'y' ? red : black)};

  &[data-placeholder] {
    color: ${towerGray};
  }

  &:hover {
    border: 1.5px solid ${towerGray};

    &:disabled {
      border: 1.5px solid ${zirconGray};
      cursor: not-allowed;
    }

    &[data-placeholder] {
      color: ${mineralGreen};
    }
  }

  &:focus {
    outline-width: 0;
    border: 1.5px solid ${elfGreen};
    color: ${black};
  }

  &:disabled {
    background-color: ${frostedGlass};
    caret-color: transparent;
  }
`

const StyledIcon = styled(SelectPrimitive.SelectIcon)`
  color: ${elfGreen};
`

const StyledViewport = styled(SelectPrimitive.Viewport)`
  background-color: ${white};
  border: 1.5px solid ${zirconGray};
  border-radius: 8px;
  padding: ${spacingSM};
  box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
`
const StyledPortal = styled(SelectPrimitive.Portal)`
  ${Layer3};
`

type ContentProps = {
  children: JSX.Element
}
const Content = ({ children, ...props }: ContentProps) => (
  <StyledPortal>
    <SelectPrimitive.Content {...props}>{children}</SelectPrimitive.Content>
  </StyledPortal>
)

const StyledItem = styled(SelectPrimitive.Item)`
  all: unset;
  display: flex;
  align-items: center;
  height: 45px;
  padding: 0 35px 0 25px;
  border: none;
  border-radius: 8px;
  cursor: pointer;

  &[data-highlighted] {
    background-color: ${elfGreen};
    color: ${white};
  }
`

const StyledItemIndicator = styled(SelectPrimitive.ItemIndicator)`
  position: absolute;
  right: 10px;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`

const SelectValue = SelectPrimitive.Value
const StyledItemText = SelectPrimitive.ItemText
const StyledGroup = SelectPrimitive.Group
const StyledSelectRoot = SelectPrimitive.Root

export type SelectOption<T = string> = { label: string; value: T }

export interface StyledSelectRef {
  resetSelect: () => void
}

type StyledSelectProps<T> = {
  options: SelectOption<T>[]
  handleChange: (value: T) => void
  errorPresent?: boolean
  disabled?: boolean
  defaultValue?: T
  placeholderText?: string
  ref?: React.Ref<StyledSelectRef>
}
const InternalStyledSelect = <T extends string>(
  {
    options,
    handleChange,
    disabled = false,
    defaultValue,
    errorPresent,
    placeholderText = 'Select one...'
  }: StyledSelectProps<T>,
  ref: React.Ref<StyledSelectRef>
) => {
  const [value, setValue] = useState<T | undefined>(undefined)

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const handleValueChange = (value: T) => {
    setValue(value)
    handleChange(value)
  }

  const resetSelect = () => {
    setValue(defaultValue)
  }

  // Expose the resetSelect method through the ref
  useImperativeHandle(ref, () => ({
    resetSelect
  }))

  return (
    <StyledSelectRoot value={value} onValueChange={handleValueChange} disabled={disabled}>
      <StyledTrigger error={errorPresent ? 'y' : 'n'}>
        <SelectValue placeholder={placeholderText} />
        <StyledIcon>
          <ChevronDownIcon />
        </StyledIcon>
      </StyledTrigger>
      <Content>
        <StyledViewport>
          <StyledGroup>
            {options.map((option, index) => {
              return (
                <StyledItem key={index} value={option.value}>
                  <StyledItemText>{option.label}</StyledItemText>
                  <StyledItemIndicator>
                    <CheckIcon />
                  </StyledItemIndicator>
                </StyledItem>
              )
            })}
          </StyledGroup>
        </StyledViewport>
      </Content>
    </StyledSelectRoot>
  )
}

export const StyledSelect = forwardRef(InternalStyledSelect) as <T extends string>(
  props: StyledSelectProps<T> & { ref?: React.Ref<StyledSelectRef> }
) => JSX.Element
