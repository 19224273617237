import { createContext, useContext, useReducer } from 'react'

import { ContextProviderProps } from 'Contexts/ContextProvider'

export enum ModalActionTypes {
  SetShowAddPurchaseModal = 'SET_SHOW_ADD_PURCHASE_MODAL',
  SetShowConnectAccountsDrawer = 'SET_SHOW_CONNECTED_ACCOUNTS_DRAWER',
  SetShowAddPaymentMethodModal = 'SET_SHOW_ADD_PAYMENT_METHOD_MODAL',
  SetShowRequestPurchaseEligibilityModal = 'SET_SHOW_REQUEST_PURCHASE_ELIGIBILITY_MODAL',
  SetShowConfirmConsentModal = 'SET_SHOW_CONFIRM_CONSENT_MODAL',
  ResetState = 'RESET_STATE'
}

const ACTION_TO_STATE_MAP = {
  [ModalActionTypes.SetShowAddPurchaseModal]: 'showAddPurchaseModal',
  [ModalActionTypes.SetShowConnectAccountsDrawer]: 'showConnectedAccountsDrawer',
  [ModalActionTypes.SetShowAddPaymentMethodModal]: 'showAddPaymentMethodModal',
  [ModalActionTypes.SetShowRequestPurchaseEligibilityModal]: 'showRequestPurchaseEligibilityModal',
  [ModalActionTypes.SetShowConfirmConsentModal]: 'showConfirmConsentModal'
}

type Action = { type: ModalActionTypes; value: boolean } | { type: ModalActionTypes.ResetState }
type Dispatch = (action: Action) => void
type State = {
  showAddPurchaseModal: boolean
  showConnectedAccountsDrawer: boolean
  showAddPaymentMethodModal: boolean
  showRequestPurchaseEligibilityModal: boolean
  showConfirmConsentModal: boolean
}

const DEFAULT_STATE = {
  showAddPurchaseModal: false,
  showConnectedAccountsDrawer: false,
  showAddPaymentMethodModal: false,
  showRequestPurchaseEligibilityModal: false,
  showConfirmConsentModal: false
}

const ModalStateContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined)
const modalReducer = (state: State, action: Action) => {
  const closedModalState = Object.assign({}, state)
  Object.keys(state).forEach((k) => (closedModalState[k as keyof State] = false))

  if (action.type === ModalActionTypes.ResetState) {
    return DEFAULT_STATE
  } else if (Object.prototype.hasOwnProperty.call(ACTION_TO_STATE_MAP, action.type)) {
    const stateKey = ACTION_TO_STATE_MAP[action.type]
    return {
      ...closedModalState,
      [stateKey]: action.value
    }
  } else {
    throw new Error('Invalid action type')
  }
}

const ModalProvider = ({ children }: ContextProviderProps) => {
  const [state, dispatch] = useReducer(modalReducer, DEFAULT_STATE)
  const value = { state, dispatch }
  return <ModalStateContext.Provider value={value}>{children}</ModalStateContext.Provider>
}

const useModalContext = () => {
  const context = useContext(ModalStateContext)
  if (context === undefined) {
    throw new Error('useModal must be used within an ModalProvider')
  }
  return context
}

export { ModalProvider, useModalContext }
