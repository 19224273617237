import { useEffect, useState } from 'react'
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useConfirmationModalContext } from 'Hooks/modals/useConfirmationModal'
import { PolicyPeriodDuration, RolloverOptions } from 'Utils/types/benefitProgramPolicy'
import { CurrencySymbol, getCurrency } from 'Utils/types/currencies'
import { BenefitProgramCategory } from 'Utils/types/benefitProgramCategory'
import { useCreateBenefitProgramQuery } from 'Hooks/api/JOONAdmin/useCreateBenefitProgramQuery'
import { CountryCode } from 'Utils/types/countries'
import {
  doubleDecimalRegex,
  nonNumberRegexNoNegative,
  singleDecimalRegex
} from 'Utils/helpers/currency'
import { useEmployerInfoContext } from 'Contexts/EmployerInfoContext'
import { EmployerBenefitProgram } from 'Utils/types/benefitProgram'
import { useUpdateBenefitProgramQuery } from 'Hooks/api/JOONAdmin/useUpdateBenefitProgramQuery'
import { useUpdateBenefitProgramPolicyQuery } from 'Hooks/api/JOONAdmin/useUpdateBenefitProgramPolicyQuery'
import { useEndBenefitProgramPolicyQuery } from 'Hooks/api/JOONAdmin/useEndBenefitProgramPolicyQuery'
import { useCountries } from 'Hooks/useCountries'
import isSameDay from 'date-fns/isSameDay'

export type BenefitProgramFormValues = {
  name: string
  scheduledStartDate: Date
  scheduledEndDate?: Date
  allowance: string
  policyPeriod: PolicyPeriodDuration
  rollover: boolean
  ongoingPurchases: boolean
  ongoingPurchaseThreshold?: number
  countries: CountryCode[]
  benefitProgramLink: string
  selectedCategories: BenefitProgramCategory['id'][]
  deleteFuturePolicies?: boolean
}

const DEFAULT_THRESHOLD = 2.0

export const useBenefitProgramForm = (
  closeModal: () => void,
  benefitProgram?: EmployerBenefitProgram
) => {
  const { countryOptions } = useCountries()

  const defaultValues: Partial<BenefitProgramFormValues> | undefined = benefitProgram
    ? {
        name: benefitProgram.name,
        scheduledStartDate: benefitProgram.policy.scheduledStartDate,
        scheduledEndDate: benefitProgram.policy.scheduledEndDate,
        allowance: benefitProgram.policy.expenditureAllowance.toString(),
        policyPeriod: benefitProgram.policy.policyPeriodDuration,
        rollover: benefitProgram.policy.rollover === RolloverOptions.Indefinite,
        ongoingPurchases: benefitProgram.policy.ongoingPurchases,
        ongoingPurchaseThreshold:
          benefitProgram.policy.ongoingPurchaseThreshold ?? DEFAULT_THRESHOLD,
        countries: benefitProgram.countries,
        benefitProgramLink: benefitProgram.programDescriptionLink,
        selectedCategories: benefitProgram.categories.map((cat) => cat.id),
        deleteFuturePolicies: false
      }
    : {
        ongoingPurchaseThreshold: DEFAULT_THRESHOLD,
        countries: [countryOptions[0].value]
      }

  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm<BenefitProgramFormValues>({ defaultValues })

  const {
    state: { employerId }
  } = useEmployerInfoContext()

  const { mutate: createBenefitProgramQuery } = useCreateBenefitProgramQuery()
  const { mutate: updateBenefitProgramQuery } = useUpdateBenefitProgramQuery()
  const { mutate: updateBenefitProgramPolicyQuery } = useUpdateBenefitProgramPolicyQuery()
  const { mutate: endBenefitProgramPolicyQuery } = useEndBenefitProgramPolicyQuery()

  const [programCadence, setProgramCadence] = useState(
    benefitProgram ? benefitProgram.policy.policyPeriodDuration : PolicyPeriodDuration.Monthly
  )

  const [startDateChanged, setStartDateChanged] = useState(false)

  const { showModal } = useConfirmationModalContext()

  const firstSelectedCountry = watch('countries')
  const currency = CurrencySymbol[getCurrency(firstSelectedCountry?.[0] ?? CountryCode.US)]

  useEffect(() => {
    const currentAllowanceValue = watch('allowance')
    // Strip out everything before the first space
    const cleanedValue = currentAllowanceValue.replace(/^\S*\s/, '').trim()
    // Add the new currency symbol and set the updated value to the allowance field
    setValue('allowance', `${currency} ${cleanedValue}`)
  }, [currency, setValue, watch])

  const handleSubmission: SubmitHandler<BenefitProgramFormValues> = async (data) => {
    if (!programCadence) {
      toast.error('Please select the policy period duration.')
      return
    }
    if (!data.selectedCategories.length) {
      toast.error('Please select a category.')
      return
    }
    if (!data.countries.length) {
      toast.error('Please select a country.')
      return
    }

    const result = await showModal(
      benefitProgram ? 'Update Program' : 'Create Program',
      `Are you sure you want to ${benefitProgram ? 'update' : 'create'} this program?`
    )

    if (!result || !employerId) {
      return
    }

    if (benefitProgram) {
      updateBenefitProgramQuery({
        ...data,
        rollover: data.rollover ? RolloverOptions.Indefinite : RolloverOptions.Disabled,
        benefitProgramId: benefitProgram.id
      })
    } else {
      createBenefitProgramQuery({
        ...data,
        rollover: data.rollover ? RolloverOptions.Indefinite : RolloverOptions.Disabled,
        employerId: employerId,
        allowance: data.allowance.replace(currency, '').trim(),
        ongoingPurchases:
          programCadence === PolicyPeriodDuration.Yearly ? false : data.ongoingPurchases,
        policyPeriod: programCadence
      })
    }
    closeModal()
  }

  const handleError: SubmitErrorHandler<BenefitProgramFormValues> = (error) => {
    toast.error(
      error.name?.message ??
        error.selectedCategories?.message ??
        error.countries?.message ??
        error.benefitProgramLink?.message ??
        error.scheduledStartDate?.message ??
        error.allowance?.message ??
        error.policyPeriod?.message ??
        error.ongoingPurchaseThreshold?.message ??
        error.scheduledEndDate?.message
    )
  }

  const handleEndPolicy: SubmitHandler<BenefitProgramFormValues> = async (data) => {
    if (!benefitProgram) return

    const { scheduledEndDate, deleteFuturePolicies } = data

    if (!scheduledEndDate) return

    endBenefitProgramPolicyQuery({
      benefitProgramId: benefitProgram.id,
      endDate: scheduledEndDate,
      deleteFuturePolicies: !!deleteFuturePolicies
    })

    closeModal()
  }

  const handleUpdatePolicy: SubmitHandler<BenefitProgramFormValues> = async (data) => {
    if (!benefitProgram) return

    const { scheduledStartDate, allowance, rollover, ongoingPurchases, ongoingPurchaseThreshold } =
      data

    const cleanedAllowance = allowance.replace(currency, '').trim()

    if (cleanedAllowance === '') {
      toast.error('Please enter a valid expenditure allowance.')
      return
    }

    updateBenefitProgramPolicyQuery({
      benefitProgramId: benefitProgram.id,
      startDate: scheduledStartDate,
      allowance: cleanedAllowance,
      rollover,
      ongoingPurchases,
      ongoingPurchaseThreshold: ongoingPurchaseThreshold || DEFAULT_THRESHOLD
    })

    closeModal()
  }

  const policyPeriodOptions = Object.keys(PolicyPeriodDuration).map((key) => {
    const value = PolicyPeriodDuration[key as keyof typeof PolicyPeriodDuration]
    return { value, label: key }
  })

  const benefitProgramRegisterOptions = {
    name: { required: 'Please enter a name for the program' },
    benefitProgramLink: { required: 'Please provide a category guide link' },
    scheduledStartDate: {
      required: 'Please provide a program start date',
      onChange: (selectedDate: Date) => {
        if (!startDateChanged) setStartDateChanged(true)
        return selectedDate
      }
    },
    ongoingPurchaseThreshold: {
      required: 'Please enter an ongoing purchase thresold',
      onChange: (e: { target: { value: string } }) => {
        e.target.value = e.target.value
          .replace(nonNumberRegexNoNegative, '')
          // Prevent multiple decimal points from being used
          .replace(singleDecimalRegex, '$1')
          // Prevent more than 2 digits after the decimal
          .replace(doubleDecimalRegex, '$1')
        return e
      }
    },
    allowance: {
      required: 'Amount is required',
      onChange: (e: { target: { value: string } }) => {
        const cleanedValue = e.target.value
          .replace(nonNumberRegexNoNegative, '')
          // Prevent multiple decimal points from being used
          .replace(singleDecimalRegex, '$1')
          // Prevent more than 2 digits after the decimal
          .replace(doubleDecimalRegex, '$1')

        e.target.value = `${currency} ${cleanedValue}`
        return e
      }
    },
    updatePolicyStartDate: {
      validate: (selectedDate: Date) => {
        const errorMessage = 'Please enter a valid policy start date'
        const defaultStartDate = defaultValues.scheduledStartDate

        if (!defaultStartDate) return errorMessage

        // If there is no active policy, start date can be the same default date
        if (defaultStartDate > new Date()) {
          return startDateChanged ? true : errorMessage
        }

        const invalidDate = isSameDay(selectedDate, defaultStartDate)

        return invalidDate ? errorMessage : true
      }
    },
    endPolicyEndDate: {
      validate: (selectedDate?: Date) => {
        const invalidDateMessage = 'Please enter a valid policy end date'
        const unchangedDateMessage = 'Please select a different policy end date'

        if (!selectedDate) return invalidDateMessage

        const { scheduledEndDate } = defaultValues
        const endDateSame = !scheduledEndDate ? false : isSameDay(selectedDate, scheduledEndDate)

        return endDateSame ? unchangedDateMessage : true
      }
    }
  }

  return {
    register,
    control,
    handleSubmit,
    errors,
    watch,
    programCadence,
    setProgramCadence,
    handleSubmission,
    handleError,
    handleUpdatePolicy,
    handleEndPolicy,
    policyPeriodOptions,
    benefitProgramRegisterOptions
  }
}
