import { useMutation, useQueryClient } from 'react-query'
import { QueryNames } from 'Hooks/api/queryNames'
import { toast } from 'react-toastify'
import { AppNotification } from 'Utils/types/appNotification'
import { updateBenefitProgramPolicy } from 'Services/JOONAdmin/benefitProgramCategoriesService'

export const useUpdateBenefitProgramPolicyQuery = () => {
  const queryClient = useQueryClient()

  return useMutation([QueryNames.UpdateBenefitProgramPolicy], updateBenefitProgramPolicy, {
    onSuccess: (response) => {
      if (response.success) {
        queryClient.invalidateQueries([QueryNames.EmployerBenefitPrograms])
        toast.success(AppNotification.UpdateBenefitProgramPolicySuccess)
      } else {
        toast.error(`${AppNotification.UpdateBenefitProgramPolicyFailure} ${response.message}`)
      }
    },
    onError: () => {
      toast.error(AppNotification.UpdateBenefitProgramPolicyFailure)
    }
  })
}
