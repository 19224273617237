import styled, { css } from 'styled-components'
import { spacingXS } from 'Utils/styles/spacing'
import { fontWeightThin } from 'Utils/styles/text'

export const CheckboxContainer = styled.div<{ $clickable?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: ${spacingXS};
  align-items: center;
  font-weight: ${fontWeightThin};
  ${({ $clickable }) =>
    $clickable &&
    css`
      cursor: pointer;
    `}
`
