import { useCallback, useEffect, useMemo, useState } from 'react'
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { useProgramOptions } from 'Hooks/useProgramOptions'
import { useAddPurchaseQuery } from 'Hooks/api/user/purchases/useAddPurchaseQuery'
import { useRequestPurchaseEligibilityQuery } from 'Hooks/api/user/purchases/useRequestPurchaseEligibilityQuery'
import { EarliestDateType, useEarliestReportableDate } from 'Hooks/useEarliestReportableDate'
import { CurrencySymbol } from 'Utils/types/currencies'
import {
  displayDate,
  displayMonthAndYear,
  firstOfPreviousMonth,
  formatDateForServer
} from 'Utils/helpers/dateUtils'
import { AddPurchaseFormValues } from 'Components/Modal/AddPurchaseModal/AddPurchaseForm'
import { Purchase } from 'Utils/types/purchase'
import { useUserInfoContext } from 'Contexts/UserInfoContext'
import { useProgramOngoingPurchaseInfo } from 'Components/Modal/AddPurchaseModal/useProgramOngoingPurchaseInfo'
import {
  nonNumberRegexNoNegative,
  singleDecimalRegex,
  doubleDecimalRegex
} from 'Utils/helpers/currency'

type UseAddPurchaseFormProps = {
  purchase?: Purchase
}

export const useAddPurchaseForm = ({ purchase }: UseAddPurchaseFormProps) => {
  const {
    state: { userId, user }
  } = useUserInfoContext()

  const {
    control,
    register,
    reset,
    watch,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm<AddPurchaseFormValues>()

  const currencySymbol = useMemo(
    () => (user?.currencyCode ? CurrencySymbol[user.currencyCode] : '$'),
    [user]
  )

  useEffect(() => {
    if (purchase) {
      reset({ name: purchase.name, amount: `${currencySymbol} ${purchase.amount.toString()}` })
      setDate(purchase.date)
    }
  }, [purchase, currencySymbol, reset])

  const { programOptions, categoryMap } = useProgramOptions({
    useName: false,
    useProgramCategoryId: !!purchase
  })

  // Program dropdown state
  // Sets a default state ONLY if user has ONE (1) program
  useEffect(() => {
    setValue('benefitProgramId', programOptions.length === 1 ? programOptions[0].value : '')
  }, [programOptions, setValue])

  const selectedProgram = watch('benefitProgramId')

  const categoryOptions = useMemo(
    () => (selectedProgram ? categoryMap[selectedProgram] : []),
    [categoryMap, selectedProgram]
  )

  // Keep track of selected program and update categories as necessary
  useEffect(() => {
    setValue(
      'benefitProgramCategoryId',
      categoryOptions.length === 1 ? categoryOptions[0].value : ''
    )
  }, [categoryOptions, setValue])

  const [date, setDate] = useState<Date | undefined>(undefined)
  const updateSelectedDate = useCallback((newDate: Date) => setDate(newDate), [])

  const [file, setFile] = useState<File | null>(null)

  /**
   * If the user is requesting eligibility on an already existing purchase, we use a slightly different
   * submission flow which includes information from the existing purchase.
   * @param data The purchase data needed for respective flows
   */
  const handleSubmission: SubmitHandler<AddPurchaseFormValues> = async (data) => {
    if (purchase) {
      handleRequestEligibility(data)
    } else {
      handleAddPurchase(data)
    }
  }

  // Handle submission of the form
  const addPurchaseQuery = useAddPurchaseQuery()
  const handleAddPurchase: SubmitHandler<AddPurchaseFormValues> = async (data) => {
    if (date === undefined) {
      toast.error('A purchase date is required.')
      return
    }
    if (!file) {
      toast.error('A purchase receipt is required.')
      return
    }

    const {
      name,
      amount,
      description,
      ongoingPurchase,
      benefitProgramId,
      benefitProgramCategoryId
    } = data
    const formData = new FormData()

    // Extra protection in case OPR is somehow true && it is not eligible
    // Lobster will return an error, so we don't want to degrade UX (user will keep seeing fail toast)
    const oprEligilbe =
      amountMeetsThreshold && !activeOngoingPurchaseForProgram && !hasPendingOngoingPurchase
    const opr = oprEligilbe && ongoingPurchase ? 'true' : 'false'

    formData.append('user_id', userId?.toString() ?? '')
    formData.append('name', name)
    formData.append('amount', amount.replace(currencySymbol, '').trim())
    formData.append('ongoing_purchase', opr)
    formData.append('benefit_program_id', benefitProgramId)
    formData.append('basic_benefit_program_category_id', benefitProgramCategoryId)
    formData.append('payment_meta', description)
    formData.append('transaction_date', formatDateForServer(date))
    formData.append('file0', file as Blob)

    addPurchaseQuery.mutate({ formData })
  }

  const requestPurchaseEligibility = useRequestPurchaseEligibilityQuery()
  const handleRequestEligibility: SubmitHandler<AddPurchaseFormValues> = async (data) => {
    if (!purchase) {
      toast.error('Missing purchase information.')
      return
    }

    const { description, ongoingPurchase, benefitProgramCategoryId } = data
    const formData = new FormData()
    const reportDetails = { benefit_program_category_id: benefitProgramCategoryId, description }

    formData.append('report_details', JSON.stringify(reportDetails))
    formData.append('ongoing_purchase', ongoingPurchase ? 'true' : 'false')

    if (file) {
      formData.append('file0', file as Blob)
    }

    requestPurchaseEligibility.mutate({ formData, purchaseId: purchase?.id })
  }

  // Handle when there are errors on the form
  const handleError: SubmitErrorHandler<AddPurchaseFormValues> = (error) => {
    toast.error(
      error.name?.message ??
        error.description?.message ??
        error.amount?.message ??
        error.benefitProgramId?.message ??
        error.benefitProgramCategoryId?.message
    )

    const scrollToRef =
      error.name?.ref ??
      error.description?.ref ??
      error.amount?.ref ??
      error.benefitProgramId?.ref ??
      error.benefitProgramCategoryId?.ref

    if (scrollToRef?.scrollIntoView) {
      scrollToRef.scrollIntoView({ behavior: 'smooth' })
      return
    }

    if (scrollToRef?.name) {
      const element = document.getElementById(`scroll_${scrollToRef.name}`)
      element?.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const registerOptions = {
    name: { required: 'Merchant is required' },
    amount: {
      required: 'Amount is required',
      validate: (v: string | number | boolean) => {
        const err = 'Amount is required'
        if (typeof v !== 'string') return err
        const parts = v.split(' ')
        if (parts.length < 2 || parts[1] === '' || Number.isNaN(v.split(' ')[1])) return err
      },
      onChange: (e: { target: { value: string } }) => {
        const cleanedValue = e.target.value
          .replace(nonNumberRegexNoNegative, '')
          // Prevent multiple decimal points from being used
          .replace(singleDecimalRegex, '$1')
          // Prevent more than 2 digits after the decimal
          .replace(doubleDecimalRegex, '$1')

        e.target.value = `${currencySymbol} ${cleanedValue}`
        return e
      }
    },
    description: { required: 'Description is required' },
    selectedCategory: { required: 'Benefits category is required' },
    selectedProgram: { required: 'Benefits program is required' }
  }

  const { earliestReportableProgramDate } = useEarliestReportableDate(selectedProgram)
  const earliestDateInfo = !earliestReportableProgramDate
    ? ''
    : earliestReportableProgramDate?.type === EarliestDateType.Membership
    ? `Purchase must be dated beginning from the membership start date (${displayDate(
        earliestReportableProgramDate.date
      )}).`
    : `Purchase must be dated after the last invoice period \n (${displayMonthAndYear(
        firstOfPreviousMonth(earliestReportableProgramDate.date)
      )}).`

  const formAmount = watch('amount')
  const currentAmount = useMemo(() => {
    return formAmount ? Number(formAmount.replace(currencySymbol, '').trim()) : 0
  }, [formAmount, currencySymbol])

  const {
    ongoingPurchases,
    ongoingPurchaseThreshold,
    amountMeetsThreshold,
    purchaseAmountRequired,
    activeOngoingPurchaseForProgram,
    hasPendingOngoingPurchase
  } = useProgramOngoingPurchaseInfo(selectedProgram, currentAmount)

  return {
    control,
    register,
    getValues,
    setValue,
    registerOptions,
    currencySymbol,
    programOptions,
    handleSubmit,
    errors,
    selectedProgram,
    categoryOptions,
    date,
    updateSelectedDate,
    file,
    setFile,
    handleSubmission,
    handleError,
    earliestReportableProgramDate,
    earliestDateInfo,
    ongoingPurchases,
    amountMeetsThreshold,
    ongoingPurchaseThreshold,
    activeOngoingPurchaseForProgram,
    hasPendingOngoingPurchase,
    purchaseAmountRequired,
    addPurchaseQueryLoading: addPurchaseQuery.isLoading,
    requestPurchaseEligibility: requestPurchaseEligibility.isLoading
  }
}
