import { FC } from 'react'
import styled from 'styled-components'
import { TableRow } from '@mui/material'
import TableCell from '@mui/material/TableCell'

import { SimpleCircularProgress } from 'Components/CircularProgress/SimpleCircularProgress'
import { MediumBoldText } from 'Utils/styles/text'

const Container = styled(TableRow)`
  height: 635px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
const StyledCell = styled(TableCell)`
  text-align: center !important;
`
type TableLoadingProps = {
  loading: boolean
}
export const TableContentPlaceholder: FC<TableLoadingProps> = ({ loading }) => {
  return (
    <Container>
      <StyledCell colSpan={8}>
        {loading ? (
          <SimpleCircularProgress />
        ) : (
          <MediumBoldText>There are no results matching the criteria.</MediumBoldText>
        )}
      </StyledCell>
    </Container>
  )
}
