import { CountryCode, CountryNames } from 'Utils/types/countries'
import { SelectOption } from 'Components/Inputs/StyledSelect'
import { ascend, descend, sortWith } from 'ramda'

export const convertCountriesToOptions = (
  countries: CountryCode[] | undefined
): SelectOption<CountryCode>[] => {
  return sortWith(
    [descend(({ value }) => value === CountryCode.US), ascend(({ label }) => label)],
    (countries || []).map((countryCode) => ({
      value: countryCode,
      label: CountryNames[countryCode]
    }))
  )
}

export const getAllCountryOptions = () => {
  const allCountryCodes = Object.keys(CountryNames) as CountryCode[]

  return convertCountriesToOptions(allCountryCodes)
}

export const filterCountryOptions = (currentCountries?: CountryCode[]) => {
  const allCountryOptions = getAllCountryOptions()

  if (!currentCountries) return allCountryOptions

  return allCountryOptions.filter(
    (countryOption) => !currentCountries.includes(countryOption.value)
  )
}
