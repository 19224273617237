import React from 'react'
import { useForm } from 'react-hook-form'
import { FlexColumn, FlexRow, spacingMD, spacingSM, spacingXL } from 'Utils/styles/spacing'
import { StyledButton } from 'Components/Buttons/StyledButton'
import { ModalBody } from 'Components/Modal/ModalBody'
import { Modal } from 'Components/Modal/Modal'
import { FormTextInput } from 'Components/Inputs/FormTextInput'
import { Employer } from 'Utils/types/JOONAdmin/employer'
import { DarkGreenText, MediumBoldText, telegrafFont } from 'Utils/styles/text'
import { StyledSwitch } from 'Components/Inputs/Switch/StyledSwitch'
import styled from 'styled-components'
import { towerGray } from 'Utils/styles/colors'
import { ModalHeader } from 'Components/Modal/ModalHeader'
import { TrashIcon } from '@radix-ui/react-icons'
import { useAddDomain } from 'Hooks/api/JOONAdmin/sso/useAddDomain'
import { useDeleteDomain } from 'Hooks/api/JOONAdmin/sso/useDeleteDomain'
import { useToggleSSOForDomain } from 'Hooks/api/JOONAdmin/sso/useToggleSSOForDomain'
import { useCreateOrganization } from 'Hooks/api/JOONAdmin/sso/useCreateOrganization'

const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${spacingMD};
  border-bottom: 1px solid ${towerGray};
`

const Header = styled(MediumBoldText)`
  ${telegrafFont};
`

type FormData = {
  domain: string
}

type WorkOSSettingsModalProps = {
  selectedEmployer: Employer
  onClose: () => void
  showModal: boolean
}

export const WorkOSSettingsModal = ({
  selectedEmployer,
  onClose,
  showModal
}: WorkOSSettingsModalProps) => {
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm<FormData>({
    defaultValues: {
      domain: ''
    }
  })

  const {
    id,
    sso: { domains, organization }
  } = selectedEmployer

  const { mutate: addDomainMutate } = useAddDomain(id)
  const { mutate: deleteDomainMutate } = useDeleteDomain(id)
  const { mutate: toggleSSOForDomainMutate } = useToggleSSOForDomain(id)
  const { mutate: createOrganization } = useCreateOrganization(id)

  const onCreateOrganization = () => {
    createOrganization({ employerId: id })
  }

  const onToggleSSOForDomain = (enabled: boolean, domainId: number) => {
    toggleSSOForDomainMutate({ employerId: id, domainId, enable: enabled })
  }

  const onSubmit = (data: FormData) => {
    addDomainMutate({ employerId: id, domainName: data.domain })
  }

  const onDeleteDomain = (domainId: number) => {
    deleteDomainMutate({ employerId: id, domainId })
  }

  const orgIdGenerated = !!organization

  return (
    <Modal showModal={showModal} setShowModal={onClose} maxWidth={500} minWidth={500} overflow>
      <ModalHeader>Manage SSO</ModalHeader>
      <ModalBody style={{ padding: `${spacingMD} 0`, gap: spacingXL }}>
        <FlexColumn gap={spacingMD}>
          <Header>Organization</Header>
          {orgIdGenerated && <DarkGreenText>Organization Id: {organization} </DarkGreenText>}
          {!orgIdGenerated && (
            <StyledButton
              id="ssoSettings.generateOrgButton.label"
              onClick={() => onCreateOrganization()}
              primary
            />
          )}
        </FlexColumn>
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FlexColumn gap={spacingMD}>
              <Header>Add a Domain</Header>
              <FlexRow gap={spacingMD}>
                <FormTextInput
                  fieldName="domain"
                  errorPresent={!!errors?.domain?.message}
                  labelId="ssoSettings.domain.label"
                  register={register}
                />
              </FlexRow>
              <FlexRow gap={spacingMD}>
                <StyledButton id="ssoSettings.submitButton.label" primary />
              </FlexRow>
            </FlexColumn>
          </form>
          <FlexColumn gap={spacingMD}>
            <Header>Enable SSO per Domain</Header>
            {domains.map(({ id, domain, ssoEnabled }) => (
              <ListItem key={`${id}-${domain}`}>
                <FlexRow gap={spacingSM}>
                  <TrashIcon style={{ cursor: 'pointer' }} onClick={() => onDeleteDomain(id)} />
                  <DarkGreenText>
                    ({id}) {domain}
                  </DarkGreenText>
                </FlexRow>
                <StyledSwitch
                  onCheckedChange={() => onToggleSSOForDomain(!ssoEnabled, id)}
                  checked={ssoEnabled}
                />
              </ListItem>
            ))}
          </FlexColumn>
        </>
      </ModalBody>
    </Modal>
  )
}
