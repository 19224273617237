import qs from 'qs'

import { getRequest } from 'Services/baseRequests/getRequest'
import {
  GetPurchasesApiResponse,
  GetPurchasesResponse,
  RequestResponse
} from 'Services/apiResponseTypes'
import { Options } from 'Utils/types/options'
import { parseDashboardResponseData } from 'Services/purchases/parsers'
import { postRequest } from 'Services/baseRequests/postRequest'
import { OngoingPurchase } from 'Utils/types/purchase'
import { parseServerDate } from 'Utils/helpers/dateUtils'

/**
 * Get a paginated list of given user's purchases
 * @param userId id of user we are getting purchases for
 * @param perPage number of purchases per page
 * @param pageNumber page number to fetch
 * @param options options to filter purchases by
 */
export const getUserPurchases = async (
  userId: number,
  perPage: number,
  pageNumber: number,
  options: Options
): Promise<GetPurchasesResponse> => {
  const optionsString = qs.stringify(options)
  const response = await getRequest<GetPurchasesApiResponse>(
    `/users/${userId}/transactions/?perpage=${perPage}&page=${pageNumber}&${optionsString}`
  )

  return {
    statusCode: response.statusCode,
    success: response?.success ?? false,
    message: response?.message ?? '',
    data: response?.data ? parseDashboardResponseData(response.data) : undefined,
    purchasesCount: response?.total_records ?? 0
  }
}

export type AddPurchasePayload = {
  formData: FormData
}

export type AddPurchaseResponse = {
  success?: boolean
  error?: string
  message?: { source: string; title: string }
}
/**
 * Used when a user is manually adding a purchase. Adds a purchase to the user's account
 * with given details. The purchase defaults to an in review status and must be manually approved by
 * a JOON admin.
 * @param payload The details of the purchase being added
 */
export const addPurchase = async (payload: AddPurchasePayload): Promise<RequestResponse> => {
  // Convert what form gives to what the API expects
  const response = await postRequest<AddPurchaseResponse, FormData>(
    `/manual_transactions`,
    payload.formData
  )

  return {
    statusCode: response.statusCode,
    success: !!(!response?.error && response?.success),
    message: response?.error ? response?.message?.title || '' : ''
  }
}

type RequestPurchaseEligibilityPayload = {
  formData?: FormData
  purchaseId: number
}
/**
 * Used when a user is requesting eligibility for a plaid purchase that wasn't automatically classified and approved.
 * The purchase defaults to an in review status and must be manually approved by a JOON admin.
 * @param payload The details of the purchase being added
 */
export const requestPurchaseEligibility = async (
  payload: RequestPurchaseEligibilityPayload
): Promise<RequestResponse> => {
  const { formData, purchaseId } = payload

  const response = await postRequest<RequestResponse, FormData | undefined>(
    `/transactions/${purchaseId}/report`,
    formData
  )

  return {
    statusCode: response.statusCode,
    success: response?.success ?? false,
    message: response?.message ?? ''
  }
}

/**
 * Invalidate the given purchase for the given user
 * @param userId
 * @param purchaseId
 */
export type InvalidatePurchaseParams = {
  userId: number
  purchaseId: number
}
export const invalidatePurchase = async ({
  userId,
  purchaseId
}: InvalidatePurchaseParams): Promise<RequestResponse> => {
  const response = await postRequest<RequestResponse, undefined>(
    `/users/${userId}/transactions/${purchaseId}/invalidate`
  )

  return {
    statusCode: response.statusCode,
    success: response?.success ?? false,
    message: response?.success ? 'Purchase successfully invalidated.' : ''
  }
}

// This is what we convert it to for use on the FE
export type GetOngoingPurchasesResponse = RequestResponse<GetOngoingPurchasesResponseData>
export type GetOngoingPurchasesResponseData = {
  ongoingPurchases: OngoingPurchase[]
}
/**
 * @param userId id of user we are getting purchases for
 */
export const getOngoingPurchases = async (userId: number): Promise<GetOngoingPurchasesResponse> => {
  const response = await getRequest<GetOngoingPurchasesResponse>(
    `/users/${userId}/ongoing_purchases`
  )

  return {
    statusCode: response.statusCode,
    success: response?.success ?? false,
    message: response?.message ?? '',
    data: response?.data
      ? {
          ongoingPurchases: response.data.ongoingPurchases.map((p) => {
            return {
              ...p,
              purchaseDate: parseServerDate(p.purchaseDate as unknown as string),
              expectedPayoffDate: parseServerDate(p.expectedPayoffDate as unknown as string)
            }
          })
        }
      : undefined
  }
}
