import { getRequest } from 'Services/baseRequests/getRequest'
import {
  GetInvoiceDetailsApiResponse,
  GetDetailedInvoiceResponse,
  GetJoonAdminInvoicesApiResponse,
  GetJoonAdminInvoicesResponse
} from 'Services/JOONAdmin/adminApiResponseTypes'
import {
  parseDetailedInvoiceApiResponseData,
  parseJoonAdminInvoiceApiResponseData
} from 'Services/invoices/parsers'
import { postRequest } from 'Services/baseRequests/postRequest'
import { RequestResponse } from 'Services/apiResponseTypes'
import { deleteRequest } from 'Services/baseRequests/deleteRequest'
import { putRequest } from 'Services/baseRequests/putRequest'
import { CountryCode } from 'Utils/types/countries'

export type GetJoonAdminInvoicesPayload = {
  perPage: number
  pageNumber: number
  searchText?: string
  state?: string
}

export const getJoonAdminInvoices = async ({
  searchText,
  state,
  perPage,
  pageNumber
}: GetJoonAdminInvoicesPayload): Promise<GetJoonAdminInvoicesResponse> => {
  const response = await getRequest<GetJoonAdminInvoicesApiResponse>(
    `/invoices?search_text=${searchText ?? ''}&state=${
      state ?? ''
    }&per_page=${perPage}&page=${pageNumber}`
  )

  return {
    success: response?.success ?? false,
    message: response?.message ?? '',
    data: response?.data
      ? {
          invoices: response.data.invoices.map(parseJoonAdminInvoiceApiResponseData),
          totalInvoices: response.data.total_records ?? 0
        }
      : undefined
  }
}

export type AddInvoicePayload = {
  employerId: string
  month: number
  year: number
  countryCode?: CountryCode
}

export const addInvoice = async ({
  employerId,
  year,
  month,
  countryCode
}: AddInvoicePayload): Promise<GetDetailedInvoiceResponse> => {
  const response = await postRequest<
    GetInvoiceDetailsApiResponse,
    { employer_id: string; month: number; year: number; country_code?: CountryCode }
  >(`/invoices`, {
    employer_id: employerId,
    month: month,
    year: year,
    country_code: countryCode
  })

  return {
    success: response?.success ?? false,
    message: response?.message ?? '',
    data: response?.data
      ? { invoice: parseDetailedInvoiceApiResponseData(response.data.invoice) }
      : undefined
  }
}

export type DeleteInvoicePayload = {
  invoiceId: number
}
export const deleteInvoice = async (payload: DeleteInvoicePayload): Promise<RequestResponse> => {
  const response = await deleteRequest<RequestResponse>(`/invoices/${payload.invoiceId}`)

  return {
    statusCode: response.statusCode,
    success: response?.success ?? false,
    message: response?.message ?? ''
  }
}

export const getJoonAdminInvoiceDetails = async (
  invoiceId: number
): Promise<GetDetailedInvoiceResponse> => {
  const response = await getRequest<GetInvoiceDetailsApiResponse>(`/invoices/${invoiceId}`)

  return {
    success: response?.success ?? false,
    message: response?.message ?? '',
    data: response?.data
      ? { invoice: parseDetailedInvoiceApiResponseData(response.data.invoice) }
      : undefined
  }
}

export type FinalizeInvoicePayload = {
  invoiceId: number
}
export const finalizeInvoice = async (
  payload: FinalizeInvoicePayload
): Promise<RequestResponse> => {
  const response = await postRequest<RequestResponse>(
    `/admin/invoices/${payload.invoiceId}/finalize`
  )

  return {
    statusCode: response.statusCode,
    success: response?.success ?? false,
    message: response?.message ?? ''
  }
}

export type RevertInvoiceToDraftPayload = {
  invoiceId: number
}
export const revertInvoiceToDraft = async (
  payload: RevertInvoiceToDraftPayload
): Promise<RequestResponse> => {
  const response = await postRequest<RequestResponse>(
    `/admin/invoices/${payload.invoiceId}/revert_to_draft`
  )

  return {
    statusCode: response.statusCode,
    success: response?.success ?? false,
    message: response?.message ?? ''
  }
}

export type RunInvoiceAutopayPayload = {
  invoiceId: number
}
export const runInvoiceAutopay = async (
  payload: RunInvoiceAutopayPayload
): Promise<RequestResponse> => {
  const response = await postRequest<RequestResponse>(
    `/admin/invoices/${payload.invoiceId}/run_auto_pay`
  )

  return {
    statusCode: response.statusCode,
    success: response?.success ?? false,
    message: response.message,
    errors: response.errors
  }
}

export type AssociateStripeInvoicePayload = {
  invoiceId: number
  stripeInvoiceId: string
}
export const associateStripeInvoice = async (
  payload: AssociateStripeInvoicePayload
): Promise<RequestResponse> => {
  const response = await putRequest<RequestResponse, { invoice: { stripe_invoice_id: string } }>(
    `/admin/invoices/${payload.invoiceId}`,
    { invoice: { stripe_invoice_id: payload.stripeInvoiceId } }
  )

  return {
    statusCode: response.statusCode,
    success: response?.success ?? false,
    message: response.message,
    errors: response.errors
  }
}

export type PayWithPrepaidInvoicePayload = {
  invoiceId: number
  stripePaymentId: string
}
export const payWithPrepaidInvoice = async (
  payload: PayWithPrepaidInvoicePayload
): Promise<RequestResponse> => {
  const response = await postRequest<RequestResponse, { stripe_payment_id: string }>(
    `/admin/invoices/${payload.invoiceId}/prepaid`,
    { stripe_payment_id: payload.stripePaymentId }
  )

  return {
    statusCode: response.statusCode,
    success: response?.success ?? false,
    message: response.message,
    errors: response.errors
  }
}
