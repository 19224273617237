import styled from 'styled-components'
import { black, mineralGreen } from 'Utils/styles/colors'
import { spacingSM, SpacingUnits } from 'Utils/styles/spacing'
import { FormattedMessage } from 'react-intl'

export const StyledAnchor = styled.a<{ padding: SpacingUnits }>`
  &:link,
  &:visited {
    color: ${mineralGreen};
    text-decoration: none;
  }
  &:link {
    color: ${mineralGreen};
    text-decoration: none;
  }
  &:hover {
    color: ${black};
    text-decoration: none;
  }

  padding: 0 ${(props) => props.padding};
`

export type ExternalLinkProps = {
  linkId: string
  href: string
  padding?: SpacingUnits
}

export const ExternalLink = ({ linkId, href, padding = spacingSM }: ExternalLinkProps) => {
  return (
    <StyledAnchor href={href} target="_blank" padding={padding}>
      <FormattedMessage id={linkId} />
    </StyledAnchor>
  )
}
