import { FC } from 'react'
import styled from 'styled-components'

import { PaymentMethodIcon } from './PaymentMethodIcon'
import { HoverIconCard } from 'Components/HoverIconCard'

import { paymentInfoWithStars, getCardTagline } from 'Utils/helpers/paymentTypes'
import {
  PaymentMethod,
  PaymentMethodType,
  PaymentCard,
  isCardPayment
} from 'Utils/types/paymentMethod'
import { spacingXS, spacingSM, FlexColumn, FlexRow } from 'Utils/styles/spacing'
import { elfGreenFaded, blackTint } from 'Utils/styles/colors'
import { fontWeightBold, fontSizeXS } from 'Utils/styles/text'
import { AppIconMap, AppIcons } from 'Utils/types/appIcons'

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${spacingXS};
  border-radius: 4px;
  background-color: ${elfGreenFaded};
`

const AccountInfo = styled(FlexColumn)`
  justify-content: center;
`

const Identifier = styled.div`
  font-weight: ${fontWeightBold};
`

const ExpiryDateAndFee = styled.div`
  font-size: ${fontSizeXS};
  color: ${blackTint};
`

const ExpiryDateAndFeeContainer = styled(FlexRow)`
  align-items: center;
`

type PaymentMethodInfoProps = {
  paymentMethod: PaymentMethod
  hasPayrollReimbursement?: boolean
  hideText?: boolean
}
export const PaymentMethodInfo: FC<PaymentMethodInfoProps> = ({
  paymentMethod,
  hasPayrollReimbursement = false,
  hideText = false
}) => {
  const expiryString = (paymentMethod: PaymentMethod) => {
    if (paymentMethod.type === PaymentMethodType.Bank) return ''

    const { expMonth, expYear } = paymentMethod as PaymentCard
    return expMonth && expYear ? `${expMonth}/${expYear}` : ''
  }

  const { countryCode } = paymentMethod

  return (
    <FlexRow gap={spacingSM}>
      <IconContainer>
        <PaymentMethodIcon
          paymentMethodName={paymentMethod.name}
          paymentMethodType={paymentMethod.type}
        />
      </IconContainer>
      <AccountInfo>
        <Identifier>{paymentInfoWithStars(paymentMethod, false)}</Identifier>
        {isCardPayment(paymentMethod) && (
          <ExpiryDateAndFeeContainer gap={spacingXS}>
            <ExpiryDateAndFee>
              {hideText
                ? `${expiryString(paymentMethod)}`
                : `${expiryString(paymentMethod)} • ${getCardTagline(
                    countryCode,
                    hasPayrollReimbursement
                  )}`}
            </ExpiryDateAndFee>
            {hasPayrollReimbursement && (
              <HoverIconCard
                altText="Payment for reimbursements issued through Joon incurs a fee."
                iconSrc={AppIconMap[AppIcons.InfoCircle]}
              >
                <span>Payment for reimbursements issued through Joon incurs a fee.</span>
              </HoverIconCard>
            )}
          </ExpiryDateAndFeeContainer>
        )}
      </AccountInfo>
    </FlexRow>
  )
}
