import React, { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { StyledButton } from 'Components/Buttons/StyledButton'
import { PageContent } from 'Components/PageContent'
import { useIsLoggedIn } from 'Hooks/useIsLoggedIn'

import {
  JoonAdminUsersTable,
  JoonAdminBenefitProgramsGrid
} from 'Pages/JOONAdmin/EmployerDetailsPage/Views'
import { EmployerSummaryCard } from 'Pages/JOONAdmin/EmployerDetailsPage/EmployerSummaryCard/EmployerSummaryCard'
import { InvoiceTable } from 'Pages/Employer/BillingPage/InvoiceTable/InvoiceTable'
import { JoonAdminCountriesGrid } from './Views/Countries/JoonAdminCountriesGrid'

import { PageRoutes } from 'Utils/types/pageRoutes'
import { FlexColumn, FlexRow, spacingLG, spacingSM } from 'Utils/styles/spacing'
import { elfGreen } from 'Utils/styles/colors'

export const ViewSelector = styled(StyledButton)<{ $selected: boolean }>`
  border: ${({ $selected }) => ($selected ? `3px solid ${elfGreen}` : `1px solid ${elfGreen}`)};
`

enum EmployerDetailsViews {
  Users = 'users',
  BenefitPrograms = 'Benefit Programs',
  Invoices = 'invoices',
  Countries = 'countries'
}

const viewComponents = {
  [EmployerDetailsViews.Users]: JoonAdminUsersTable,
  [EmployerDetailsViews.BenefitPrograms]: JoonAdminBenefitProgramsGrid,
  [EmployerDetailsViews.Invoices]: () => <InvoiceTable isJoonAdmin />,
  [EmployerDetailsViews.Countries]: JoonAdminCountriesGrid
}

const EmployerDetailsPageContent: FC = () => {
  const { isLoggedIn } = useIsLoggedIn()
  const navigate = useNavigate()
  const [selectedView, setSelectedView] = useState(EmployerDetailsViews.Users)

  useEffect(() => {
    if (!isLoggedIn) navigate(PageRoutes.Logout)
  }, [isLoggedIn, navigate])

  const SelectedViewComponent = viewComponents[selectedView]

  return (
    <FlexColumn gap={spacingSM}>
      <PageContent rowGap={spacingLG}>
        <EmployerSummaryCard />
        <FlexRow gap={spacingSM}>
          {Object.entries(EmployerDetailsViews).map(([key, value]) => (
            <ViewSelector
              key={key}
              id={`employer.details.page.view.selection.${key}.label`}
              secondary
              $selected={selectedView === value}
              onClick={() => setSelectedView(value)}
            />
          ))}
        </FlexRow>
        <SelectedViewComponent />
      </PageContent>
    </FlexColumn>
  )
}

export default EmployerDetailsPageContent
