import { UserInfoActionTypes, useUserInfoContext } from 'Contexts/UserInfoContext'
import { UserProgramActionTypes, useUserProgramContext } from 'Contexts/UserProgramContext'
import { UserPurchaseActionTypes, useUserPurchasesContext } from 'Contexts/UserPurchasesContext'
import {
  UserReimbursementsActionTypes,
  useUserReimbursementsContext
} from 'Contexts/UserReimbursementsContext'
import { EmployerInfoActionTypes, useEmployerInfoContext } from 'Contexts/EmployerInfoContext'
import {
  EmployerProgramsActionTypes,
  useEmployerProgramsContext
} from 'Contexts/EmployerProgramsContext'
import { EmployerActionTypes, useEmployerUsersContext } from 'Contexts/EmployerUsersContext'
import { ModalActionTypes, useModalContext } from 'Contexts/ModalContext'
import { JoonAdminActionTypes, useJoonAdminContext } from 'Contexts/JOONAdmin/JOONAdminContext'

export const useResetContexts = (logOut = false) => {
  const { dispatch: dispatchUserInfo } = useUserInfoContext()
  const { dispatch: dispatchProgram } = useUserProgramContext()
  const { dispatch: dispatchUserPurchases } = useUserPurchasesContext()
  const { dispatch: dispatchUserReimbursements } = useUserReimbursementsContext()
  const { dispatch: dispatchEmployerInfo } = useEmployerInfoContext()
  const { dispatch: dispatchEmployerPrograms } = useEmployerProgramsContext()
  const { dispatch: dispatchEmployerUsers } = useEmployerUsersContext()
  const { dispatch: dispatchModal } = useModalContext()
  const { dispatch: dispatchJoonAdmin } = useJoonAdminContext()

  const resetContexts = () => {
    dispatchUserInfo({ type: UserInfoActionTypes.ResetState })
    dispatchProgram({ type: UserProgramActionTypes.ResetState })
    dispatchUserPurchases({ type: UserPurchaseActionTypes.ResetState })
    dispatchUserReimbursements({ type: UserReimbursementsActionTypes.ResetState })
    dispatchEmployerInfo({ type: EmployerInfoActionTypes.ResetState })
    dispatchEmployerPrograms({ type: EmployerProgramsActionTypes.ResetState })
    dispatchEmployerUsers({ type: EmployerActionTypes.ResetState })
    dispatchModal({ type: ModalActionTypes.ResetState })
    logOut && dispatchJoonAdmin({ type: JoonAdminActionTypes.ResetState })
  }

  return { resetContexts }
}
