import React, { FC, useCallback } from 'react'
import { StyledButton } from 'Components/Buttons/StyledButton'
import { useJoonAdminContext } from 'Contexts/JOONAdmin/JOONAdminContext'
import { useDateSelectionModal } from 'Hooks/modals/useDateSelectionModal'
import { useTerminateEmployerQuery } from 'Hooks/api/JOONAdmin/useTerminateEmployerQuery'

export const EmployerTerminateButton: FC = () => {
  const {
    state: { selectedEmployer }
  } = useJoonAdminContext()

  const { showModal } = useDateSelectionModal()
  const { mutate, isLoading } = useTerminateEmployerQuery()

  const handleShowTerminateModal = useCallback(async () => {
    if (!selectedEmployer) return
    if (isLoading) return

    const selectedDate = await showModal(
      'Terminate Employer - Select Effective Date',
      'All memberships and program policies will be set to end as of the selected date. Warning: This is difficult to undo.',
      'employer.terminate.confirm.label'
    )

    if (!selectedDate) return
    mutate({
      employerId: selectedEmployer.id,
      terminationDate: selectedDate
    })
  }, [selectedEmployer, showModal, mutate, isLoading])

  return (
    <StyledButton
      disabled={isLoading}
      danger
      id="employer.terminate.label"
      onClick={handleShowTerminateModal}
    />
  )
}
