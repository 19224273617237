import { FC, useMemo, useState, useEffect } from 'react'

import { JoonAdminCountryCard } from './JoonAdminCountryCard'
import { CreateCountryModal } from './CreateCountryModal/CreateCountryModal'
import { StyledButton } from 'Components/Buttons/StyledButton'

import { useEmployerInfoContext } from 'Contexts/EmployerInfoContext'
import { GridContainer } from '../BenefitPrograms/JoonAdminBenefitProgramsGrid'
import { FlexColumn, FlexRow, spacingSM } from 'Utils/styles/spacing'
import { TableLabel } from 'Utils/styles/text'
import { EmployerCountry } from 'Utils/types/employerInfo'

export const JoonAdminCountriesGrid: FC = () => {
  const {
    state: { employerInfo }
  } = useEmployerInfoContext()

  const [showCreateCountryModal, setShowCreateCountryModal] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState<EmployerCountry | undefined>(undefined)

  const countryConfigs = useMemo(() => {
    const configs = employerInfo?.countryConfigs

    return configs ? Object.values(configs) : null
  }, [employerInfo?.countryConfigs])

  const handleCardClick = (countryConfig: EmployerCountry) => {
    setSelectedCountry(countryConfig)
    setShowCreateCountryModal(true)
  }

  useEffect(() => {
    if (!showCreateCountryModal) setSelectedCountry(undefined)
  }, [showCreateCountryModal])

  if (!countryConfigs) return null

  return (
    <>
      <FlexColumn gap={spacingSM}>
        <FlexRow style={{ justifyContent: 'space-between' }}>
          <TableLabel>Countries</TableLabel>
          <StyledButton
            id="createEmployerCountry.button.label"
            primary
            onClick={() => setShowCreateCountryModal(true)}
          />
        </FlexRow>
        <GridContainer>
          {countryConfigs.map((countryConfig) => (
            <JoonAdminCountryCard
              key={`country_config_${countryConfig.id}`}
              countryConfig={countryConfig}
              onClick={() => handleCardClick(countryConfig)}
            />
          ))}
        </GridContainer>
      </FlexColumn>
      <CreateCountryModal
        showModal={showCreateCountryModal}
        setShowModal={setShowCreateCountryModal}
        countryConfig={selectedCountry}
      />
    </>
  )
}
