import React, { FC, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import EmployerDetailsContent from 'Pages/JOONAdmin/EmployerDetailsPage/EmployerDetailsContent'
import { AdminLoggedInPage } from 'Pages/AdminLoggedInPage'

import { PeoplePageProvider } from 'Contexts/PeoplePageContext'
import { EmployerInfoActionTypes, useEmployerInfoContext } from 'Contexts/EmployerInfoContext'

import { useEmployerDashboardQuery } from 'Hooks/api/employer/useEmployerDashboardQuery'
import { useGetEmployerDetailsQuery } from 'Hooks/api/JOONAdmin/useGetEmployerDetailsQuery'
import { useEmployerBenefitProgramsQuery } from 'Hooks/api/employer/useEmployerBenefitProgramsQuery'

const EmployerDetailsPage: FC = () => {
  const { dispatch, state } = useEmployerInfoContext()

  const { employerId } = useParams()

  const formattedEmployerId = Number(employerId)

  useEmployerDashboardQuery(formattedEmployerId)
  useGetEmployerDetailsQuery(formattedEmployerId)

  // We don't use benefit program utilization in JOON admin currently
  useEmployerBenefitProgramsQuery(false, formattedEmployerId)

  useEffect(() => {
    if (formattedEmployerId !== state.employerId) {
      dispatch({ type: EmployerInfoActionTypes.SetEmployerId, value: formattedEmployerId })
    }
  }, [dispatch, formattedEmployerId, state.employerId])

  return (
    <PeoplePageProvider>
      <AdminLoggedInPage headerText="Employer Details">
        <EmployerDetailsContent />
      </AdminLoggedInPage>
    </PeoplePageProvider>
  )
}

export default EmployerDetailsPage
