import { TableHeaderCell } from 'Components/Table/SortableTableHeader'
import { PeopleTableRow } from 'Utils/types/user'

export enum PeopleTableHeaderCell {
  ID = 'ID',
  CountryCode = 'CountryCode'
}

const peopleTableHeaderCellDefaults: TableHeaderCell<PeopleTableRow>[] = [
  {
    id: 'firstName',
    numeric: false,
    label: 'First'
  },
  {
    id: 'lastName',
    numeric: false,
    label: 'Last'
  },
  {
    id: 'email',
    numeric: true,
    label: 'Email'
  },
  {
    id: 'programs',
    numeric: false,
    label: 'Benefits',
    sortable: false,
    style: { minWidth: 70 }
  },
  {
    id: 'roles',
    numeric: false,
    label: 'Permissions',
    sortable: false
  },
  {
    id: 'status',
    numeric: false,
    label: 'Status',
    sortable: false
  }
]

const peopleTableHeaderIdCell: TableHeaderCell<PeopleTableRow> = {
  id: 'id',
  numeric: true,
  label: 'Id'
}

const peopleTableHeaderCountryCell: TableHeaderCell<PeopleTableRow> = {
  id: 'countryCode',
  numeric: false,
  label: 'Country',
  sortable: false
}

export const getPeopleTableHeaderCells = (options: PeopleTableHeaderCell[]) => {
  return [
    // May have to adjust if there are going to be more optional/conditional columns
    // Need to ensure order of header names matches column in table
    ...(options.includes(PeopleTableHeaderCell.ID) ? [peopleTableHeaderIdCell] : []),
    ...peopleTableHeaderCellDefaults,
    ...(options.includes(PeopleTableHeaderCell.CountryCode) ? [peopleTableHeaderCountryCell] : [])
  ]
}
