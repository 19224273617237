import styled from 'styled-components'
import { ToastContainer } from 'react-toastify'
import { elfGreen, red } from 'Utils/styles/colors'

export const StyledToastContainer = styled(ToastContainer)`
  --toastify-color-success: ${elfGreen};
  --toastify-color-progress-success: ${elfGreen};
  --toastify-icon-color-success: ${elfGreen};
  --toastify-color-error: ${red};
  --toastify-color-progress-error: ${red};
  --toastify-icon-color-error: ${red};
`
