import React, { FC } from 'react'
import styled from 'styled-components'

import { ProgramDefinitionSection } from 'Pages/User/UserDashboard/ProgramSummary/UsageCardSection'

import { DarkGreenText, MediumBoldText, telegrafFont } from 'Utils/styles/text'
import { FlexColumn, spacingMD, spacingSM } from 'Utils/styles/spacing'
import { Employer } from 'Utils/types/JOONAdmin/employer'
import { displayDate } from 'Utils/helpers/dateUtils'

const DetailsContainer = styled.div`
  ${ProgramDefinitionSection};
  grid-area: basicInfo;
  min-width: 180px;
`

const Header = styled(MediumBoldText)`
  ${telegrafFont};
`

const InnerSection = styled(FlexColumn)`
  gap: ${spacingSM};
`

type BasicInfoProps = {
  employer: Employer
}
export const BasicInfo: FC<BasicInfoProps> = ({
  employer: { contactEmail, createdAt, admins }
}) => {
  return (
    <DetailsContainer>
      <FlexColumn gap={spacingMD}>
        <InnerSection>
          <Header>Basic Information</Header>
          <DarkGreenText>Contact Email: {contactEmail}</DarkGreenText>
          <DarkGreenText>Created On: {displayDate(createdAt)}</DarkGreenText>
        </InnerSection>
        <InnerSection>
          <FlexColumn gap={spacingSM}>
            <Header>Admins</Header>
            {admins.map((admin) => (
              <DarkGreenText key={admin}>{admin}</DarkGreenText>
            ))}
          </FlexColumn>
        </InnerSection>
      </FlexColumn>
    </DetailsContainer>
  )
}
