import { Modal } from '../Modal'
import { ModalType, usePeoplePageContext } from 'Contexts/PeoplePageContext'
import { ModalHeader } from 'Components/Modal/ModalHeader'
import { BulkForm } from 'Components/Modal/BulkModal/BulkForm'
import { useEmployerInfoContext } from 'Contexts/EmployerInfoContext'
import { useCsvUpdateQuery } from 'Hooks/api/employer/userManagement/useCsvUpdateQuery'

export const BulkUpdateModal = () => {
  const {
    state: { modal },
    setModal
  } = usePeoplePageContext()

  const {
    state: { employerId }
  } = useEmployerInfoContext()

  const setShowModal = (show: boolean) => setModal(show ? ModalType.BulkUpdate : undefined)

  const { mutateAsync, isLoading } = useCsvUpdateQuery()

  const onSubmit = async (formData: FormData) => {
    if (employerId) {
      await mutateAsync({ employerId: employerId, formData: formData })
      setModal(undefined)
    }
  }

  return (
    <Modal showModal={modal === ModalType.BulkUpdate} setShowModal={setShowModal}>
      <ModalHeader>Bulk Update User Meta</ModalHeader>
      <BulkForm messageId="users.updateMultiple" onSubmit={onSubmit} isLoading={isLoading} />
    </Modal>
  )
}
