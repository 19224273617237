import React from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { CountryCode } from 'Utils/types/countries'
import { SelectOption } from 'Components/Inputs/StyledSelect'
import { InputContainer } from 'Components/Inputs/ControlledSelect'
import { Control, Controller, FieldValues, Path, PathValue, RegisterOptions } from 'react-hook-form'

type ControlledChipsProps<T extends FieldValues> = {
  options: SelectOption<CountryCode>[]
  control: Control<T>
  name: Path<T>
  rules?: RegisterOptions
  label?: string
  defaultSelected?: PathValue<T, Path<T>>
}

export const CountryMultiselect = <T extends FieldValues>({
  options,
  control,
  name,
  rules,
  label = 'Countries',
  defaultSelected
}: ControlledChipsProps<T>) => {
  return (
    <InputContainer>
      <Controller
        rules={rules}
        name={name}
        control={control}
        defaultValue={defaultSelected || ([] as PathValue<T, Path<T>>)}
        render={({ field }) => (
          <Autocomplete
            multiple
            id="countries-multi-select"
            options={options}
            getOptionLabel={(option) => option.label}
            value={options.filter((option) =>
              (field.value as CountryCode[]).includes(option.value)
            )}
            onChange={(_, newValue) => {
              field.onChange(newValue.map((item) => item.value))
            }}
            renderInput={(params) => <TextField {...params} variant="outlined" label={label} />}
          />
        )}
      />
    </InputContainer>
  )
}
