import { useQuery } from 'react-query'

import { QueryNames } from 'Hooks/api/queryNames'
import { useErrorReporter } from 'Hooks/useErrorReporter'

import { getJoonAdminInvoiceDetails } from 'Services/JOONAdmin/joonAdminInvoicesService'
import { getEmployerAdminInvoiceDetails } from 'Services/invoices/invoiceService'

export const useGetInvoiceDetailsQuery = (
  invoiceId?: number,
  employerId?: number,
  shouldFetch = true
) => {
  const reportError = useErrorReporter()

  return useQuery({
    queryKey: [QueryNames.GetInvoiceDetails, invoiceId, employerId],
    queryFn: async () => {
      if (!invoiceId) return

      let response

      if (employerId) {
        response = await getEmployerAdminInvoiceDetails(invoiceId, employerId)
      } else {
        response = await getJoonAdminInvoiceDetails(invoiceId)
      }

      if (!response.data) {
        reportError('useGetInvoiceDetailsQuery failure', response)
      }

      return response
    },
    enabled: shouldFetch
  })
}
