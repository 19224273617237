import { FC, useMemo, useState } from 'react'

import { Checkbox } from 'Components/Inputs/Checkbox/Checkbox'
import { StyledButton } from 'Components/Buttons/StyledButton'

import { useGrantUserAdminPermissions } from 'Hooks/api/employer/userManagement/useGrantUserAdminPermissions'
import { useRevokeUserAdminPermissions } from 'Hooks/api/employer/userManagement/useRevokeUserAdminPermissions'

import { ButtonContainer } from 'Utils/styles/spacing'
import { UserRole } from 'Utils/types/roles'
import { UserDetailsForEmployer } from 'Utils/types/user'

type ManageUserPermissionsFormProps = {
  user?: UserDetailsForEmployer
}
export const ManageUserPermissionsForm: FC<ManageUserPermissionsFormProps> = ({ user }) => {
  const initialAdminRole = useMemo(() => {
    return user?.roles?.includes(UserRole.EMPLOYERADMIN) || false
  }, [user])

  const [adminRole, setAdminRole] = useState(initialAdminRole)

  const grantUserAdminPermissions = useGrantUserAdminPermissions()
  const revokeUserAdminPermissions = useRevokeUserAdminPermissions()

  const updatePermissions = () => {
    if (!user?.id) return
    if (adminRole) {
      grantUserAdminPermissions.mutate({ userId: user.id })
    } else {
      revokeUserAdminPermissions.mutate({ userId: user.id })
    }
  }

  return (
    <>
      <Checkbox
        labelText="Allow management of users, benefits, and billing information."
        value={adminRole}
        onClick={() => setAdminRole(!adminRole)}
      />

      <ButtonContainer>
        <StyledButton primary id="permissionsChange.update" onClick={updatePermissions} />
      </ButtonContainer>
    </>
  )
}
