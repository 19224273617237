import { MouseEvent, useCallback, useMemo, useState } from 'react'
import { Order } from 'Utils/helpers/tables'

export const useTableOrder = <T extends Record<string, unknown>>() => {
  const [order, setOrder] = useState<Order>('desc')
  const [orderBy, setOrderBy] = useState<keyof T>('date' as keyof T)

  const resetSortOrder = useCallback(() => {
    setOrderBy('date' as keyof T)
    setOrder('desc')
  }, [])

  const handleRequestSort = useCallback(
    (event: MouseEvent<unknown>, property: keyof T) => {
      const isAsc = orderBy === property && order === 'asc'
      setOrder(isAsc ? 'desc' : 'asc')
      setOrderBy(property)
    },
    [order, orderBy]
  )

  return useMemo(
    () => ({ handleRequestSort, order, orderBy, resetSortOrder }),
    [handleRequestSort, order, orderBy, resetSortOrder]
  )
}
