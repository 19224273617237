import { spacingLG } from 'Utils/styles/spacing'
import styled from 'styled-components'

export const ModalBody = styled.div<{
  maxHeight?: string
}>`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  gap: ${spacingLG};
  ${(props) => (props.maxHeight ? `max-height: ${props.maxHeight};` : '')}
`
