import { CurrencyCode } from 'Utils/types/currencies'
import { CurrencyFormattedParts } from './CurrencyFormattedParts'

type CurrencyTextProps = {
  value: number
  currencyCode: CurrencyCode
  // If true, intentionally show trailing zeroes for minor units (ex. cents)
  // There are currencies that don't use minor units (ex. Japanese Yen) - shouldn't be a concern for v1
  showMinorUnit?: boolean
}

export const CurrencyText = ({ value, currencyCode, showMinorUnit = true }: CurrencyTextProps) => {
  const hasMinorUnit = value - Math.floor(value) !== 0
  const fractionalDigitCount = showMinorUnit || hasMinorUnit ? 2 : 0

  return (
    <CurrencyFormattedParts
      value={value}
      currencyCode={currencyCode}
      fractionalDigitCount={fractionalDigitCount}
    />
  )
}

export const ExchangeRateText = ({ value, currencyCode }: CurrencyTextProps) => {
  return (
    <>
      USD/{currencyCode}&nbsp;
      <CurrencyFormattedParts value={value} currencyCode={currencyCode} fractionalDigitCount={10} />
    </>
  )
}
