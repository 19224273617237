import { BankAccountDetails, CardDetails } from 'Utils/types/payment'
import { CountryCode } from './countries'

export enum PaymentMethodType {
  Card = 'card',
  Bank = 'bank',
  USBank = 'us_bank_account'
}

export enum MajorCreditCardBrand {
  Visa = 'visa',
  Mastercard = 'mastercard',
  Discover = 'discover',
  Amex = 'amex'
}

export type PaymentMethodBasicDetails = {
  id: string
  type: PaymentMethodType
  stripeCustomerId: number
  countryCode: CountryCode
}

export type PaymentCard = CardDetails & PaymentMethodBasicDetails
export type PaymentBankAccount = BankAccountDetails & PaymentMethodBasicDetails

export type PaymentMethod = PaymentCard | PaymentBankAccount

export const isPaymentMethod = (object: unknown): object is PaymentMethod =>
  !!(object && typeof object === 'object' && 'type' in object)

export const isCardPayment = (object: unknown): object is PaymentCard =>
  !!(object && typeof object === 'object' && 'expMonth' in object)

export const isMajorCreditCardBrand = (name: MajorCreditCardBrand | string) =>
  Object.values<string>(MajorCreditCardBrand).includes(name)
