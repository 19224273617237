import { StyledInput } from 'Components/Inputs/StyledInput'
import styled from 'styled-components'
import { useCallback, useMemo, useRef } from 'react'
import { FlexColumn, GridColumn, spacingMD, spacingSM } from 'Utils/styles/spacing'
import { UserStatus } from 'Utils/types/userStatus'
import { titleize } from 'Utils/helpers/text'
import { CountryCode } from 'Utils/types/countries'

import { PeopleTableFilters } from 'Pages/Employer/PeoplePage/PeopleTable/PeopleTable'
import { SelectOption, StyledSelect, StyledSelectRef } from 'Components/Inputs/StyledSelect'
import { StyledButton } from 'Components/Buttons/StyledButton'
import { ClearFiltersButton } from 'Components/Table/ClearFiltersButton'

import { ModalType, usePeoplePageContext } from 'Contexts/PeoplePageContext'
import { useProgramOptions } from 'Hooks/useProgramOptions'
import { useCountries } from 'Hooks/useCountries'
import { useDebouncedSearchInput } from 'Hooks/useDebouncedSearchInput'
import { TableLabel, fontWeightSlightlyBold, fontSizeSM } from 'Utils/styles/text'
import { elfGreen, elfGreenHover, elfGreenActive, white } from 'Utils/styles/colors'

import {
  DropdownContent,
  DropdownItem,
  DropdownRoot,
  DropdownTrigger
} from 'Components/DropdownMenu/DropdownMenu'

import { PersonIcon, Pencil1Icon } from '@radix-ui/react-icons'
import { FormattedMessage } from 'react-intl'

const StyledDropdownTrigger = styled(DropdownTrigger)`
  height: 48px;
  border-radius: 8px;
  cursor: pointer;
  background-color: ${elfGreen};
  color: ${white};

  &:hover,
  &:focus {
    background-color: ${elfGreenHover};
  }

  &:active {
    background-color: ${elfGreenActive};
  }

  font-family: 'BasisGrotesquePro', sans-serif;
  font-weight: ${fontWeightSlightlyBold};
  font-size: ${fontSizeSM};
  line-height: 1;
`

const ActionsContainer = styled.div<{
  showProgramFilters: boolean
  showBulkInvite: boolean
  showCountryFilter: boolean
}>`
  display: grid;
  grid-column-gap: ${spacingMD};
  align-items: center;
  grid-template-columns:
    auto
    ${({ showProgramFilters }) => (showProgramFilters ? ` 20% ` : '')}
    13%
    ${({ showCountryFilter }) => (showCountryFilter ? ` 13% ` : '')}
    13%
    ${({ showBulkInvite }) => (showBulkInvite ? ` 14%` : '')};
`

type PeopleTableFiltersProps = {
  updateFilters: (newFilters: Partial<PeopleTableFilters>) => void
  clearFilters?: () => void
  isJoonAdmin: boolean
}

export const PeopleTableActions = ({
  updateFilters,
  clearFilters,
  isJoonAdmin = false
}: PeopleTableFiltersProps) => {
  const { setModal } = usePeoplePageContext()
  const { hasMultipleCountries, countryOptionsWithAll } = useCountries()

  const programSelectRef = useRef<StyledSelectRef | null>(null)
  const countrySelectRef = useRef<StyledSelectRef | null>(null)
  const statusSelectRef = useRef<StyledSelectRef | null>(null)

  const resetSelects = useCallback(() => {
    programSelectRef?.current?.resetSelect()
    countrySelectRef?.current?.resetSelect()
    statusSelectRef?.current?.resetSelect()
  }, [programSelectRef, countrySelectRef, statusSelectRef])

  const updateQueryFilter = useCallback(
    (query: string) => updateFilters({ query }),
    [updateFilters]
  )

  const {
    value: searchInputValue,
    setValue: setSearchInputValue,
    flush
  } = useDebouncedSearchInput(updateQueryFilter, 3000)

  const resetFilters = useMemo(() => {
    return searchInputValue || clearFilters
      ? () => {
          resetSelects()
          setSearchInputValue('')
          clearFilters && clearFilters()
        }
      : undefined
  }, [clearFilters, resetSelects, searchInputValue, setSearchInputValue])

  const handleStatusChange = useCallback(
    (status: UserStatus | '') => {
      updateFilters({ status: status !== '' ? status : undefined })
    },
    [updateFilters]
  )
  const handleCountryChange = useCallback(
    (country: CountryCode | '') => {
      updateFilters({ country: country !== '' ? country : undefined })
    },
    [updateFilters]
  )
  const handleBenefitProgramChange = useCallback(
    (benefitProgram: string) => updateFilters({ filterProgramName: benefitProgram }),
    [updateFilters]
  )

  const showInviteUserModal = useCallback(() => setModal(ModalType.InviteUser), [setModal])
  const showBulkInviteModal = useCallback(() => setModal(ModalType.BulkInvite), [setModal])
  const showBulkUpdateModal = useCallback(() => setModal(ModalType.BulkUpdate), [setModal])

  const userStatusOptions = useMemo<{ value: UserStatus | ''; label: string }[]>(
    () => [
      { value: '', label: 'All Users' },
      ...Object.values(UserStatus)
        .filter(
          (status) => ![UserStatus.ScheduledInvite, UserStatus.ScheduledRemoval].includes(status)
        )
        .map((value) => ({
          value: value,
          label: titleize(value)
        }))
    ],
    []
  )

  const { programOptions } = useProgramOptions({ useName: true, forEmployerAdmin: true })
  const bpOptions: SelectOption[] = [{ value: '', label: 'All Programs' }, ...programOptions]

  return (
    <FlexColumn gap={spacingSM}>
      <GridColumn>
        <TableLabel>Users</TableLabel>
        <ClearFiltersButton onClear={resetFilters} />
      </GridColumn>
      <ActionsContainer
        showProgramFilters={programOptions.length > 1}
        showBulkInvite={isJoonAdmin}
        showCountryFilter={hasMultipleCountries}
      >
        <StyledInput
          errorPresent={false}
          placeholder="Search By Name"
          value={searchInputValue}
          onChange={(e) => setSearchInputValue(e.target.value)}
          onBlur={flush}
          onKeyUp={({ key }) => key === 'Enter' && flush()}
        />
        {programOptions.length > 1 && (
          <StyledSelect
            ref={programSelectRef}
            handleChange={handleBenefitProgramChange}
            options={bpOptions}
            defaultValue={bpOptions[0]?.value}
          />
        )}
        <StyledSelect
          ref={statusSelectRef}
          handleChange={handleStatusChange}
          options={userStatusOptions}
          defaultValue={userStatusOptions[0]?.value}
        />
        {hasMultipleCountries && (
          <StyledSelect
            ref={countrySelectRef}
            handleChange={handleCountryChange}
            options={countryOptionsWithAll}
            defaultValue={countryOptionsWithAll[0].value}
          />
        )}
        <StyledButton primary id="users.invite" onClick={showInviteUserModal} />

        {isJoonAdmin && (
          <DropdownRoot>
            <StyledDropdownTrigger>
              <FormattedMessage id="users.bulkActions" />
            </StyledDropdownTrigger>
            <DropdownContent>
              <DropdownItem onSelect={showBulkInviteModal}>
                <PersonIcon />
                <FormattedMessage id="users.bulkInvite" />
              </DropdownItem>
              <DropdownItem onSelect={showBulkUpdateModal}>
                <Pencil1Icon />
                <FormattedMessage id="users.bulkUpdate" />
              </DropdownItem>
            </DropdownContent>
          </DropdownRoot>
        )}
      </ActionsContainer>
    </FlexColumn>
  )
}
