import { useMutation, useQueryClient } from 'react-query'
import { useErrorReporter } from 'Hooks/useErrorReporter'

import { QueryNames } from 'Hooks/api/queryNames'

import { addUserToPrograms } from 'Services/benefitProgramService'
import { toast } from 'react-toastify'
import { AppNotification } from 'Utils/types/appNotification'
import { usePeoplePageContext } from 'Contexts/PeoplePageContext'

export const useAddUserToProgramsQuery = () => {
  const queryClient = useQueryClient()
  const reportError = useErrorReporter()

  const { setModal, setActionableUserId } = usePeoplePageContext()

  return useMutation([QueryNames.AddUserToPrograms], addUserToPrograms, {
    onSuccess: (response) => {
      if (response.success) {
        // TODO: Nice to have here is getting an updated object to update context/state
        queryClient.invalidateQueries([QueryNames.UsersForEmployer])
        toast.success(AppNotification.UserActivateSuccess)
      } else {
        toast.error(AppNotification.UserActivateFailed)
      }

      setModal(undefined)
      setActionableUserId(undefined)
    },
    onError: (err: string) => {
      reportError(`useAddUserToProgramsQuery error: ${err}`)
    }
  })
}
