import { Modal } from '../Modal'
import { ModalType, usePeoplePageContext } from 'Contexts/PeoplePageContext'
import { InviteUserForm } from 'Components/Modal/InviteUserModal/InviteUserForm'

export const InviteUserModal = () => {
  const {
    state: { modal },
    setModal
  } = usePeoplePageContext()

  const setShowModal = (show: boolean) => setModal(show ? ModalType.InviteUser : undefined)

  return (
    <Modal showModal={modal === ModalType.InviteUser} setShowModal={setShowModal}>
      <InviteUserForm />
    </Modal>
  )
}
