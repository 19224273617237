import { CSSProperties } from 'react'
import styled from 'styled-components'
import { spacingSM, spacingXS } from 'Utils/styles/spacing'
import { black, Colors, frostedGlass } from 'Utils/styles/colors'
import { fontSizeXS, fontSizeXXS, fontWeightSlightlyBold } from 'Utils/styles/text'

const Container = styled.span<{ backgroundColor: Colors; fontColor: Colors }>`
  display: inline-flex;
  align-items: center;
  padding: ${spacingXS} ${spacingSM};
  background: ${(props) => props.backgroundColor};
  color: ${(props) => props.fontColor};
  border-radius: 24px;
  font-size: ${fontSizeXS};
  line-height: ${fontSizeXXS};
  font-weight ${fontWeightSlightlyBold};
  box-sizing: border-box;
  gap: ${spacingSM};
  text-align: center;
  justify-content: center;
`

export const Chip = ({
  text,
  children,
  style,
  onClick,
  fontColor = black,
  backgroundColor = frostedGlass
}: {
  text: string
  children?: JSX.Element
  hoverInfo?: string
  fontColor?: Colors
  backgroundColor?: Colors
  style?: CSSProperties
  onClick?: () => void
}) => {
  return (
    <Container
      style={style}
      backgroundColor={backgroundColor}
      fontColor={fontColor}
      onClick={onClick}
    >
      {text}
      {children}
    </Container>
  )
}
