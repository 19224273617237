import { useMemo } from 'react'
import { useEmployerInfoContext } from 'Contexts/EmployerInfoContext'

import { convertCountriesToOptions } from 'Utils/helpers/countries'
import { SelectOption } from 'Components/Inputs/StyledSelect'
import { CountryCode } from 'Utils/types/countries'

export const useCountries = (isJoonAdmin = false) => {
  const {
    state: { employerInfo }
  } = useEmployerInfoContext()

  const hasMultipleCountries = useMemo(
    () => (employerInfo?.countries || []).length > 1,
    [employerInfo]
  )

  const showCountries = useMemo(
    () => isJoonAdmin || hasMultipleCountries,
    [hasMultipleCountries, isJoonAdmin]
  )

  const countryOptions = useMemo(
    () => convertCountriesToOptions(employerInfo?.countries),
    [employerInfo]
  )

  const countryOptionsWithAll = useMemo<SelectOption<CountryCode | ''>[]>(
    () => [{ value: '', label: 'All Countries' }, ...countryOptions],
    [countryOptions]
  )

  return { hasMultipleCountries, showCountries, countryOptions, countryOptionsWithAll }
}
