import { useCallback, useMemo } from 'react'
import { useUserProgramContext } from 'Contexts/UserProgramContext'
import { BenefitProgramCategory } from 'Utils/types/benefitProgramCategory'
import { fromPairs } from 'ramda'
import { useEmployerProgramsContext } from 'Contexts/EmployerProgramsContext'

export const useProgramOptions = ({
  useName = false,
  useProgramCategoryId = false,
  forEmployerAdmin = false
}: { useName?: boolean; useProgramCategoryId?: boolean; forEmployerAdmin?: boolean } = {}) => {
  const {
    state: { programs: userPrograms }
  } = useUserProgramContext()
  const {
    state: { programs: employerPrograms }
  } = useEmployerProgramsContext()

  const programs = forEmployerAdmin && employerPrograms.length > 0 ? employerPrograms : userPrograms

  const programOptions = useMemo(
    () =>
      programs.map((item) => ({
        value: useName ? item.name : item.id.toString(),
        label: item.name
      })),
    [programs, useName]
  )

  const categoryOption = useCallback(
    (category: BenefitProgramCategory) => ({
      value: useName
        ? category.name
        : useProgramCategoryId
        ? category.benefit_program_category_id?.toString() ?? category.id?.toString()
        : category.id?.toString(),
      label: category.name
    }),
    [useName, useProgramCategoryId]
  )

  const categoryMap = useMemo(
    () =>
      fromPairs(programs.map(({ id, categories }) => [`${id}`, categories.map(categoryOption)])),
    [categoryOption, programs]
  )

  return useMemo(() => ({ programOptions, categoryMap }), [categoryMap, programOptions])
}
