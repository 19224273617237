import { useQuery } from 'react-query'
import { useErrorReporter } from 'Hooks/useErrorReporter'

import { UserInfoActionTypes, useUserInfoContext } from 'Contexts/UserInfoContext'
import { UserProgramActionTypes, useUserProgramContext } from 'Contexts/UserProgramContext'

import { getUserDashboardData } from 'Services/dashboardService'

import { QueryNames } from 'Hooks/api/queryNames'

export const useDashboardQuery = (userId?: number) => {
  const reportError = useErrorReporter()
  const userProgramContext = useUserProgramContext()
  const userInfoContext = useUserInfoContext()
  return useQuery({
    queryKey: [QueryNames.Dashboard, userId],
    queryFn: async () => {
      if (userId === undefined) return

      userInfoContext.dispatch({ type: UserInfoActionTypes.SetLoadingUser, value: true })
      const response = await getUserDashboardData(userId)
      userInfoContext.dispatch({ type: UserInfoActionTypes.SetLoadingUser, value: false })

      if (response.data) {
        userInfoContext.dispatch({ type: UserInfoActionTypes.SetUser, value: response.data.user })
        response.data.latestInvoiceDetails &&
          userInfoContext.dispatch({
            type: UserInfoActionTypes.SetLatestInvoiceDetails,
            value: response.data.latestInvoiceDetails
          })

        userInfoContext.dispatch({
          type: UserInfoActionTypes.SetEmployerCountry,
          value: response.data.employerCountry
        })
        userProgramContext.dispatch({
          type: UserProgramActionTypes.SetPrograms,
          value: response.data.programs
        })

        userInfoContext.dispatch({
          type: UserInfoActionTypes.SetNotificationPreferences,
          value: response.data.user.notificationConfig
        })
        return response
      } else {
        reportError('useDashboardQuery failure', response)
      }
    },
    onError: (err: string) => {
      userInfoContext.dispatch({ type: UserInfoActionTypes.SetLoadingUser, value: false })
      reportError(`useDashboardQuery error: ${err}`)
      return undefined
    }
  })
}
