import { ReactNode } from 'react'

import styled from 'styled-components'
import { Colors } from 'Utils/styles/colors'
import { FontSizes, FontWeights } from 'Utils/styles/text'

const Span = styled.span<{
  color?: Colors
  fontWeight?: FontWeights
  fontSize?: FontSizes
  lineHeight?: number
}>`
  ${(props) => (props.color ? `color: ${props.color};` : '')}
  ${(props) => (props.fontSize ? `font-size: ${props.fontSize};` : '')}
  ${(props) => (props.fontWeight ? `font-weight: ${props.fontWeight};` : '')}
  ${(props) => (props.lineHeight ? `line-height: ${props.lineHeight}%;` : '')}
`

type TextSpanProps = {
  children: ReactNode
  color?: Colors
  fontWeight?: FontWeights
  fontSize?: FontSizes
  lineHeight?: number
}
export const TextSpan = ({ children, color, fontWeight, fontSize, lineHeight }: TextSpanProps) => {
  return (
    <Span color={color} fontWeight={fontWeight} fontSize={fontSize} lineHeight={lineHeight}>
      {children}
    </Span>
  )
}
