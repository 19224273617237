import styled from 'styled-components'
import {
  black,
  elfGreen,
  elfGreenActive,
  elfGreenHover,
  frostedGlass,
  towerGray,
  white,
  zirconGray,
  bloodOrange,
  bloodOrangeFaded
} from 'Utils/styles/colors'
import { BaseText, fontWeightSlightlyBold } from 'Utils/styles/text'
import { FormattedMessage } from 'react-intl'
import React, { CSSProperties, ReactNode, MouseEvent } from 'react'
import { spacingLG, spacingMD } from 'Utils/styles/spacing'
import { SimpleCircularProgress } from 'Components/CircularProgress/SimpleCircularProgress'

export type ButtonSizes = 'small' | 'medium' | 'large'

export const BUTTON_SIZES: Record<ButtonSizes, string> = {
  small: '40px',
  medium: '48px',
  large: '56px'
}

const getButtonColor = (primary: boolean, disabled: boolean) => {
  if (disabled) return zirconGray
  if (primary) return elfGreen
  return white
}

const getButtonHoverColor = (primary: boolean, disabled: boolean, danger: boolean) => {
  if (disabled) return zirconGray
  if (primary) return elfGreenHover
  if (danger) return bloodOrangeFaded
  return frostedGlass
}

const getButtonActiveColor = (primary: boolean, disabled: boolean, danger: boolean) => {
  if (disabled) return zirconGray
  if (primary) return elfGreenActive
  if (danger) return bloodOrangeFaded
  return zirconGray
}

const getButtonTextColor = (
  primary: boolean,
  secondary: boolean,
  disabled: boolean,
  danger: boolean
) => {
  if (disabled) return towerGray
  if (primary) return white
  if (secondary) return black
  if (danger) return bloodOrange
  return elfGreen
}

export const JoonButton = styled.button<{
  primary: boolean
  secondary: boolean
  disabled: boolean
  danger: boolean
  size: 'small' | 'medium' | 'large'
  rightIcon: boolean
}>`
  ${BaseText};
  position: relative;
  height: ${(props) => BUTTON_SIZES[props.size]};
  padding: ${spacingMD} ${spacingLG};
  ${(props) => (props.rightIcon ? `padding-right: 2.5em` : '')};

  border-radius: 8px;
  border: ${(props) => (props.secondary ? `1px solid ${zirconGray}` : 'none')};

  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  background-color: ${(props) => getButtonColor(props.primary, props.disabled)};
  color: ${(props) =>
    getButtonTextColor(props.primary, props.secondary, props.disabled, props.danger)};
  &:hover,
  &:focus {
    outline: none;
    background-color: ${(props) =>
      getButtonHoverColor(props.primary, props.disabled, props.danger)};
  }
  &:active {
    background-color: ${(props) =>
      getButtonActiveColor(props.primary, props.disabled, props.danger)};
  }

  display: flex;
  gap: ${spacingMD};
  justify-content: center;
  align-items: center;
  font-family: 'BasisGrotesquePro', sans-serif;
  font-weight: ${fontWeightSlightlyBold};
`

const ButtonIcon = styled.div<{
  leftIcon: boolean
}>`
  position: absolute;
  ${(props) => (props.leftIcon ? `left: ${spacingMD};` : `right: ${spacingMD};`)};

  width: ${spacingMD};
  height: ${spacingMD};

  img {
    width: 100%;
    height: 100%;
  }
`

export type ButtonTypes = 'submit' | 'button'
export type StyledButtonProps = {
  primary?: boolean
  secondary?: boolean
  disabled?: boolean
  danger?: boolean
  isLoading?: boolean
  size?: ButtonSizes
  type?: ButtonTypes
  onClick?: (e: MouseEvent<HTMLButtonElement>) => any
  style?: CSSProperties
  icon?: ReactNode
  iconPosition?: 'left' | 'right'
  className?: string
  id: string
  values?: Record<string, string>
}

export const StyledButton: React.FC<StyledButtonProps> = ({
  primary = false,
  secondary = false,
  disabled = false,
  danger = false,
  isLoading = false,
  size = 'medium',
  type = 'submit',
  iconPosition = 'left',
  onClick = () => undefined,
  style,
  icon,
  className,
  values,
  id
}) => (
  <JoonButton
    type={type}
    primary={primary}
    secondary={secondary}
    danger={danger}
    disabled={disabled || isLoading}
    size={size}
    onClick={onClick}
    style={style}
    className={className}
    rightIcon={iconPosition === 'right'}
  >
    {icon && <ButtonIcon leftIcon={iconPosition === 'left'}>{icon}</ButtonIcon>}
    {isLoading && <SimpleCircularProgress />}
    <FormattedMessage id={id} values={values} />
  </JoonButton>
)
