import { FC } from 'react'
import styled from 'styled-components'

import { CardLabel, DarkGreenText, OrangeText } from 'Utils/styles/text'
import { displayDate } from 'Utils/helpers/dateUtils'
import { FlexColumn, spacingMD } from 'Utils/styles/spacing'
import { UserDetailsForEmployer } from 'Utils/types/user'
import { InviteStatus } from 'Utils/types/inviteStatus'
import { UserStatus } from 'Utils/types/userStatus'

const StyledContainer = styled(FlexColumn)`
  flex: 1;
  gap: ${spacingMD};
`

type UserDetailsInfoProps = {
  userPrograms: string[]
  user?: UserDetailsForEmployer
}
export const UserDetailsInfo: FC<UserDetailsInfoProps> = ({ userPrograms, user }) => {
  const now = new Date()
  const enabled =
    user?.status === UserStatus.Enabled && user?.inviteStatus === InviteStatus.Accepted
  const invited = user?.status === UserStatus.Invited && user?.inviteStatus === InviteStatus.Invited
  const removed = user?.status && [UserStatus.Removed, UserStatus.Disabled].includes(user.status)
  const invitedAndSent = invited && user.invitedAt <= now
  const invitedAndScheduled = invited && user.invitedAt > now

  return (
    <StyledContainer>
      <FlexColumn>
        <CardLabel>User Details Info</CardLabel>
        {enabled && (
          <>
            <DarkGreenText>Date Invited: {displayDate(user.invitedAt)}</DarkGreenText>
            <DarkGreenText>
              Date Enabled: {user?.joinedAt && displayDate(user.joinedAt)}
            </DarkGreenText>
            <DarkGreenText>Login Attempts: {user.failedLoginCount}</DarkGreenText>
          </>
        )}
        {invitedAndSent && (
          <DarkGreenText>Date Invite Sent: {displayDate(user.invitedAt)}</DarkGreenText>
        )}
        {invitedAndScheduled && (
          <OrangeText>
            Date Invite Scheduled: {user.inviteSendAt && displayDate(user.inviteSendAt)}
          </OrangeText>
        )}
        {removed && <OrangeText>User has been removed/disabled.</OrangeText>}
      </FlexColumn>
      <FlexColumn>
        <CardLabel>Active Benefit Programs</CardLabel>
        {userPrograms.length === 0 ? (
          <OrangeText>No programs</OrangeText>
        ) : (
          userPrograms.map((program, idx) => (
            <DarkGreenText key={`${program}-${idx}`}>{program}</DarkGreenText>
          ))
        )}
      </FlexColumn>
    </StyledContainer>
  )
}
