import { AxiosRequestConfig, RawAxiosRequestHeaders, AxiosResponse } from 'axios'
import { isAxiosError, parseErrorResponse } from './shared'
import { api } from '../../index'

export const postRequest = async <TResponse, TPayload = undefined>(
  url: string,
  data?: TPayload
): Promise<TResponse & { statusCode?: number }> => {
  const contentType =
    data && data instanceof FormData
      ? { 'Content-Type': 'multipart/form-data' }
      : { 'Content-Type': 'application/json' }

  const headers: RawAxiosRequestHeaders = {
    Accept: 'application/json',
    ...contentType
  }

  const config: AxiosRequestConfig = {
    method: 'POST',
    url: url,
    headers: headers,
    data: data
  }

  try {
    const response: AxiosResponse = await api.request(config)

    if (response.status === 200 && response.data) {
      return { ...response.data, statusCode: response.status }
    } else {
      if (isAxiosError(response) && response) {
        return { ...(response.data as any), statusCode: response.status }
      }
      throw new Error(parseErrorResponse(response.data))
    }
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response?.status === 401) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return {
          success: false,
          message: 'You are unauthorized to access this resource. Please login again.',
          statusCode: error.response.status
        }
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return {
        success: false,
        message: 'Unknown Error',
        statusCode: error.response?.status
      }
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return {
      success: false,
      message: 'Unknown Error',
      statusCode: 404
    }
  }
}

export const postRequestDownloadFile = async <TPayload>(
  url: string,
  data?: TPayload,
  options: AxiosRequestConfig = {}
): Promise<{ data: ArrayBuffer; statusCode?: number } | undefined> => {
  const headers: RawAxiosRequestHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }

  const config: AxiosRequestConfig = {
    method: 'POST',
    url: url,
    headers: headers,
    data: data,
    ...options
  }

  try {
    const response: AxiosResponse = await api.request(config)

    if (response.status === 200 && response.data) {
      return { data: response.data, statusCode: response.status }
    } else {
      if (isAxiosError(response) && response) {
        return { data: response.data, statusCode: response.status }
      }
      throw new Error(parseErrorResponse(response.data))
    }
  } catch (error) {
    if (isAxiosError(error)) {
      // TODO: Check response codes
      // return { success: false, message: error.message }
    }
    return undefined
  }
}
