export enum AppNotification {
  GeneralError = 'Something went wrong, please contact support if the error persists.',
  AddPurchaseSuccess = 'Your purchase has been added.',
  AddPurchaseFailure = 'Something went wrong adding your purchase.',
  RequestPurchaseEligibilitySuccess = 'Your purchase has been submitted for review.',
  RequestPurchaseEligibilityFailure = 'Something went wrong submitting your purchase for review. Please contact support (info@joon.io) if error persists.',
  AddPurchaseAccountSuccess = 'Your purchase account has been added.',
  AddPurchaseAccountFailure = 'Something went wrong adding your purchase account.',
  CountryNotSupported = 'JOON does not currently support your country for purchase account connection. Please upload your receipts manually.',
  RelinkPurchaseAccountFailure = 'Something went wrong reauthorizing of your purchase account.',
  RelinkPurchaseAccountExitFailure = 'You exited the purchase account authorization flow.',
  RelinkPurchaseAccountSuccess = 'Reauthorized purchase account.',
  RemovePurchaseAccountFailure = 'Something went wrong removing of your purchase account.',
  RemovePurchaseAccountSuccess = 'Removed purchase account.',
  DepositAccountLinkFail = 'Something went wrong redirecting you to Stripe.',
  AddDepositAccountFailure = 'Something went wrong adding your deposit account.',
  AddDepositAccountSuccess = 'Your deposit account has been added.',
  ChangePasswordSuccess = 'Your password has been changed.',
  ChangePasswordFailure = 'Something went wrong changing your password.',
  UpdateUserDetailsSuccess = 'User details have been updated.',
  UpdateUserDetailsFailure = 'Something went wrong updating user details.',
  SetUserNotificationPreferencesSuccess = 'Your notification preferences have been updated.',
  SetUserNotificationPreferencesFailure = 'Something went wrong changing your notification preferences.',
  RemovePaymentAccountSuccess = 'Your payment account has been removed.',
  RemovePaymentAccountFailure = 'Something went wrong removing your payment account.',
  AddPaymentAccountFailure = 'Something went wrong adding your payment account.',
  TurnOnAutopaySuccess = 'Automatic payments have been enabled.',
  TurnOnAutopayFailure = 'Something went wrong enabling automatic payments.',
  TurnOffAutopaySuccess = 'Automatic payments have been disabled.',
  TurnOffAutopayFailure = 'Something went wrong disabling automatic payments.',
  SubmitInvoicePaymentSuccess = 'Payment successfully submitted.',
  SubmitInvoicePaymentFailure = 'Something went wrong submitting the payment.',
  UserActivateSuccess = 'User added to programs.',
  UserActivateFailed = 'Issue adding user to programs.',
  UserRemovalSuccess = 'User Removed.',
  UserRemovalFailed = 'Issue removing user.',
  UserPermissionsUpdatedSuccessful = 'User permissions updated.',
  ResetPasswordLinkSent = 'If you have a valid account with JOON, an email has been sent to you. Please check your email and follow the steps to reset your password.',
  ResetPasswordSuccess = 'Password has been reset successfully.',
  UserInviteSuccess = 'User invitation has been sent.',
  UserInviteFailure = 'Something went wrong when sending the invite.',
  CsvUserInviteSuccess = 'CSV invites has been processed',
  CsvUserInviteFailure = 'Something went wrong with CSV invites.',
  CsvUserUpdateMetaDataSuccess = 'Bulk user metadata has been successfully processed.',
  CsvUserUpdateMetaDataFailure = 'An error has occurred while processing the bulk update of user metadata.',
  PurchaseInvalidationSuccess = 'Purchase invalidated.',
  InvoiceDownloadSuccess = 'Invoice downloaded.',
  ReportDownloadSuccess = 'Report request queued. It will be emailed to you shortly.',
  ReportDownloadFailure = 'Report request failed to queue.',
  InvoiceDownloadFailure = 'Something went wrong downloading the invoice.',
  AllowanceModificationUpdateSuccess = 'Allowance successfully updated.',
  AllowanceModificationUpdateFailure = 'Something went wrong updating the policy.',
  ApproveManualPurchaseSuccess = 'Purchase successfully approved.',
  ApproveManualPurchaseFailure = 'Something went wrong approving this purchase.',
  DeclineManualPurchaseSuccess = 'Purchase successfully declined.',
  DeclineManualPurchaseFailure = 'Something went wrong declining this purchase.',
  AddInvoiceSuccess = 'Preparing Invoice... Refresh in a few minutes.',
  AddInvoiceFailure = 'Something went wrong adding this invoice.',
  DeleteInvoiceSuccess = 'Invoice deleted.',
  DeleteInvoiceFailure = 'Something went wrong deleting this invoice.',
  DeleteStripeAccountSuccess = 'Stripe account deleted.',
  DeleteStripeAccountFailure = "Something went wrong deleting user's stripe account.",
  FinalizeInvoiceSuccess = 'Invoice state changed to open.',
  FinalizeInvoiceFailure = 'Something went wrong finalizing this invoice.',
  RevertInvoiceToDraftSuccess = 'Invoice reverted to draft.',
  RevertInvoiceToDraftFailure = 'Something went wrong reverting this invoice to draft.',
  RunInvoiceAutopaySuccess = 'Invoice paid using auto-pay.',
  RunInvoiceAutopayFailure = 'Something went wrong charging for this invoice.',
  AssociateStripeInvoiceSuccess = 'Stripe Invoice ID saved.',
  AssociateStripeInvoiceFailure = 'Something went wrong updating this invoice.',
  UpdateUserCountrySuccess = 'User country successfully updated.',
  UpdateUserCountryFailure = 'Something went wrong updating user country.',
  RemoveUserFromProgramSuccess = 'Successfully updated eligibility dates for user.',
  RemoveUserFromProgramFailure = 'Failed to update user eligibility dates. Please contact support.',
  CreateBenefitProgramSuccess = 'Benefit program successfully created.',
  CreateBenefitProgramFailure = 'Something went wrong creating this benefit program.',
  UpdateBenefitProgramSuccess = 'Benefit program successfully updated.',
  UpdateBenefitProgramFailure = 'Something went wrong updating this benefit program.',
  UpdateBenefitProgramPolicySuccess = 'Benefit program policy successfully updated.',
  UpdateBenefitProgramPolicyFailure = 'Something went wrong updating this benefit program policy.',
  RemoveMerchantFromListSuccess = 'Merchant was successfully removed from the list.',
  RemoveMerchantFromListFailure = 'Something went wrong removing the merchant from the list.',
  AddProgramToMerchantSuccess = 'Merchant was successfully associated with program.',
  AddProgramToMerchantFailure = 'Something went wrong adding merchant to the list.',
  ExtendConnectionSuccess = 'Successfully reconfirmed purchase card connection.',
  ExtendConnectionFailure = 'Something went wrong reconfirming purchase card connection.',
  InviteNotFound = 'Invite not found. Please contact your admin.',
  ResetRenewDateSuccess = 'User purchase account connection renewal date updated.',
  ResetRenewDateFailure = 'Something went wrong updating user purchase account connection.',
  TerminateEmployerSuccess = 'Successfully terminated employer.',
  TerminateEmployerFailure = 'Something went wrong terminating employer.',
  BenefitProgramReceiptDownloadSuccess = 'Request to download receipts successfully processed',
  BenefitProgramReceiptDownloadFailure = 'Something went wrong requesting receipt downloads.',
  PayWithPrepaidInvoiceSuccess = 'Request to use prepaid invoice was successful!',
  PayWithPrepaidInvoiceFailure = 'Request to use prepaid invoice failed!',
  AccountUnlockedSuccess = 'User account unlocked.',
  AccountUnlockedFailure = 'Something went wrong unlocking user account.',
  GenericSuccess = 'Success!',
  GenericFailure = 'Something went wrong.',
  CreateEmployerSuccess = 'Employer successfully created.',
  CreateEmployerFailure = 'Something went wrong creating this employer.',
  CreateEmployerCountrySuccess = 'Country successfully created.',
  CreateEmployerCountryFailure = 'Something went wrong creating this country.',
  UpdateEmployerCountrySuccess = 'Country successfully updated.',
  UpdateEmployerCountryFailure = 'Something went wrong updating this country.',
  DeleteEmployerCountrySuccess = 'Country successfully deleted.',
  DeleteEmployerCountryFailure = 'Something went wrong deleting this country.',
  StripeInvoiceLinkFailure = 'Something went wrong redirecting you to your Stripe invoice.'
}
