import styled from 'styled-components'
import { fontSizeHeader, fontWeightBold } from 'Utils/styles/text'
import { black, white } from 'Utils/styles/colors'
import { pagePadding, spacingSM } from 'Utils/styles/spacing'

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-basis: 100%;
  flex: 1;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: ${spacingSM};
`

export const CenteredColumn = styled(Column)`
  justify-content: center;
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${pagePadding}
  background-color: ${white};
`

export const HeaderText = styled.span`
  font-weight: ${fontWeightBold};
  font-size: ${fontSizeHeader};
  color: ${black};
`

export const JOONLogo = styled.img`
  width: 150px;
`
