import {
  ApiBenefitProgramUtilization,
  ApiMemberships,
  ApiUserInvite,
  GetUsersForEmployerApiResponseData,
  GetUsersForEmployerResponseData,
  InviteUserApiPayload,
  InviteUserPayload
} from 'Services/apiResponseTypes'
import { InviteStatus } from 'Utils/types/inviteStatus'
import { Memberships, UserBenefitProgramUtilization } from 'Utils/types/benefitProgram'
import { parseInvoice } from 'Services/invoices/parsers'
import { parseServerDate } from 'Utils/helpers/dateUtils'

export const parseGetUsersForEmployerData = (
  data: GetUsersForEmployerApiResponseData
): GetUsersForEmployerResponseData => {
  return {
    users: data.users.map((apiUser) => {
      const {
        id,
        email,
        first_name,
        last_name,
        latest_invoice_details,
        status,
        program_utilization_details,
        active_memberships,
        future_memberships,
        expired_memberships,
        benefit_programs,
        earliest_invite,
        accepted_invite,
        country_code,
        currency_code,
        roles,
        stripe_customer,
        purchase_accounts,
        failed_login_count
      } = apiUser

      const invitedAt = parseServerDate(earliest_invite?.sent_at ?? earliest_invite?.send_at)
      const joinedAt = accepted_invite?.accepted_at
        ? parseServerDate(accepted_invite.accepted_at)
        : undefined

      return {
        id,
        userInfo: {
          email: email,
          firstName: first_name,
          lastName: last_name,
          ssoRedirectUrl: ''
        },
        stripeAccount: stripe_customer && {
          stripeCustomerId: stripe_customer.stripe_customer_id,
          id: stripe_customer.id,
          createdAt: parseServerDate(stripe_customer.created_at)
        },
        failedLoginCount: failed_login_count,
        countryCode: country_code,
        currencyCode: currency_code,
        status,
        programUtilizationDetails: parseUtilization(program_utilization_details),
        activeMemberships: parseMembership(active_memberships || []),
        futureMemberships: parseMembership(future_memberships || []),
        expiredMemberships: parseMembership(expired_memberships || []),
        inviteStatus: parseUserInviteStatus(earliest_invite, accepted_invite),
        inviteId: earliest_invite.id,
        inviteSendAt: earliest_invite.send_at
          ? parseServerDate(earliest_invite.send_at)
          : undefined,
        invitedAt,
        joinedAt,
        latestInvoiceDetails: parseInvoice(latest_invoice_details),
        invitedPrograms: benefit_programs.map((program) => program.name),
        roles: roles.map((role) => role.name),
        hasPurchaseAccount: purchase_accounts?.length > 0
      }
    })
  }
}

export const parseUtilization = (
  programUtilizationDetails: ApiBenefitProgramUtilization[]
): UserBenefitProgramUtilization[] => {
  return programUtilizationDetails.map((utilization) => {
    return {
      ...utilization,
      membershipStartDate: parseServerDate(utilization.membershipStartDate),
      membershipEndDate: utilization.membershipEndDate
        ? parseServerDate(utilization.membershipEndDate)
        : undefined
    }
  })
}

export const parseMembership = (memberships: ApiMemberships[]): Memberships[] => {
  return memberships.map((membership) => {
    return {
      ...membership,
      programName: membership.programName,
      membershipStartDate: parseServerDate(membership.membershipStartDate),
      membershipEndDate: membership.membershipEndDate
        ? parseServerDate(membership.membershipEndDate)
        : undefined
    }
  })
}

export const parseUserInviteStatus = (
  currentInvite: ApiUserInvite,
  acceptedInvite: ApiUserInvite
) => {
  return acceptedInvite
    ? InviteStatus.Accepted
    : currentInvite?.cancelled_at
    ? InviteStatus.Cancelled
    : InviteStatus.Invited
}

export const formatInviteUserPayload = (
  users: InviteUserPayload['users']
): InviteUserApiPayload => {
  return {
    employees: users.map((user) => {
      const { name, email, inviteDate, benefitPrograms, roleName, countryCode } = user
      return {
        name,
        email,
        country_code: countryCode,
        role_name: roleName,
        invite_date: inviteDate,
        benefit_programs: benefitPrograms.map((program) => {
          return {
            id: program.id,
            scheduled_start_date: program.scheduledStartDate
          }
        })
      }
    })
  }
}
