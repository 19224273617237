import { useErrorHandler } from 'react-error-boundary'
import { useCallback } from 'react'
import { RequestResponse } from 'Services/apiResponseTypes'

type Response = RequestResponse<unknown>

export const useErrorReporter = () => {
  const errorHandler = useErrorHandler()
  return useCallback(
    (message: string, response?: Response) => {
      if (response?.statusCode === 401) return

      const msg = `${message}${response?.statusCode ? ` (status_code ${response.statusCode})` : ''}`
      errorHandler(new Error(msg))
    },
    [errorHandler]
  )
}
