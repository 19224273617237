import { Merchant } from 'Utils/types/JOONAdmin/merchant'
import { getRequest } from 'Services/baseRequests/getRequest'
import { RequestResponse } from 'Services/apiResponseTypes'
import { postRequest } from 'Services/baseRequests/postRequest'
import { EmployerBenefitProgram, BenefitProgramMerchants } from 'Utils/types/benefitProgram'
import { MerchantFormValues } from 'Pages/JOONAdmin/EmployerDetailsPage/Views/BenefitPrograms/CreateBenefitProgramModal/MerchantForm'

export type GetMerchantsResponse = RequestResponse<GetMerchantsResponseData>
export type GetMerchantsResponseData = {
  merchants: Merchant[]
}
export const getMerchants = async (searchTerm?: string): Promise<GetMerchantsResponse> => {
  const response = await getRequest<GetMerchantsResponse>(
    `/admin/merchants?search_term=${searchTerm || ''}`
  )

  return {
    success: response?.success ?? false,
    message: response?.message ?? '',
    data: response?.data
      ? {
          merchants: response.data.merchants
        }
      : undefined
  }
}

export type GetBenefitProgramMerchantsResponse = RequestResponse<BenefitProgramMerchants>

export const getBenefitProgramMerchants = async (
  benefitProgramId: EmployerBenefitProgram['id']
): Promise<GetBenefitProgramMerchantsResponse> => {
  const response = await getRequest<GetBenefitProgramMerchantsResponse>(
    `/admin/benefit_programs/${benefitProgramId}/fetch_program_merchants`
  )

  return {
    success: response?.success ?? false,
    message: response?.message ?? '',
    data: response?.data
  }
}

export type AddMerchantToProgramPayload = {
  programId: EmployerBenefitProgram['id']
  action: string
} & MerchantFormValues

type AddMerchantToProgramApiPayload = {
  list_type: string
  merchant_id?: Merchant['id']
  merchant_name?: Merchant['name']
}

export const addMerchantToProgram = async ({
  programId,
  selectedMerchant,
  merchantName,
  action
}: AddMerchantToProgramPayload): Promise<RequestResponse> => {
  const payload = {
    merchant_id: selectedMerchant,
    merchant_name: merchantName,
    list_type: action
  }
  const response = await postRequest<RequestResponse, AddMerchantToProgramApiPayload>(
    `/admin/benefit_programs/${programId}/benefit_program_merchants`,
    payload
  )

  return {
    success: response?.success ?? false,
    message: response?.message ?? '',
    data: response?.data
  }
}

export type RemoveMerchantFromListPayload = {
  programId: EmployerBenefitProgram['id']
  merchantId: Merchant['id']
}

export const removeMerchantFromAllowList = async ({
  programId,
  merchantId
}: RemoveMerchantFromListPayload): Promise<RequestResponse> => {
  const response = await postRequest<RequestResponse>(
    `/admin/benefit_programs/${programId}/benefit_program_merchants/${merchantId}/remove_from_allow_list`
  )

  return {
    success: response?.success ?? false,
    message: response?.message ?? '',
    data: response?.data
  }
}

export const removeMerchantFromBlockList = async ({
  programId,
  merchantId
}: RemoveMerchantFromListPayload): Promise<RequestResponse> => {
  const response = await postRequest<RequestResponse>(
    `/admin/benefit_programs/${programId}/benefit_program_merchants/${merchantId}/remove_from_block_list`
  )

  return {
    success: response?.success ?? false,
    message: response?.message ?? '',
    data: response?.data
  }
}
