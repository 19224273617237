import { FC, useMemo } from 'react'

import { StyledButton } from 'Components/Buttons/StyledButton'
import { ProgramSelectionForm } from 'Components/Modal/ProgramSelectionForm'
import { ModalBody } from 'Components/Modal/ModalBody'

import { useEmployerProgramsContext } from 'Contexts/EmployerProgramsContext'

import { useRemoveUserFromProgramQuery } from 'Hooks/api/employer/userManagement/useRemoveUserFromProgramQuery'
import { useAddUserToProgramsQuery } from 'Hooks/api/employer/userManagement/useAddUserToProgramsQuery'
import { useUpdateUserProgram } from 'Hooks/useUpdateUserProgram'

import { ButtonContainer } from 'Utils/styles/spacing'
import { UserDetailsForEmployer } from 'Utils/types/user'

type ManageUserProgramFormProps = {
  user?: UserDetailsForEmployer
}

export const ManageUserProgramForm: FC<ManageUserProgramFormProps> = ({ user }) => {
  const {
    state: { programs }
  } = useEmployerProgramsContext()

  const removeUserFromProgramQuery = useRemoveUserFromProgramQuery()
  const addUserToProgramsQuery = useAddUserToProgramsQuery()

  const userPrograms = useMemo(() => {
    const futureMemberships =
      user?.futureMemberships?.map((m) => ({
        id: m.programId,
        membershipStartDate: m.membershipStartDate,
        membershipEndDate: m.membershipEndDate
      })) || []

    const currentMemberships =
      user?.programUtilizationDetails?.map((m) => ({
        id: m.programId,
        membershipStartDate: m.membershipStartDate,
        membershipEndDate: m.membershipEndDate
      })) || []
    return futureMemberships.concat(currentMemberships)
  }, [user])

  const {
    programChanges,
    filteredPrograms,
    toggleProgram,
    setProgramChangeDate,
    programStartDate,
    programMinStartDate,
    isSelected
  } = useUpdateUserProgram(programs, user?.countryCode, userPrograms)

  const addedPrograms = useMemo(
    () =>
      programChanges
        .filter((p) => p.enabled)
        .filter((p) => !userPrograms?.find((up) => up.id === p.id))
        .map((p) => ({ id: p.id, dateOfChange: p.dateOfChange })),
    [programChanges, userPrograms]
  )

  const removedPrograms = useMemo(
    () =>
      programChanges
        .filter((p) => !p.enabled)
        .filter((p) => userPrograms?.find((up) => up.id === p.id))
        .map((p) => ({ id: p.id, dateOfChange: p.dateOfChange })),
    [programChanges, userPrograms]
  )

  const removingFromAllPrograms = useMemo(
    () => addedPrograms.length === 0 && removedPrograms.length === programs.length,
    [addedPrograms.length, removedPrograms.length, programs.length]
  )

  const updateAssignedPrograms = async () => {
    if (!user) return

    if (addedPrograms.length)
      addUserToProgramsQuery.mutate({ userId: user.id, programs: addedPrograms })

    if (removedPrograms.length)
      removeUserFromProgramQuery.mutate({ userId: user.id, programs: removedPrograms })
  }

  return (
    <>
      <ModalBody>
        <ProgramSelectionForm
          employerPrograms={filteredPrograms}
          userPrograms={userPrograms}
          toggleProgram={toggleProgram}
          setProgramChangeDate={setProgramChangeDate}
          programStartDate={programStartDate}
          programMinStartDate={programMinStartDate}
          isSelected={isSelected}
          userName={user?.userInfo.firstName ?? 'this user'}
          removingFromAllPrograms={removingFromAllPrograms}
        />
      </ModalBody>

      <ButtonContainer>
        <StyledButton primary id="programChange.update" onClick={updateAssignedPrograms} />
      </ButtonContainer>
    </>
  )
}
