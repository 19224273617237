import { FC } from 'react'
import styled from 'styled-components'
import { Control, Controller } from 'react-hook-form'

import { StyledInput } from 'Components/Inputs/StyledInput'
import { StyledInputLabel } from 'Components/Inputs/StyledInputLabel'
import { CountryFlagIcon } from 'Components/Icons/CountryFlagIcon'
import { ControlledStyledSelect } from 'Components/Inputs/ControlledStyledSelect'
import { ControlledSwitch } from 'Components/Inputs/Switch/ControlledSwitch'
import { InputContainer } from 'Components/Inputs/ControlledSelect'

import { FlexColumn, FlexRow, spacingMD, spacingLG } from 'Utils/styles/spacing'
import { CountryCode, CountryNames } from 'Utils/types/countries'
import { ChargeFeeType, chargeFeeTypeOptions } from 'Utils/types/employerInfo'
import {
  nonNumberRegexNoNegative,
  singleDecimalRegex,
  doubleDecimalRegex
} from 'Utils/helpers/currency'

const CountryFieldContainer = styled(FlexColumn)`
  gap: ${spacingMD};
`

type CountryFormProps = {
  countryCode: CountryCode
  control: Control<any>
  index?: number
  chargeFeeType?: ChargeFeeType
}

export const CountryForm: FC<CountryFormProps> = ({
  control,
  countryCode,
  index,
  chargeFeeType
}) => {
  const hasIndex = index !== undefined

  return (
    <CountryFieldContainer>
      <CountryFlagIcon text={CountryNames[countryCode]} countryCode={countryCode} />
      <FlexRow gap={spacingLG}>
        <InputContainer>
          <StyledInputLabel>Processing Fee</StyledInputLabel>
          <Controller
            control={control}
            name={hasIndex ? `employerCountries.${index}.processingFee` : 'processingFee'}
            rules={{
              required: `Processing fee for ${CountryNames[countryCode]} is required.`
            }}
            render={({ field: { onChange, value } }) => (
              <StyledInput
                onChange={(e: { target: { value: string } }) => {
                  e.target.value = e.target.value
                    .replace(nonNumberRegexNoNegative, '')
                    // Prevent multiple decimal points from being used
                    .replace(singleDecimalRegex, '$1')
                    // Prevent more than 2 digits after the decimal
                    .replace(doubleDecimalRegex, '$1')
                  onChange(e)
                }}
                value={value}
              />
            )}
          />
        </InputContainer>
        <InputContainer>
          <ControlledStyledSelect
            label="Charge Fee Per"
            name={hasIndex ? `employerCountries.${index}.chargeFeePer` : 'chargeFeePer'}
            options={chargeFeeTypeOptions}
            control={control}
            defaultValue={chargeFeeType}
          />
        </InputContainer>
      </FlexRow>
      <FlexRow gap={spacingLG}>
        <InputContainer>
          <StyledInputLabel>Pricing Description</StyledInputLabel>
          <Controller
            control={control}
            name={hasIndex ? `employerCountries.${index}.pricingDescription` : 'pricingDescription'}
            rules={{
              required: `Pricing description for ${CountryNames[countryCode]} is required.`
            }}
            render={({ field: { onChange, value } }) => (
              <StyledInput onChange={onChange} value={value} />
            )}
          />
        </InputContainer>
        <ControlledSwitch
          label="Alternative Reimbursement"
          name={
            hasIndex
              ? `employerCountries.${index}.alternativeReimbursement`
              : 'alternativeReimbursement'
          }
          control={control}
        />
      </FlexRow>
    </CountryFieldContainer>
  )
}
