import { getRequest } from 'Services/baseRequests/getRequest'
import { postRequest } from 'Services/baseRequests/postRequest'
import { RequestResponse } from 'Services/apiResponseTypes'
import {
  GetEmployerDetailsApiResponse,
  GetEmployerDetailsResponse,
  GetEmployersApiResponse,
  GetEmployersResponse,
  GetActiveEmployersApiResponse,
  GetActiveEmployersResponse
} from 'Services/JOONAdmin/adminApiResponseTypes'
import { parseServerDate, formatDateForServer } from 'Utils/helpers/dateUtils'
import { ChargeFeeType, EmployerCountry } from 'Utils/types/employerInfo'
import { CountryCode } from 'Utils/types/countries'
import { Employer } from 'Utils/types/JOONAdmin/employer'
import { putRequest } from 'Services/baseRequests/putRequest'
import { deleteRequest } from 'Services/baseRequests/deleteRequest'

export type GetEmployersPayload = {
  perPage: number
  pageNumber: number
  searchText?: string
}
export const getEmployers = async ({
  searchText,
  perPage,
  pageNumber
}: GetEmployersPayload): Promise<GetEmployersResponse> => {
  const response = await getRequest<GetEmployersApiResponse>(
    `/employers?search_text=${
      searchText ?? ''
    }&invite_status=all&per_page=${perPage}&page=${pageNumber}`
  )

  return {
    success: response?.success ?? false,
    message: response?.message ?? '',
    data: response?.data
      ? {
          employers: response.data.employers.map((e) => ({
            ...e,
            createdAt: parseServerDate(e.createdAt)
          })),
          totalEmployers: response.total_records ?? 0
        }
      : undefined
  }
}

export const getEmployerDetails = async (
  employerId: number
): Promise<GetEmployerDetailsResponse> => {
  const response = await getRequest<GetEmployerDetailsApiResponse>(`/employers/${employerId}`)

  return {
    success: response?.success ?? false,
    message: response?.message ?? '',
    data: response?.data
      ? {
          employer: {
            ...response.data.employer,
            createdAt: parseServerDate(response.data.employer.createdAt)
          }
        }
      : undefined
  }
}

type CreateEmployerPayload = {
  name: string
  contactEmail: string
  employerCountries: Omit<EmployerCountry, 'id' | 'employerId' | 'autoPaymentMethodId'>[]
}

type CreateEmployerApiPayload = {
  name: string
  contact_email: string
  employer_countries_config: {
    country_code: CountryCode
    processing_fee: string
    charge_fee_per: ChargeFeeType
    pricing_description: string
    alternative_reimbursement: boolean
  }[]
}

export const createEmployer = async ({
  name,
  contactEmail,
  employerCountries
}: CreateEmployerPayload) => {
  const payload = {
    name,
    contact_email: contactEmail,
    employer_countries_config: employerCountries.map((ec) => ({
      country_code: ec.countryCode,
      processing_fee: ec.processingFee.toString(),
      charge_fee_per: ec.chargeFeePer,
      pricing_description: ec.pricingDescription || '',
      alternative_reimbursement: ec.alternativeReimbursement
    }))
  }

  const response = await postRequest<RequestResponse, CreateEmployerApiPayload>(
    '/admin/employers',
    payload
  )

  return {
    success: response?.success ?? false,
    message: response?.message ?? ''
  }
}

type CreateEmployerCountryPayload = Omit<
  EmployerCountry,
  'id' | 'employerId' | 'autoPaymentMethodId'
> & {
  employerId: Employer['id']
}

type CreateEmployerCountryApiPayload = {
  country_code: CountryCode
  processing_fee: string
  charge_fee_per: ChargeFeeType
  pricing_description: string
  alternative_reimbursement: boolean
}

export const createEmployerCountry = async ({
  employerId,
  countryCode,
  processingFee,
  chargeFeePer,
  pricingDescription,
  alternativeReimbursement
}: CreateEmployerCountryPayload) => {
  const payload = {
    country_code: countryCode,
    processing_fee: processingFee.toString(),
    charge_fee_per: chargeFeePer,
    pricing_description: pricingDescription || '',
    alternative_reimbursement: alternativeReimbursement
  }

  const response = await postRequest<RequestResponse, CreateEmployerCountryApiPayload>(
    `/admin/employers/${employerId}/create_country`,
    payload
  )

  return {
    success: response?.success ?? false,
    message: response?.message ?? ''
  }
}

type UpdateEmployerCountryPayload = Omit<
  CreateEmployerCountryPayload,
  'alternativeReimbursement'
> & {
  employerId: Employer['id']
}

type UpdateEmployerCountryApiPayload = {
  country_code: string
  processing_fee: string
  charge_fee_per: ChargeFeeType
  pricing_description: string
}

export const updateEmployercountry = async ({
  employerId,
  countryCode,
  processingFee,
  chargeFeePer,
  pricingDescription
}: UpdateEmployerCountryPayload) => {
  const payload = {
    country_code: countryCode,
    processing_fee: processingFee.toString(),
    charge_fee_per: chargeFeePer,
    pricing_description: pricingDescription || ''
  }

  const response = await putRequest<RequestResponse, UpdateEmployerCountryApiPayload>(
    `/admin/employers/${employerId}/update_country`,
    payload
  )

  return {
    success: response?.success ?? false,
    message: response?.message ?? ''
  }
}

type DeleteEmployerCountryPayload = {
  employerId: Employer['id']
  countryCode: CountryCode
}

type DeleteEmployerCountryApiPayload = {
  country_code: CountryCode
}

export const deleteEmployerCountry = async ({
  employerId,
  countryCode
}: DeleteEmployerCountryPayload) => {
  const response = await deleteRequest<RequestResponse, DeleteEmployerCountryApiPayload>(
    `/admin/employers/${employerId}/delete_country`,
    { country_code: countryCode }
  )

  return {
    success: response?.success ?? false,
    message: response?.message ?? ''
  }
}

export type TerminateEmployerPayload = {
  employerId: number
  terminationDate: Date
}

type TerminateEmployerApiPayload = {
  termination_date: string
}

export const terminateEmployer = async (
  payload: TerminateEmployerPayload
): Promise<RequestResponse> => {
  const response = await postRequest<RequestResponse, TerminateEmployerApiPayload>(
    `/admin/employers/${payload.employerId}/terminate`,
    {
      termination_date: formatDateForServer(payload.terminationDate)
    }
  )

  return {
    success: response?.success ?? false,
    message: response?.message ?? '',
    data: response?.data
  }
}

export const listActiveEmployers = async (
  showCountries: boolean
): Promise<GetActiveEmployersResponse> => {
  const response = await getRequest<GetActiveEmployersApiResponse>(
    `/admin/employers/list_active_employers?countries=${showCountries}`
  )

  return {
    success: response?.success ?? false,
    message: response?.message ?? '',
    data: response?.data
      ? {
          employerList: response.data.employerList.map(({ countries, ...rest }) => ({
            ...rest,
            countries: (countries || []).map(({ code }) => code)
          }))
        }
      : undefined
  }
}
