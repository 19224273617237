import { usePeoplePageContext } from 'Contexts/PeoplePageContext'
import { useEmployerProgramsContext } from 'Contexts/EmployerProgramsContext'

import { StyledButton } from 'Components/Buttons/StyledButton'
import { ProgramSelectionForm } from '../ProgramSelectionForm'

import { useAddUserToProgramsQuery } from 'Hooks/api/employer/userManagement/useAddUserToProgramsQuery'
import { useUpdateUserProgram } from 'Hooks/useUpdateUserProgram'

import { ButtonContainer } from 'Utils/styles/spacing'
import { UserDetailsForEmployer } from 'Utils/types/user'
import { useMemo, FC } from 'react'

import { ModalBody } from '../ModalBody'

type ReactivateUserFormProps = {
  user?: UserDetailsForEmployer
}
export const ReactivateUserForm: FC<ReactivateUserFormProps> = ({ user }) => {
  const {
    state: { actionableUserId }
  } = usePeoplePageContext()

  const {
    state: { programs }
  } = useEmployerProgramsContext()

  const addUserToProgramsQuery = useAddUserToProgramsQuery()

  const {
    programChanges,
    filteredPrograms,
    toggleProgram,
    setProgramChangeDate,
    programStartDate,
    programMinStartDate,
    isSelected
  } = useUpdateUserProgram(programs, user?.countryCode)

  const addedPrograms = useMemo(() => {
    return programChanges.filter((p) => p.enabled)
  }, [programChanges])

  const reactivateUser = async () => {
    const payload = addedPrograms.map((p) => ({
      id: p.id,
      dateOfChange: p.dateOfChange
    }))

    if (addedPrograms.length && actionableUserId) {
      addUserToProgramsQuery.mutate({
        userId: actionableUserId,
        programs: payload
      })
    }
  }

  return (
    <>
      <ModalBody>
        <ProgramSelectionForm
          employerPrograms={filteredPrograms}
          toggleProgram={toggleProgram}
          setProgramChangeDate={setProgramChangeDate}
          programStartDate={programStartDate}
          programMinStartDate={programMinStartDate}
          isSelected={isSelected}
        />
      </ModalBody>
      <ButtonContainer>
        <StyledButton
          primary
          id="permissionsChange.reactivate"
          onClick={reactivateUser}
          disabled={addedPrograms.length === 0}
        />
      </ButtonContainer>
    </>
  )
}
