import { useMutation, useQueryClient } from 'react-query'
import { QueryNames } from 'Hooks/api/queryNames'
import { toast } from 'react-toastify'
import { AppNotification } from 'Utils/types/appNotification'
import { updateBenefitProgram } from 'Services/JOONAdmin/benefitProgramCategoriesService'

export const useUpdateBenefitProgramQuery = () => {
  const queryClient = useQueryClient()

  return useMutation([QueryNames.UpdateBenefitProgram], updateBenefitProgram, {
    onSuccess: (response) => {
      if (response.success) {
        queryClient.invalidateQueries([QueryNames.EmployerBenefitPrograms])
        toast.success(AppNotification.UpdateBenefitProgramSuccess)
      } else {
        toast.error(`${AppNotification.UpdateBenefitProgramFailure} ${response.message}`)
      }
    },
    onError: () => {
      toast.error(AppNotification.UpdateBenefitProgramFailure)
    }
  })
}
