enum GeneralQueries {
  SsoLogin = 'ssoLogin',
  Login = 'login',
  Logout = 'logout',
  ForgotPassword = 'forgotPassword',
  ResetPassword = 'resetPassword',
  VerifyUserInvite = 'verifyUserInvite',
  RegisterUser = 'registerUser',
  ChangePassword = 'changePassword',
  UpdateUserDetails = 'updateUserDetails'
}

enum UserQueries {
  Dashboard = 'dashboard',
  GetUserPurchases = 'getUserPurchases',
  GetUserPendingOPRPurchases = 'GetUserPendingOPRPurchases',
  GetUserOngoingPurchases = 'getUserOngoingPurchases',
  ToggleUserNotificationSettings = 'setUserEmailSettings',
  GetUserReimbursements = 'getUserReimbursements',
  AddPurchase = 'addPurchase',
  RequestPurchaseEligibility = 'requestPurchaseEligibility',
  InvalidatePurchase = 'invalidatePurchase',
  GetDepositAccount = 'getDepositAccount',
  AddDepositAccount = 'addDepositAccount',
  GetPurchaseAccounts = 'getPurchaseAccounts',
  GeneratePlaidToken = 'generatePlaidToken',
  CreatePurchaseAccountAuthLink = 'createPurchaseAccountAuthLink',
  ExtendConnection = 'extendConnection',
  AddPurchaseAccount = 'addPurchaseAccount',
  RemovePurchaseAccount = 'removePurchaseAccount',
  RemovePurchaseAccountConnection = 'removePurchaseAccountConnection',
  RelinkPurchaseAccount = 'relinkPurchaseAccount',
  UpdatePlaidLinkToken = 'updatePlaidLinkToken',
  AddPlaidError = 'addPlaidError',
  GetConnectAccountLinkUrl = 'getConnectAccountLinkUrl'
}

enum EmployerQueries {
  EmployerDashboard = 'employerDashboard',
  GetInvoices = 'getInvoices',
  UsersForEmployer = 'usersForEmployer',
  InviteUser = 'inviteUser',
  BulkInvite = 'bulkInvite',
  BulkUpdate = 'bulkUpdate',
  CancelUserInvite = 'cancelUserInvite',
  RemoveUser = 'removeUser',
  ReinviteUser = 'reinviteUser',
  GrantUserAdminPermissions = 'grantUserAdminPermissions',
  RevokeUserAdminPermissions = 'revokeUserAdminPermissions',
  AddUserToPrograms = 'addUserToPrograms',
  RemoveUserFromProgram = 'removeUserFromProgram',
  GetPaymentMethods = 'getPaymentMethods',
  ToggleAutopay = 'toggleAutopay',
  SubmitInvoicePayment = 'submitInvoicePayment',
  RemovePaymentMethod = 'removePaymentMethod',
  DownloadInvoice = 'downloadInvoice',
  GetAppVersion = 'getAppVersion',
  GetStripeSetupIntentToken = 'getStripeSetupIntentToken',
  EmployerBenefitPrograms = 'getEmployerBenefitPrograms',
  BenefitProgramUtilization = 'getBenefitProgramUtilization'
}

enum JOONAdminQueries {
  GetEmployers = 'getEmployers',
  GetEmployerDetails = 'getEmployerDetails',
  GetAdminInvoices = 'getAdminInvoices',
  AddInvoice = 'addInvoice',
  DeleteInvoice = 'deleteInvoice',
  FinalizeInvoice = 'finalizeInvoice',
  RevertInvoiceToDraft = 'revertInvoiceToDraft',
  RunInvoiceAutopay = 'runInvoiceAutopay',
  AssociateStripeInvoice = 'associateStripeInvoice',
  GetInvoiceDetails = 'getInvoiceDetails',
  ResetLogin = 'resetLogin',
  DownloadBenefitRecipientsOverviewReport = 'downloadBenefitRecipientsOverviewReport',
  DownloadPurchasesReport = 'downloadPurchasesReport',
  GetReportedPurchases = 'getReportedPurchases',
  UpdateReportedPurchase = 'updateReportedPurchase',
  DownloadPurchaseReceipt = 'downloadPurchaseReceipt',
  DeleteUserStripeCustomer = 'deleteUserStripeCustomer',
  UpdateUserCountry = 'updateUserCountry',
  GetUserAllowances = 'getUserAllowances',
  AddUserAllowance = 'addUserAllowance',
  GetEligibleSpend = 'getEligibleSpend',
  CreateBenefitProgram = 'createBenefitProgram',
  UpdateBenefitProgram = 'updateBenefitProgram',
  GetBenefitProgramCategories = 'getBenefitProgramCategories',
  AddProgramToMerchant = 'addProgramToMerchant',
  RemoveMerchantFromAllowList = 'removeMerchantFromAllowList',
  RemoveMerchantFromBlockList = 'removeMerchantFromBlockList',
  GetMerchants = 'getMerchants',
  ResetRenewDate = 'resetRenewDate',
  TerminateEmployer = 'terminateEmployer',
  BenefitProgramReceiptsDownload = 'benefitProgramReceiptsDownload',
  PayWithPrepaidInvoice = 'payWithPrepaidInvoice',
  UpdateBenefitProgramPolicy = 'updateBenefitProgramPolicy',
  EndBenefitProgramPolicy = 'endBenefitProgramPolicy',
  GetBenefitProgramMerchants = 'getBenefitProgramMerchants',
  GetActiveEmployersList = 'getActiveEmployersList',
  CreateEmployer = 'createEmployer',
  CreateEmployerCountry = 'createEmployerCountry',
  UpdateEmployerCountry = 'updateEmployerCountry',
  DeleteEmployerCountry = 'deleteEmployerCountry'
}

enum SSOQueries {
  AddDomain = 'addDomain',
  DeleteDomain = 'deleteDomain',
  ToggleSSOForDomain = 'toggleSSOForDomain',
  CreateOrg = 'createOrg',
  LoginOptions = 'loginOptions'
}

export const QueryNames = {
  ...GeneralQueries,
  ...UserQueries,
  ...EmployerQueries,
  ...JOONAdminQueries,
  ...SSOQueries
}
export type QueryNames = typeof QueryNames
