import { useMemo } from 'react'

import { Modal } from '../Modal'
import { ModalHeader } from 'Components/Modal/ModalHeader'
import { ManageUserPermissionsForm } from 'Components/Modal/ManageUserPermissionsModal/ManageUserPermissionsForm'

import { ModalType, usePeoplePageContext } from 'Contexts/PeoplePageContext'
import { useEmployerUsersContext } from 'Contexts/EmployerUsersContext'

import { MediumBoldText, ThinDarkGreenText } from 'Utils/styles/text'
import { FlexColumn } from 'Utils/styles/spacing'

export const ManageUserPermissionsModal = () => {
  const {
    state: { modal, actionableUserId },
    setModal
  } = usePeoplePageContext()

  const {
    state: { users }
  } = useEmployerUsersContext()

  const user = useMemo(() => {
    return users.find((user) => user.id == actionableUserId)
  }, [users, actionableUserId])

  const setShowModal = (show: boolean) =>
    setModal(show ? ModalType.ManageUserPermissions : undefined)

  return (
    <Modal showModal={modal === ModalType.ManageUserPermissions} setShowModal={setShowModal}>
      <ModalHeader>Manage permissions</ModalHeader>

      <FlexColumn>
        <MediumBoldText>
          {user?.userInfo.firstName} {user?.userInfo.lastName}
        </MediumBoldText>
        <ThinDarkGreenText>{user?.userInfo.email}</ThinDarkGreenText>
      </FlexColumn>

      <ManageUserPermissionsForm user={user} />
    </Modal>
  )
}
