import { CSSProperties, FC } from 'react'
import * as HoverCardPrimitive from '@radix-ui/react-hover-card'
import styled from 'styled-components'
import { black, white } from 'Utils/styles/colors'
import { fontSizeXS, fontWeightThin } from 'Utils/styles/text'
import { spacingSM } from 'Utils/styles/spacing'
import { Layer3 } from 'Utils/styles/zIndex'

const StyledContent = styled(HoverCardPrimitive.Content)`
  border-radius: 6px;
  padding: 20px;
  background-color: ${black};
  font-size: ${fontSizeXS};
  font-weight: ${fontWeightThin};
  color: ${white};
  ${Layer3};
`

const StyledArrow = styled(HoverCardPrimitive.Arrow)`
  fill: ${black};
`

const Icon = styled.img`
  height: 15px;
  width: 15px;
`

export const ContentLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacingSM};
`

type Align = 'end' | 'start' | 'center' | undefined
type ContentProps = {
  children: JSX.Element
  align?: Align
}
const Content = ({ align = 'start', children, ...props }: ContentProps) => (
  <HoverCardPrimitive.Portal>
    <StyledContent align={align} {...props}>
      {children}
      <StyledArrow />
    </StyledContent>
  </HoverCardPrimitive.Portal>
)

// Exports
export const HoverCard = HoverCardPrimitive.Root
export const HoverCardTrigger = HoverCardPrimitive.Trigger
export const HoverCardContent = Content

const ImageTrigger = styled.a<{ cursor: 'default' | 'pointer' }>`
  cursor: ${({ cursor }) => cursor};
  margin-top: 3px;
`

type HoverIconProps = {
  children: JSX.Element
  altText: string
  iconSrc: string
  iconStyles?: CSSProperties
  cursor?: 'default' | 'pointer'
  align?: Align
}
export const HoverIconCard: FC<HoverIconProps> = ({
  children,
  iconSrc,
  altText,
  align,
  iconStyles,
  cursor = 'pointer'
}) => (
  <HoverCard openDelay={200}>
    <HoverCardTrigger asChild>
      <ImageTrigger style={iconStyles} cursor={cursor}>
        <Icon src={iconSrc} alt={altText} />
      </ImageTrigger>
    </HoverCardTrigger>
    <HoverCardContent align={align}>
      <ContentLayout>{children}</ContentLayout>
    </HoverCardContent>
  </HoverCard>
)
