import React, { lazy, Suspense, useEffect } from 'react'
import styled from 'styled-components'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

import { LocalStorageKeys, useLocalStorage } from 'Hooks/useLocalStorage'
import { useGetAppVersionQuery } from 'Hooks/useGetAppVersionQuery'
import { useIsLoggedIn } from 'Hooks/useIsLoggedIn'

import { UserInfoActionTypes, useUserInfoContext } from 'Contexts/UserInfoContext'
import { EmployerInfoActionTypes, useEmployerInfoContext } from 'Contexts/EmployerInfoContext'

import { PageNames, PageRoutes } from 'Utils/types/pageRoutes'
import { RegistrationInfo } from 'Utils/types/registrationInfo'
import { UserRole } from 'Utils/types/roles'

import BasicErrorBoundary from 'Components/ErrorBoundaries/BasicErrorBoundary'

import { initializeLogging, setLogContextUser } from 'Services/logging'

import EmployerDetailsPage from 'Pages/JOONAdmin/EmployerDetailsPage/EmployerDetailsPage'
const LoginPage = lazy(() => import('Pages/User/LoginPage/LoginPage'))
const SsoRedirect = lazy(() => import('Pages/User/SsoRedirect/SsoRedirect'))
const ForgotPasswordPage = lazy(() => import('Pages/User/ForgotPassword/ForgotPasswordPage'))
const ResetPasswordPage = lazy(() => import('Pages/User/ResetPassword/ResetPasswordPage'))
const UserActivateBenefitsPage = lazy(
  () => import('Pages/User/ActivateBenefitsPage/UserActivateBenefitsPage')
)
const UserDashboard = lazy(() => import('Pages/User/UserDashboard/UserDashboard'))
const UserPurchasesPage = lazy(() => import('Pages/User/UserPurchases/UserPurchasesPage'))
const ReimbursementsPage = lazy(() => import('Pages/User/Reimbursements/ReimbursementsPage'))
const SettingsPage = lazy(() => import('Pages/User/Settings/SettingsPage'))
const EmployerDashboard = lazy(() => import('Pages/Employer/EmployerDashboard/EmployerDashboard'))
const VerifyInvite = lazy(() => import('Pages/User/ActivateBenefitsPage/VerifyInvite'))
const Logout = lazy(() => import('Pages/User/Logout'))
const PeoplePage = lazy(() => import('Pages/Employer/PeoplePage/PeoplePage'))
const BillingPage = lazy(() => import('Pages/Employer/BillingPage/BillingPage'))
const EmployersListPage = lazy(() => import('Pages/JOONAdmin/EmployersListPage/EmployersListPage'))
const ReportedPurchasesPage = lazy(
  () => import('Pages/JOONAdmin/ReportedPurchasesPage/ReportedPurchasesPage')
)
const InvoicesListPage = lazy(() => import('Pages/JOONAdmin/InvoicesListPage/InvoicesListPage'))
const InvoiceDetailsPage = lazy(
  () => import('Pages/JOONAdmin/InvoiceDetailsPage/InvoiceDetailsPage')
)

initializeLogging()

const CatchNotFoundRoute = () => {
  const { pathname } = useLocation()
  useEffect(() => {
    console.error(`Route not found: ${pathname}`)
  }, [pathname])
  // both /login and /dashboard would work, because they redirect to the other one if necessary,
  // but /dashboard->/login would trigger an unnecessary warning message for logged-out users
  return <Navigate replace to={PageRoutes.Login} />
}

export const AppContainer = styled.div`
  min-height: 100vh;
`
const App = () => {
  const [employerId] = useLocalStorage<number | undefined>(LocalStorageKeys.EmployerId, undefined)
  const [roles] = useLocalStorage<UserRole[]>(LocalStorageKeys.Roles, [])
  const [registrationInfo] = useLocalStorage<RegistrationInfo | undefined>(
    LocalStorageKeys.RegistrationInfo,
    undefined
  )

  const location = useLocation()
  const { isLoggedIn, userId } = useIsLoggedIn()

  const userInfoContext = useUserInfoContext()
  const userInfoDispatch = userInfoContext.dispatch
  const employerInfoContext = useEmployerInfoContext()
  const employerInfoDispatch = employerInfoContext.dispatch

  // Set up our app to make API calls in general
  useEffect(() => {
    if (isLoggedIn && userId) {
      // Necessary to make initial API calls for user
      userInfoDispatch({ type: UserInfoActionTypes.SetUserId, value: userId })
      userInfoDispatch({ type: UserInfoActionTypes.SetUserRoles, value: roles })
      setLogContextUser({ id: userId, employerId })
    } else if (registrationInfo) {
      userInfoDispatch({ type: UserInfoActionTypes.SetRegistrationInfo, value: registrationInfo })
    }

    if (
      isLoggedIn &&
      employerId !== undefined &&
      !location.pathname.includes(PageRoutes.AdminEmployerDetails)
    ) {
      // Necessary to make initial API calls for employer
      employerInfoDispatch({
        type: EmployerInfoActionTypes.SetEmployerId,
        value: employerId
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // Scroll to top of page when switching pages
    const pageName = location.pathname as PageRoutes
    document.title = PageNames[pageName] ?? 'JOON'
    window.scrollTo(0, 0)
  }, [location])

  useGetAppVersionQuery()

  return (
    <AppContainer>
      <Suspense>
        <BasicErrorBoundary>
          <Routes>
            <Route path={'/'} element={<LoginPage />} />
            <Route path={PageRoutes.Login} element={<LoginPage />} />
            <Route path={PageRoutes.ForgotPassword} element={<ForgotPasswordPage />} />
            <Route path={PageRoutes.ResetPassword} element={<ResetPasswordPage />} />
            <Route path={PageRoutes.MicrosoftCallbackLogin} element={<LoginPage />} />
            <Route path={PageRoutes.SsoRedirect} element={<SsoRedirect />} />
            <Route path={PageRoutes.Logout} element={<Logout />} />
            <Route path={PageRoutes.ForcedLogout} element={<Logout forcedLogout />} />
            <Route path={PageRoutes.OldActivate} element={<VerifyInvite />} />
            <Route path={PageRoutes.OldActivateAdmin} element={<VerifyInvite />} />
            <Route
              path={PageRoutes.UserActivate}
              element={<UserActivateBenefitsPage includeEmployerSection={false} />}
            />
            <Route path={PageRoutes.UserDashboard} element={<UserDashboard />} />
            <Route path={PageRoutes.UserPurchases} element={<UserPurchasesPage />} />
            <Route path={PageRoutes.UserReimbursements} element={<ReimbursementsPage />} />
            <Route path={PageRoutes.UserSettings} element={<SettingsPage />} />

            {/* Employer pages */}
            <Route
              path={PageRoutes.EmployerActivate}
              element={<UserActivateBenefitsPage includeEmployerSection={true} />}
            />
            <Route path={PageRoutes.EmployerDashboard} element={<EmployerDashboard />} />
            <Route path={PageRoutes.EmployerPeople} element={<PeoplePage />} />
            <Route path={PageRoutes.EmployerBilling} element={<BillingPage />} />

            {/* JOON Admin pages */}
            <Route path={PageRoutes.AdminEmployerDetails}>
              <Route path=":employerId" element={<EmployerDetailsPage />} />
            </Route>
            <Route path={PageRoutes.AdminEmployersList} element={<EmployersListPage />} />
            <Route path={PageRoutes.AdminReportedPurchases} element={<ReportedPurchasesPage />} />
            <Route path={PageRoutes.AdminInvoicesList} element={<InvoicesListPage />} />
            <Route path={PageRoutes.AdminInvoiceDetails}>
              <Route path=":invoiceId" element={<InvoiceDetailsPage />} />
            </Route>

            <Route path="*" element={<CatchNotFoundRoute />} />
          </Routes>
        </BasicErrorBoundary>
      </Suspense>
    </AppContainer>
  )
}

export default App
