import { useMutation } from 'react-query'
import { toast } from 'react-toastify'

import { downloadBenefitRecipientsOverviewReport } from 'Services/JOONAdmin/joonAdminReportsService'

import { QueryNames } from 'Hooks/api/queryNames'

import { AppNotification } from 'Utils/types/appNotification'
import { Employer } from 'Utils/types/JOONAdmin/employer'

export const useDownloadBenefitRecipientsOverviewReport = () =>
  useMutation(
    [QueryNames.DownloadBenefitRecipientsOverviewReport],
    async ({
      employerId,
      day,
      month,
      year
    }: {
      employerId: Employer['id']
      day: number
      month: number
      year: number
    }) => {
      const response = await downloadBenefitRecipientsOverviewReport(employerId, day, month, year)
      if (response.success) {
        toast.success(AppNotification.ReportDownloadSuccess)
      } else {
        toast.error(response.message || AppNotification.ReportDownloadFailure)
      }
    },
    {
      onError: () => {
        toast.error(AppNotification.ReportDownloadSuccess)
        return undefined
      }
    }
  )
