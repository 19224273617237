import { useMutation, useQueryClient } from 'react-query'
import { QueryNames } from 'Hooks/api/queryNames'
import { toast } from 'react-toastify'
import { AppNotification } from 'Utils/types/appNotification'
import { addDomain } from 'Services/JOONAdmin/sso/ssoService'
import { Employer } from 'Utils/types/JOONAdmin/employer'

export const useAddDomain = (employerId: Employer['id']) => {
  const queryClient = useQueryClient()

  return useMutation([QueryNames.AddDomain], addDomain, {
    onSuccess: (response) => {
      if (response.success) {
        queryClient.invalidateQueries([QueryNames.GetEmployerDetails, employerId])
        toast.success(AppNotification.GenericSuccess)
      } else {
        toast.error(`${AppNotification.GenericFailure} ${response.message}`)
      }
    },
    onError: () => {
      toast.error(AppNotification.GenericFailure)
    }
  })
}
