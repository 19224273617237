import { CSSProperties, FC } from 'react'
import styled from 'styled-components'

import CreditCardIcon from 'Assets/credit-card.svg'
import AmexLogo from 'Assets/majorCards/amex_logo.svg'
import DiscoverLogo from 'Assets/majorCards/discover_logo.svg'
import VisaLogo from 'Assets/majorCards/visa_logo.svg'
import MastercardLogo from 'Assets/majorCards/mastercard_logo.svg'
import BankIcon from 'Assets/bank.svg'

import { PaymentMethodType, MajorCreditCardBrand } from 'Utils/types/paymentMethod'
import { fontSizeHeader } from 'Utils/styles/text'

type PaymentMethodIconProps = {
  paymentMethodType: PaymentMethodType
  paymentMethodName: MajorCreditCardBrand | string
  style?: CSSProperties
}

const Icon = styled.img`
  width: ${fontSizeHeader};
  height: ${fontSizeHeader};
`

const getCreditCardLogo = (cardType: MajorCreditCardBrand | string): string => {
  switch (cardType) {
    case MajorCreditCardBrand.Amex:
      return AmexLogo
    case MajorCreditCardBrand.Mastercard:
      return MastercardLogo
    case MajorCreditCardBrand.Discover:
      return DiscoverLogo
    case MajorCreditCardBrand.Visa:
      return VisaLogo
    default:
      return CreditCardIcon
  }
}

export const PaymentMethodIcon: FC<PaymentMethodIconProps> = ({
  paymentMethodType,
  paymentMethodName,
  style
}) => {
  return (
    <>
      {paymentMethodType === PaymentMethodType.Card ? (
        <Icon src={getCreditCardLogo(paymentMethodName)} style={style} />
      ) : (
        <Icon src={BankIcon} style={style} />
      )}
    </>
  )
}
