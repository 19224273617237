import { useMutation } from 'react-query'
import { useErrorReporter } from 'Hooks/useErrorReporter'
import { QueryNames } from 'Hooks/api/queryNames'
import { reinviteUser } from 'Services/users/userService'
import { toast } from 'react-toastify'
import { AppNotification } from 'Utils/types/appNotification'

export const useReinviteUserQuery = () => {
  const reportError = useErrorReporter()

  return useMutation([QueryNames.ReinviteUser], reinviteUser, {
    onSuccess: (response) => {
      if (response.success) {
        // TODO: Do something when success... refresh invite date of user in context?
        toast.success(AppNotification.UserInviteSuccess)
      }
    },
    onError: (err: string) => {
      reportError(`useReinviteUserQuery error: ${err}`)
    }
  })
}
