import { useMemo } from 'react'
import { useUserProgramContext } from 'Contexts/UserProgramContext'
import { useGetOngoingPurchasesQuery } from 'Hooks/api/user/purchases/useGetOngoingPurchasesQuery'
import { useUserInfoContext } from 'Contexts/UserInfoContext'
import { useGetPendingOPRPurchasesQuery } from 'Hooks/api/user/purchases/useGetPendingOPRPurchasesQuery'

export const useProgramOngoingPurchaseInfo = (programId?: string, amount?: number) => {
  const {
    state: { programs }
  } = useUserProgramContext()

  const {
    state: { userId }
  } = useUserInfoContext()

  const { data } = useGetOngoingPurchasesQuery(userId)
  const { data: pendingOprData, isFetching } = useGetPendingOPRPurchasesQuery(1, 1, {}, userId)

  const selectedProgram =
    programs.find((program) => program.id.toString() === programId) ?? undefined

  const ongoingPurchases = selectedProgram?.membership.ongoingPurchases ?? false

  const hasPendingOngoingPurchase = useMemo(() => {
    return pendingOprData?.purchasesCount !== 0 && isFetching === false
  }, [pendingOprData, isFetching])

  const pendingOngoingPurchase = useMemo(() => {
    return !isFetching && pendingOprData?.data?.purchases?.length
      ? pendingOprData.data.purchases[0]
      : undefined
  }, [pendingOprData, isFetching])

  if (!selectedProgram) {
    return {
      ongoingPurchases: false,
      ongoingPurchaseThreshold: 0,
      hasPendingOngoingPurchase,
      pendingOngoingPurchase,
      purchaseAmountRequired: 0,
      amountMeetsThreshold: false,
      activeOngoingPurchaseForProgram: false
    }
  }

  const purchaseAmountRequired =
    selectedProgram.currentPeriodAllowance * selectedProgram.membership.ongoingPurchaseThreshold

  const amountMeetsThreshold = amount ? amount >= purchaseAmountRequired : false

  const activeOngoingPurchaseForProgram = !!data?.ongoingPurchases.find(
    (p) => p.programName === selectedProgram.name && p.calculatedRemainingAmount > 0
  )

  return {
    ongoingPurchases,
    ongoingPurchaseThreshold: selectedProgram?.membership.ongoingPurchaseThreshold ?? 0,
    purchaseAmountRequired,
    hasPendingOngoingPurchase,
    pendingOngoingPurchase,
    amountMeetsThreshold,
    activeOngoingPurchaseForProgram
  }
}
