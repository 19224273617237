import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import axios from 'axios'

import App from './App'

// Env
import { REACT_APP_SERVER_URL } from 'Services/config'
// Style related
import './index.css'
import './fonts/BasisGrotesquePro-Regular.ttf'
import { fontSizeXS } from 'Utils/styles/text'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import 'react-toastify/dist/ReactToastify.css'
// Global components
import { AddPurchaseModal } from 'Components/Modal/AddPurchaseModal/AddPurchaseModal'
import { StyledToastContainer } from 'Components/Toasts/StyledToastContainer'
import BasicErrorBoundary from 'Components/ErrorBoundaries/BasicErrorBoundary'
// i18n
import { IntlProvider } from 'react-intl'
import English from './lang/en.json'
// For getting business days on invoice timeline
import moment from 'moment-business-days'
// Context providers
import { UserProgramProvider } from 'Contexts/UserProgramContext'
import { UserInfoProvider } from 'Contexts/UserInfoContext'
import { UserPurchasesProvider } from 'Contexts/UserPurchasesContext'
import { UserReimbursementsProvider } from 'Contexts/UserReimbursementsContext'
import { ModalProvider } from 'Contexts/ModalContext'
import { EmployerUsersProvider } from 'Contexts/EmployerUsersContext'
import { EmployerInfoProvider } from 'Contexts/EmployerInfoContext'
import { EmployerProgramsProvider } from 'Contexts/EmployerProgramsContext'
import { JoonAdminProvider } from 'Contexts/JOONAdmin/JOONAdminContext'
import DateSelectionModalProvider from 'Hooks/modals/useDateSelectionModal'
import ConfirmationModalProvider from 'Hooks/modals/useConfirmationModal'
import SelectModalProvider from 'Hooks/modals/useSelectModal'
// API
import { addAxiosInterceptors } from 'Services/interceptors/addAxiosInterceptors'
import { ContextProvider, ContextProviderProps } from 'Contexts/ContextProvider'

moment.updateLocale('us', {
  workingWeekdays: [1, 2, 3, 4, 5],
  nextBusinessDayLimit: 31
})

// import Slovak from './lang/sk.json'
const locale = navigator.language

// i18n config
// const localeMap: Record<string, any> = {
//   'en': English,
//   'sk': Slovak
// }
//
// const lang = localeMap[locale]

// react-query client used to interact with our API
const TEN_MINUTES = 10 * 60 * 1000
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: TEN_MINUTES,
      refetchOnWindowFocus: false
    }
  }
})

// Add our fonts for MUI
const rootElement = document.getElementById('root')
const root = createRoot(rootElement as Element)
const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'BasisGrotesqueProRegular, sans-serif',
      textTransform: 'none',
      fontSize: fontSizeXS
    }
  }
})

// Initialize API
const baseUrl = () => REACT_APP_SERVER_URL
export const api = axios.create({
  baseURL: baseUrl(),
  headers: {
    'User-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone
  },
  withCredentials: true
})

addAxiosInterceptors(api)

// App contexts
const providers = [
  ModalProvider,
  EmployerInfoProvider,
  EmployerProgramsProvider,
  EmployerUsersProvider,
  UserInfoProvider,
  UserReimbursementsProvider,
  UserPurchasesProvider,
  UserProgramProvider,
  ConfirmationModalProvider,
  DateSelectionModalProvider,
  SelectModalProvider,
  JoonAdminProvider,
  ({ children }: ContextProviderProps) => QueryClientProvider({ client: queryClient, children }),
  ({ children }: ContextProviderProps) => ThemeProvider({ theme, children })
]

root.render(
  <StrictMode>
    <BrowserRouter>
      <IntlProvider locale={locale} messages={English}>
        <ContextProvider components={providers}>
          <BasicErrorBoundary>
            <App />
            <AddPurchaseModal />
            <StyledToastContainer />
          </BasicErrorBoundary>
        </ContextProvider>
      </IntlProvider>
    </BrowserRouter>
  </StrictMode>
)
