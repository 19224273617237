import { createContext, useContext, useReducer } from 'react'
import { Reimbursement } from 'Utils/types/reimbursement'
import { Purchase } from 'Utils/types/purchase'
import { ContextProviderProps } from 'Contexts/ContextProvider'

export enum UserReimbursementsActionTypes {
  SetReimbursements = 'SET_REIMBURSEMENTS',
  SetLoading = 'SET_LOADING',
  ResetState = 'RESET_STATE'
}
type Action =
  | { type: UserReimbursementsActionTypes.SetReimbursements; value: Reimbursement[] }
  | { type: UserReimbursementsActionTypes.SetLoading; value: boolean }
  | { type: UserReimbursementsActionTypes.ResetState }
type Dispatch = (action: Action) => void
type State = {
  reimbursements: Reimbursement[]
  purchasesByReimbursementId: Record<number, Purchase[]>
  isLoadingReimbursements: boolean
  latestReimbursement?: Reimbursement
}

const DEFAULT_STATE = {
  reimbursements: [],
  latestReimbursement: undefined,
  purchasesByReimbursementId: {},
  isLoadingReimbursements: false
}

type ContextType = { state: State; dispatch: Dispatch } | undefined
const UserReimbursementsContext = createContext<ContextType>(undefined)

const userReimbursementsReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case UserReimbursementsActionTypes.SetReimbursements: {
      const latestReimbursement = action.value.length ? action.value[0] : undefined
      return { ...state, reimbursements: action.value, latestReimbursement: latestReimbursement }
    }
    case UserReimbursementsActionTypes.SetLoading: {
      return { ...state, isLoadingReimbursements: action.value }
    }
    case UserReimbursementsActionTypes.ResetState: {
      return DEFAULT_STATE
    }
    default: {
      throw new Error(`Invalid action type`)
    }
  }
}

const UserReimbursementsProvider = ({ children }: ContextProviderProps) => {
  const [state, dispatch] = useReducer(userReimbursementsReducer, DEFAULT_STATE)
  const value = { state, dispatch }
  return (
    <UserReimbursementsContext.Provider value={value}>
      {children}
    </UserReimbursementsContext.Provider>
  )
}

const useUserReimbursementsContext = () => {
  const context = useContext(UserReimbursementsContext)
  if (context === undefined) {
    throw new Error('useUserReimbursements must be used within an UserReimbursementsProvider')
  }
  return context
}

export { UserReimbursementsProvider, useUserReimbursementsContext }
