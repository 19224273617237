import { useMemo } from 'react'
import styled from 'styled-components'

import { Chip } from 'Components/Chip/Chip'
import { ShowMoreChips } from 'Components/Chip/ShowMoreChips'
import { BenefitProgram } from 'Utils/types/benefitProgram'
import { spacingXXS } from 'Utils/styles/spacing'
import { sortBy, prop } from 'ramda'

const ChipsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacingXXS};
  flex-wrap: wrap;
`
type CategoryChipsProps = {
  program: BenefitProgram
  handleToggle?: () => void
  maxChipsShown?: number
  showAll?: boolean
}
export const CategoryChips = ({
  program,
  maxChipsShown = 1,
  showAll = false,
  handleToggle
}: CategoryChipsProps) => {
  const extraCategoryCount = program.categories.length - maxChipsShown
  const showMoreCategoryButton = !showAll && extraCategoryCount > 0

  const categories = useMemo(() => {
    const categories = sortBy(prop('name'), program.categories) ?? []
    return showMoreCategoryButton ? categories.slice(0, maxChipsShown) : categories
  }, [program, showMoreCategoryButton, maxChipsShown])

  return (
    <ChipsContainer>
      {categories.map((category) => {
        return <Chip key={category.id} text={category.name} />
      })}
      {
        // Button opens modal showing more benefit program info
        showMoreCategoryButton && handleToggle && (
          <ShowMoreChips handleToggle={handleToggle} numExtraChips={extraCategoryCount} />
        )
      }
    </ChipsContainer>
  )
}
