import { FC } from 'react'
import { Control, Controller, RegisterOptions } from 'react-hook-form'
import { StyledInputLabel } from 'Components/Inputs/StyledInputLabel'
import { StyledSelect } from 'Components/Inputs/StyledSelect'
import { InputContainer } from './ControlledSelect'

export type ControlledStyledSelectProps = {
  label: string
  name: string
  options: any[]
  control: Control<any>
  errorPresent?: boolean
  rules?: RegisterOptions
  disabled?: boolean
  defaultValue?: any
  placeholder?: string
}

export const ControlledStyledSelect: FC<ControlledStyledSelectProps> = ({
  label,
  name,
  options,
  control,
  errorPresent,
  disabled,
  defaultValue,
  rules,
  placeholder = 'Select an option...'
}) => (
  <Controller
    rules={rules}
    control={control}
    name={name}
    render={({ field: { onChange } }) => (
      <InputContainer id={`scroll_${name}`}>
        <StyledInputLabel>{label}</StyledInputLabel>
        <StyledSelect
          errorPresent={errorPresent ?? false}
          options={options}
          handleChange={onChange}
          defaultValue={defaultValue}
          placeholderText={placeholder}
          disabled={disabled}
        />
      </InputContainer>
    )}
  />
)
