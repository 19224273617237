import { FC } from 'react'

import { ActionLabel, ActionType, PeopleAction } from 'Components/Label/ActionLabel'
import {
  AccordionActionItem,
  AccordionContent,
  AccordionHeader,
  AccordionItem,
  AccordionRoot,
  AccordionTrigger
} from 'Components/AccordionMenu/AccordionMenu'

import { UserStatus } from 'Utils/types/userStatus'
import { UserRole } from 'Utils/types/roles'

import { usePeopleActions } from 'Hooks/usePeopleActions'
import { useCountries } from 'Hooks/useCountries'
import { isEmployerAdminOnly } from 'Utils/helpers/user'

type PeopleAdminActionsProps = {
  userId: number
  userStatus: UserStatus
  userRoles?: UserRole[]
  hasStripeAccount: boolean
  hasPurchaseAccount: boolean
  userLocked: boolean
}
export const PeopleAdminActions: FC<PeopleAdminActionsProps> = ({
  userId,
  userRoles,
  userStatus,
  hasStripeAccount,
  hasPurchaseAccount,
  userLocked
}) => {
  const { hasMultipleCountries } = useCountries()

  const {
    isUserInvited,
    isUserEnabled,
    onUserReinvite,
    onCancelUserInvite,
    onDeleteStripeCustomer,
    onResetRenewDate,
    onResetLogin,
    showChangeUserPermissionsModal,
    showChangeUserProgramModal,
    showRemoveUserModal,
    showReactivateUserModal,
    showManageUserAllowancesModal,
    showUpdateUserCountryModal,
    showViewUserMembershipsModal
  } = usePeopleActions(userId, userStatus)

  return (
    <>
      <AccordionRoot type="multiple" defaultValue={['program-mods']}>
        <AccordionItem value="user-actions-header">
          <AccordionHeader>User Actions</AccordionHeader>
        </AccordionItem>

        <AccordionItem value="program-mods">
          <AccordionTrigger>
            <ActionLabel action={ActionType.ProgramModifications} />
          </AccordionTrigger>
          <AccordionContent>
            <AccordionActionItem onClick={showChangeUserProgramModal}>
              <ActionLabel action={PeopleAction.ManageBenefits} />
            </AccordionActionItem>
            <AccordionActionItem onClick={showViewUserMembershipsModal}>
              <ActionLabel action={PeopleAction.ViewEnrollmentHistory} />
            </AccordionActionItem>
            {isUserEnabled ? (
              <>
                <AccordionActionItem onClick={showRemoveUserModal}>
                  {isEmployerAdminOnly(userRoles) ? (
                    <ActionLabel action={PeopleAction.TerminateBenfitsAndAdmin} />
                  ) : (
                    <ActionLabel action={PeopleAction.TerminateBenfits} />
                  )}
                </AccordionActionItem>
              </>
            ) : (
              <AccordionActionItem onClick={showReactivateUserModal}>
                <ActionLabel action={PeopleAction.ReEnableBenefits} />
              </AccordionActionItem>
            )}
            {userLocked && (
              <AccordionActionItem onClick={onResetLogin}>
                <ActionLabel action={PeopleAction.UnlockAccount} />
              </AccordionActionItem>
            )}
            <AccordionActionItem onClick={showManageUserAllowancesModal}>
              <ActionLabel action={PeopleAction.ManageUserAllowances} />
            </AccordionActionItem>
          </AccordionContent>
        </AccordionItem>

        <AccordionItem value="user-mods">
          <AccordionTrigger>
            <ActionLabel action={ActionType.UserModifications} />
          </AccordionTrigger>
          <AccordionContent>
            {isUserInvited && (
              <>
                <AccordionActionItem onClick={onUserReinvite}>
                  <ActionLabel action={PeopleAction.ResendInvite} />
                </AccordionActionItem>
                <AccordionActionItem onClick={onCancelUserInvite}>
                  <ActionLabel action={PeopleAction.CancelInvite} />
                </AccordionActionItem>
              </>
            )}
            <AccordionActionItem onClick={showChangeUserPermissionsModal} disabled={!isUserEnabled}>
              <ActionLabel action={PeopleAction.ManagePermissions} />
            </AccordionActionItem>
            <AccordionActionItem onClick={onDeleteStripeCustomer} disabled={!hasStripeAccount}>
              <ActionLabel action={PeopleAction.StripeAccountDelete} />
            </AccordionActionItem>
            <AccordionActionItem
              onClick={showUpdateUserCountryModal}
              disabled={!hasMultipleCountries}
            >
              <ActionLabel action={PeopleAction.UpdateCountry} />
            </AccordionActionItem>
            {hasPurchaseAccount && (
              <AccordionActionItem onClick={onResetRenewDate}>
                <ActionLabel action={PeopleAction.ResetPurchaseAccount} />
              </AccordionActionItem>
            )}
          </AccordionContent>
        </AccordionItem>
      </AccordionRoot>
    </>
  )
}
