import { Chip } from 'Components/Chip/Chip'

import {
  bloodOrange,
  bloodOrangeFaded,
  bronzeYellow,
  bronzeYellowFaded,
  Colors,
  elfGreen,
  elfGreenFaded
} from 'Utils/styles/colors'
import { InvoiceState } from 'Utils/types/invoice'
import { displayDate } from 'Utils/helpers/dateUtils'

type InvoiceStatusProps = {
  invoiceState: InvoiceState
  payDate?: Date
}

export const InvoiceStateLabels = {
  [InvoiceState.Processing]: 'Pending',
  [InvoiceState.Completed]: 'Paid',
  [InvoiceState.Open]: 'Due',
  [InvoiceState.Draft]: 'Draft',
  [InvoiceState.Preparing]: 'Preparing'
}

const ChipStyles: Record<InvoiceState, { text: string; backgroundColor: Colors; color: Colors }> = {
  [InvoiceState.Processing]: {
    text: InvoiceStateLabels[InvoiceState.Processing],
    backgroundColor: bronzeYellowFaded,
    color: bronzeYellow
  },
  [InvoiceState.Completed]: {
    text: InvoiceStateLabels[InvoiceState.Completed],
    backgroundColor: elfGreenFaded,
    color: elfGreen
  },
  [InvoiceState.Open]: {
    text: InvoiceStateLabels[InvoiceState.Open],
    backgroundColor: bloodOrangeFaded,
    color: bloodOrange
  },
  [InvoiceState.Draft]: {
    text: InvoiceStateLabels[InvoiceState.Draft],
    backgroundColor: bloodOrangeFaded,
    color: bloodOrange
  },
  [InvoiceState.Preparing]: {
    text: InvoiceStateLabels[InvoiceState.Preparing],
    backgroundColor: bloodOrangeFaded,
    color: bloodOrange
  }
}

export const InvoiceStatus = ({ invoiceState, payDate }: InvoiceStatusProps) => {
  const { backgroundColor, color, text } = ChipStyles[invoiceState]

  const chipText =
    invoiceState === InvoiceState.Completed && payDate ? `${text} on ${displayDate(payDate)}` : text

  return <Chip backgroundColor={backgroundColor} fontColor={color} text={chipText} />
}
