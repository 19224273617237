import styled from 'styled-components'
import { blackTint } from 'Utils/styles/colors'
import { Layer2 } from 'Utils/styles/zIndex'
import { spacingMD } from 'Utils/styles/spacing'

export const Background = styled.div`
  ${Layer2};
  width: 100%;
  height: 100%;
  background: ${blackTint};
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: ${spacingMD};
  padding-bottom: ${spacingMD};
`
