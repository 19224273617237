import { PurchaseAccount, PurchaseAccountProvider } from 'Utils/types/connectedAccounts'

/**
 * Truncates a string to a specified length, appending '...' if the string was truncated.
 * If no length is provided, defaults to 20.
 * @param {string} input - The string to be truncated.
 * @param {number} length - The maximum length of the string.
 * @returns {string} - The truncated string.
 */
const truncate = (input: string, length = 20): string =>
  input.length > length ? `${input.substring(0, length - 3)}...` : input

/**
 * Truncates the name of a purchase account, the institution name, and appends the account mask.
 * @param {PurchaseAccount} account - The purchase account object.
 * @returns {string} - The truncated name of the card.
 */
export const truncateCardName = (account: PurchaseAccount) => {
  const { name, institutionName, mask, provider } = account

  return provider === PurchaseAccountProvider.TrueLayer
    ? `(${mask}) ${truncate(name)} ${truncate(institutionName)}`
    : `${truncate(name)} ${truncate(institutionName)} (${mask})`
}

/**
 * Converts the first character of each word in the provided text to uppercase.
 * @param {string} text - The text to titleize.
 * @returns {string} - The titleized text.
 */
export const titleize = (text: string) => {
  return text
    .split(' ')
    .map((word) => ucFirst(word))
    .join(' ')
}

/**
 * Converts the first character of the provided text to uppercase and the rest to lowercase.
 * @param {string} text - The text to convert.
 * @returns {string} - The text with the first character in uppercase and the rest in lowercase.
 */
const ucFirst = (text: string) => `${text.charAt(0).toUpperCase()}${text.slice(1).toLowerCase()}`
