import { FC, useMemo } from 'react'
import styled from 'styled-components'
import { ControlledStyledSelect } from 'Components/Inputs/ControlledStyledSelect'
import { CountryForm } from './CountryForm'
import { StyledButton } from 'Components/Buttons/StyledButton'
import { useEmployerInfoContext } from 'Contexts/EmployerInfoContext'
import { useCreateCountryForm } from './useCreateCountryForm'
import { FlexRow, spacingSM, spacingMD, ButtonContainer } from 'Utils/styles/spacing'
import { EmployerCountry } from 'Utils/types/employerInfo'
import { filterCountryOptions } from 'Utils/helpers/countries'

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${spacingMD};
`

type CreateCountryFormProps = {
  closeModal: () => void
  countryConfig?: EmployerCountry
}

export const CreateCountryForm: FC<CreateCountryFormProps> = ({ closeModal, countryConfig }) => {
  const { control, registerOptions, watch, handleSubmit, handleSubmission, handleError } =
    useCreateCountryForm(closeModal, countryConfig)

  const {
    state: { employerInfo }
  } = useEmployerInfoContext()

  const filteredCountryOptions = useMemo(() => {
    const allOptions = filterCountryOptions(employerInfo?.countries)

    return allOptions
  }, [employerInfo?.countries])

  const countryCode = watch('countryCode')
  const chargeFeeType = watch('chargeFeePer')

  return (
    <FormContainer onSubmit={handleSubmit(handleSubmission, handleError)}>
      <FlexRow gap={spacingSM}>
        {!countryConfig && (
          <ControlledStyledSelect
            label="Country"
            name="countryCode"
            options={filteredCountryOptions}
            control={control}
            rules={registerOptions.countryCode}
          />
        )}
      </FlexRow>
      {countryCode && (
        <CountryForm control={control} countryCode={countryCode} chargeFeeType={chargeFeeType} />
      )}
      <ButtonContainer>
        <StyledButton primary id="save.label" isLoading={false} />
      </ButtonContainer>
    </FormContainer>
  )
}
