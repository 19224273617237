import { FC, useMemo, useCallback } from 'react'

import { StyledButton } from 'Components/Buttons/StyledButton'

import { useDownloadBenefitProgramReceipts } from 'Hooks/api/JOONAdmin/useDownloadBenefitProgramReceiptsQuery'
import { useSelectModal } from 'Hooks/modals/useSelectModal'
import { useUserInfoContext } from 'Contexts/UserInfoContext'

import {
  getBenefitProgramReceiptDownloadOptions,
  parseBenefitProgramDownloadOption
} from 'Utils/helpers/receipts'
import { EmployerBenefitProgram } from 'Utils/types/benefitProgram'

type DownloadReceiptsButtonProps = {
  program: EmployerBenefitProgram
}

export const DownloadReceiptsButton: FC<DownloadReceiptsButtonProps> = ({ program }) => {
  const {
    state: { user }
  } = useUserInfoContext()

  const { showModal } = useSelectModal()

  const monthOptions = useMemo(() => {
    return getBenefitProgramReceiptDownloadOptions(program.policy.scheduledStartDate)
  }, [program.policy.scheduledStartDate])

  const { mutate } = useDownloadBenefitProgramReceipts()

  const handleDownloadBenefitProgramReceipts = useCallback(async () => {
    if (!user?.id || !program.id) return

    const selectedMonth = await showModal({
      title: 'Benefit Program Receipts',
      selectOptions: monthOptions,
      placeholderText: 'Select a month',
      confirmButtonId: 'download.label'
    })

    if (!selectedMonth) return

    const parsedDates = parseBenefitProgramDownloadOption(selectedMonth)

    mutate({ userId: user.id, benefitProgramId: program.id, ...parsedDates })
  }, [user?.id, program.id, showModal, monthOptions, mutate])

  return (
    <StyledButton
      onClick={(e) => {
        e.stopPropagation()
        handleDownloadBenefitProgramReceipts()
      }}
      primary
      id="benefitProgram.downloadReceipts.label"
    />
  )
}
