import { useQuery } from 'react-query'

import { QueryNames } from 'Hooks/api/queryNames'
import { useErrorReporter } from 'Hooks/useErrorReporter'

import { getUserAllowances } from 'Services/allowances'
import { GetAllowancesPayload } from 'Services/apiResponseTypes'

export const useGetUserAllowancesQuery = (payload: GetAllowancesPayload) => {
  const reportError = useErrorReporter()
  const { userId, benefitProgramId, perPage, pageNumber } = payload

  return useQuery({
    cacheTime: 1,
    queryKey: [QueryNames.GetUserAllowances, userId, benefitProgramId, perPage, pageNumber],
    queryFn: async () => {
      const response = await getUserAllowances(payload)

      if (response.data) {
        return response.data
      } else {
        reportError('useGetUserAllowancesQuery failure', response)
      }
    }
  })
}
