import { FC, useCallback, useMemo, useState } from 'react'
import { Table, TableBody } from '@mui/material'
import styled from 'styled-components'
import { equals } from 'ramda'

import { PeopleTableActions } from 'Pages/Employer/PeoplePage/PeopleTable/PeopleTableActions'
import {
  getPeopleTableHeaderCells,
  PeopleTableHeaderCell
} from 'Pages/Employer/PeoplePage/PeopleTable/PeopleTableHeader'
import { SortableTableHeader } from 'Components/Table/SortableTableHeader'
import { Placeholder } from 'Components/Placeholder/Placeholder'
import { TableContentPlaceholder } from 'Components/CircularProgress/TableContentPlaceholder'
import { LinkButton } from 'Components/ExternalLink/LinkButton'
import { Pagination } from 'Components/Table/Pagination'
import { StyledPaper, StyledTableContainer } from 'Components/Table/StyledTableComponents'
import { PageContent } from 'Components/PageContent'
import { PeopleRow } from './PeopleRow'

import { useTableOrder } from 'Hooks/useTableOrder'
import { usePagination } from 'Hooks/usePagination'
import { useCountries } from 'Hooks/useCountries'
import { useGetUsersForEmployerQuery } from 'Hooks/api/employer/useGetUsersForEmployerQuery'

import { useEmployerUsersContext } from 'Contexts/EmployerUsersContext'
import { useEmployerProgramsContext } from 'Contexts/EmployerProgramsContext'
import { ModalType, usePeoplePageContext } from 'Contexts/PeoplePageContext'
import { useEmployerInfoContext } from 'Contexts/EmployerInfoContext'

import { convertUserToTableRow } from 'Utils/helpers/user'
import { spacingMD, spacingSM } from 'Utils/styles/spacing'
import { UserStatus } from 'Utils/types/userStatus'
import { MediumBoldText } from 'Utils/styles/text'
import { PeopleTableRow } from 'Utils/types/user'
import { CountryCode } from 'Utils/types/countries'

export type PeopleTableFilters = {
  query?: string
  status?: UserStatus
  country?: CountryCode
  filterProgramName?: string
}
const EMPTY_FILTERS: PeopleTableFilters = {
  query: '',
  country: undefined,
  status: undefined,
  filterProgramName: undefined
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: ${spacingMD};
`

const NoPeopleSubtextContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: ${spacingSM};
  align-content: center;
`

const NoPeopleAtAll = () => {
  const { setModal } = usePeoplePageContext()

  return (
    <NoPeopleSubtextContainer>
      <MediumBoldText>You haven&apos;t invited anyone yet.</MediumBoldText>
      <LinkButton text="Invite now" onClick={() => setModal(ModalType.InviteUser)} />
    </NoPeopleSubtextContainer>
  )
}

export const PeopleTable: FC<{ isJoonAdmin?: boolean }> = ({ isJoonAdmin = false }) => {
  const {
    state: { users }
  } = useEmployerUsersContext()
  const {
    state: { employerId }
  } = useEmployerInfoContext()

  const { showCountries } = useCountries(isJoonAdmin)

  const { order, orderBy, handleRequestSort } = useTableOrder<PeopleTableRow>()
  const {
    state: { programs }
  } = useEmployerProgramsContext()

  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = usePagination()
  const [filters, setFilters] = useState(EMPTY_FILTERS)
  const updateFilters = useCallback(
    (newFilters: Partial<PeopleTableFilters>) => {
      handleChangePage(null, 0)
      setFilters((currentFilters) => ({ ...currentFilters, ...newFilters }))
    },
    [handleChangePage]
  )

  const clearFilters = useCallback(() => setFilters(EMPTY_FILTERS), [])

  // Load the users for people page
  const { isFetching, data: responseData } = useGetUsersForEmployerQuery({
    pageNumber: page + 1,
    employerId,
    perPage: rowsPerPage,
    status: filters.status,
    searchText: filters.query,
    sortBy: orderBy,
    sortOrder: order,
    benefitProgramId: programs.find((p) => p.name === filters.filterProgramName)?.id,
    country: filters.country
  })

  const allRows = useMemo(() => {
    const activeProgramIDs = new Set(programs.map((ep) => ep.id))
    return users.map((user) => convertUserToTableRow(user, activeProgramIDs))
  }, [users, programs])

  const headerCells = useMemo(
    () =>
      getPeopleTableHeaderCells([
        ...(isJoonAdmin ? [PeopleTableHeaderCell.ID] : []),
        ...(showCountries ? [PeopleTableHeaderCell.CountryCode] : [])
      ]),
    [isJoonAdmin, showCountries]
  )

  const emptyFilters = Object.values(filters).every((v) => v?.trim() === '')

  if (!isFetching && users.length === 0 && emptyFilters) {
    return (
      <PageContent>
        <Placeholder imageUrl="/assets/park-scene.svg" subtext={NoPeopleAtAll} />
      </PageContent>
    )
  }

  const loadingContent = isFetching || employerId === undefined
  const showTablePlaceholder = loadingContent || (!isFetching && allRows.length === 0)

  return (
    <StyledContainer>
      <PeopleTableActions
        updateFilters={updateFilters}
        clearFilters={equals(filters, EMPTY_FILTERS) ? undefined : clearFilters}
        isJoonAdmin={isJoonAdmin}
      />
      <StyledPaper>
        <StyledTableContainer>
          <Table>
            <SortableTableHeader
              headerCells={headerCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {/* Show placeholder in table if we are still loading the users */}
              {showTablePlaceholder ? (
                <TableContentPlaceholder loading={loadingContent} />
              ) : (
                allRows.map((row) => {
                  return <PeopleRow key={row.inviteId} row={row} isJoonAdmin={isJoonAdmin} />
                })
              )}
            </TableBody>
          </Table>
        </StyledTableContainer>

        <Pagination
          optionText="people"
          totalRows={responseData?.usersCount ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </StyledPaper>
    </StyledContainer>
  )
}
