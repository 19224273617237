import React, { FC, useEffect, useState } from 'react'
import { Modal } from 'Components/Modal/Modal'
import { BenefitProgramForm } from 'Pages/JOONAdmin/EmployerDetailsPage/Views/BenefitPrograms/CreateBenefitProgramModal/BenefitProgramForm'
import { BenefitProgramPolicyForm } from 'Pages/JOONAdmin/EmployerDetailsPage/Views/BenefitPrograms/CreateBenefitProgramModal/BenefitProgramPolicyForm'
import { EmployerBenefitProgram } from 'Utils/types/benefitProgram'
import { FlexRow, spacingSM } from 'Utils/styles/spacing'
import { ViewSelector } from 'Pages/JOONAdmin/EmployerDetailsPage/EmployerDetailsContent'
import { MerchantForm } from 'Pages/JOONAdmin/EmployerDetailsPage/Views/BenefitPrograms/CreateBenefitProgramModal/MerchantForm'

enum BenefitProgramViews {
  Program = 'Program',
  Merchants = 'Merchants',
  Policy = 'Policy'
}

type CreateBenefitProgramModalProps = {
  showModal: boolean
  setShowModal: (a: boolean) => void
  selectedProgram?: EmployerBenefitProgram
}

export const CreateBenefitProgramModal: FC<CreateBenefitProgramModalProps> = ({
  showModal,
  setShowModal,
  selectedProgram
}) => {
  const [selectedView, setSelectedView] = useState(BenefitProgramViews.Program)

  useEffect(() => setSelectedView(BenefitProgramViews.Program), [showModal])

  const viewComponents = {
    [BenefitProgramViews.Program]: () => (
      <BenefitProgramForm
        selectedProgram={selectedProgram}
        closeModal={() => setShowModal(false)}
      />
    ),
    [BenefitProgramViews.Merchants]: () =>
      selectedProgram ? <MerchantForm programId={selectedProgram.id} /> : null,
    [BenefitProgramViews.Policy]: () =>
      selectedProgram ? (
        <BenefitProgramPolicyForm
          selectedProgram={selectedProgram}
          closeModal={() => setShowModal(false)}
        />
      ) : null
  }

  const SelectedViewComponent = viewComponents[selectedView]

  return (
    <Modal showModal={showModal} setShowModal={setShowModal} maxWidth={800} minWidth={800} overflow>
      <FlexRow gap={spacingSM}>
        {Object.entries(BenefitProgramViews).map(([key, value]) => (
          <ViewSelector
            key={key}
            id={`edit.benefit.program.view.selection.${key}.label`}
            secondary
            disabled={selectedProgram === undefined}
            $selected={selectedView === value}
            onClick={() => setSelectedView(value)}
          />
        ))}
      </FlexRow>
      <SelectedViewComponent />
    </Modal>
  )
}
