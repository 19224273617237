import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import { black, frostedGlass } from 'Utils/styles/colors'
import { fontSizeXXL, fontWeightBold, MediumBoldText } from 'Utils/styles/text'
import { FlexColumn, FlexRow, spacingMD, spacingSM } from 'Utils/styles/spacing'

import ParkScene from 'Assets/park-scene.svg'

import { TextLink } from 'Components/ExternalLink/TextLink'
import { StyledButton } from 'Components/Buttons/StyledButton'
import { useNavigate } from 'react-router-dom'
import { PageRoutes } from 'Utils/types/pageRoutes'

const ErrorPageContainer = styled(FlexColumn)`
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: ${frostedGlass};
  gap: ${spacingMD};
`

const InfoContainer = styled(FlexRow)`
  align-items: center;
  justify-content: center;
  gap: ${spacingSM};
`

const ErrorPageHeader = styled.h1`
  margin: 0;
  font-weight: ${fontWeightBold};
  font-size: ${fontSizeXXL};
  color: ${black};
`

const ParkSceneImage = styled.img`
  max-height: 550px;
`

export const GeneralErrorPage = ({ resetErrorBoundary }: { resetErrorBoundary: () => void }) => {
  const navigate = useNavigate()

  return (
    <ErrorPageContainer>
      <ErrorPageHeader>
        <FormattedMessage id="generalErrorPage.header" />
      </ErrorPageHeader>
      <ParkSceneImage src={ParkScene} alt="Peaceful scene" />
      <InfoContainer>
        <MediumBoldText>
          It looks like something went wrong on the page you were visiting.
        </MediumBoldText>
        <TextLink textId="loginPage.support.contact" href="mailto:info@joon.io" target="_blank" />
      </InfoContainer>
      <StyledButton
        primary
        id="continue.label"
        onClick={() => {
          resetErrorBoundary()
          navigate(PageRoutes.UserDashboard)
        }}
      />
    </ErrorPageContainer>
  )
}
