import { getRequest } from 'Services/baseRequests/getRequest'
import {
  GetEmployerAdminInvoicesApiResponse,
  RequestResponse,
  GetEmployerAdminInvoicesResponse
} from 'Services/apiResponseTypes'
import {
  GetDetailedInvoiceResponse,
  GetInvoiceDetailsApiResponse
} from 'Services/JOONAdmin/adminApiResponseTypes'
import {
  parseEmployerAdminInvoiceApiResponseData,
  parseDetailedInvoiceApiResponseData
} from 'Services/invoices/parsers'
import { postRequest, postRequestDownloadFile } from 'Services/baseRequests/postRequest'

import { EmployerInfo } from 'Utils/types/employerInfo'
import { Invoice } from 'Utils/types/invoice'
import { PaymentMethod } from 'Utils/types/paymentMethod'

export type GetEmployerAdminInvoicePayload = {
  perPage: number
  pageNumber: number
  employerId: EmployerInfo['id']
}

export const getEmployerAdminInvoices = async ({
  employerId,
  pageNumber,
  perPage
}: GetEmployerAdminInvoicePayload): Promise<GetEmployerAdminInvoicesResponse> => {
  const response = await getRequest<GetEmployerAdminInvoicesApiResponse>(
    `/employers/${employerId}/invoices/grouped?&page=${pageNumber}&per_page=${perPage}`
  )

  return {
    statusCode: response.statusCode,
    success: response?.success ?? false,
    message: response?.message ?? '',
    data: {
      invoices: response?.data?.invoices.map(parseEmployerAdminInvoiceApiResponseData) ?? [],
      totalInvoices: response?.data?.total_records ?? 0
    }
  }
}

export const getEmployerAdminInvoiceDetails = async (
  invoiceId: number,
  employerId: number
): Promise<GetDetailedInvoiceResponse> => {
  const response = await getRequest<GetInvoiceDetailsApiResponse>(
    `/employers/${employerId}/invoices/${invoiceId}/fetch_invoice_details`
  )

  return {
    success: response?.success ?? false,
    message: response?.message ?? '',
    data: response?.data
      ? { invoice: parseDetailedInvoiceApiResponseData(response.data.invoice) }
      : undefined
  }
}

export const downloadInvoice = async (employerId: EmployerInfo['id'], invoiceId: Invoice['id']) => {
  const response = await postRequestDownloadFile<ArrayBuffer>(
    `/employers/${employerId}/invoices/${invoiceId}/generate_csv`,
    undefined,
    { responseType: 'arraybuffer' }
  )

  return response
    ? {
        success: true,
        statusCode: response.statusCode ?? undefined,
        message: '',
        data: response.data
      }
    : {
        success: false,
        message: '',
        data: undefined
      }
}

export type SubmitInvoicePaymentPayload = {
  employerId: EmployerInfo['id']
  invoiceId: Invoice['id']
  paymentMethodId: PaymentMethod['id']
  idempotencyKey: string
}
export const submitInvoicePayment = async ({
  employerId,
  invoiceId,
  paymentMethodId,
  idempotencyKey
}: SubmitInvoicePaymentPayload): Promise<RequestResponse> => {
  const response = await postRequest<
    RequestResponse,
    { payment_method_id: PaymentMethod['id']; idempotency_key: string }
  >(`/employers/${employerId}/invoices/${invoiceId}/submit_payment`, {
    payment_method_id: paymentMethodId,
    idempotency_key: idempotencyKey
  })

  return {
    statusCode: response.statusCode,
    success: response?.success ?? false,
    message: response?.message ?? '',
    data: response?.data
  }
}

export type GetStripeInvoiceUrlPayload = {
  employerId: number
  invoiceId: number
}

type GetStripeInvoicePaymentUrlResponse = RequestResponse<GetStripeInvoicePaymentUrlResponseData>
type GetStripeInvoicePaymentUrlResponseData = {
  url: string
}

export const getStripeInvoiceUrl = async ({
  employerId,
  invoiceId
}: GetStripeInvoiceUrlPayload): Promise<GetStripeInvoicePaymentUrlResponse> => {
  const response = await getRequest<GetStripeInvoicePaymentUrlResponse>(
    `/employers/${employerId}/invoices/${invoiceId}/stripe_invoice_link`
  )

  return {
    statusCode: response.statusCode,
    success: response?.success ?? false,
    message: response?.message ?? '',
    data: { url: response?.data?.url || '' }
  }
}
