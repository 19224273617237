import React, { useState } from 'react'
import styled from 'styled-components'

import { Card } from 'Components/Card/Card'
import { ComponentLoading } from 'Components/CircularProgress/ComponentLoading'
import { BasicInfo } from 'Pages/JOONAdmin/EmployerDetailsPage/EmployerSummaryCard/BasicInfo'
import { EmployerReportsMenuButton } from 'Pages/JOONAdmin/EmployerDetailsPage/EmployerSummaryCard/EmployerReportsMenuButton'

import { useJoonAdminContext } from 'Contexts/JOONAdmin/JOONAdminContext'
import { useEmployerInfoContext } from 'Contexts/EmployerInfoContext'

import { useGetInvoicesQuery } from 'Hooks/api/employer/invoices/useGetInvoicesQuery'

import {
  FlexColumn,
  GridColumn,
  minPageContentWidth,
  noPadding,
  spacingLG,
  spacingMD,
  spacingSM,
  spacingXL
} from 'Utils/styles/spacing'
import { MediumBoldText, telegrafFont, ThinDarkGreenText } from 'Utils/styles/text'
import { zirconGray } from 'Utils/styles/colors'
import { displayDate } from 'Utils/helpers/dateUtils'
import { EmployerTerminateButton } from './EmployerTerminateButton'
import { WorkOSSettingsModal } from 'Pages/JOONAdmin/EmployerDetailsPage/EmployerSummaryCard/WorkOSSettingsModal'
import { StyledButton } from 'Components/Buttons/StyledButton'

const HeaderSection = styled(GridColumn)`
  grid-area: header;
  justify-content: center;
  grid-template-columns: 1fr min-content;
  grid-gap: ${spacingMD};
  padding: ${spacingMD} ${spacingXL};
`

const ProgramName = styled.h1`
  display: grid;
  align-content: center;
  justify-content: center;
  margin: 0;
`

const StatsHeader = styled(MediumBoldText)`
  ${telegrafFont};
`

const EmployerSummaryContainer = styled.div`
  display: grid;
  min-width: ${minPageContentWidth};
  grid-auto-columns: 1fr;
  grid-template-areas:
    'header header header'
    'basicInfo latestInvoice latestInvoice';
`

const InvoiceSectionContainer = styled(FlexColumn)`
  border-top: 1px ${zirconGray} solid;
  border-left: 1px ${zirconGray} solid;
  padding: ${spacingLG};
  grid-area: latestInvoice;
  gap: ${spacingSM};
`

export const EmployerSummaryCard = () => {
  const [isWorkOSModalOpen, setWorkOSModalOpen] = useState(false)

  const {
    state: { selectedEmployer }
  } = useJoonAdminContext()

  const {
    state: { invoices }
  } = useEmployerInfoContext()

  // The invoices table also fetches these, but we can grab them ahead of time to display the
  // first one since the query is cached.
  const { isFetching } = useGetInvoicesQuery({
    pageNumber: 1,
    employerId: selectedEmployer?.id,
    perPage: 1
  })

  if (!selectedEmployer) return <ComponentLoading />

  return (
    <Card padding={noPadding}>
      <EmployerSummaryContainer>
        <HeaderSection>
          <ProgramName>{selectedEmployer.name}</ProgramName>
          <EmployerTerminateButton />
          <StyledButton
            id="workOsSettings.button.label"
            type="button"
            onClick={() => setWorkOSModalOpen(true)}
          />
          <EmployerReportsMenuButton />
        </HeaderSection>
        <BasicInfo employer={selectedEmployer} />
        <InvoiceSectionContainer>
          <StatsHeader>Latest Invoice</StatsHeader>
          {isFetching ? (
            <ComponentLoading />
          ) : invoices.length ? (
            <>
              <ThinDarkGreenText>Status: {invoices[0].state}</ThinDarkGreenText>
              <ThinDarkGreenText>Due Date: {displayDate(invoices[0].dueDate)}</ThinDarkGreenText>
              <ThinDarkGreenText>
                Users Charged For: {invoices[0].numberOfUsersChargedFor}
              </ThinDarkGreenText>
              <ThinDarkGreenText>
                Users Benefited: {invoices[0].numberOfUsersReimbursed}
              </ThinDarkGreenText>
              <ThinDarkGreenText>
                Revenue:{' '}
                {invoices[0].joonFees +
                  invoices[0].benefitTransferFees +
                  (invoices[0].creditCardSurcharge || 0)}
              </ThinDarkGreenText>
              <ThinDarkGreenText>Invoice Total: {invoices[0].totalAmount}</ThinDarkGreenText>
            </>
          ) : (
            <ThinDarkGreenText>This employer has no invoices.</ThinDarkGreenText>
          )}
        </InvoiceSectionContainer>
      </EmployerSummaryContainer>
      <WorkOSSettingsModal
        selectedEmployer={selectedEmployer}
        onClose={() => setWorkOSModalOpen(false)}
        showModal={isWorkOSModalOpen}
      />
    </Card>
  )
}
