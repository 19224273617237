import { CSSProperties, FC } from 'react'

import { HoverIconCard } from 'Components/HoverIconCard'
import { Chip } from 'Components/Chip/Chip'
import { LinkButton } from 'Components/ExternalLink/LinkButton'

import { elfGreen, frostedGlass } from 'Utils/styles/colors'
import { ExternalLinkRoutes } from 'Utils/types/pageRoutes'
import { AppIconMap, AppIcons } from 'Utils/types/appIcons'

interface RolloverChipProps {
  margin?: string
}

export const RolloverChip: FC<RolloverChipProps> = ({ margin = '' }) => {
  const chipStyle: CSSProperties = {
    verticalAlign: 'middle',
    margin: margin
  }

  return (
    <Chip backgroundColor={frostedGlass} fontColor={elfGreen} text="ROLLOVER" style={chipStyle}>
      <HoverIconCard
        iconSrc={AppIconMap[AppIcons.InfoCircle]}
        altText="Rollover program info"
        iconStyles={{ margin: `1.5px 0` }}
      >
        <>
          <span>This program has rollover enabled.</span>
          <span>Unused balances will carry over to the following period.</span>
          <LinkButton text="Learn more" href={ExternalLinkRoutes.WhatIsRolloverArticle} />
        </>
      </HoverIconCard>
    </Chip>
  )
}
