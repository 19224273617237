import { AxiosError } from 'axios'
import { RequestResponse, ResponseError } from 'Services/apiResponseTypes'

/**
 * Typescript doesn't allow us to type catch blocks, so we use a type guard to check if the error
 * is an AxiosError in order to access that type's properties.
 * @param object we are checking
 */
export const isAxiosError = (object: unknown): object is AxiosError =>
  !!(object && typeof object === 'object' && 'message' in object)

export const isResponseError = (object: unknown): object is ResponseError =>
  !!(object && typeof object === 'object' && 'source' in object)

export const parseErrorResponse = (responseData?: RequestResponse<any>): string | undefined => {
  if (responseData === undefined) return undefined
  /*  TODO: We need to define how errors come across. They come across inconsistently.
        Version 1: responseData.errors =
        Array of { source: "username_or_password", title: "Username Or Password is invalid" }
        Version 2: responseData.errors =
        Object { source: "username_or_password", title: "Username Or Password is invalid" }
        Version 3: responseData.errors =
        Array of { "email": string[] }
   */
  if (isResponseError(responseData.errors)) {
    return responseData.errors?.title
  } else if (Array.isArray(responseData.errors) && isResponseError(responseData.errors?.[0])) {
    return responseData.errors?.[0].title
  } else {
    const typedErrors = responseData?.errors
      ? (responseData?.errors as { [p: string]: string[] }[])
      : undefined

    if (!typedErrors) return undefined

    return Object.values(typedErrors[0])[0][0]
  }
}

export const GhostModeError = async (): Promise<RequestResponse> => ({
  success: false,
  message: 'Action not available while in ghost mode.'
})
