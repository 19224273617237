export enum PolicyPeriodDuration {
  Monthly = 'monthly',
  Quarterly = 'quarterly',
  Yearly = 'yearly'
}

export const PolicyPeriodDurationDisplay: Map<PolicyPeriodDuration, string> = new Map([
  [PolicyPeriodDuration.Monthly, 'month'],
  [PolicyPeriodDuration.Quarterly, 'quarter'],
  [PolicyPeriodDuration.Yearly, 'year']
])

export enum RolloverOptions {
  Disabled = 'disabled',
  Indefinite = 'indefinite'
}

export type BenefitProgramPolicy = {
  id: string
  policyPeriodDuration: PolicyPeriodDuration
  expenditureAllowance: number
  rollover: RolloverOptions
  ongoingPurchases: boolean
  ongoingPurchaseThreshold?: number
  scheduledStartDate: Date
  scheduledEndDate?: Date
}

export type UserProgramMembership = BenefitProgramPolicy & {
  policyPeriodDuration: PolicyPeriodDuration
  expenditureAllowance: number
  rollover: string
  ongoingPurchases: boolean
  ongoingPurchaseThreshold: number
  scheduledStartDate: Date
  scheduledEndDate?: Date
}

export const hasRollover = (policy: BenefitProgramPolicy) =>
  policy.rollover !== RolloverOptions.Disabled
