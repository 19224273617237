import { useEffect, useMemo } from 'react'

import { FlexColumn, FlexRow, spacingSM } from 'Utils/styles/spacing'
import { Checkbox } from 'Components/Inputs/Checkbox/Checkbox'
import { AppIconMap, AppIcons } from 'Utils/types/appIcons'
import { HoverIconCard } from 'Components/HoverIconCard'
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form'
import { AddPurchaseFormValues } from 'Components/Modal/AddPurchaseModal/AddPurchaseForm'

type OngoingPurchaseCheckboxProps = {
  getValues: UseFormGetValues<AddPurchaseFormValues>
  setValue: UseFormSetValue<AddPurchaseFormValues>
  currencySymbol: string
  amountMeetsThreshold: boolean
  activeOngoingPurchaseForProgram: boolean
  hasPendingOngoingPurchase: boolean
  purchaseAmountRequired: number
}
export const OngoingPurchaseCheckbox = ({
  getValues,
  setValue,
  currencySymbol,
  amountMeetsThreshold,
  activeOngoingPurchaseForProgram,
  hasPendingOngoingPurchase,
  purchaseAmountRequired
}: OngoingPurchaseCheckboxProps) => {
  const disabled = useMemo(
    () => !amountMeetsThreshold || activeOngoingPurchaseForProgram || hasPendingOngoingPurchase,
    [amountMeetsThreshold, activeOngoingPurchaseForProgram, hasPendingOngoingPurchase]
  )

  useEffect(() => {
    if (disabled) setValue('ongoingPurchase', false)
  }, [disabled, setValue])

  return (
    <FlexRow gap={spacingSM}>
      <Checkbox
        disabled={disabled}
        labelText="Request ongoing reimbursements for excess amount"
        value={getValues('ongoingPurchase')}
        onClick={() => setValue('ongoingPurchase', !getValues('ongoingPurchase'))}
      />
      <HoverIconCard
        iconSrc={AppIconMap[AppIcons.InfoCircle]}
        altText={'Ongoing reimbursement requirement'}
      >
        <FlexColumn gap={spacingSM} style={{ maxWidth: 300 }}>
          <span>You may only have one ongoing purchase per program at a time.</span>
          {hasPendingOngoingPurchase && (
            <span>You already have a pending request for ongoing reimbursements.</span>
          )}
          {!amountMeetsThreshold && (
            <span>
              Purchase must be at least {currencySymbol}
              {purchaseAmountRequired} to request ongoing reimbursements.
            </span>
          )}
        </FlexColumn>
      </HoverIconCard>
    </FlexRow>
  )
}
