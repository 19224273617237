import Plant from 'Assets/plant.svg'
import Library from 'Assets/library.svg'
import InfoCircle from 'Assets/info-icon.svg'
import Flag from 'Assets/flag.svg'
import Lock from 'Assets/lock.svg'
import Warning from 'Assets/exclamation-triangle-red.svg'

export enum AppIcons {
  Plant = 'Plant',
  Library = 'Library',
  InfoCircle = 'InfoCircle',
  Flag = 'Flag',
  Lock = 'Lock',
  Warning = 'Warning'
}
export const AppIconMap: Record<AppIcons, string> = {
  Plant: Plant,
  Library: Library,
  InfoCircle: InfoCircle,
  Flag: Flag,
  Lock: Lock,
  Warning: Warning
}
