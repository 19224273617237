import styled from 'styled-components'
import { createContext, FC, useContext, useRef, useState } from 'react'

import { Modal } from 'Components/Modal/Modal'
import { StyledButton } from 'Components/Buttons/StyledButton'
import { ModalHeader } from 'Components/Modal/ModalHeader'

import { spacingSM } from 'Utils/styles/spacing'
import { ThinDarkGreenText } from 'Utils/styles/text'
import {
  ConfirmationModalContent,
  ConfirmationModalContextProviderProps,
  ModalContextType,
  useModalShow
} from 'Hooks/modals/useModalShow'

const ButtonContainer = styled.div`
  display: flex;
  gap: ${spacingSM};
  justify-content: end;
`

const UseConfirmationModal = createContext<ModalContextType<boolean>>(
  {} as ModalContextType<boolean>
)

const ConfirmationModalProvider: FC<ConfirmationModalContextProviderProps> = (props) => {
  const { setShow, show, onHide } = useModalShow()

  const [content, setContent] = useState<ConfirmationModalContent | null>()
  const resolver = useRef<(value: boolean | PromiseLike<boolean>) => void>()

  const showModal = (
    title: string,
    message: string | JSX.Element,
    confirmButtonId = 'im.sure.label',
    cancelButtonId = 'cancel.label'
  ): Promise<boolean> => {
    setContent({
      title,
      message,
      confirmButtonId,
      cancelButtonId
    })
    setShow(true)
    return new Promise((resolve) => {
      resolver.current = resolve
    })
  }

  const handleOk = () => {
    resolver.current && resolver.current(true)
    onHide()
  }

  const handleCancel = () => {
    resolver.current && resolver.current(false)
    onHide()
  }

  return (
    <UseConfirmationModal.Provider
      value={{
        showModal
      }}
    >
      {props.children}

      {content && (
        <Modal showModal={show} setShowModal={onHide} maxWidth={500}>
          <ModalHeader>{content.title}</ModalHeader>

          <ThinDarkGreenText>{content.message}</ThinDarkGreenText>

          <ButtonContainer>
            <StyledButton id={content.cancelButtonId} onClick={handleCancel} />
            <StyledButton id={content.confirmButtonId} primary onClick={handleOk} />
          </ButtonContainer>
        </Modal>
      )}
    </UseConfirmationModal.Provider>
  )
}

const useConfirmationModalContext = (): ModalContextType<boolean> =>
  useContext(UseConfirmationModal)

export { useModalShow, useConfirmationModalContext }

export default ConfirmationModalProvider
