import { SelectOption } from 'Components/Inputs/StyledSelect'
import { ReactNode, useState } from 'react'

export type ConfirmationModalContextProviderProps = {
  children: ReactNode
}

export type ConfirmationModalContent = {
  title: string
  message: string | JSX.Element
  cancelButtonId: string
  confirmButtonId: string
}

export type SelectModalPayload = {
  title: string
  selectOptions: SelectOption[]
  message?: string | JSX.Element
  placeholderText?: string
  confirmButtonId?: string
  cancelButtonId?: string
}

export type SelectModalContextType<T> = {
  showModal: (payload: SelectModalPayload) => Promise<T>
}

export type SelectModalContent = Pick<ConfirmationModalContent, 'title' | 'confirmButtonId'> & {
  message?: string | JSX.Element
  cancelButtonId?: string
  selectOptions: SelectOption[]
  placeholderText: string
}

export type ModalContextType<T> = {
  showModal: (
    title: string,
    message: string | JSX.Element,
    confirmButtonId?: string,
    cancelButtonId?: string
  ) => Promise<T>
}

type UseModalShowReturnType = {
  show: boolean
  setShow: (value: boolean) => void
  onHide: () => void
}

export const useModalShow = (): UseModalShowReturnType => {
  const [show, setShow] = useState(false)

  const handleOnHide = () => {
    setShow(false)
  }

  return {
    show,
    setShow,
    onHide: handleOnHide
  }
}
