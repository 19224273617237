import { deleteRequest } from 'Services/baseRequests/deleteRequest'
import { putRequest } from 'Services/baseRequests/putRequest'
import { getRequest } from 'Services/baseRequests/getRequest'
import {
  RequestResponse,
  GetEligibleSpendApiResponse,
  GetEligibleSpendResponse,
  GetEligibleSpendPayload
} from 'Services/apiResponseTypes'

import { CountryCode } from 'Utils/types/countries'

export const deleteUserStripeCustomer = async (payload: {
  userId: number
}): Promise<RequestResponse> => {
  const response = await deleteRequest<RequestResponse>(
    `/admin/employees/${payload.userId}/delete_stripe_account`
  )

  return {
    success: response?.success ?? false,
    message: response?.message ?? ''
  }
}

export type UpdateUserCountryPayload = {
  userId: number
  countryCode: CountryCode
}
export const updateUserCountry = async (
  payload: UpdateUserCountryPayload
): Promise<RequestResponse> => {
  const response = await putRequest<RequestResponse, { country_code: CountryCode }>(
    `/admin/employees/${payload.userId}/update_user_country`,
    { country_code: payload.countryCode }
  )

  return {
    success: response?.success ?? false,
    message: response?.message ?? ''
  }
}

export const getEligibleSpend = async (
  payload: GetEligibleSpendPayload
): Promise<GetEligibleSpendResponse> => {
  const { userId, benefitProgramId } = payload

  const response = await getRequest<GetEligibleSpendApiResponse>(
    `/admin/employees/${userId}/list_eligible_spend?benefit_program_id=${benefitProgramId}`
  )

  return {
    statusCode: response.statusCode,
    success: response.success ?? false,
    message: response.message ?? '',
    data:
      response.success && response.data
        ? {
            currentEligibleSpend: response.data.current_eligible_spend,
            previousEligibleSpend: response.data.previous_eligible_spend
          }
        : undefined
  }
}

export const resetConnectionRenewDate = async (payload: {
  userId: number
}): Promise<RequestResponse> => {
  const response = await putRequest<RequestResponse>(
    `/admin/employees/${payload.userId}/update_renew_date`
  )

  return {
    success: response?.success ?? false,
    message: response?.message ?? ''
  }
}
