import React, { FC, ReactNode, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { TrademarkFooter } from 'Components/TrademarkFooter'
import { PageLoading } from 'Components/CircularProgress/PageLoading'
import { LoggedInJoonAdminHeader } from 'Components/Header/LoggedInJoonAdminHeader'
import { AdminPageContainer } from 'Components/Containers/AdminPageContainer'

import { useIsLoggedIn } from 'Hooks/useIsLoggedIn'
import { useDashboardQuery } from 'Hooks/api/user/useDashboardQuery'

import { useUserInfoContext } from 'Contexts/UserInfoContext'
import { JoonAdminActionTypes, useJoonAdminContext } from 'Contexts/JOONAdmin/JOONAdminContext'

import { PageRoutes } from 'Utils/types/pageRoutes'

type PageProps = {
  headerText: string
  children?: ReactNode
}
export const AdminLoggedInPage: FC<PageProps> = ({ headerText, children }) => {
  const {
    dispatch,
    state: { joonAdminEmployerId, joonAdminUserId }
  } = useJoonAdminContext()
  const {
    state: { userId }
  } = useUserInfoContext()

  const { isLoggedIn } = useIsLoggedIn()
  const navigate = useNavigate()
  const location = useLocation()

  // Load user data
  const dashboardQuery = useDashboardQuery(joonAdminUserId)

  useEffect(() => {
    if (joonAdminEmployerId === undefined) {
      // Save the employer ID of the original JOON admin so that we can set it when leaving ghost mode.
      dispatch({ type: JoonAdminActionTypes.SetJoonAdminEmployerId })
    }
  }, [dispatch, joonAdminEmployerId])

  useEffect(() => {
    if (userId && joonAdminUserId === undefined) {
      // Save the user ID of the original JOON admin so that we can set it when leaving ghost mode.
      dispatch({ type: JoonAdminActionTypes.SetJoonAdminUserId, value: userId })
    }
  }, [userId, dispatch, joonAdminUserId])

  // If admin hits a protected route while logged out, redirect to page after successful login
  useEffect(() => {
    if (!isLoggedIn) navigate(PageRoutes.Logout, { state: { previousLocation: location } })
  }, [location, isLoggedIn, navigate])

  if (dashboardQuery.isFetching) return <PageLoading />

  return (
    <AdminPageContainer>
      {/* Header */}
      <LoggedInJoonAdminHeader headerText={headerText} />
      {children}
      {/* Footer */}
      <TrademarkFooter />
    </AdminPageContainer>
  )
}
