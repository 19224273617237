import React from 'react'
import styled from 'styled-components'
import { spacingXXS } from 'Utils/styles/spacing'

import { FormattedMessage } from 'react-intl'
import { StyledInputLabel } from 'Components/Inputs/StyledInputLabel'
import { StyledSelect, SelectOption } from 'Components/Inputs/StyledSelect'
import { CountryCode } from 'Utils/types/countries'

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacingXXS};
`

type InviteUserCountrySelectProps = {
  countryOptions: SelectOption<CountryCode>[]
  handleChange: (value: CountryCode) => void
}
export const InviteUserCountrySelect = ({
  countryOptions,
  handleChange
}: InviteUserCountrySelectProps) => {
  return (
    <InputContainer>
      <StyledInputLabel>
        <FormattedMessage id="country.label" />
      </StyledInputLabel>
      <StyledSelect
        options={countryOptions}
        placeholderText={'Select a country...'}
        handleChange={handleChange}
      />
    </InputContainer>
  )
}
