import { toast } from 'react-toastify'
import { AlertReload } from 'Components/AlertBanner/AlertReload'
import { REACT_APP_VERSION } from 'Services/config'

import { useQuery } from 'react-query'

import { QueryNames } from 'Hooks/api/queryNames'

import { getAppVersion } from 'Services/appMetaDataService'
import { useNavigate, useLocation } from 'react-router-dom'
import { logMessage, LogLevel } from 'Services/logging'

export const useGetAppVersionQuery = () => {
  const appVersion = REACT_APP_VERSION
  const navigate = useNavigate()
  const location = useLocation()

  const reload = (newAppVersion: string) => {
    logMessage('Reloaded to new version', {
      level: LogLevel.INFO,
      extra: { newAppVersion, currentAppVersion: appVersion, path: location.pathname }
    })
    navigate(0)
  }

  const twoMinutes = 120000

  return useQuery({
    queryKey: [QueryNames.GetAppVersion],
    refetchInterval: twoMinutes,
    queryFn: async () => {
      const response = await getAppVersion()
      const newAppVersion = response.data

      if (newAppVersion && newAppVersion !== appVersion) {
        toast.warning(<AlertReload onClick={() => reload(newAppVersion)} />, {
          toastId: 'heartbeat',
          autoClose: false
        })
      }
    }
  })
}
