import { useMutation } from 'react-query'
import { useErrorReporter } from 'Hooks/useErrorReporter'

import { QueryNames } from 'Hooks/api/queryNames'
import { toast } from 'react-toastify'
import { AppNotification } from 'Utils/types/appNotification'
import { usePeoplePageContext } from 'Contexts/PeoplePageContext'
import { useEmployerUsersContext, EmployerActionTypes } from 'Contexts/EmployerUsersContext'
import { UserRole } from 'Utils/types/roles'

import { grantUserAdminPermissions } from 'Services/users/userService'

export const useGrantUserAdminPermissions = () => {
  const reportError = useErrorReporter()

  const { setModal, setActionableUserId } = usePeoplePageContext()
  const { dispatch } = useEmployerUsersContext()

  return useMutation([QueryNames.GrantUserAdminPermissions], grantUserAdminPermissions, {
    onSuccess: (response, parameters) => {
      toast.success(AppNotification.UserPermissionsUpdatedSuccessful)
      setModal(undefined)
      dispatch({
        type: EmployerActionTypes.AddRoles,
        value: { id: parameters.userId, roles: [UserRole.EMPLOYERADMIN] }
      })
      setActionableUserId(undefined)
    },
    onError: (err: string) => {
      reportError(`useGrantUserAdminPermissions error: ${err}`)
    }
  })
}
