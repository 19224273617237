import { FC } from 'react'
import { Table, TableBody, TableRow } from '@mui/material'

import { SortableTableHeader, TableHeaderCell } from 'Components/Table/SortableTableHeader'
import { StyledPaper, StyledTableContainer } from 'Components/Table/StyledTableComponents'
import { StyledTableCell } from 'Components/Table/StyledTableCell'
import { CurrencyText } from 'Components/Text/CurrencyText'
import { Pagination } from 'Components/Table/Pagination'

import { useGetUserAllowancesQuery } from 'Hooks/api/JOONAdmin/useGetUserAllowancesQuery'
import { usePagination } from 'Hooks/usePagination'
import { useTableOrder } from 'Hooks/useTableOrder'

import { convertAllowanceToTableRow, UserAllowanceRow } from 'Utils/helpers/allowance'
import { getComparator } from 'Utils/helpers/tables'

const headerCells: readonly TableHeaderCell<UserAllowanceRow>[] = [
  { id: 'id', numeric: true, label: 'Id' },
  { id: 'initial', numeric: true, label: 'Initial' },
  { id: 'remaining', numeric: true, label: 'Remaining' },
  { id: 'start', numeric: true, label: 'Start' },
  { id: 'end', numeric: true, label: 'End' },
  { id: 'reason', numeric: true, label: 'Reason' },
  { id: 'status', numeric: true, label: 'Status' }
]

type UserAllowancesTableProps = {
  benefitProgramId: number
  userId: number
}

export const UserAllowancesTable: FC<UserAllowancesTableProps> = ({ benefitProgramId, userId }) => {
  const { order, orderBy, handleRequestSort } = useTableOrder<UserAllowanceRow>()

  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = usePagination()

  const { data } = useGetUserAllowancesQuery({
    userId,
    benefitProgramId,
    pageNumber: page + 1,
    perPage: rowsPerPage
  })

  if (!data) return null

  const { allowances, totalAllowances } = data

  const rows = allowances.map((allowance) => convertAllowanceToTableRow(allowance))

  return (
    <>
      <StyledPaper style={{ height: 'fit-content' }}>
        <StyledTableContainer>
          <Table>
            <SortableTableHeader
              headerCells={headerCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              hideActionColumn={true}
            />
            <TableBody>
              {rows.sort(getComparator(order, orderBy)).map((row) => (
                <TableRow key={row.id}>
                  <StyledTableCell>{row.id}</StyledTableCell>
                  <StyledTableCell>
                    <CurrencyText value={row.initial} currencyCode={row.allowance.currencyCode} />
                  </StyledTableCell>
                  <StyledTableCell>
                    <CurrencyText value={row.remaining} currencyCode={row.allowance.currencyCode} />
                  </StyledTableCell>
                  <StyledTableCell>{row.start}</StyledTableCell>
                  <StyledTableCell>{row.end}</StyledTableCell>
                  <StyledTableCell title={row.reason}>{row.reason}</StyledTableCell>
                  <StyledTableCell>{row.status}</StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </StyledPaper>
      <Pagination
        optionText="allowances"
        totalRows={totalAllowances}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  )
}
