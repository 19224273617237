import { AxiosRequestConfig, RawAxiosRequestHeaders, AxiosResponse } from 'axios'
import { isAxiosError, parseErrorResponse } from 'Services/baseRequests/shared'
import { api } from '../../index'
import { RequestResponse } from 'Services/apiResponseTypes'

export const getRequest = async <TResponse extends RequestResponse<unknown>>(
  url: string
): Promise<TResponse & { statusCode?: number }> => {
  const headers: RawAxiosRequestHeaders = {
    Accept: 'application/json'
  }

  const config: AxiosRequestConfig = {
    method: 'GET',
    url: url,
    headers: headers
  }

  try {
    const response: AxiosResponse = await api.request(config)

    if (response.data.success) {
      return { ...response.data, statusCode: response.status }
    } else {
      // This is when we are getting success codes but the API is really giving us some error
      // message. This needs to be fixed to send a proper status code.
      throw new Error(parseErrorResponse(response.data))
    }
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response?.status === 401) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return {
          success: false,
          message: 'You are unauthorized to access this resource. Please login again.',
          statusCode: error.response.status
        }
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return {
        success: false,
        message: 'Unknown Error',
        statusCode: error.response?.status
      }
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return {
      success: false,
      message: 'Unknown Error',
      statusCode: 404
    }
  }
}

export const getRequestDownloadFile = async (
  url: string,
  options: AxiosRequestConfig = {}
): Promise<{ data: ArrayBuffer; statusCode?: number } | undefined> => {
  const headers: RawAxiosRequestHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }

  const config: AxiosRequestConfig = {
    method: 'GET',
    url: url,
    headers: headers,
    ...options
  }

  try {
    const response: AxiosResponse = await api.request(config)

    if (response.status === 200 && response.data) {
      return { data: response.data, statusCode: response.status }
    } else {
      if (isAxiosError(response) && response) {
        return { data: response.data, statusCode: response.status }
      }
      throw new Error(parseErrorResponse(response.data))
    }
  } catch (error) {
    if (isAxiosError(error)) {
      // TODO: Check response codes
      // return { success: false, message: error.message }
    }
    return undefined
  }
}
