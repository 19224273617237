import { createContext, useContext, useReducer } from 'react'
import { User } from 'Utils/types/user'
import { RegistrationInfo } from 'Utils/types/registrationInfo'
import { DepositAccount, PurchaseAccount } from 'Utils/types/connectedAccounts'
import { LatestInvoice } from 'Utils/types/invoice'
import { EmployerCountryInfo } from 'Utils/types/countries'
import { ContextProviderProps } from 'Contexts/ContextProvider'
import { UserRole } from 'Utils/types/roles'
import { propEq, reject } from 'ramda'

export enum UserInfoActionTypes {
  SetUser = 'SET_USER',
  SetDepositAccount = 'SET_DEPOSIT_ACCOUNT',
  SetPurchaseAccounts = 'SET_PURCHASE_ACCOUNTS',
  AddPurchaseAccount = 'ADD_PURCHASE_ACCOUNT',
  SetPlaidToken = 'SET_PLAID_TOKEN',
  SetUserId = 'SET_USER_ID',
  SetLoadingUser = 'SET_LOADING_USER',
  SetLoadingDepositAccount = 'SET_LOADING_DEPOSIT_ACCOUNT',
  SetLoadingAddDepositAccount = 'SET_LOADING_ADD_DEPOSIT_ACCOUNT',
  SetRegistrationInfo = 'SET_REGISTRATION_INFO',
  SetUserRoles = 'SET_USER_ROLES',
  ResetState = 'RESET_STATE',
  SetNotificationPreferences = 'SET_NOTIFICATION_PREFERENCES',
  SetLoadingNotificationPreferences = 'SET_LOADING_NOTIFICATION_PREFERENCES',
  SetLoadingPassword = 'SET_LOADING_PASSWORD',
  SetLatestInvoiceDetails = 'SET_LATEST_INVOICE_DETAILS',
  SetEmployerCountry = 'SET_EMPLOYER_COUNTRY',
  UpdatePurchaseAccount = 'UPDATE_PURCHASE_ACCOUNT',
  RemovePurchaseAccount = 'REMOVE_PURCHASE_ACCOUNT'
}

type Action =
  | { type: UserInfoActionTypes.SetUser; value: User }
  | { type: UserInfoActionTypes.SetDepositAccount; value: DepositAccount | undefined }
  | { type: UserInfoActionTypes.SetPurchaseAccounts; value: PurchaseAccount[] }
  | { type: UserInfoActionTypes.AddPurchaseAccount; value: PurchaseAccount }
  | { type: UserInfoActionTypes.SetUserId; value: number | undefined }
  | { type: UserInfoActionTypes.SetPlaidToken; value: string | undefined }
  | { type: UserInfoActionTypes.SetLoadingUser; value: boolean }
  | { type: UserInfoActionTypes.SetLoadingDepositAccount; value: boolean }
  | { type: UserInfoActionTypes.SetLoadingAddDepositAccount; value: boolean }
  | { type: UserInfoActionTypes.SetRegistrationInfo; value: RegistrationInfo }
  | { type: UserInfoActionTypes.SetUserRoles; value: UserRole[] }
  | { type: UserInfoActionTypes.ResetState }
  | { type: UserInfoActionTypes.SetNotificationPreferences; value: User['notificationConfig'] }
  | { type: UserInfoActionTypes.SetLoadingNotificationPreferences; value: boolean }
  | { type: UserInfoActionTypes.SetLoadingPassword; value: boolean }
  | { type: UserInfoActionTypes.SetLatestInvoiceDetails; value: LatestInvoice }
  | { type: UserInfoActionTypes.SetEmployerCountry; value: EmployerCountryInfo }
  | {
      type: UserInfoActionTypes.UpdatePurchaseAccount
      value: Partial<PurchaseAccount> & { purchaseAccountId: PurchaseAccount['id'] }
    }
  | { type: UserInfoActionTypes.RemovePurchaseAccount; value: PurchaseAccount['id'] }
type Dispatch = (action: Action) => void

export type UserInfoContextState = {
  isLoadingUser: boolean
  isLoadingDepositAccount: boolean
  isLoadingAddDepositAccount: boolean
  isLoadingNotificationPreferences: boolean
  isLoadingPassword: boolean
  userRoles: UserRole[]
  userId?: number
  user?: User
  plaidToken?: string // Used in the linking process for user purchase accounts via Plaid
  registrationInfo?: RegistrationInfo // Basic info used to pre-populate the un-editable registration form fields
  depositAccount?: DepositAccount // The account which we send reimbursements to
  purchaseAccounts: PurchaseAccount[] // The accounts which we automatically classify purchases from
  latestInvoiceDetails?: LatestInvoice
  employerCountry?: EmployerCountryInfo
}

export const USER_INFO_CONTEXT_DEFAULT_STATE: UserInfoContextState = {
  isLoadingUser: false,
  isLoadingDepositAccount: false,
  isLoadingAddDepositAccount: false,
  isLoadingNotificationPreferences: false,
  isLoadingPassword: false,
  purchaseAccounts: [],
  userRoles: [],
  latestInvoiceDetails: undefined,
  employerCountry: undefined
}

const UserInfoStateContext = createContext<
  { state: UserInfoContextState; dispatch: Dispatch } | undefined
>(undefined)

const userInfoReducer = (state: UserInfoContextState, action: Action): UserInfoContextState => {
  switch (action.type) {
    case UserInfoActionTypes.SetUser: {
      return { ...state, user: action.value, userId: action.value.id }
    }
    case UserInfoActionTypes.SetUserId: {
      return { ...state, userId: action.value }
    }
    case UserInfoActionTypes.SetDepositAccount: {
      return { ...state, depositAccount: action.value }
    }
    case UserInfoActionTypes.SetPurchaseAccounts: {
      return { ...state, purchaseAccounts: action.value }
    }
    case UserInfoActionTypes.AddPurchaseAccount: {
      return { ...state, purchaseAccounts: [...state.purchaseAccounts, action.value] }
    }
    case UserInfoActionTypes.SetLoadingUser: {
      return { ...state, isLoadingUser: action.value }
    }
    case UserInfoActionTypes.SetLoadingDepositAccount: {
      return { ...state, isLoadingDepositAccount: action.value }
    }
    case UserInfoActionTypes.SetLoadingAddDepositAccount: {
      return { ...state, isLoadingAddDepositAccount: action.value }
    }
    case UserInfoActionTypes.SetRegistrationInfo: {
      return { ...state, registrationInfo: action.value }
    }
    case UserInfoActionTypes.SetPlaidToken: {
      return { ...state, plaidToken: action.value }
    }
    case UserInfoActionTypes.SetUserRoles: {
      return { ...state, userRoles: action.value }
    }
    case UserInfoActionTypes.ResetState: {
      return USER_INFO_CONTEXT_DEFAULT_STATE
    }
    case UserInfoActionTypes.SetNotificationPreferences: {
      return {
        ...state,
        user: state.user ? { ...state.user, notificationConfig: action.value } : state.user
      }
    }
    case UserInfoActionTypes.SetLoadingNotificationPreferences: {
      return { ...state, isLoadingNotificationPreferences: action.value }
    }
    case UserInfoActionTypes.SetLoadingPassword: {
      return { ...state, isLoadingPassword: action.value }
    }
    case UserInfoActionTypes.SetLatestInvoiceDetails: {
      return { ...state, latestInvoiceDetails: action.value }
    }
    case UserInfoActionTypes.SetEmployerCountry: {
      return { ...state, employerCountry: action.value }
    }
    case UserInfoActionTypes.UpdatePurchaseAccount: {
      const purchaseAccounts = [...state.purchaseAccounts]
      const { purchaseAccountId, ...rest } = action.value
      const index = purchaseAccounts.findIndex((acct) => acct.id === purchaseAccountId)
      if (index !== -1) {
        purchaseAccounts[index] = {
          ...purchaseAccounts[index],
          ...rest
        }
      }
      return { ...state, purchaseAccounts }
    }
    case UserInfoActionTypes.RemovePurchaseAccount: {
      const purchaseAccounts = reject(propEq(action.value, 'id'))(state.purchaseAccounts)
      return { ...state, purchaseAccounts }
    }
    default: {
      throw new Error(`Invalid action type`)
    }
  }
}

const UserInfoProvider = ({
  children,
  defaultState
}: ContextProviderProps<UserInfoContextState>) => {
  const [state, dispatch] = useReducer(
    userInfoReducer,
    defaultState ?? USER_INFO_CONTEXT_DEFAULT_STATE
  )
  const value = { state, dispatch }
  return <UserInfoStateContext.Provider value={value}>{children}</UserInfoStateContext.Provider>
}

const useUserInfoContext = () => {
  const context = useContext(UserInfoStateContext)
  if (context === undefined) {
    throw new Error('useUserInfo must be used within an UserInfoProvider')
  }
  return context
}

export { UserInfoProvider, useUserInfoContext }
