import React from 'react'
import { Controller } from 'react-hook-form'

import { FormTextInput } from 'Components/Inputs/FormTextInput'
import { StyledButton } from 'Components/Buttons/StyledButton'
import { FormDatePicker } from 'Components/Inputs/Calendar/FormDatePicker'
import { ModalBody } from 'Components/Modal/ModalBody'
import { CurrencySymbol } from 'Utils/types/currencies'
import { ControlledSelect } from 'Components/Inputs/ControlledSelect'
import { ControlledSwitch } from 'Components/Inputs/Switch/ControlledSwitch'
import { useBenefitProgramForm } from 'Pages/JOONAdmin/EmployerDetailsPage/Views/BenefitPrograms/CreateBenefitProgramModal/useBenefitProgramForm'
import { useCountries } from 'Hooks/useCountries'
import { useGetBenefitProgramCategoriesQuery } from 'Hooks/api/JOONAdmin/useGetBenefitProgramCategoriesQuery'
import { CategoryMultiselect } from 'Components/Chip/CategoryMultiselect'
import { ButtonContainer, FlexRow, spacingLG } from 'Utils/styles/spacing'
import { EmployerBenefitProgram } from 'Utils/types/benefitProgram'
import { CountryMultiselect } from 'Components/Chip/CountryMultiselect'
import { PolicyPeriodDuration } from 'Utils/types/benefitProgramPolicy'

type BenefitProgramFormProps = {
  selectedProgram?: EmployerBenefitProgram
  closeModal: () => void
}

export const BenefitProgramForm = ({ selectedProgram, closeModal }: BenefitProgramFormProps) => {
  const {
    register,
    control,
    handleSubmit,
    errors,
    watch,
    programCadence,
    setProgramCadence,
    handleSubmission,
    handleError,
    policyPeriodOptions,
    benefitProgramRegisterOptions
  } = useBenefitProgramForm(closeModal, selectedProgram)

  const { showCountries, countryOptions } = useCountries()

  const { data: programCategories } = useGetBenefitProgramCategoriesQuery()

  const ongoingPurchasesEnabled = watch('ongoingPurchases')

  return (
    <form onSubmit={handleSubmit(handleSubmission, handleError)}>
      <ModalBody>
        <FlexRow gap={spacingLG}>
          <FormTextInput
            fieldName="name"
            errorPresent={!!errors?.name?.message}
            labelId="createBenefitProgram.programName.label"
            register={register}
            registerOptions={benefitProgramRegisterOptions.name}
          />
          {!selectedProgram && (
            <FormTextInput
              fieldName="allowance"
              defaultValue={`${CurrencySymbol.USD} `}
              errorPresent={!!errors?.allowance?.message}
              labelId="createBenefitProgram.allowance.label"
              disabled={!!selectedProgram}
              register={register}
              registerOptions={benefitProgramRegisterOptions.allowance}
            />
          )}
        </FlexRow>

        {!selectedProgram && (
          <FlexRow gap={spacingLG}>
            <Controller
              control={control}
              name="scheduledStartDate"
              rules={benefitProgramRegisterOptions.scheduledStartDate}
              render={({ field: { onChange, value } }) => (
                <FormDatePicker
                  labelId="createBenefitProgram.scheduledStartDate.label"
                  date={value}
                  onChange={onChange}
                  disabled={!!selectedProgram}
                  hasError={!!errors.scheduledStartDate}
                  style={{ width: '100%' }}
                />
              )}
            />

            <Controller
              control={control}
              name="scheduledEndDate"
              render={({ field: { onChange, value } }) => (
                <FormDatePicker
                  labelId="createBenefitProgram.scheduledEndDate.label"
                  date={value}
                  onChange={onChange}
                  disabled={!!selectedProgram}
                  hasError={!!errors.scheduledEndDate}
                  style={{ width: '100%' }}
                />
              )}
            />
          </FlexRow>
        )}

        <FlexRow gap={spacingLG}>
          <FormTextInput
            fieldName="benefitProgramLink"
            errorPresent={!!errors?.benefitProgramLink?.message}
            labelId="createBenefitProgram.benefitProgramLink.label"
            register={register}
            registerOptions={benefitProgramRegisterOptions.benefitProgramLink}
          />
          {!selectedProgram && (
            <ControlledSelect
              label="Policy Period Duration"
              options={policyPeriodOptions}
              disabled={!!selectedProgram}
              defaultValue={programCadence}
              setControlValue={setProgramCadence}
            />
          )}
        </FlexRow>

        <FlexRow gap={spacingLG}>
          {showCountries && (
            <CountryMultiselect
              options={countryOptions}
              control={control}
              name="countries"
              label="Program Countries"
              defaultSelected={selectedProgram?.countries ?? []}
            />
          )}
          {programCategories && (
            <CategoryMultiselect
              options={programCategories}
              control={control}
              name="selectedCategories"
              defaultSelected={selectedProgram?.categories.map((c) => c.id) ?? []}
            />
          )}
        </FlexRow>

        {!selectedProgram && (
          <FlexRow gap={spacingLG}>
            <ControlledSwitch
              label="Rollover"
              control={control}
              name="rollover"
              disabled={!!selectedProgram}
            />
            <ControlledSwitch
              label="Ongoing Purchases"
              control={control}
              name="ongoingPurchases"
              disabled={!!selectedProgram || programCadence === PolicyPeriodDuration.Yearly}
            />
          </FlexRow>
        )}

        {!selectedProgram && ongoingPurchasesEnabled && (
          <FlexRow gap={spacingLG}>
            <FormTextInput
              fieldName="ongoingPurchaseThreshold"
              labelId="createBenefitProgram.ongoingPurchaseThreshold.label"
              register={register}
              disabled={!!selectedProgram}
              errorPresent={!!errors?.ongoingPurchaseThreshold?.message}
              registerOptions={benefitProgramRegisterOptions.ongoingPurchaseThreshold}
            />
          </FlexRow>
        )}

        <ButtonContainer>
          <StyledButton primary id="save.label" isLoading={false} />
        </ButtonContainer>
      </ModalBody>
    </form>
  )
}
