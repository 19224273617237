import { CSSProperties, FC } from 'react'

import { HoverIconCard } from 'Components/HoverIconCard'
import { Chip } from 'Components/Chip/Chip'
import { LinkButton } from 'Components/ExternalLink/LinkButton'

import { elfGreen, frostedGlass } from 'Utils/styles/colors'
import { ExternalLinkRoutes } from 'Utils/types/pageRoutes'
import { AppIconMap, AppIcons } from 'Utils/types/appIcons'

interface OngoingPurchasesChipProps {
  margin?: string
}

export const OngoingPurchasesChip: FC<OngoingPurchasesChipProps> = ({ margin = '' }) => {
  const chipStyle: CSSProperties = {
    verticalAlign: 'middle',
    margin: margin
  }

  return (
    <Chip
      backgroundColor={frostedGlass}
      fontColor={elfGreen}
      text="ONGOING PURCHASES"
      style={chipStyle}
    >
      <HoverIconCard
        iconSrc={AppIconMap[AppIcons.InfoCircle]}
        altText="Ongoing purchase info"
        iconStyles={{ margin: `1.5px 0` }}
      >
        <>
          <span>This program has ongoing purchases enabled.</span>
          <span>Large purchases are eligible for reimbursement over time.</span>
          <LinkButton text="Learn more" href={ExternalLinkRoutes.HowToReportLargePurchases} />
        </>
      </HoverIconCard>
    </Chip>
  )
}
