import { useMutation, useQueryClient } from 'react-query'
import { QueryNames } from 'Hooks/api/queryNames'
import { toast } from 'react-toastify'
import { AppNotification } from 'Utils/types/appNotification'
import { removeMerchantFromAllowList } from 'Services/JOONAdmin/merchantService'

export const useRemoveMerchantFromAllowListQuery = () => {
  const queryClient = useQueryClient()

  return useMutation([QueryNames.RemoveMerchantFromAllowList], removeMerchantFromAllowList, {
    onSuccess: (response) => {
      if (response.success) {
        queryClient.invalidateQueries([QueryNames.GetBenefitProgramMerchants])
        toast.success(AppNotification.RemoveMerchantFromListSuccess)
      } else {
        toast.error(`${AppNotification.RemoveMerchantFromListFailure} ${response.message}`)
      }
    },
    onError: () => {
      toast.error(AppNotification.RemoveMerchantFromListFailure)
    }
  })
}
