import { useQuery } from 'react-query'
import { QueryNames } from 'Hooks/api/queryNames'
import { useErrorReporter } from 'Hooks/useErrorReporter'
import { EmployerBenefitProgram, BenefitProgramMerchants } from 'Utils/types/benefitProgram'
import { getBenefitProgramMerchants } from 'Services/JOONAdmin/merchantService'

export const useGetBenefitProgramMerchantsQuery = (
  benefitProgramId: EmployerBenefitProgram['id']
) => {
  const reportError = useErrorReporter()

  return useQuery<BenefitProgramMerchants>({
    queryKey: [QueryNames.GetBenefitProgramMerchants, benefitProgramId],
    queryFn: async () => {
      const response = await getBenefitProgramMerchants(benefitProgramId)
      if (!response.data) {
        reportError('useGetBenefitProgramMerchantsQuery failure', response)
      }

      return response.data ?? { allowList: [], blockList: [] }
    }
  })
}
