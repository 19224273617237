import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { QueryNames } from 'Hooks/api/queryNames'
import { useErrorReporter } from 'Hooks/useErrorReporter'

import { submitInvoicePayment, SubmitInvoicePaymentPayload } from 'Services/invoices/invoiceService'

import { AppNotification } from 'Utils/types/appNotification'

export const useSubmitInvoicePaymentQuery = () => {
  const queryClient = useQueryClient()
  const reportError = useErrorReporter()

  return useMutation(
    [QueryNames.SubmitInvoicePayment],
    (payload: SubmitInvoicePaymentPayload) => {
      return submitInvoicePayment(payload)
    },
    {
      onSuccess: (response) => {
        if (response.success) {
          queryClient.invalidateQueries([QueryNames.GetInvoices])
          toast.success(AppNotification.SubmitInvoicePaymentSuccess)
        } else {
          toast.error(`${AppNotification.SubmitInvoicePaymentFailure} ${response.message}`)
        }
      },
      onError: (err: Error) => {
        reportError(`useSubmitInvoicePaymentQuery error: ${err.message}`)
      }
    }
  )
}
