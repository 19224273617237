import { FC } from 'react'
import { IconButton } from '@mui/material'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'

type CollapsibleIconProps = {
  toggled: boolean
  toggle: () => void
  label?: string
}
export const CollapsibleIcon: FC<CollapsibleIconProps> = ({ toggled, toggle, label = '' }) => {
  return (
    <IconButton aria-label={label} size="small" onClick={() => toggle()}>
      {toggled ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
    </IconButton>
  )
}
