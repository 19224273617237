import { useQuery } from 'react-query'

import { QueryNames } from 'Hooks/api/queryNames'
import { useErrorReporter } from 'Hooks/useErrorReporter'

import { getEligibleSpend } from 'Services/JOONAdmin/joonAdminUsersService'
import { GetEligibleSpendPayload } from 'Services/apiResponseTypes'

export const useGetUserEligibleSpendQuery = (payload: GetEligibleSpendPayload) => {
  const reportError = useErrorReporter()
  const { userId, benefitProgramId } = payload

  return useQuery({
    cacheTime: 1,
    queryKey: [QueryNames.GetEligibleSpend, userId, benefitProgramId],
    queryFn: async () => {
      const response = await getEligibleSpend(payload)

      if (response.data) {
        return response.data
      } else {
        reportError('useGetUserEligibleSpendQuery failure', response)
      }
    }
  })
}
