import { useQuery } from 'react-query'

import { QueryNames } from 'Hooks/api/queryNames'
import { useErrorReporter } from 'Hooks/useErrorReporter'

import { getEmployerDetails } from 'Services/JOONAdmin/joonAdminEmployersService'

import { JoonAdminActionTypes, useJoonAdminContext } from 'Contexts/JOONAdmin/JOONAdminContext'

export const useGetEmployerDetailsQuery = (employerId?: number) => {
  const reportError = useErrorReporter()

  const { dispatch } = useJoonAdminContext()

  return useQuery({
    queryKey: [QueryNames.GetEmployerDetails, employerId],
    queryFn: async () => {
      if (!employerId) return

      const response = await getEmployerDetails(employerId)
      if (response.data) {
        dispatch({ type: JoonAdminActionTypes.SetSelectedEmployer, value: response.data.employer })
      } else {
        reportError('useGetEmployerDetailsQuery failure', response)
      }

      return response
    }
  })
}
