import styled from 'styled-components'
import { CSSProperties, ReactNode } from 'react'
import { pagePadding, spacingLG, SpacingUnits } from 'Utils/styles/spacing'

const StyledPageContent = styled.div<{ columnCount: number; rowGap?: SpacingUnits }>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columnCount}, auto);
  grid-column-gap: ${spacingLG};
  grid-row-gap: ${(props) => (props.rowGap ? props.rowGap : 0)};
  justify-content: stretch;
  ${pagePadding}
`

export type PageContentProps = {
  children: ReactNode
  twoColumnLayout?: boolean
  rowGap?: SpacingUnits
  style?: CSSProperties
}
export const PageContent = ({
  children,
  twoColumnLayout = false,
  rowGap,
  style
}: PageContentProps) => {
  const columnCount = twoColumnLayout ? 2 : 1

  return (
    <StyledPageContent columnCount={columnCount} rowGap={rowGap} style={style}>
      {children}
    </StyledPageContent>
  )
}
