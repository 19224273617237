import axios, { AxiosRequestConfig, RawAxiosRequestHeaders, AxiosResponse } from 'axios'
import { REACT_APP_CLIENT_SERVER_URL } from 'Services/config'
import { RequestResponse } from 'Services/apiResponseTypes'

/**
 * Gets current app version of benefits app
 */
export const getAppVersion = async (): Promise<RequestResponse<string>> => {
  const benefitAppServer = axios.create({ baseURL: REACT_APP_CLIENT_SERVER_URL })
  const headers: RawAxiosRequestHeaders = {
    Accept: 'application/json'
  }

  const config: AxiosRequestConfig = {
    method: 'GET',
    url: '/version',
    headers: headers
  }

  try {
    const response: AxiosResponse = await benefitAppServer.request(config)

    if (response.data.appVersion) {
      return {
        success: !!response.data.appVersion,
        message: '',
        data: response.data.appVersion
      }
    } else {
      return { success: false, message: '', data: undefined }
    }
  } catch (error) {
    return { success: false, message: '', data: undefined }
  }
}
