import { useState } from 'react'
import { StyledButton } from 'Components/Buttons/StyledButton'
import { FileUpload, FileType } from 'Components/Inputs/FileUpload'
import { ButtonContainer } from 'Utils/styles/spacing'
import { ModalBody } from '../ModalBody'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

export type BulkFormProps = {
  messageId: string
  isLoading: boolean
  onSubmit: (formData: FormData) => void
}

export const BulkForm = ({ messageId, onSubmit, isLoading }: BulkFormProps) => {
  const { handleSubmit } = useForm()

  const [file, setFile] = useState<File | null>(null)

  const handleClick = async () => {
    if (!file) {
      toast.error('No file found. Upload csv to invite users.')
      return
    }

    const formData = new FormData()
    formData.append('file', file as Blob)

    onSubmit(formData)
  }

  return (
    <form onSubmit={handleSubmit(handleClick)}>
      <ModalBody>
        <FileUpload setFileValue={setFile} fileType={FileType.Csv} />
      </ModalBody>
      <ButtonContainer>
        <StyledButton primary id={messageId} disabled={file === null || isLoading} />
      </ButtonContainer>
    </form>
  )
}
