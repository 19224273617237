import { getRequest } from './baseRequests/getRequest'
import {
  ApiEmployerBenefitProgram,
  BenefitProgramUtiilizationResponse,
  BenefitProgramUtilizationApiResponse,
  EmployerBenefitProgramsApiResponse,
  EmployerBenefitProgramsResponse
} from './apiResponseTypes'
import { EmployerBenefitProgram } from 'Utils/types/benefitProgram'
import { parseServerDate } from 'Utils/helpers/dateUtils'

export const getEmployerBenefitProgramDetails = async (
  employerId: number,
  monthlyUtilization: boolean
): Promise<EmployerBenefitProgramsResponse> => {
  const response = await getRequest<EmployerBenefitProgramsApiResponse>(
    `/employers/${employerId}/benefit_program_details?monthly_utilization=${monthlyUtilization}`
  )

  return {
    statusCode: response.statusCode,
    success: response.success ?? false,
    message: response.message ?? '',
    data:
      response.success && response.data?.benefit_programs
        ? {
            benefitPrograms: parseEmployerBenefitProgramsResponseData(
              response.data.benefit_programs
            )
          }
        : undefined
  }
}

const parseEmployerBenefitProgramsResponseData = (
  benefitPrograms: ApiEmployerBenefitProgram[]
): EmployerBenefitProgram[] => {
  return (
    benefitPrograms
      .filter((bp) => bp.policy?.scheduledStartDate)
      .map((bp) => ({
        ...bp,
        policy: {
          ...bp.policy,
          scheduledStartDate: parseServerDate(bp.policy.scheduledStartDate),
          scheduledEndDate: bp.policy.scheduledEndDate
            ? parseServerDate(bp.policy.scheduledEndDate)
            : undefined
        }
      })) ?? []
  )
}

export const getBenefitProgramUtilization = async (
  employerId: number
): Promise<BenefitProgramUtiilizationResponse> => {
  const response = await getRequest<BenefitProgramUtilizationApiResponse>(
    `/employers/${employerId}/benefit_program_utilization`
  )

  return {
    statusCode: response.statusCode,
    success: response.success ?? false,
    message: response.message ?? '',
    data:
      response.success && response.data?.benefit_program_utilization
        ? {
            benefitProgramUtilization: response.data.benefit_program_utilization
          }
        : undefined
  }
}
