import { FC, useMemo } from 'react'
import styled from 'styled-components'
import { v4 } from 'uuid'

import { Modal } from 'Components/Modal/Modal'
import { PayInvoiceForm } from 'Pages/Employer/BillingPage/PayInvoice/PayInvoiceForm'
import { SimpleCircularProgress } from 'Components/CircularProgress/SimpleCircularProgress'

import { useEmployerInfoContext } from 'Contexts/EmployerInfoContext'
import { useGetInvoiceDetailsQuery } from 'Hooks/api/JOONAdmin/useGetInvoiceDetailsQuery'

import { spacingLG, spacingMD } from 'Utils/styles/spacing'
import { EmployerAdminInvoice } from 'Utils/types/invoice'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: ${spacingLG};
  padding-top: ${spacingMD};
`

type PayInvoiceModalProps = {
  open: boolean
  onClose: () => void
  invoiceId: EmployerAdminInvoice['id']
}
export const PayInvoiceModal: FC<PayInvoiceModalProps> = ({
  open,
  onClose,
  invoiceId
}: PayInvoiceModalProps) => {
  const {
    state: { employerId }
  } = useEmployerInfoContext()

  const idempotencyKey = v4()

  const { data, isLoading } = useGetInvoiceDetailsQuery(invoiceId, employerId, open)

  const invoice = useMemo(() => data?.data?.invoice ?? undefined, [data?.data?.invoice])

  return (
    <Modal showModal={open} setShowModal={onClose} minWidth={500} maxWidth={500}>
      {isLoading || !invoice ? (
        <SimpleCircularProgress />
      ) : (
        <Container>
          <PayInvoiceForm
            invoice={invoice}
            employerId={employerId}
            setShowModal={onClose}
            idempotencyKey={idempotencyKey}
          />
        </Container>
      )}
    </Modal>
  )
}
