import { useCallback, useMemo } from 'react'
import { useUserInfoContext } from 'Contexts/UserInfoContext'
import { firstOfFollowingMonth, sameDay } from 'Utils/helpers/dateUtils'
import { useUserProgramContext } from 'Contexts/UserProgramContext'
import isBefore from 'date-fns/isBefore'
import isAfter from 'date-fns/isAfter'
import compareDesc from 'date-fns/compareDesc'
import { PurchasesTableRow } from 'Utils/types/purchase'

export enum EarliestDateType {
  Membership = 'MEMBERSHIP',
  Invoice = 'INVOICE'
}

/*  Gets the earliest date a purchase can be reported for.
 *  The first day of the most recent uninvoiced month is preferable, but
 *  that doesn't exist if there are no invoices yet.
 *  In that case we use the beginning of the user's membership */
export const useEarliestReportableDate = (programId?: string) => {
  const {
    state: { latestInvoiceDetails }
  } = useUserInfoContext()

  const {
    state: { programs }
  } = useUserProgramContext()

  const earliestReportableProgramDate = useMemo(() => {
    const membershipStartDate = programs.find((program) => program.id.toString() === programId)
      ?.membership.scheduledStartDate

    if (membershipStartDate && !latestInvoiceDetails)
      return {
        date: membershipStartDate,
        type: EarliestDateType.Membership
      }

    if (membershipStartDate && latestInvoiceDetails) {
      if (isBefore(membershipStartDate, latestInvoiceDetails.dateCreated)) {
        return {
          date: firstOfFollowingMonth(latestInvoiceDetails.dateCreated),
          type: EarliestDateType.Invoice
        }
      } else {
        return { date: membershipStartDate, type: EarliestDateType.Membership }
      }
    }

    return undefined
  }, [latestInvoiceDetails, programs, programId])

  const reportable = useCallback(
    (purchase: Pick<PurchasesTableRow, 'date'>) => {
      const membershipStartDate = programs
        .map((program) => program.membership.scheduledStartDate)
        .sort(compareDesc)

      const afterMembershipStart = membershipStartDate.some(
        (membershipStart) =>
          sameDay(purchase.date, membershipStart) || isAfter(purchase.date, membershipStart)
      )

      const afterLatestInvoice =
        !latestInvoiceDetails || isAfter(purchase.date, latestInvoiceDetails.dateCreated)

      return afterLatestInvoice && afterMembershipStart
    },
    [latestInvoiceDetails, programs]
  )

  return {
    earliestReportableProgramDate,
    reportable
  }
}
