import styled from 'styled-components'
import { BaseText } from 'Utils/styles/text'
import {
  azure,
  black,
  elfGreen,
  mineralGreen,
  red,
  towerGray,
  zirconGray
} from 'Utils/styles/colors'
import { spacingLG, spacingSM } from 'Utils/styles/spacing'

const InputValidCheckmark = `
  background-image: url(assets/green-checkmark.svg);
  background-position: right center;
  background-repeat: no-repeat;
  background-origin: content-box;
`

export const StyledInput = styled.input<{
  errorPresent?: boolean
  showValidCheck?: boolean
  leftPadding?: boolean
}>`
  ${BaseText};
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 48px;
  width: calc(100% - ${spacingSM} * 2 - 3px);
  padding: 0 ${spacingSM} 0
    ${(props) => (props.leftPadding ? `calc(${spacingLG} + ${spacingSM})` : spacingSM)};

  border-radius: 8px;
  border: 1.5px solid ${(props) => (props.errorPresent ? red : zirconGray)};
  color: ${(props) => (props.errorPresent ? red : black)};

  ${/* Green checkmark to indicate input is valid */ ''}
  ${(props) => (props.showValidCheck ? InputValidCheckmark : '')}
  ::placeholder {
    color: ${towerGray};
  }

  &:hover {
    border: 1.5px solid ${towerGray};

    ::placeholder {
      color: ${mineralGreen};
    }
  }

  &:focus {
    outline-width: 0;
    border: 1.5px solid ${elfGreen};
    color: ${black};
  }

  &:disabled {
    background-color: ${azure};
    caret-color: transparent;
    cursor: not-allowed;
  }

  overflow: hidden;
  text-overflow: ellipsis;
`
