import { PaymentMethod, MajorCreditCardBrand } from 'Utils/types/paymentMethod'

export type CardDetails = {
  name: MajorCreditCardBrand | string
  expMonth: number
  expYear: number
  last4: string
}

export type BankAccountDetails = {
  name: string
  last4: string
  expMonth: null
  expYear: null
}

export type StripeChargeDetails = {
  stripeCustomerId?: string
  stripeChargeId: string
}

export type PendingPaymentInfo = {
  name: string
  last4: string
}

export type Payment = {
  id: number
  amount: number
  createdAt: Date
  autoPayment: boolean

  paymentInfo: PaymentMethod | StripeChargeDetails | null
}

export const isPendingPaymentInfo = (object: unknown): object is PendingPaymentInfo =>
  !!(object && typeof object === 'object' && 'last4' in object)

export const isStripePayment = (object: unknown): object is StripeChargeDetails =>
  !!(object && typeof object === 'object' && 'stripeChargeId' in object)
