import { TableHeaderCell } from 'Components/Table/SortableTableHeader'
import { EmployerAdminInvoiceTableRow } from 'Utils/types/invoice'

export const getInvoiceTableHeaderCells = (
  showCountries: boolean,
  hasInvoiceWithReimbursementFees: boolean,
  showType: boolean
): TableHeaderCell<EmployerAdminInvoiceTableRow>[] => {
  const invoiceTableHeaderCountryCell: TableHeaderCell<EmployerAdminInvoiceTableRow> = {
    id: 'countryCode',
    numeric: false,
    label: 'Country',
    sortable: true
  }

  const invoiceTableHeaderReimbursementFeesCell: TableHeaderCell<EmployerAdminInvoiceTableRow> = {
    id: 'benefitTransferFees',
    numeric: true,
    label: 'Reimbursement Fees',
    sortable: true
  }

  const invoiceTableHeaderTypeCell: TableHeaderCell<EmployerAdminInvoiceTableRow> = {
    id: 'type',
    numeric: false,
    label: 'Type',
    sortable: false
  }

  return [
    {
      id: 'date',
      numeric: false,
      label: 'Month',
      sortable: true
    },
    ...(showCountries ? [invoiceTableHeaderCountryCell] : []),
    {
      id: 'state',
      numeric: false,
      label: 'Status',
      sortable: false,
      style: { minWidth: 125 }
    },
    {
      id: 'paymentMethod',
      numeric: true,
      label: 'Payment Method',
      sortable: true,
      style: { minWidth: 195 }
    },
    {
      id: 'joonFees',
      numeric: true,
      label: 'Subscription Fees',
      sortable: true
    },
    ...(hasInvoiceWithReimbursementFees ? [invoiceTableHeaderReimbursementFeesCell] : []),
    {
      id: 'benefitTransferAmount',
      numeric: true,
      label: 'Reimbursements',
      sortable: true
    },
    ...(showType ? [invoiceTableHeaderTypeCell] : [])
  ]
}
