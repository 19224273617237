import styled from 'styled-components'
import { screenSizes } from 'Utils/styles/screenSizes'

const spacingUnit = `1em` // 16px
export const noPadding = `0` // 0px
export const spacingXXS = `calc(0.25 * ${spacingUnit})` // 4px
export const spacingXS = `calc(0.5 * ${spacingUnit})` // 8px
export const spacingSM = `calc(0.625 * ${spacingUnit})` // 10px
export const spacingMD = `calc(1 * ${spacingUnit})` // 16px
export const spacingLG = `calc(2 * ${spacingUnit})` // 32px
export const spacingXL = `calc(3.25 * ${spacingUnit})` // 52px
export const spacingXXL = `calc(5.25 * ${spacingUnit})` // 84px
export type SpacingUnits =
  | typeof noPadding
  | typeof spacingXXS
  | typeof spacingXS
  | typeof spacingSM
  | typeof spacingMD
  | typeof spacingLG
  | typeof spacingXL
  | typeof spacingXXL

export const pagePadding = `
  padding: ${spacingLG} 13vw;
  
  @media ${screenSizes.mobile} {
    padding: ${spacingSM};
    box-sizing: border-box;
  }
`

export const minPageContentWidth = '650px'

export const GridRow = styled.div<{ gap?: SpacingUnits }>`
  display: grid;
  grid-auto-flow: row;
  grid-gap: ${({ gap }) => gap};
`

export const GridColumn = styled.div<{ gap?: SpacingUnits }>`
  display: grid;
  grid-auto-flow: column;
  grid-gap: ${({ gap }) => gap};
`

export const FlexRow = styled.div<{ gap?: SpacingUnits }>`
  display: flex;
  flex-direction: row;
  gap: ${({ gap }) => gap};
`

export const FlexColumn = styled.div<{ gap?: SpacingUnits }>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap};
`

export const CardWithLabelContainer = styled.div`
  display: grid;
  grid-template-rows: 20px auto;
  grid-gap: ${spacingLG};
`

export const TwoColumnLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 50%);
  gap: ${spacingLG};
  justify-content: center;

  @media ${screenSizes.mobile} {
    margin-top: ${spacingSM};
    display: flex;
    flex-direction: column;
    gap: ${spacingLG};
    min-width: inherit;
    width: calc(98vw - ${spacingLG}*2);
  }
`

export const ButtonContainer = styled.div<{ marginTop?: SpacingUnits; gap?: SpacingUnits }>`
  display: flex;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : spacingSM)};
  justify-content: end;
  gap: ${({ gap }) => gap};
`

export const FormContainer = styled.form<{ gap?: SpacingUnits }>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap};
`

export const ScrollableFormContainer = styled(FormContainer)`
  overflow-y: auto;
`
