import { spacingMD } from 'Utils/styles/spacing'
import { elfGreen } from 'Utils/styles/colors'
import styled from 'styled-components'
import { CalendarIcon } from '@radix-ui/react-icons'
import { StyledInput } from 'Components/Inputs/StyledInput'

export const inlineCalendarCssOverride = {
  '.rdrDateRangePickerWrapper': {
    border: `1px solid ${elfGreen}`
  }
}

export const calendarCssOverride = {
  position: 'absolute',
  zIndex: 1,
  ...inlineCalendarCssOverride
}

export const InputDatePickerContainer = styled.div<{ hasError?: boolean }>`
  position: relative;

  border: ${({ hasError }) => (hasError ? '1px solid red' : 'initial')};
`

export const CalendarButton = styled(CalendarIcon)`
  color: ${elfGreen};
  height: 20px;
  width: 20px;
  position: absolute;
  right: ${spacingMD};
  bottom: ${spacingMD};
  pointer-events: none;
`

export const CalendarContainer = styled.div`
  position: relative;
`

export const StyledInputWithPointer = styled(StyledInput)`
  cursor: pointer;
`
