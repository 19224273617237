import { CountryCode } from 'Utils/types/countries'
import {
  isStripePayment,
  Payment,
  PendingPaymentInfo,
  isPendingPaymentInfo
} from 'Utils/types/payment'
import {
  PaymentMethodType,
  isPaymentMethod,
  isMajorCreditCardBrand
} from 'Utils/types/paymentMethod'

export const PaymentTypeText = {
  [PaymentMethodType.Card]: { displayType: 'Credit card', tagline: 'incurs a fee' },
  [PaymentMethodType.Bank]: { displayType: 'Bank account', tagline: 'no surcharge' },
  [PaymentMethodType.USBank]: { displayType: 'Bank account', tagline: 'no surcharge' }
}

export const getCardTagline = (countryCode: CountryCode, hasAlternativeReimbursement: boolean) =>
  `${hasAlternativeReimbursement ? 'may incur' : 'incurs'} ${
    countryCode === CountryCode.US ? '3' : '4.5'
  }% fee`

export const paymentInfoWithStars = (
  payment: Payment['paymentInfo'] | PendingPaymentInfo,
  forceShowCard = true
) => {
  if (isStripePayment(payment)) {
    return `Paid via Stripe`
  }

  if (isPaymentMethod(payment)) {
    const majorCardBrand = isMajorCreditCardBrand(payment.name)
    const name = forceShowCard || !majorCardBrand ? `${payment.name}: ` : ''

    return `${name}**** ${payment.last4}`
  }

  if (isPendingPaymentInfo(payment)) {
    return `${payment.name}: **** ${payment.last4}`
  }

  return 'No payment information'
}
