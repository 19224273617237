import addYears from 'date-fns/addYears'
import startOfYear from 'date-fns/startOfYear'
import addQuarters from 'date-fns/addQuarters'
import startOfQuarter from 'date-fns/startOfQuarter'
import addMonths from 'date-fns/addMonths'
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'

import { SelectOption } from 'Components/Inputs/StyledSelect'
import { PolicyPeriodDuration } from 'Utils/types/benefitProgramPolicy'
import { displayMonthAndYear, formatDateForServer } from './dateUtils'

export const getSelectOptions = (policyDuration: PolicyPeriodDuration, start = true) => {
  switch (policyDuration) {
    case PolicyPeriodDuration.Monthly:
      return getMonthlySelectOptions(start)
    case PolicyPeriodDuration.Quarterly:
      return getQuarterlySelectOptions()
    case PolicyPeriodDuration.Yearly:
      return getYearlySelectOptions()
    default:
      return []
  }
}

// Monthly: Next 16 months
const getMonthlySelectOptions = (start = true) => {
  const monthlyOptions: SelectOption[] = []
  let nextDate = new Date()

  // If it's not start, include current month
  if (!start) {
    monthlyOptions.push({
      value: formatDateForServer(endOfMonth(nextDate)),
      label: 'End of this month'
    })
  }

  for (let i = 0; i < 16; i++) {
    const nextMonth = addMonths(nextDate, 1)

    nextDate = start ? startOfMonth(nextMonth) : endOfMonth(nextMonth)

    monthlyOptions.push({
      value: formatDateForServer(nextDate),
      label: displayMonthAndYear(nextDate)
    })
  }

  return monthlyOptions
}

// Quarterly: Next 6 quarters
const getQuarterlySelectOptions = () => {
  const quarterlyOptions: SelectOption[] = []
  let nextQuarter = new Date()

  for (let i = 0; i < 6; i++) {
    nextQuarter = startOfQuarter(addQuarters(nextQuarter, 1))

    quarterlyOptions.push({
      value: formatDateForServer(nextQuarter),
      label: displayMonthAndYear(nextQuarter)
    })
  }

  return quarterlyOptions
}

// Yearly: Next 2 years
const getYearlySelectOptions = (): SelectOption[] => {
  const today = new Date()

  const nextYear = startOfYear(addYears(today, 1))
  const followingYear = startOfYear(addYears(today, 2))
  return [
    { value: formatDateForServer(nextYear), label: nextYear.getFullYear().toString() },
    { value: formatDateForServer(followingYear), label: followingYear.getFullYear().toString() }
  ]
}
