import { useQuery } from 'react-query'
import { useErrorReporter } from 'Hooks/useErrorReporter'

import { QueryNames } from 'Hooks/api/queryNames'

import { EmployerInfoActionTypes, useEmployerInfoContext } from 'Contexts/EmployerInfoContext'
import {
  getEmployerAdminInvoices,
  GetEmployerAdminInvoicePayload
} from 'Services/invoices/invoiceService'

export const useGetInvoicesQuery = (
  payload: Omit<GetEmployerAdminInvoicePayload, 'employerId'> & {
    employerId?: GetEmployerAdminInvoicePayload['employerId']
  }
) => {
  const { employerId, pageNumber, perPage } = payload
  const reportError = useErrorReporter()

  const { dispatch } = useEmployerInfoContext()

  return useQuery({
    queryKey: [QueryNames.GetInvoices, employerId, pageNumber, perPage],
    queryFn: async () => {
      if (employerId === undefined) return

      const response = await getEmployerAdminInvoices({ employerId, pageNumber, perPage })

      if (response.success && response.data) {
        dispatch({ type: EmployerInfoActionTypes.SetInvoices, value: response.data.invoices })
      } else {
        reportError('useGetInvoicesQuery failure', response)
      }

      return response
    },
    onError: (err: string) => {
      reportError(`useGetInvoicesQuery error: ${err}`)
      return undefined
    }
  })
}
