import { FC, useMemo } from 'react'
import TableCell from '@mui/material/TableCell'
import styled from 'styled-components'
import { GearIcon } from '@radix-ui/react-icons'

import {
  DropdownContent,
  DropdownItem,
  DropdownRoot,
  DropdownTrigger
} from 'Components/DropdownMenu/DropdownMenu'
import { ActionLabel, PeopleAction } from 'Components/Label/ActionLabel'

import { usePeopleActions } from 'Hooks/usePeopleActions'
import { useEmployerInfoContext } from 'Contexts/EmployerInfoContext'

import { spacingXS } from 'Utils/styles/spacing'
import { UserStatus } from 'Utils/types/userStatus'
import { UserRole } from 'Utils/types/roles'
import { getGlobalAdminRole } from 'Utils/helpers/user'

const Container = styled.div`
  display: flex;
  gap: ${spacingXS};
  justify-content: center;
`

type PeopleActionsTableCellProps = {
  userId: number
  inviteId: number
  userStatus: UserStatus
  roles: UserRole[]
}
export const PeopleActionsTableCell: FC<PeopleActionsTableCellProps> = ({
  userId,
  userStatus,
  roles
}) => {
  const {
    state: { employerId }
  } = useEmployerInfoContext()

  const {
    isUserInvited,
    isUserEnabled,
    onUserReinvite,
    onCancelUserInvite,
    showChangeUserProgramModal,
    showRemoveUserModal,
    showReactivateUserModal,
    showChangeUserPermissionsModal,
    showViewUserMembershipsModal
  } = usePeopleActions(userId, userStatus)

  const adminRole = useMemo(() => {
    return !!getGlobalAdminRole(roles)
  }, [roles])

  return (
    <TableCell>
      <Container>
        <DropdownRoot>
          <DropdownTrigger>
            <GearIcon />
          </DropdownTrigger>
          <DropdownContent>
            {/* TODO: We want to show "re-send" option if they are disabled/removed and have never accepted an invite */}
            {/* Table actions for active or previously active users */}
            {userId && employerId && (
              <>
                <DropdownItem onSelect={showChangeUserPermissionsModal} disabled={!isUserEnabled}>
                  <ActionLabel action={PeopleAction.ManagePermissions} />
                </DropdownItem>
                <DropdownItem onSelect={showChangeUserProgramModal} disabled={!isUserEnabled}>
                  <ActionLabel action={PeopleAction.ManageBenefits} />
                </DropdownItem>
                <DropdownItem onSelect={showViewUserMembershipsModal}>
                  <ActionLabel action={PeopleAction.ViewEnrollmentHistory} />
                </DropdownItem>

                {/* Show removal option if they aren't already removed. If they are already removed,
                  show the 're-enable user' option instead. */}
                {isUserEnabled ? (
                  <DropdownItem onSelect={showRemoveUserModal} disabled={adminRole}>
                    <ActionLabel action={PeopleAction.TerminateBenfits} />
                  </DropdownItem>
                ) : (
                  <DropdownItem onSelect={showReactivateUserModal}>
                    <ActionLabel action={PeopleAction.ReEnableBenefits} />
                  </DropdownItem>
                )}
              </>
            )}
            {/* Table actions for invited users */}
            {isUserInvited && userId && (
              <>
                <DropdownItem onSelect={onUserReinvite}>
                  <ActionLabel action={PeopleAction.ResendInvite} />
                </DropdownItem>
                <DropdownItem onSelect={onCancelUserInvite}>
                  <ActionLabel action={PeopleAction.CancelInvite} />
                </DropdownItem>
              </>
            )}
          </DropdownContent>
        </DropdownRoot>
      </Container>
    </TableCell>
  )
}
