import { useQuery } from 'react-query'
import { useErrorReporter } from 'Hooks/useErrorReporter'
import { getUserPurchases } from 'Services/purchases/purchasesService'
import { Options } from 'Utils/types/options'
import { QueryNames } from 'Hooks/api/queryNames'

export const useGetPendingOPRPurchasesQuery = (
  rowsPerPage: number,
  page: number,
  options: Options,
  userId?: number
) => {
  const reportError = useErrorReporter()

  return useQuery({
    cacheTime: 5 * 1000,
    queryKey: [QueryNames.GetUserPendingOPRPurchases, userId, rowsPerPage, page, options],
    queryFn: async () => {
      if (userId === undefined) return

      const pendingOprOptions = Object.assign({}, options, { pending_opr: true })

      const response = await getUserPurchases(userId, rowsPerPage, page, pendingOprOptions)
      if (!response.data && response.statusCode !== 401) {
        reportError('useGetPendingOPRPurchasesQuery failure', response)
      }

      return response
    }
  })
}
