import { getRequest, getRequestDownloadFile } from 'Services/baseRequests/getRequest'
import { RequestResponse } from 'Services/apiResponseTypes'

export const downloadBenefitRecipientsOverviewReport = async (
  employerId: number,
  day: number,
  month: number,
  year: number
): Promise<RequestResponse> => {
  const response = await getRequest(
    `/employers/${employerId}/users/reports/benefits_recipients_overview?day=${day}&month=${month}&year=${year}`
  )

  return {
    success: response?.success ?? false,
    message: ''
  }
}

export const downloadPurchasesReport = async (
  employerId: number,
  date: string
): Promise<RequestResponse<ArrayBuffer>> => {
  const response = await getRequestDownloadFile(
    `/employers/${employerId}/users/reports/purchases_report?date=${date}`,
    { responseType: 'arraybuffer' }
  )

  return response
    ? {
        success: true,
        statusCode: response.statusCode ?? undefined,
        message: '',
        data: response.data
      }
    : {
        success: false,
        message: '',
        data: undefined
      }
}
