import { getRequest } from 'Services/baseRequests/getRequest'
import { UserBenefitProgram } from 'Utils/types/benefitProgram'
import { AppIcons } from 'Utils/types/appIcons'
import { parseInvoice } from './invoices/parsers'
import {
  ApiEmployerDetails,
  DashboardApiResponse,
  DashboardApiResponseData,
  DashboardData,
  DashboardResponse,
  EmployerDashboardApiResponse,
  EmployerDashboardData,
  EmployerDashboardResponse
} from './apiResponseTypes'
import { parseServerDate } from 'Utils/helpers/dateUtils'

/**
 * Fetches the information required to populate dashboard, which includes user info,
 * benefit program info, and purchase/deposit account info.
 * @param userId The id of the user to fetch the dashboard data for
 */
export const getUserDashboardData = async (userId: number): Promise<DashboardResponse> => {
  const response = await getRequest<DashboardApiResponse>(`/users/${userId}/dashboard`)

  return {
    statusCode: response.statusCode,
    success: response?.success ?? false,
    message: response?.message ?? '',
    data: response?.data ? parseUserDashboardResponseData(response.data) : undefined
  }
}

const parseUserDashboardResponseData = ({
  id,
  employerId,
  employerName,
  email,
  firstName,
  lastName,
  stripeCustomerExists,
  benefitPrograms,
  latestInvoiceDetails,
  notificationConfig,
  employerCountry,
  countryCode,
  currencyCode,
  purchaseAccountProvider
}: DashboardApiResponseData): DashboardData => {
  return {
    user: {
      id,
      employerId,
      employerName,
      countryCode,
      currencyCode,
      purchaseAccountProvider,
      userInfo: {
        email,
        firstName,
        lastName,
        hasPassword: true,
        ssoRedirectUrl: ''
      },
      notificationConfig,
      stripeCustomerExists,
      validTransactions: [],
      validTransactionsCount: 0
    },
    programs: benefitPrograms.map((program: UserBenefitProgram) => {
      return {
        ...program,
        membership: parseMembership(program.membership),
        // The response from the server should be a string type
        // We should have an API response type for UserBenefitProgram
        createdAt: parseServerDate(program.createdAt as unknown as string),
        icon: AppIcons.Library
      }
    }),
    transactionAccounts: [],
    latestInvoiceDetails: parseInvoice(latestInvoiceDetails),
    employerCountry: employerCountry
  }
}

const parseMembership = (
  membership: UserBenefitProgram['membership']
): UserBenefitProgram['membership'] => ({
  ...membership,
  scheduledStartDate: parseServerDate(membership.scheduledStartDate.toString()),
  scheduledEndDate: membership.scheduledEndDate
    ? parseServerDate(membership.scheduledEndDate.toString())
    : undefined
})

/**
 * Fetches the information required to populate dashboard which includes employer/program info
 * @param employerId The id of the employer to fetch dashboard data for
 */
export const getEmployerDashboardData = async (
  employerId: number
): Promise<EmployerDashboardResponse> => {
  const response = await getRequest<EmployerDashboardApiResponse>(
    `/employers/${employerId}/dashboard`
  )

  return {
    statusCode: response.statusCode,
    success: response?.success ?? false,
    message: response?.message ?? '',
    data: response?.data
      ? parseEmployerDashboardResponseData(response.data.employer_details)
      : undefined
  }
}

const parseEmployerDashboardResponseData = (details: ApiEmployerDetails): EmployerDashboardData => {
  const { businessDetail, earliestInvoiceDate, ...employerInfo } = details

  return {
    employerInfo: {
      ...employerInfo,
      earliestInvoiceDate: earliestInvoiceDate ? new Date(earliestInvoiceDate) : null,
      name: businessDetail.businessName
    }
  }
}
