import { CSSProperties, MouseEvent } from 'react'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableSortLabel from '@mui/material/TableSortLabel'
import styled from 'styled-components'

import { Order } from 'Utils/helpers/tables'
import { black, zirconGray } from 'Utils/styles/colors'
import { fontWeightSlightlyBold } from 'Utils/styles/text'
import { FlexRow, spacingXS } from 'Utils/styles/spacing'

export type TableHeaderCell<T> = {
  id: keyof T
  label: string
  numeric: boolean
  sortable?: boolean
  style?: CSSProperties
  tooltip?: JSX.Element
}

export const StyledHeaderCell = styled(TableCell)`
  background-color: ${zirconGray} !important;
  color: ${black} !important;
  font-weight: ${fontWeightSlightlyBold} !important;
  font-family: 'BasisGrotesqueProRegular', sans-serif !important;
`

type SortableTableHeaderProps<T extends Record<string, unknown>> = {
  headerCells: readonly TableHeaderCell<T>[]
  onRequestSort: (event: MouseEvent<unknown>, property: keyof T) => void
  order: Order
  orderBy: string
  hideActionColumn?: boolean
  actionColumnPosition?: 'center' | 'left'
  isSticky?: boolean
  actionColumnName?: string
}

export const SortableTableHeader = <T extends Record<string, unknown>>({
  headerCells,
  order,
  orderBy,
  onRequestSort,
  hideActionColumn,
  actionColumnPosition = 'center',
  actionColumnName = 'Actions'
}: SortableTableHeaderProps<T>) => {
  const createSortHandler = (property: keyof T) => (event: MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headerCells.map((headerCell) => (
          <StyledHeaderCell
            key={`${headerCell.id as string}${Math.random()}`}
            sortDirection={orderBy === headerCell.id ? order : false}
            style={headerCell.style}
          >
            <FlexRow gap={spacingXS}>
              <TableSortLabel
                active={orderBy === headerCell.id}
                direction={orderBy === headerCell.id ? order : 'asc'}
                onClick={headerCell.sortable ?? true ? createSortHandler(headerCell.id) : undefined}
                hideSortIcon={!(headerCell.sortable ?? true)}
              >
                {headerCell.label}
              </TableSortLabel>
              {/* Note: Be wary of using sort when using tooltips */}
              {/* The invisible sort icon can make tooltip icon look weird */}
              {headerCell.tooltip && <>{headerCell.tooltip}</>}
            </FlexRow>
          </StyledHeaderCell>
        ))}
        {!hideActionColumn && (
          <StyledHeaderCell key={'actions'} align={actionColumnPosition}>
            <TableSortLabel hideSortIcon>{actionColumnName}</TableSortLabel>
          </StyledHeaderCell>
        )}
      </TableRow>
    </TableHead>
  )
}
