import { FC, useMemo } from 'react'
import styled from 'styled-components'

import { Modal } from 'Components/Modal/Modal'
import { InvoiceReimbursementsTable } from './InvoiceReimbursementsTable'
import { InvoiceChargeDetails } from 'Pages/Employer/BillingPage/InvoiceChargeDetails'
import { InvoiceStatus } from 'Pages/Employer/BillingPage/InvoiceTable/InvoiceStatus'
import { SimpleCircularProgress } from 'Components/CircularProgress/SimpleCircularProgress'

import { useGetInvoiceDetailsQuery } from 'Hooks/api/JOONAdmin/useGetInvoiceDetailsQuery'
import { useEmployerInfoContext } from 'Contexts/EmployerInfoContext'

import { fontSizeLG, fontWeightBold } from 'Utils/styles/text'
import { displayMonthAndYear } from 'Utils/helpers/dateUtils'
import { FlexRow, spacingSM } from 'Utils/styles/spacing'

const InvoiceDate = styled.div`
  margin: 0;
  font-size: ${fontSizeLG};
  font-weight: ${fontWeightBold};
`

type InvoiceDetailsModalProps = {
  showModal: boolean
  setShowModal: (a: boolean) => void
  invoiceId: number
}
export const InvoiceDetailsModal: FC<InvoiceDetailsModalProps> = ({
  showModal,
  setShowModal,
  invoiceId
}) => {
  const {
    state: { employerId }
  } = useEmployerInfoContext()

  const { data, isLoading } = useGetInvoiceDetailsQuery(invoiceId, employerId, showModal)

  const invoice = useMemo(() => data?.data?.invoice ?? undefined, [data?.data?.invoice])

  return (
    <Modal showModal={showModal} setShowModal={setShowModal} minWidth={800} overflow>
      {isLoading || !invoice ? (
        <SimpleCircularProgress />
      ) : (
        <>
          <FlexRow gap={spacingSM}>
            <InvoiceDate>{displayMonthAndYear(invoice.dateCreated)}</InvoiceDate>
            <InvoiceStatus invoiceState={invoice.state} />
          </FlexRow>
          <InvoiceReimbursementsTable
            invoiceState={invoice.state}
            reimbursements={invoice.userReimbursements}
            currencyCode={invoice.currencyCode}
            hideStatus={invoice.alternativeReimbursement}
          />
          <InvoiceChargeDetails invoice={invoice} />
        </>
      )}
    </Modal>
  )
}
