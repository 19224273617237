import { FC } from 'react'
import { Table, TableBody, TableRow } from '@mui/material'

import { SortableTableHeader, TableHeaderCell } from 'Components/Table/SortableTableHeader'
import { getComparator } from 'Utils/helpers/tables'
import { useTableOrder } from 'Hooks/useTableOrder'
import { StyledTableCell } from 'Components/Table/StyledTableCell'
import { InvoiceState, InvoiceUserReimbursement } from 'Utils/types/invoice'
import { spacingSM } from 'Utils/styles/spacing'
import { StyledPaper, StyledTableContainer } from 'Components/Table/StyledTableComponents'
import { CurrencyText } from 'Components/Text/CurrencyText'
import { CurrencyCode } from 'Utils/types/currencies'
import { StyledAnchor } from 'Components/ExternalLink/ExternalLink'

const getHeaderCells = (
  hideStatus: boolean,
  isNotUSD: boolean,
  invoiceState: InvoiceState
): readonly TableHeaderCell<InvoiceUserReimbursement>[] => {
  const isComplete = invoiceState === InvoiceState.Completed

  const statusHeader: TableHeaderCell<InvoiceUserReimbursement> = {
    id: 'status',
    numeric: false,
    label: 'Status'
  }

  const amountHeader: TableHeaderCell<InvoiceUserReimbursement> = {
    id: 'amount',
    numeric: true,
    label: 'Amount'
  }

  const convertedCurrencyHeaders: TableHeaderCell<InvoiceUserReimbursement>[] = [
    {
      id: 'amount',
      numeric: true,
      label: `Reimbursement ${isComplete ? 'Sent' : 'Amount'}`
    },
    {
      id: 'amountReceived',
      numeric: true,
      label: 'Reimbursement Received'
    }
  ]

  return [
    {
      id: 'employeeName',
      numeric: false,
      label: 'Name'
    },

    // Only show reimbursement amount/received if it's not alternative reimbursement
    // && currency is not USD
    ...(!hideStatus && isNotUSD ? convertedCurrencyHeaders : [amountHeader]),
    ...(hideStatus ? [] : [statusHeader])
  ]
}

type InvoiceReimbursementsTableProps = {
  reimbursements: InvoiceUserReimbursement[]
  currencyCode: CurrencyCode
  invoiceState: InvoiceState
  hideStatus?: boolean
  height?: number | string
}
export const InvoiceReimbursementsTable: FC<InvoiceReimbursementsTableProps> = ({
  reimbursements,
  currencyCode,
  invoiceState,
  hideStatus = false,
  height = 375
}) => {
  const { order, orderBy, handleRequestSort } = useTableOrder<InvoiceUserReimbursement>()
  const isNotUSD = currencyCode !== CurrencyCode.USDollar

  return (
    <StyledPaper style={{ height: height, marginBottom: spacingSM }}>
      <StyledTableContainer sx={{ maxHeight: height }}>
        <Table stickyHeader>
          <SortableTableHeader
            headerCells={getHeaderCells(hideStatus, isNotUSD, invoiceState)}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            hideActionColumn
          />
          <TableBody>
            {reimbursements.sort(getComparator(order, orderBy)).map((r) => (
              <TableRow key={r.id}>
                <StyledTableCell>{r.employeeName}</StyledTableCell>
                <StyledTableCell>
                  <CurrencyText value={r.amount} currencyCode={currencyCode} />
                </StyledTableCell>
                {!hideStatus && isNotUSD && (
                  <StyledTableCell>
                    <CurrencyText value={r.amountReceived || 0} currencyCode={currencyCode} />
                  </StyledTableCell>
                )}
                {!hideStatus && (
                  <StyledTableCell>
                    {r.transferUrl ? (
                      <StyledAnchor
                        href={`https://dashboard.stripe.com/connect/transfers/${r.transferUrl}`}
                        target="_blank"
                        padding={spacingSM}
                      >
                        {r.status}
                      </StyledAnchor>
                    ) : (
                      r.status
                    )}
                  </StyledTableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </StyledPaper>
  )
}
