import { useState, useMemo } from 'react'
import { Controller } from 'react-hook-form'

import { FormTextInput } from 'Components/Inputs/FormTextInput'
import { StyledButton } from 'Components/Buttons/StyledButton'
import { FormDatePicker } from 'Components/Inputs/Calendar/FormDatePicker'
import { ModalBody } from 'Components/Modal/ModalBody'
import { ControlledSwitch } from 'Components/Inputs/Switch/ControlledSwitch'
import { UpdateBenefitProgramInfo, EndBenefitProgramPolicyInfo } from './ModifyBenefitProgramInfo'
import { BenefitProgramStartDateSelector } from './BenefitProgramStartDateSelector'
import { BenefitProgramEndDateSelector } from './BenefitProgramEndDateSelector'
import { useBenefitProgramForm } from 'Pages/JOONAdmin/EmployerDetailsPage/Views/BenefitPrograms/CreateBenefitProgramModal/useBenefitProgramForm'

import { ButtonContainer, FlexRow, spacingLG, spacingMD } from 'Utils/styles/spacing'
import { CurrencySymbol } from 'Utils/types/currencies'
import { EmployerBenefitProgram } from 'Utils/types/benefitProgram'
import { PolicyPeriodDuration, RolloverOptions } from 'Utils/types/benefitProgramPolicy'

type BenefitProgramPolicyFormProps = {
  selectedProgram: EmployerBenefitProgram
  closeModal: () => void
}

export enum EditMode {
  Update = 'update',
  End = 'end'
}

export const BenefitProgramPolicyForm = ({
  selectedProgram,
  closeModal
}: BenefitProgramPolicyFormProps) => {
  const {
    register,
    control,
    handleSubmit,
    errors,
    watch,
    programCadence,
    handleUpdatePolicy,
    handleEndPolicy,
    handleError,
    benefitProgramRegisterOptions
  } = useBenefitProgramForm(closeModal, selectedProgram)

  const ongoingPurchasesEnabled = watch('ongoingPurchases')

  const [editMode, setEditMode] = useState<null | EditMode>(null)

  const hasPolicyStarted = selectedProgram.policy.scheduledStartDate <= new Date()
  const hasActivePolicy =
    hasPolicyStarted &&
    (selectedProgram.policy.scheduledEndDate === undefined ||
      selectedProgram.policy.scheduledEndDate >= new Date())

  const isUpdatePolicyMode = editMode === EditMode.Update
  const isEndPolicyMode = editMode === EditMode.End

  const rolloverEnabled = selectedProgram.policy.rollover === RolloverOptions.Indefinite

  const formHandler = useMemo(() => {
    if (isUpdatePolicyMode) return handleUpdatePolicy
    if (isEndPolicyMode) return handleEndPolicy

    return () => {
      return undefined
    }
  }, [isUpdatePolicyMode, isEndPolicyMode, handleEndPolicy, handleUpdatePolicy])

  return (
    <>
      {!editMode && (
        <ButtonContainer gap={spacingMD}>
          <StyledButton
            id="updateBenefitProgramPolicy.button.label"
            primary
            onClick={() => setEditMode(EditMode.Update)}
          />
          <StyledButton
            id="endBenefitProgramPolicy.button.label"
            primary
            disabled={hasPolicyStarted && !hasActivePolicy}
            onClick={() => setEditMode(EditMode.End)}
          />
        </ButtonContainer>
      )}

      {isUpdatePolicyMode && <UpdateBenefitProgramInfo />}
      {isEndPolicyMode && !hasPolicyStarted && <EndBenefitProgramPolicyInfo />}

      <form onSubmit={handleSubmit(formHandler, handleError)}>
        <ModalBody>
          {!isEndPolicyMode && (
            <FlexRow gap={spacingLG}>
              <FormTextInput
                fieldName="allowance"
                defaultValue={`${CurrencySymbol.USD} `}
                errorPresent={!!errors?.allowance?.message}
                labelId="createBenefitProgram.allowance.label"
                disabled={!isUpdatePolicyMode}
                register={register}
                registerOptions={benefitProgramRegisterOptions.allowance}
              />
            </FlexRow>
          )}

          <FlexRow gap={spacingLG}>
            {isUpdatePolicyMode ? (
              <Controller
                control={control}
                name="scheduledStartDate"
                rules={benefitProgramRegisterOptions.updatePolicyStartDate}
                render={({ field: { onChange } }) => (
                  <BenefitProgramStartDateSelector
                    policyDuration={selectedProgram.policy.policyPeriodDuration}
                    onChange={onChange}
                  />
                )}
              />
            ) : (
              <>
                <Controller
                  control={control}
                  name="scheduledStartDate"
                  rules={benefitProgramRegisterOptions.scheduledStartDate}
                  render={({ field: { onChange, value } }) => (
                    <FormDatePicker
                      labelId="createBenefitProgram.scheduledStartDate.label"
                      date={value}
                      onChange={onChange}
                      disabled={!!selectedProgram || isEndPolicyMode}
                      hasError={!!errors.scheduledStartDate}
                      style={{ width: isEndPolicyMode ? '50%' : '100%' }}
                    />
                  )}
                />
                {isEndPolicyMode ? (
                  hasPolicyStarted ? (
                    <Controller
                      control={control}
                      name="scheduledEndDate"
                      rules={benefitProgramRegisterOptions.endPolicyEndDate}
                      render={({ field: { onChange } }) => (
                        <BenefitProgramEndDateSelector onChange={onChange} />
                      )}
                    />
                  ) : null
                ) : (
                  <Controller
                    control={control}
                    name="scheduledEndDate"
                    render={({ field: { onChange, value } }) => (
                      <FormDatePicker
                        labelId="createBenefitProgram.scheduledEndDate.label"
                        date={value}
                        onChange={onChange}
                        disabled={!!selectedProgram}
                        hasError={!!errors.scheduledEndDate}
                        style={{ width: '100%' }}
                      />
                    )}
                  />
                )}
              </>
            )}
          </FlexRow>

          {isEndPolicyMode ? (
            <FlexRow>
              <ControlledSwitch
                label="Delete Future Policies"
                control={control}
                name="deleteFuturePolicies"
              />
            </FlexRow>
          ) : (
            <>
              <FlexRow gap={spacingLG}>
                <ControlledSwitch
                  label="Rollover"
                  control={control}
                  name="rollover"
                  disabled={rolloverEnabled || !isUpdatePolicyMode}
                />
                <ControlledSwitch
                  label="Ongoing Purchases"
                  control={control}
                  name="ongoingPurchases"
                  disabled={!isUpdatePolicyMode || programCadence === PolicyPeriodDuration.Yearly}
                />
              </FlexRow>

              {ongoingPurchasesEnabled && (
                <FlexRow gap={spacingLG}>
                  <FormTextInput
                    fieldName="ongoingPurchaseThreshold"
                    labelId="createBenefitProgram.ongoingPurchaseThreshold.label"
                    register={register}
                    disabled={!isUpdatePolicyMode}
                    errorPresent={!!errors?.ongoingPurchaseThreshold?.message}
                    registerOptions={benefitProgramRegisterOptions.ongoingPurchaseThreshold}
                  />
                </FlexRow>
              )}
            </>
          )}

          {editMode && (
            <ButtonContainer>
              <StyledButton primary id="save.label" isLoading={false} />
            </ButtonContainer>
          )}
        </ModalBody>
      </form>
    </>
  )
}
