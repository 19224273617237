import { Modal } from '../Modal'
import { AddPurchaseForm } from 'Components/Modal/AddPurchaseModal/AddPurchaseForm'
import { ModalActionTypes, useModalContext } from 'Contexts/ModalContext'
import { ModalHeader } from 'Components/Modal/ModalHeader'

export const AddPurchaseModal = () => {
  const {
    state: { showAddPurchaseModal },
    dispatch
  } = useModalContext()

  const setShowModal = (value: boolean) =>
    dispatch({ type: ModalActionTypes.SetShowAddPurchaseModal, value: value })

  return (
    <Modal showModal={showAddPurchaseModal} setShowModal={setShowModal} maxWidth={500}>
      <ModalHeader>Add Purchase</ModalHeader>
      <AddPurchaseForm />
    </Modal>
  )
}
