import { Arrow, Content, Root, Trigger } from '@radix-ui/react-hover-card'
import styled from 'styled-components'

import { useEmployerProgramsContext } from 'Contexts/EmployerProgramsContext'

import { black, white, zirconGray } from 'Utils/styles/colors'
import { fontSizeXS, fontWeightThin } from 'Utils/styles/text'
import { FlexRow, spacingLG, spacingSM, spacingXS, spacingXXS } from 'Utils/styles/spacing'

const StyledContent = styled(Content)`
  background-color: ${black};
  border-radius: ${spacingXS};
  padding: ${spacingSM};
  font-size: ${fontSizeXS};
  font-weight: ${fontWeightThin};
  color: ${white};
`

const Counter = styled.span`
  white-space: pre;
  cursor: default;
  padding: ${spacingSM};
  border: 1px solid ${zirconGray};
  margin-left: ${spacingSM};
  border-radius: ${spacingLG};
  cursor: pointer;
`

const Container = styled(FlexRow)`
  gap: ${spacingXXS};
  align-items: center;
`

const RemainingProgramsIndicator = ({ programNames }: { programNames: string[] }) => (
  <Root openDelay={200} closeDelay={200}>
    <Trigger>
      <Counter>{`+ ${programNames.length}`}</Counter>
    </Trigger>
    <StyledContent>
      {programNames.map((name) => (
        <div key={name}>{name}</div>
      ))}
      <Arrow />
    </StyledContent>
  </Root>
)

export const ProgramsList = ({ programNames }: { programNames: string[] }) => {
  const {
    state: { programs: allEmployersPrograms }
  } = useEmployerProgramsContext()

  if (programNames.length === 0) return <span>No programs</span>
  if (programNames.length >= allEmployersPrograms.length) return <span>All programs</span>

  return (
    <Container gap={spacingSM}>
      {programNames[0]}
      {programNames.length > 1 && (
        <RemainingProgramsIndicator programNames={programNames.slice(1)} />
      )}
    </Container>
  )
}
