import { FC, FormEvent, useState } from 'react'
import styled from 'styled-components'

import { StyledButton } from 'Components/Buttons/StyledButton'
import { InvoiceChargeDetails } from '../InvoiceChargeDetails'

import { useSubmitInvoicePaymentQuery } from 'Hooks/api/employer/invoices/useSubmitInvoicePaymentQuery'

import { ButtonContainer, FormContainer, spacingLG, spacingMD } from 'Utils/styles/spacing'
import { DetailedInvoice } from 'Utils/types/invoice'
import { Employer } from 'Utils/types/JOONAdmin/employer'
import { useInvoicePaymentMethodOptions } from 'Hooks/useInvoicePaymentMethodOptions'
import { PaymentMethodSelector } from 'Pages/Employer/BillingPage/AutopayMenu/AutopayForm/PaymentMethodSelector'

const StyledForm = styled(FormContainer)`
  align-items: stretch;
  padding-top: ${spacingMD};
`

type PayInvoiceFormProps = {
  invoice: DetailedInvoice
  idempotencyKey: string
  employerId?: Employer['id']
  setShowModal: (a: boolean) => void
}
export const PayInvoiceForm: FC<PayInvoiceFormProps> = ({
  invoice,
  idempotencyKey,
  employerId,
  setShowModal
}) => {
  const { paymentMethods, paymentMethodOptions } = useInvoicePaymentMethodOptions(
    invoice.countryCode,
    invoice.alternativeReimbursement
  )

  const { mutateAsync, isLoading } = useSubmitInvoicePaymentQuery()

  const [selectedMethodId, setSelectedMethodId] = useState<string | null>(null)

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!selectedMethodId || !employerId) return

    await mutateAsync({
      employerId: employerId,
      invoiceId: invoice.id,
      paymentMethodId: selectedMethodId,
      idempotencyKey
    })

    setShowModal(false)
  }

  return (
    <StyledForm gap={spacingLG} onSubmit={handleSubmit}>
      <PaymentMethodSelector
        alternativeReimbursement={invoice.alternativeReimbursement}
        setSelectedPaymentMethod={setSelectedMethodId}
        paymentMethodOptions={paymentMethodOptions}
        placeholderText="Select a payment method..."
        editMode
      />
      <InvoiceChargeDetails
        invoice={invoice}
        paymentMethod={
          selectedMethodId ? paymentMethods.find((pm) => pm.id === selectedMethodId) : undefined
        }
      />
      <ButtonContainer>
        <StyledButton
          primary
          id="payment.confirmButton.default"
          disabled={!selectedMethodId}
          isLoading={isLoading}
        />
      </ButtonContainer>
    </StyledForm>
  )
}
