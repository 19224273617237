import styled from 'styled-components'
import { UserStatus } from 'Utils/types/userStatus'
import { Colors, elfGreen, mintGreen, red, towerGray } from 'Utils/styles/colors'
import { HoverIconCard } from 'Components/HoverIconCard'
import { AppIconMap, AppIcons } from 'Utils/types/appIcons'
import { displayDate } from 'Utils/helpers/dateUtils'
import { spacingSM } from 'Utils/styles/spacing'
import { PeopleTableRow } from 'Utils/types/user'

const displayConfig: { [key in UserStatus]: { label: string; textColor: Colors } } = {
  [UserStatus.Invited]: {
    label: 'Invited',
    textColor: mintGreen
  },
  [UserStatus.ScheduledInvite]: {
    label: 'Scheduled Invite',
    textColor: mintGreen
  },
  [UserStatus.Enabled]: {
    label: 'Enabled',
    textColor: elfGreen
  },
  [UserStatus.Disabled]: {
    label: 'Disabled',
    textColor: towerGray
  },
  [UserStatus.Removed]: {
    label: 'Removed',
    textColor: red
  },
  [UserStatus.ScheduledRemoval]: {
    label: 'Scheduled Removal',
    textColor: red
  }
}

const StyledLabel = styled.div`
  color: ${({ status }: { status: UserStatus }) => displayConfig[status].textColor};
  white-space: nowrap;
  display: flex;
  gap: ${spacingSM};
`

const StatusToolTip = ({ text }: { text: string }) => (
  <HoverIconCard align={'center'} iconSrc={AppIconMap[AppIcons.InfoCircle]} altText="">
    <span>{text}</span>
  </HoverIconCard>
)

export const UserStatusLabel = ({
  user: { status, inviteSent, lastMembershipEndDate, inviteSendAt }
}: {
  user: PeopleTableRow
}) => {
  return (
    <StyledLabel status={status}>
      {displayConfig[status].label}
      {status == UserStatus.ScheduledRemoval && lastMembershipEndDate && (
        <StatusToolTip text={`Scheduled for removal on: ${displayDate(lastMembershipEndDate)}`} />
      )}

      {status == UserStatus.ScheduledInvite && !inviteSent && inviteSendAt && (
        <StatusToolTip text={`Invite will be sent on: ${displayDate(inviteSendAt)}`} />
      )}
    </StyledLabel>
  )
}
