import { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import {
  FlexColumn,
  FlexRow,
  GridColumn,
  spacingXS,
  spacingSM,
  spacingLG
} from 'Utils/styles/spacing'
import { HoverIconCard } from 'Components/HoverIconCard'
import { Checkbox } from 'Components/Inputs/Checkbox/Checkbox'
import { ProgramSelectionForm } from '../ProgramSelectionForm'

import { EmployerBenefitProgram } from 'Utils/types/benefitProgram'
import { zirconGray } from 'Utils/styles/colors'
import { AppIcons, AppIconMap } from 'Utils/types/appIcons'

const StyledRow = styled(FlexRow)`
  gap: ${spacingSM};
  align-items: center;
`

const BenefitRecipientCheckboxContainer = styled(GridColumn)`
  justify-content: start;
  gap: ${spacingXS};
  line-height: ${spacingLG};
`

const ProgramsContainer = styled(FlexColumn)`
  background-color: ${zirconGray};
  padding: ${spacingLG};
  gap: ${spacingLG};
  border-radius: ${spacingXS};
`

export type InviteUserProgramSelectProps = {
  programs: EmployerBenefitProgram[]
  isCountrySelected: boolean
  toggleProgram: (id: number) => void
  setProgramChangeDate: (id: number, date: Date) => void
  programStartDate: (id: number) => Date
  programMinStartDate: (id: number) => Date
  isSelected: (id: number) => boolean
}
export const InviteUserProgramSelect = ({
  programs,
  isCountrySelected,
  programMinStartDate,
  toggleProgram,
  programStartDate,
  setProgramChangeDate,
  isSelected
}: InviteUserProgramSelectProps) => {
  const [showProgramOptions, setShowProgramOptions] = useState(false)

  const noProgramErrorText = useMemo(
    () =>
      !isCountrySelected
        ? 'A country must be selected first.'
        : 'No programs have been set up for this country',
    [isCountrySelected]
  )

  // Make sure showProgramOptions is toggled off if country selection changes
  useEffect(() => {
    setShowProgramOptions(false)
  }, [programs])

  return (
    <FlexColumn gap={spacingSM}>
      <StyledRow>
        Benefits
        <HoverIconCard
          iconSrc={AppIconMap[AppIcons.InfoCircle]}
          altText="Counted toward active user count"
          align="center"
        >
          <span>Benefits will begin on the selected dates for each program.</span>
        </HoverIconCard>
      </StyledRow>

      {!isCountrySelected || programs.length === 0 ? (
        <span style={{ fontWeight: '300' }}>{noProgramErrorText}</span>
      ) : (
        <BenefitRecipientCheckboxContainer>
          <Checkbox
            labelText="This user receives benefits"
            value={showProgramOptions}
            onClick={() => setShowProgramOptions(!showProgramOptions)}
          />
        </BenefitRecipientCheckboxContainer>
      )}

      {showProgramOptions && (
        <ProgramsContainer>
          <ProgramSelectionForm
            employerPrograms={programs}
            toggleProgram={toggleProgram}
            setProgramChangeDate={setProgramChangeDate}
            programStartDate={programStartDate}
            programMinStartDate={programMinStartDate}
            isSelected={isSelected}
          />
        </ProgramsContainer>
      )}
    </FlexColumn>
  )
}
