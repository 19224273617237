import { useMutation, useQueryClient } from 'react-query'
import { useErrorReporter } from 'Hooks/useErrorReporter'

import { QueryNames } from 'Hooks/api/queryNames'

import { AppNotification } from 'Utils/types/appNotification'
import { removeUserFromPrograms } from 'Services/benefitProgramService'
import { usePeoplePageContext } from 'Contexts/PeoplePageContext'
import { toast } from 'react-toastify'

export const useRemoveUserFromProgramQuery = () => {
  const queryClient = useQueryClient()
  const reportError = useErrorReporter()

  const { setModal, setActionableUserId } = usePeoplePageContext()

  return useMutation([QueryNames.RemoveUserFromProgram], removeUserFromPrograms, {
    onSuccess: (response) => {
      if (response.success) {
        // TODO: ideally we would avoid re-fetching users, but there's useful logic performed when fetching
        queryClient.invalidateQueries([QueryNames.UsersForEmployer])
        toast.success(AppNotification.RemoveUserFromProgramSuccess)
      } else {
        toast.error(AppNotification.RemoveUserFromProgramFailure)
      }

      setModal(undefined)
      setActionableUserId(undefined)
    },
    onError: (err: string) => {
      toast.error(AppNotification.RemoveUserFromProgramFailure)
      reportError(`useRemoveUserFromProgramQuery error: ${err}`)
    }
  })
}
