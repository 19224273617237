import { animated } from '@react-spring/web'
import { frostedGlass } from 'Utils/styles/colors'
import { Layer2 } from 'Utils/styles/zIndex'
import styled from 'styled-components'
import { StyledCircularProgress } from 'Components/CircularProgress/StyledCircularProgress'

const Background = styled.div`
  ${Layer2};
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${frostedGlass};
`

export const PageLoading = () => (
  <Background>
    <animated.div>
      <StyledCircularProgress />
    </animated.div>
  </Background>
)
