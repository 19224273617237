import { zirconGray } from 'Utils/styles/colors'
import { spacingLG, spacingSM, FlexColumn } from 'Utils/styles/spacing'
import styled from 'styled-components'

export const ProgramDefinitionSection = `
  grid-area: currentUsage;

  border-top: 1px ${zirconGray} solid;
  padding: ${spacingLG};
  display: grid;
  grid-row-gap: ${spacingSM};
  grid-auto-flow: row;
`

export const UsageCardSection = `
  grid-area: currentUsage;

  border-top: 1px ${zirconGray} solid;
  padding: ${spacingLG};
  display: grid;
  grid-row-gap: ${spacingSM};
  grid-template-rows: 104px auto 20px;
`
export const PastUsageCardSection = `
  grid-area: pastUsage;
  border-top: 1px ${zirconGray} solid;
  padding: ${spacingLG};
  display: grid;
  grid-row-gap: ${spacingSM};
  grid-template-rows: auto auto;
`

export const LinksContainer = styled.div`
  display: flex;
  justify-content: end;
  flex-direction: column;
  gap: ${spacingSM};
`

// slightly wider to accommodate past usage information
export const DescriptionContainer = styled(FlexColumn)`
  width: 103%;
`
