import { FieldPath, FieldValues, RegisterOptions, UseFormRegister } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import React, { CSSProperties } from 'react'
import styled from 'styled-components'

import { StyledInputLabel } from './StyledInputLabel'
import { StyledInput } from './StyledInput'
import { HoverIconCard } from 'Components/HoverIconCard'
import { LinkButton } from 'Components/ExternalLink/LinkButton'
import { InputHoverInfo } from 'Components/Inputs/types'

import { spacingXXS } from 'Utils/styles/spacing'
import { AppIconMap, AppIcons } from 'Utils/types/appIcons'

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacingXXS};
  flex: 1;
`

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

export type FormTextInputProps<
  TFormValues extends FieldValues,
  TFieldName extends keyof TFormValues
> = {
  fieldName: TFieldName
  register: UseFormRegister<TFormValues>
  registerOptions?: RegisterOptions<TFormValues>
  labelId?: string
  customOnFocus?: () => void
  customOnBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void
  showValidCheck?: boolean
  errorPresent?: boolean
  disabled?: boolean
  placeholder?: string
  defaultValue?: string
  value?: string
  type?: 'text' | 'password' | 'email'
  // Enables the info circle icon next to the label
  inputInformation?: InputHoverInfo
  style?: CSSProperties
  InputIcon?: React.ComponentType<any> // Add this line
}
export const FormTextInput = <
  TFormValues extends FieldValues,
  TFieldName extends keyof TFormValues
>({
  labelId,
  fieldName,
  register,
  registerOptions = {},
  defaultValue,
  value,
  placeholder,
  errorPresent,
  disabled,
  showValidCheck,
  customOnFocus,
  customOnBlur,
  inputInformation,
  type = 'text',
  style,
  InputIcon
}: FormTextInputProps<TFormValues, TFieldName>) => {
  // We can't use `FieldPath` for fieldName because `FieldPath` does not work with generics
  // Issue: https://github.com/react-hook-form/react-hook-form/issues/6726
  const { onChange, onBlur, name, ref } = register(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    fieldName as FieldPath<TFormValues>,
    registerOptions
  )

  const handleOnBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.value = event.target.value.trim()
    customOnBlur ? customOnBlur(event) : onBlur(event)
  }

  return (
    <InputContainer>
      {labelId && (
        <StyledInputLabel>
          <FormattedMessage id={labelId} />
          {/* Info icon that is hover-able with information about the input and a link */}
          {inputInformation && (
            <HoverIconCard
              altText={`${String(fieldName)} information`}
              iconSrc={AppIconMap[AppIcons.InfoCircle]}
            >
              <>
                <span>{inputInformation.cardText}</span>
                {inputInformation.linkText && inputInformation.linkHref && (
                  <LinkButton text={inputInformation.linkText} href={inputInformation.linkHref} />
                )}
              </>
            </HoverIconCard>
          )}
        </StyledInputLabel>
      )}
      <InputWrapper>
        {InputIcon && <InputIcon />}
        <StyledInput
          ref={ref}
          name={name}
          onChange={onChange}
          type={type}
          defaultValue={defaultValue}
          placeholder={placeholder}
          value={value}
          showValidCheck={showValidCheck}
          errorPresent={errorPresent ?? false}
          leftPadding={!!InputIcon}
          disabled={disabled}
          onFocus={customOnFocus ? customOnFocus : undefined}
          onBlur={handleOnBlur}
          style={style}
        />
      </InputWrapper>
    </InputContainer>
  )
}
