import { createContext, useContext, useReducer } from 'react'
import { EmployerBenefitProgram, MonthlyUtilizationData } from 'Utils/types/benefitProgram'
import { ContextProviderProps } from 'Contexts/ContextProvider'

export enum EmployerProgramsActionTypes {
  SetPrograms = 'SET_PROGRAMS',
  SetProgramsIncludingExpired = 'SET_PROGRAMS_INCLUDING_EXPIRED',
  ResetState = 'RESET_STATE',
  SetProgramUtilization = 'SET_PROGRAM_UTILIZATION',
  SetUtilizationLoading = 'SET_UTILIZATION_LOADING'
}
type Action =
  | { type: EmployerProgramsActionTypes.SetPrograms; value: EmployerBenefitProgram[] }
  | {
      type: EmployerProgramsActionTypes.SetProgramsIncludingExpired
      value: EmployerBenefitProgram[]
    }
  | { type: EmployerProgramsActionTypes.ResetState }
  | { type: EmployerProgramsActionTypes.SetProgramUtilization; value: MonthlyUtilizationData[] }
  | { type: EmployerProgramsActionTypes.SetUtilizationLoading; value: boolean }
type Dispatch = (action: Action) => void
type State = {
  programs: EmployerBenefitProgram[]
  allPrograms: EmployerBenefitProgram[]
  isUtilizationLoading: boolean
}

const DEFAULT_STATE = { programs: [], allPrograms: [], isUtilizationLoading: false }

const EmployerProgramsStateContext = createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined)

const userProgramReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case EmployerProgramsActionTypes.SetPrograms: {
      return { ...state, programs: action.value }
    }
    case EmployerProgramsActionTypes.SetProgramsIncludingExpired: {
      return { ...state, allPrograms: action.value }
    }
    case EmployerProgramsActionTypes.ResetState: {
      return DEFAULT_STATE
    }
    case EmployerProgramsActionTypes.SetProgramUtilization: {
      const programCopy = state.programs.map((program) => {
        const monthlyUtilization = action.value.find((util) => util.benefitProgramId === program.id)
          ?.monthlyUtilization

        if (!monthlyUtilization) return { ...program }

        return { ...program, monthlyUtilization }
      })

      return { ...state, programs: programCopy }
    }
    case EmployerProgramsActionTypes.SetUtilizationLoading: {
      return { ...state, isUtilizationLoading: action.value }
    }
    default: {
      throw new Error(`Invalid action type`)
    }
  }
}

const EmployerProgramsProvider = ({ children }: ContextProviderProps) => {
  const [state, dispatch] = useReducer(userProgramReducer, DEFAULT_STATE)
  const value = { state, dispatch }
  return (
    <EmployerProgramsStateContext.Provider value={value}>
      {children}
    </EmployerProgramsStateContext.Provider>
  )
}

const useEmployerProgramsContext = () => {
  const context = useContext(EmployerProgramsStateContext)
  if (context === undefined) {
    throw new Error('useEmployerPrograms must be used within an EmployerProgramsProvider')
  }
  return context
}

export { EmployerProgramsProvider, useEmployerProgramsContext }
