import { useCallback, useState } from 'react'

export const useToggled = (initialState = false) => {
  const [toggled, setToggled] = useState(initialState)

  const handleToggle = useCallback(() => setToggled((t) => !t), [])

  const resetToggle = useCallback(() => setToggled(initialState), [initialState])

  return {
    toggled,
    handleToggle,
    resetToggle
  }
}
