import React, { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import {
  DropdownContent,
  DropdownItem,
  DropdownRoot,
  DropdownTrigger
} from 'Components/DropdownMenu/DropdownMenu'

import { useJoonAdminContext } from 'Contexts/JOONAdmin/JOONAdminContext'
import { useUserInfoContext } from 'Contexts/UserInfoContext'

import { useSwitchActiveUser } from 'Hooks/useSwitchActiveUser'

import { electricViolet, white } from 'Utils/styles/colors'
import { getUserInitials } from 'Utils/helpers/userInfo'
import { PageRoutes } from 'Utils/types/pageRoutes'
import { getGlobalAdminRole, getJOONAdminRole } from 'Utils/helpers/user'
import { PurpleText } from 'Utils/styles/text'

const StyledAvatar = styled.div`
  display: inline-flex;
  color: ${white};
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  overflow: hidden;
  user-select: none;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  cursor: pointer;
  background-color: ${electricViolet};
`

type HeaderDropdownProps = {
  onEmployerViews?: boolean
}
export const HeaderDropdown: React.FC<HeaderDropdownProps> = ({ onEmployerViews = false }) => {
  const {
    state: { user, userRoles }
  } = useUserInfoContext()
  const {
    state: { ghostModeActive }
  } = useJoonAdminContext()
  const { exitGhostMode } = useSwitchActiveUser()
  const navigate = useNavigate()
  const location = useLocation()

  const userInitials = useMemo(() => {
    return getUserInitials(user?.userInfo.firstName, user?.userInfo.lastName)
  }, [user])

  const isAdmin = useMemo(() => {
    return !!getGlobalAdminRole(userRoles)
  }, [userRoles])

  const isJoonAdmin = useMemo(() => {
    return !!getJOONAdminRole(userRoles)
  }, [userRoles])

  return (
    <DropdownRoot>
      <DropdownTrigger>
        <StyledAvatar>{userInitials}</StyledAvatar>
      </DropdownTrigger>

      <DropdownContent $absolute>
        {isAdmin && (
          <DropdownItem
            onSelect={() =>
              navigate(onEmployerViews ? PageRoutes.UserDashboard : PageRoutes.EmployerDashboard)
            }
          >
            {onEmployerViews ? 'User Dashboard' : 'Admin'}
          </DropdownItem>
        )}
        <DropdownItem onSelect={() => navigate(PageRoutes.UserSettings)}>
          Account Settings
        </DropdownItem>
        {isJoonAdmin && (
          <DropdownItem
            onSelect={() => {
              if (ghostModeActive) {
                exitGhostMode()
              } else {
                navigate(PageRoutes.AdminEmployersList)
              }
            }}
          >
            <PurpleText>
              <b>{ghostModeActive ? 'Exit Ghost Mode' : 'JOON Admin'}</b>
            </PurpleText>
          </DropdownItem>
        )}
        <DropdownItem
          onSelect={() =>
            navigate(PageRoutes.Logout, {
              state: { showErrorMessage: false, previousLocation: location }
            })
          }
        >
          Logout
        </DropdownItem>
      </DropdownContent>
    </DropdownRoot>
  )
}
