import React, { CSSProperties, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { Box } from '@mui/system'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { Calendar } from 'react-date-range'
import { StyledInputLabel } from '../StyledInputLabel'

import { displayDate } from 'Utils/helpers/dateUtils'
import { elfGreen, red, zirconGray } from 'Utils/styles/colors'
import {
  CalendarButton,
  CalendarContainer,
  inlineCalendarCssOverride,
  InputDatePickerContainer,
  StyledInputWithPointer
} from './styles'

import { FormattedMessage } from 'react-intl'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { HoverIconCard } from 'Components/HoverIconCard'
import { AppIconMap, AppIcons } from 'Utils/types/appIcons'
import { InputHoverInfo } from 'Components/Inputs/types'
import { FlexColumn, spacingMD, spacingSM, spacingXXS } from 'Utils/styles/spacing'
import { Subtext } from 'Utils/styles/text'

const singleDateCSSOverrides = {
  ...inlineCalendarCssOverride,
  '.rdrCalendarWrapper': {
    boxShadow: 'rgba(99, 99, 99, 0.2) 0 2px 8px 0',
    border: `1px solid ${zirconGray}`
  }
}

const errorSingleDateCSSOverrides = {
  ...singleDateCSSOverrides,
  '.rdrCalendarWrapper': {
    ...singleDateCSSOverrides['.rdrCalendarWrapper'],
    border: `1px solid ${red}`
  }
}

const SubtextContainer = styled.div`
  padding-top: ${spacingMD};
  width: 75%;
  color: ${zirconGray};
  white-space: pre-line;
  vertical-align: sub;
  display: flex;
  gap: ${spacingSM};
  ${Subtext}
`

type FormDatePickerProps = {
  onChange: (date: Date) => void
  minDate?: Date
  date?: Date
  labelId?: string
  maxDate?: Date
  disabled?: boolean
  placeholder?: string
  // Enables the info circle icon next to the label
  inputInformation?: InputHoverInfo
  calenderSubText?: string
  hasError?: boolean
  style?: CSSProperties
  // Tracks the date the calendar points to by default
  shownDate?: Date
}

const isInViewport = (element: HTMLDivElement | null) => {
  if (!element) return false

  const rect = element.getBoundingClientRect()
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

export const FormDatePicker = ({
  minDate,
  date,
  onChange,
  maxDate,
  labelId,
  inputInformation,
  placeholder = 'Date',
  disabled = true,
  calenderSubText,
  hasError,
  style,
  shownDate
}: FormDatePickerProps) => {
  const [calendarOpen, setCalendarOpen] = useState(false)

  const handleChange = (date: Date) => {
    onChange(date)
    setCalendarOpen(false)
  }

  const calendarRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (calendarOpen && !isInViewport(calendarRef.current)) {
      calendarRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [calendarRef, calendarOpen])

  const formattedDate = useMemo(() => (date ? displayDate(date) : ''), [date])
  return (
    <ClickAwayListener onClickAway={() => setCalendarOpen(false)}>
      <CalendarContainer style={style}>
        <div ref={calendarRef} />
        <FlexColumn gap={spacingXXS}>
          {labelId && (
            <StyledInputLabel>
              <FormattedMessage id={labelId} />
              {/* Info icon that is hover-able with information about the input and a link */}
              {inputInformation && (
                <HoverIconCard
                  altText={`date input information`}
                  iconSrc={AppIconMap[AppIcons.InfoCircle]}
                >
                  <span>{inputInformation.cardText}</span>
                </HoverIconCard>
              )}
            </StyledInputLabel>
          )}
          <InputDatePickerContainer
            onClick={() => {
              setCalendarOpen((open) => !open && !disabled)
            }}
          >
            <StyledInputWithPointer
              placeholder={placeholder}
              value={formattedDate}
              errorPresent={hasError}
              disabled={disabled}
              readOnly
            />
            <CalendarButton />
          </InputDatePickerContainer>
        </FlexColumn>

        {calendarOpen && (
          <>
            <Box sx={hasError ? errorSingleDateCSSOverrides : singleDateCSSOverrides}>
              <Calendar
                months={shownDate ? 2 : 1}
                shownDate={shownDate}
                minDate={minDate}
                date={date}
                color={elfGreen}
                onChange={handleChange}
                maxDate={maxDate}
              />
            </Box>
            {calenderSubText && (
              <SubtextContainer>
                <div>*</div>
                <span>{calenderSubText}</span>
              </SubtextContainer>
            )}
          </>
        )}
      </CalendarContainer>
    </ClickAwayListener>
  )
}
