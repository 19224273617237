import { getRequest } from 'Services/baseRequests/getRequest'
import { RequestResponse } from 'Services/apiResponseTypes'
import { BenefitProgramCategory } from 'Utils/types/benefitProgramCategory'
import { postRequest } from 'Services/baseRequests/postRequest'
import { BenefitProgram } from 'Utils/types/benefitProgram'
import { formatDateForServer } from 'Utils/helpers/dateUtils'
import { Employer } from 'Utils/types/JOONAdmin/employer'
import { putRequest } from 'Services/baseRequests/putRequest'
import { PolicyPeriodDuration } from 'Utils/types/benefitProgramPolicy'
import {
  UpdateBenefitProgramPolicyApiPayload,
  EndBenefitProgramPolicyApiPayload
} from './adminApiResponseTypes'

export type GetBenefitProgramCategoriesResponse =
  RequestResponse<GetBenefitProgramCategoriesResponseData>
export type GetBenefitProgramCategoriesResponseData = {
  programCategories: BenefitProgramCategory[]
}
export const getBenefitProgramCategories =
  async (): Promise<GetBenefitProgramCategoriesResponse> => {
    const response = await getRequest<GetBenefitProgramCategoriesResponse>(
      `/basic_benefit_program_categories/list`
    )

    return {
      success: response?.success ?? false,
      message: response?.message ?? '',
      data: response?.data
        ? {
            programCategories: response.data.programCategories
          }
        : undefined
    }
  }

export type CreateBenefitProgramPayload = {
  employerId: Employer['id']
  name: string
  scheduledStartDate: Date
  scheduledEndDate?: Date
  allowance: string
  policyPeriod: PolicyPeriodDuration
  rollover: string
  ongoingPurchases: boolean
  ongoingPurchaseThreshold?: number
  countries: string[]
  benefitProgramLink: string
  selectedCategories: BenefitProgramCategory['id'][]
}

export type CreateBenefitProgramResponse = RequestResponse<CreateBenefitProgramResponseData>
export type CreateBenefitProgramResponseData = {
  benefitProgramId: BenefitProgram['id']
}

type CreateBenefitProgramApiPayload = {
  employer_id: Employer['id']
  benefit_program: {
    name: string
    benefit_program_link: string
    countries: string[]
    basic_benefit_program_category_ids: number[]
    benefit_program_policies_attributes: {
      scheduled_start_date: string
      scheduled_end_date?: string
      expenditure_allowance: string
      policy_period_duration: string
      rollover: string
      ongoing_purchases_enabled?: boolean
      ongoing_purchase_threshold?: number
    }
  }
}

export const createBenefitProgram = async ({
  employerId,
  name,
  scheduledStartDate,
  scheduledEndDate,
  allowance,
  policyPeriod,
  rollover,
  ongoingPurchases,
  ongoingPurchaseThreshold,
  benefitProgramLink,
  selectedCategories,
  countries
}: CreateBenefitProgramPayload): Promise<CreateBenefitProgramResponse> => {
  const payload = {
    employer_id: employerId,
    benefit_program: {
      name: name,
      benefit_program_link: benefitProgramLink,
      countries: countries,
      basic_benefit_program_category_ids: [...selectedCategories],
      benefit_program_policies_attributes: {
        scheduled_start_date: formatDateForServer(scheduledStartDate),
        scheduled_end_date: scheduledEndDate ? formatDateForServer(scheduledEndDate) : undefined,
        expenditure_allowance: allowance,
        policy_period_duration: policyPeriod,
        rollover: rollover,
        ongoing_purchases_enabled: ongoingPurchases ?? false,
        ongoing_purchase_threshold: ongoingPurchaseThreshold
      }
    }
  }
  const response = await postRequest<CreateBenefitProgramResponse, CreateBenefitProgramApiPayload>(
    '/admin/benefit_programs',
    payload
  )

  return {
    success: response?.success ?? false,
    message: response?.message ?? '',
    data: response?.data
  }
}

export type UpdateBenefitProgramPayload = {
  benefitProgramId: BenefitProgram['id']
} & Omit<CreateBenefitProgramPayload, 'employerId'>

export type UpdateBenefitProgramResponse = RequestResponse<UpdateBenefitProgramResponseData>
export type UpdateBenefitProgramResponseData = {
  benefitProgramId: BenefitProgram['id']
}

type UpdateBenefitProgramApiPayload = {
  benefit_program: {
    name: string
    benefit_program_link: string
    countries: string[]
    basic_benefit_program_category_ids: number[]
  }
}

export const updateBenefitProgram = async ({
  benefitProgramId,
  name,
  benefitProgramLink,
  selectedCategories,
  countries
}: UpdateBenefitProgramPayload): Promise<UpdateBenefitProgramResponse> => {
  const payload = {
    benefit_program: {
      name: name,
      benefit_program_link: benefitProgramLink,
      countries: countries,
      basic_benefit_program_category_ids: [...selectedCategories]
    }
  }

  const response = await putRequest<UpdateBenefitProgramResponse, UpdateBenefitProgramApiPayload>(
    `/admin/benefit_programs/${benefitProgramId}`,
    payload
  )

  return {
    success: response?.success ?? false,
    message: response?.message ?? '',
    data: response?.data
  }
}

export type UpdateBenefitProgramPolicyPayload = {
  benefitProgramId: number
  startDate: Date
  allowance: string
  ongoingPurchases: boolean
  rollover: boolean
  ongoingPurchaseThreshold: number
}

export const updateBenefitProgramPolicy = async ({
  benefitProgramId,
  startDate,
  allowance,
  rollover,
  ongoingPurchases,
  ongoingPurchaseThreshold
}: UpdateBenefitProgramPolicyPayload): Promise<RequestResponse> => {
  const response = await postRequest<RequestResponse, UpdateBenefitProgramPolicyApiPayload>(
    `/admin/benefit_programs/${benefitProgramId}/update_policy`,
    {
      start_date: formatDateForServer(startDate),
      expenditure_allowance: parseInt(allowance),
      rollover,
      ongoing_purchase: ongoingPurchases,
      threshold: ongoingPurchaseThreshold
    }
  )

  return {
    success: response?.success ?? false,
    message: response?.message ?? ''
  }
}

export type EndBenefitProgramPolicyPayload = {
  benefitProgramId: number
  endDate: Date
  deleteFuturePolicies: boolean
}

export const endBenefitProgramPolicy = async ({
  benefitProgramId,
  endDate,
  deleteFuturePolicies
}: EndBenefitProgramPolicyPayload) => {
  const response = await postRequest<RequestResponse, EndBenefitProgramPolicyApiPayload>(
    `/admin/benefit_programs/${benefitProgramId}/end_policy`,
    {
      end_date: formatDateForServer(endDate),
      delete_future_policies: deleteFuturePolicies
    }
  )

  return {
    success: response?.success ?? false,
    message: response?.message ?? ''
  }
}
