import { getRequest } from 'Services/baseRequests/getRequest'
import {
  GetUsersForEmployerApiResponse,
  GetUsersForEmployerResponse,
  InviteUserApiPayload,
  InviteUserPayload,
  RequestResponse,
  RemoveUserResponse,
  RemoveUserPayload,
  BulkInvitePayload,
  BulkUserUpdatePayload
} from '../apiResponseTypes'
import { formatInviteUserPayload, parseGetUsersForEmployerData } from 'Services/users/parsers'
import { postRequest } from 'Services/baseRequests/postRequest'
import { deleteRequest } from 'Services/baseRequests/deleteRequest'
import { putRequest } from 'Services/baseRequests/putRequest'
import { parseErrorResponse } from 'Services/baseRequests/shared'
import { UserStatus } from 'Utils/types/userStatus'
import { UserRole } from 'Utils/types/roles'
import { Order } from 'Utils/helpers/tables'
import { CountryCode } from 'Utils/types/countries'

export type GetUserForEmployerPayload = {
  employerId: number
  perPage: number
  pageNumber: number
  status?: UserStatus
  benefitProgramId?: number
  role?: UserRole
  searchText?: string
  sortBy?: string
  sortOrder?: Order
  country?: CountryCode
}

/**
 * Returns a list of the users for the given employer
 * @param payload
 */
export const getUsersForEmployer = async (
  payload: GetUserForEmployerPayload
): Promise<GetUsersForEmployerResponse> => {
  const {
    employerId,
    perPage,
    pageNumber,
    status,
    benefitProgramId,
    role,
    searchText,
    sortOrder,
    sortBy,
    country
  } = payload

  const response = await getRequest<GetUsersForEmployerApiResponse>(
    [
      `/employers/${employerId}/users/list_for_employer?`,
      `per_page=${perPage}`,
      `&page=${pageNumber}`,
      `&status=${status || ''}`,
      `&benefit_programs=${benefitProgramId || ''}`,
      `&country_code=${country || ''}`,
      `&role=${role || ''}`,
      `&search_text=${searchText || ''}`,
      `&sort_order=${sortOrder || ''}`,
      `&sort_by=${sortBy || ''}`
    ].join('')
  )

  return {
    statusCode: response.statusCode,
    success: response?.success ?? false,
    message: response?.message ?? '',
    data: response?.data ? parseGetUsersForEmployerData(response.data) : undefined,
    usersCount: response?.data?.total_records ?? 0
  }
}

/**
 * Invite a user to use JOON for given employer
 * @param payload The details of the user being invited
 */
export const inviteUser = async ({
  users,
  employerId
}: InviteUserPayload): Promise<RequestResponse> => {
  const response = await postRequest<RequestResponse, InviteUserApiPayload>(
    `/employers/${employerId}/users/invites/invite_multiple`,
    formatInviteUserPayload(users)
  )

  return {
    statusCode: response.statusCode,
    success: response?.success ?? false,
    message: response?.success ? '' : parseErrorResponse(response.data) ?? ''
  }
}

/**
 * Bulk invite users to JOON via .csv for given employer
 * @param payload The csv file containing user info for invites
 */
export const bulkInvite = async ({
  employerId,
  formData
}: BulkInvitePayload): Promise<RequestResponse> => {
  const response = await postRequest<RequestResponse, FormData>(
    `/employers/${employerId}/users/invites/bulk_invite`,
    formData
  )

  // TODO:
  // Success is true if invites resulted in any successful invites
  // API call returns and a 'results' array for successful invites
  // API call also returns errors: [email: [inviteErrors]] for failed invites
  // We may want to add some sort of notification?

  return {
    statusCode: response.statusCode,
    success: response?.success ?? false,
    message: response?.message ?? ''
  }
}

/**
 * Bulk update user meta data via .csv for given employer
 * @param payload The csv file containing user meta data for update
 */
export const bulkUpdateUsersMetaData = async ({
  employerId,
  formData
}: BulkUserUpdatePayload): Promise<RequestResponse> => {
  const response = await postRequest<RequestResponse, FormData>(
    `/employers/${employerId}/bulk_user_update`,
    formData
  )

  return {
    statusCode: response.statusCode,
    success: response?.success ?? false,
    message: response?.message ?? ''
  }
}

/**
 * Re-invites a user to use JOON for given employer
 * @param payload The userId of the user being re-invited
 */
export const reinviteUser = async (payload: { userId: number }): Promise<RequestResponse> => {
  const response = await postRequest<RequestResponse, { user_id: number }>(`/invites/reinvite`, {
    user_id: payload.userId
  })

  return {
    statusCode: response.statusCode,
    success: response?.success ?? false,
    message: response?.message ?? ''
  }
}

/**
 * Reset login attempt
 * @param payload The id of the user
 */
export const resetLogin = async (payload: { userId: number }): Promise<RequestResponse> => {
  const response = await postRequest<RequestResponse>(
    `/admin/employees/${payload.userId}/reset_login`
  )

  return {
    statusCode: response.statusCode,
    success: response?.success ?? false,
    message: response?.message ?? ''
  }
}

/**
 * Cancels the given invite if it is active
 * @param payload The invite being cancelled
 */
export const cancelUserInvite = async (payload: { userId: number }): Promise<RequestResponse> => {
  const response = await postRequest<RequestResponse, { user_id: number }>(`/invites/cancel`, {
    user_id: payload.userId
  })

  return {
    statusCode: response.statusCode,
    success: response?.success ?? false,
    message: response?.message ?? ''
  }
}

/**
 * Sets the user to REMOVED and updates everything as needed
 * @param payload The user being removed and the employer they are being removed from
 */
export const removeUser = async (payload: RemoveUserPayload): Promise<RemoveUserResponse> => {
  const response = await deleteRequest<RequestResponse>(
    `/employers/${payload.employerId}/users/${payload.userId}?end_time=${payload.endTime}`
  )

  return {
    statusCode: response.statusCode,
    userId: response?.data
  }
}

/**
 * Grants the given user the EmployerAdmin role.
 * @param payload The id of the user having permissions added.
 */
export const grantUserAdminPermissions = async (payload: {
  userId: number
}): Promise<RequestResponse> => {
  const response = await putRequest<RequestResponse>(
    `/admin/employees/${payload.userId}/add_administrative_permission`
  )

  return {
    statusCode: response.statusCode,
    success: response?.success ?? false,
    message: response?.message ?? ''
  }
}

/**
 * Revokes admin permissions from the given user.
 * @param payload The id of the user having permissions removed.
 */
export const revokeUserAdminPermissions = async (payload: {
  userId: number
}): Promise<RequestResponse> => {
  const response = await putRequest<RequestResponse>(
    `/admin/employees/${payload.userId}/remove_administrative_permission`
  )

  return {
    statusCode: response.statusCode,
    success: response?.success ?? false,
    message: response?.message ?? ''
  }
}
