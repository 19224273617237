import styled from 'styled-components'
import { createContext, FC, useContext, useRef, useState } from 'react'

import { Modal } from 'Components/Modal/Modal'
import { StyledButton } from 'Components/Buttons/StyledButton'
import { ModalHeader } from 'Components/Modal/ModalHeader'

import {
  ConfirmationModalContent,
  ConfirmationModalContextProviderProps,
  ModalContextType,
  useModalShow
} from 'Hooks/modals/useModalShow'

import { spacingSM } from 'Utils/styles/spacing'
import { ThinDarkGreenText } from 'Utils/styles/text'
import { FormDatePicker } from 'Components/Inputs/Calendar/FormDatePicker'
import { createDate } from 'Utils/helpers/dateUtils'

const ButtonContainer = styled.div`
  display: flex;
  gap: ${spacingSM};
  justify-content: end;
`

const UseDateSelectionModal = createContext<ModalContextType<Date | undefined>>(
  {} as ModalContextType<Date | undefined>
)

const DateSelectionModalProvider: FC<ConfirmationModalContextProviderProps> = (props) => {
  const { setShow, show, onHide } = useModalShow()

  const [selectedDate, setSelectedDate] = useState(createDate())
  const [content, setContent] = useState<ConfirmationModalContent | null>()
  const resolver = useRef<(value: Date | undefined | PromiseLike<Date | undefined>) => void>()

  const showModal = (
    title: string,
    message: string | JSX.Element,
    confirmButtonId = 'continue.label',
    cancelButtonId = 'cancel.label'
  ): Promise<Date | undefined> => {
    setContent({
      title,
      message,
      confirmButtonId,
      cancelButtonId
    })
    setShow(true)
    return new Promise((resolve) => {
      resolver.current = resolve
    })
  }

  const handleOk = () => {
    resolver.current && resolver.current(selectedDate)
    onHide()
  }

  const handleCancel = () => {
    resolver.current && resolver.current(undefined)
    onHide()
  }

  return (
    <UseDateSelectionModal.Provider
      value={{
        showModal
      }}
    >
      {props.children}

      {content && (
        <Modal showModal={show} setShowModal={onHide}>
          <ModalHeader>{content.title}</ModalHeader>

          <ThinDarkGreenText>{content.message}</ThinDarkGreenText>

          <FormDatePicker date={selectedDate} onChange={setSelectedDate} disabled={false} />

          <ButtonContainer>
            <StyledButton id={content.cancelButtonId} onClick={handleCancel} />
            <StyledButton id={content.confirmButtonId} primary onClick={handleOk} />
          </ButtonContainer>
        </Modal>
      )}
    </UseDateSelectionModal.Provider>
  )
}

const useDateSelectionModal = (): ModalContextType<Date | undefined> =>
  useContext(UseDateSelectionModal)

export { useDateSelectionModal }

export default DateSelectionModalProvider
