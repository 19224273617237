import { ErrorBoundary } from 'react-error-boundary'
import { ReactNode } from 'react'
import { toast } from 'react-toastify'
import { captureException } from '@sentry/react'

import { GeneralErrorPage } from 'Pages/GeneralErrorPage'
import { AppNotification } from 'Utils/types/appNotification'

type BasicErrorBoundaryProps = { children: ReactNode }
const BasicErrorBoundary = ({ children }: BasicErrorBoundaryProps) => (
  <ErrorBoundary
    FallbackComponent={GeneralErrorPage}
    onError={(error, extra) => {
      toast.error(AppNotification.GeneralError)
      captureException(error, { extra })
    }}
  >
    {children}
  </ErrorBoundary>
)

export default BasicErrorBoundary
