import { FC, useMemo, useState } from 'react'
import { Table, TableBody } from '@mui/material'
import styled from 'styled-components'
import { equals, sort } from 'ramda'

import { SortableTableHeader } from 'Components/Table/SortableTableHeader'
import { getInvoiceTableHeaderCells } from 'Pages/Employer/BillingPage/InvoiceTable/InvoiceTableHeader'
import { InvoiceDetailsModal } from 'Pages/Employer/BillingPage/InvoiceDetailsModal/InvoiceDetailsModal'
import { InvoiceTableActions } from 'Pages/Employer/BillingPage/InvoiceTable/InvoiceTableActions'
import { Placeholder } from 'Components/Placeholder/Placeholder'
import { TableContentPlaceholder } from 'Components/CircularProgress/TableContentPlaceholder'
import { Pagination } from 'Components/Table/Pagination'
import { StyledPaper, StyledTableContainer } from 'Components/Table/StyledTableComponents'
import { InvoiceRow } from 'Pages/Employer/BillingPage/InvoiceTable/InvoiceRow'

import { useTableOrder } from 'Hooks/useTableOrder'
import { useToggled } from 'Hooks/useToggled'
import { useGetInvoicesQuery } from 'Hooks/api/employer/invoices/useGetInvoicesQuery'
import { usePagination } from 'Hooks/usePagination'
import { useCountries } from 'Hooks/useCountries'

import { getComparator } from 'Utils/helpers/tables'
import { convertEmployerAdminInvoiceToTableRow } from 'Utils/helpers/invoice'
import { MediumBoldText } from 'Utils/styles/text'
import { InvoiceState, EmployerAdminInvoiceTableRow, InvoiceType } from 'Utils/types/invoice'
import { CountryCode } from 'Utils/types/countries'

import { useEmployerInfoContext } from 'Contexts/EmployerInfoContext'
import { useTableFilters } from 'Hooks/useTableFilters'

export type InvoiceTableFilters = {
  state: InvoiceState | ''
  paymentMethod: string
  countryCode: CountryCode | ''
}
const EMPTY_FILTERS: InvoiceTableFilters = {
  state: '',
  paymentMethod: '',
  countryCode: ''
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  position: relative;
`

const NoInvoices = () => <MediumBoldText>You have no invoices yet.</MediumBoldText>

type InvoiceTableProps = {
  isJoonAdmin?: boolean
}
export const InvoiceTable: FC<InvoiceTableProps> = ({ isJoonAdmin = false }) => {
  const {
    state: { employerId }
  } = useEmployerInfoContext()

  const { showCountries } = useCountries(isJoonAdmin)
  const { toggled: modalOpen, handleToggle: toggleModal } = useToggled()
  const [selectedInvoice, setSelectedInvoice] = useState<number | undefined>(undefined)

  const { order, orderBy, handleRequestSort } = useTableOrder<EmployerAdminInvoiceTableRow>()
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = usePagination(10)
  const { filters, updateFilters, clearFilters } =
    useTableFilters<InvoiceTableFilters>(EMPTY_FILTERS)

  const { isFetching, data } = useGetInvoicesQuery({
    pageNumber: page + 1,
    employerId,
    perPage: rowsPerPage
  })

  const totalRows = data?.data?.totalInvoices || 0

  const invoices = useMemo(() => data?.data?.invoices ?? [], [data?.data?.invoices])

  const allRows = useMemo(
    () => invoices?.map(convertEmployerAdminInvoiceToTableRow, employerId) ?? [],
    [invoices, employerId]
  )

  const filteredRows = useMemo(
    () =>
      allRows
        .filter((row) => !filters.state || row.state === filters.state)
        .filter((row) => !filters.paymentMethod || row.paymentMethod === filters.paymentMethod)
        .filter((row) => !filters.countryCode || row.countryCode === filters.countryCode),
    [allRows, filters.state, filters.paymentMethod, filters.countryCode]
  )

  const displayRows = useMemo(
    () => sort(getComparator(order, orderBy), filteredRows),
    [order, orderBy, filteredRows]
  )

  const hasInvoiceWithReimbursementFees = useMemo(
    () => invoices.filter((invoice) => invoice.benefitTransferFees > 0).length > 0,
    [invoices]
  )

  const hasPayrollReimbursementInvoice = useMemo(
    () => displayRows.filter((row) => row.type === InvoiceType.Payroll).length > 0,
    [displayRows]
  )

  if (!isFetching && totalRows == 0) {
    return <Placeholder imageUrl="/assets/shopping.svg" subtext={NoInvoices} />
  }

  const loadingContent = isFetching || employerId === undefined
  const showTablePlaceholder = loadingContent || (!isFetching && displayRows.length === 0)

  return (
    <StyledContainer>
      <InvoiceTableActions
        invoices={allRows}
        filters={filters}
        updateFilters={updateFilters}
        clearFilters={equals(filters, EMPTY_FILTERS) ? undefined : clearFilters}
        disabled={isFetching}
      />
      <StyledPaper>
        <StyledTableContainer>
          <Table>
            <SortableTableHeader
              headerCells={getInvoiceTableHeaderCells(
                showCountries,
                hasInvoiceWithReimbursementFees,
                hasPayrollReimbursementInvoice
              )}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {/* Show placeholder in table if we are still loading the invoices */}
              {showTablePlaceholder ? (
                <TableContentPlaceholder loading={loadingContent} />
              ) : (
                displayRows.map((row) => (
                  <InvoiceRow
                    key={row.id}
                    row={row}
                    toggleModal={toggleModal}
                    setSelected={() => setSelectedInvoice(row.id)}
                    showReimbursementFee={hasInvoiceWithReimbursementFees}
                    showType={hasPayrollReimbursementInvoice}
                    isJoonAdmin={isJoonAdmin}
                  />
                ))
              )}
            </TableBody>
          </Table>
        </StyledTableContainer>
        <Pagination
          optionText="invoices"
          totalRows={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />

        {selectedInvoice && (
          <InvoiceDetailsModal
            invoiceId={selectedInvoice}
            showModal={modalOpen}
            setShowModal={toggleModal}
          />
        )}
      </StyledPaper>
    </StyledContainer>
  )
}
