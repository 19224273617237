import { useCallback } from 'react'

import { useCancelUserInviteQuery } from './api/employer/userManagement/useCancelUserInviteQuery'
import { useResetLoginQuery } from './api/employer/userManagement/useResetLoginQuery'

import { useReinviteUserQuery } from './api/employer/userManagement/useReinviteUserQuery'
import { useDeleteUserStripeCustomerQuery } from './api/JOONAdmin/useDeleteUserStripeCustomerQuery'
import { useResetRenewDateQuery } from './api/JOONAdmin/useResetRenewDateQuery'

import { useConfirmationModalContext } from './modals/useConfirmationModal'

import { ModalType, usePeoplePageContext } from 'Contexts/PeoplePageContext'
import { UserStatus } from 'Utils/types/userStatus'

export const usePeopleActions = (userId: number, userStatus: UserStatus) => {
  const { setModal, setActionableUserId } = usePeoplePageContext()
  const { showModal } = useConfirmationModalContext()
  const cancelUserInviteQuery = useCancelUserInviteQuery()
  const resetLoginQuery = useResetLoginQuery()
  const reinviteUserQuery = useReinviteUserQuery()
  const deleteUserStripeCustomerQuery = useDeleteUserStripeCustomerQuery()
  const resetRenewDateQuery = useResetRenewDateQuery()

  const isUserInvited = [UserStatus.Invited, UserStatus.ScheduledInvite].includes(userStatus)
  const isUserEnabled = isUserInvited || userStatus === UserStatus.Enabled

  const onUserReinvite = useCallback(() => {
    reinviteUserQuery.mutate({ userId })
  }, [userId, reinviteUserQuery])

  // Delete Stripe user confirmation modal
  const onDeleteStripeCustomer = useCallback(async () => {
    const result = await showModal(
      'Delete Stripe Account',
      `User's Stripe account can only be deleted if they do not have any pending
      benefit transfers and have a zero (0) balance in their account.
      NOTE: After deletion, Stripe account information will no longer be available
      in Stripe dashboard.`,
      'delete.stripeCustomer.confirmation.confirm.button',
      'confirmation.cancel.button'
    )
    if (result && userId) {
      deleteUserStripeCustomerQuery.mutate({ userId })
    }
  }, [userId, deleteUserStripeCustomerQuery, showModal])

  // Cancel user invite confirmation modal
  const onCancelUserInvite = useCallback(async () => {
    const result = await showModal(
      'Cancel Invite',
      'If the invitation has already been sent, the link will no longer be valid. ' +
        'Otherwise, the invite will no longer be sent at the scheduled time.',
      'cancel.invite.confirmation.confirm.button',
      'confirmation.cancel.button'
    )
    if (result && userId) {
      cancelUserInviteQuery.mutate({ userId })
    }
  }, [userId, cancelUserInviteQuery, showModal])

  const onResetLogin = useCallback(async () => {
    const result = await showModal(
      'Reset Login',
      'Clear user login retry count?',
      'Confirm',
      'Cancel'
    )

    if (result && userId) {
      resetLoginQuery.mutate({ userId })
    }
  }, [userId, resetLoginQuery, showModal])

  // Reset renew date for Trueayer purchase account connections
  const onResetRenewDate = useCallback(async () => {
    const result = await showModal(
      'Reset Renew Date',
      "Resetting renew date will set user's Truelayer purchase account connection " +
        'renewal date to three(3) days from now.',
      'reset.purchaseAccountConnection.confirmation.cancel.button',
      'confirmation.cancel.button'
    )

    if (result && userId) {
      resetRenewDateQuery.mutate({ userId })
    }
  }, [userId, resetRenewDateQuery, showModal])

  const showUpdateUserCountryModal = useCallback(() => {
    setModal(ModalType.UpdateUserCountry)
    setActionableUserId(userId)
  }, [setModal, setActionableUserId, userId])

  const showChangeUserProgramModal = useCallback(() => {
    setModal(ModalType.ManageUserPrograms)
    setActionableUserId(userId)
  }, [setModal, setActionableUserId, userId])

  const showRemoveUserModal = useCallback(() => {
    setModal(ModalType.RemoveUser)
    setActionableUserId(userId)
  }, [setModal, setActionableUserId, userId])

  const showReactivateUserModal = useCallback(() => {
    setModal(ModalType.ReactivateUser)
    setActionableUserId(userId)
  }, [setModal, setActionableUserId, userId])

  const showChangeUserPermissionsModal = useCallback(() => {
    setModal(ModalType.ManageUserPermissions)
    setActionableUserId(userId)
  }, [setModal, setActionableUserId, userId])

  const showManageUserAllowancesModal = useCallback(() => {
    setModal(ModalType.ManageUserAllowances)
    setActionableUserId(userId)
  }, [setModal, setActionableUserId, userId])

  const showViewUserMembershipsModal = useCallback(() => {
    setModal(ModalType.ViewUserMemberships)
    setActionableUserId(userId)
  }, [setModal, setActionableUserId, userId])

  return {
    isUserInvited,
    isUserEnabled,
    onUserReinvite,
    onCancelUserInvite,
    onDeleteStripeCustomer,
    onResetRenewDate,
    onResetLogin,
    showChangeUserProgramModal,
    showRemoveUserModal,
    showReactivateUserModal,
    showChangeUserPermissionsModal,
    showUpdateUserCountryModal,
    showManageUserAllowancesModal,
    showViewUserMembershipsModal
  }
}
