import { createContext, useState, useContext } from 'react'
import { UserDetailsForEmployer } from 'Utils/types/user'
import { ContextProviderProps } from 'Contexts/ContextProvider'

export enum ModalType {
  InviteUser,
  BulkInvite,
  BulkUpdate,
  ManageUserPrograms,
  ManageUserPermissions,
  RemoveUser,
  ReactivateUser,
  UpdateUserCountry,
  ManageUserAllowances,
  ViewUserMemberships
}

type State = {
  modal?: ModalType
  actionableUserId?: number
}

export const PeoplePageContext = createContext<
  | {
      state: State
      setModal: (modalType?: ModalType) => void
      setActionableUserId: (userId?: number) => void
    }
  | undefined
>(undefined)

const PeoplePageProvider = ({ children }: ContextProviderProps) => {
  const [modal, setModal] = useState<ModalType | undefined>(undefined)
  const [actionableUserId, setActionableUserId] = useState<
    UserDetailsForEmployer['id'] | undefined
  >(undefined)

  return (
    <PeoplePageContext.Provider
      value={{ state: { modal, actionableUserId }, setModal, setActionableUserId }}
    >
      {children}
    </PeoplePageContext.Provider>
  )
}

const usePeoplePageContext = () => {
  const context = useContext(PeoplePageContext)
  if (context === undefined) {
    throw new Error('usePeoplePageContext must be used within a PeoplePageProvider')
  }
  return context
}

export { PeoplePageProvider, usePeoplePageContext }
