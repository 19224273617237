import { JSXElementConstructor, ReactNode, useMemo } from 'react'

export type ContextProviderProps<T = any> = { children: ReactNode; defaultState?: T }

type PropsWithRequiredChildren<P = unknown> = P & { children: ReactNode }

type Props = {
  components: Array<JSXElementConstructor<PropsWithRequiredChildren>>
  children: ReactNode
}

export const ContextProvider = (props: Props) => {
  const { components = [], children, ...remaining } = props

  const ContextTree = useMemo(() => {
    return components.reduceRight((acc, Comp) => {
      return <Comp {...remaining}>{acc}</Comp>
    }, children)
  }, [children, components, remaining])

  return <>{ContextTree}</>
}
