import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useConfirmationModalContext } from 'Hooks/modals/useConfirmationModal'
import { useCreateEmployerCountryQuery } from 'Hooks/api/JOONAdmin/useCreateEmployerCountryQuery'
import { useUpdateEmployerCountryQuery } from 'Hooks/api/JOONAdmin/useUpdateEmployerCountryQuery'
import { CountryCode, CountryNames } from 'Utils/types/countries'
import { ChargeFeeType, EmployerCountry } from 'Utils/types/employerInfo'
import { useJoonAdminContext } from 'Contexts/JOONAdmin/JOONAdminContext'

export type CreateCountryFormValues = {
  countryCode: CountryCode
  processingFee: number
  pricingDescription: string
  alternativeReimbursement: boolean
  chargeFeePer: ChargeFeeType
}

export const useCreateCountryForm = (closeModal: () => void, countryConfig?: EmployerCountry) => {
  const defaultValues: Partial<CreateCountryFormValues> = countryConfig
    ? {
        countryCode: countryConfig.countryCode,
        processingFee: countryConfig.processingFee,
        pricingDescription: countryConfig.pricingDescription || '',
        alternativeReimbursement: countryConfig.alternativeReimbursement,
        chargeFeePer: countryConfig.chargeFeePer
      }
    : {
        processingFee: 0,
        pricingDescription: '',
        alternativeReimbursement: false,
        chargeFeePer: ChargeFeeType.Invite
      }

  const { control, getValues, watch, handleSubmit } = useForm<CreateCountryFormValues>({
    defaultValues
  })

  const {
    state: { selectedEmployer }
  } = useJoonAdminContext()

  const { mutateAsync: createEmployerCountryQuery } = useCreateEmployerCountryQuery()
  const { mutateAsync: updateEmployercountryQuery } = useUpdateEmployerCountryQuery()

  const { showModal } = useConfirmationModalContext()

  const handleSubmission: SubmitHandler<CreateCountryFormValues> = async (data) => {
    if (!selectedEmployer?.id) return

    const countryName = CountryNames[getValues('countryCode')]
    const mutationTypeText = countryConfig ? 'Update' : 'Add'

    const result = await showModal(
      `${mutationTypeText} Country: ${countryName}`,
      `Are you sure you want to ${mutationTypeText.toLowerCase()} this country?`
    )

    if (result) {
      let response
      if (countryConfig) {
        response = await updateEmployercountryQuery({ ...data, employerId: selectedEmployer.id })
      } else {
        response = await createEmployerCountryQuery({ ...data, employerId: selectedEmployer.id })
      }

      if (response.success) closeModal()
    }
  }

  const handleError: SubmitErrorHandler<CreateCountryFormValues> = (error) => {
    toast.error(
      error.processingFee?.message ??
        error.pricingDescription?.message ??
        error.countryCode?.message
    )
  }

  const registerOptions = {
    countryCode: { required: 'A country selection is required' }
  }

  return {
    control,
    registerOptions,
    watch,
    handleSubmit,
    handleSubmission,
    handleError
  }
}
