import { useCallback, useState, useMemo, useEffect } from 'react'
import max from 'date-fns/max'
import { EmployerBenefitProgram } from 'Utils/types/benefitProgram'
import { CountryCode } from 'Utils/types/countries'
import { findLatestInvoiceDate } from 'Utils/helpers/invoice'
import { firstOfFollowingMonth, firstOfMonth, createDate } from 'Utils/helpers/dateUtils'

import { useEmployerInfoContext } from 'Contexts/EmployerInfoContext'

export type ProgramChangeDetails = {
  id: number
  dateOfChange: Date
  enabled: boolean
  minDateOfChange: Date
}

export type UserProgram = {
  id: number
  membershipStartDate: Date
  membershipEndDate: Date | undefined
}

const convertToProgramChangeDetails = (
  programs: EmployerBenefitProgram[],
  latestInvoiceDate: Date | null,
  userPrograms?: UserProgram[]
): ProgramChangeDetails[] => {
  const today = createDate()

  return programs.map((p) => {
    const enabled = userPrograms ? !!userPrograms.find((up) => up.id === p.id) : false
    let minDateOfChange = today
    let defaultDateOfChange = today

    if (enabled) {
      // If user is being removed from a program, set min date to last invoiced day
      // If no invoice exist, set to program start day
      minDateOfChange = latestInvoiceDate ? latestInvoiceDate : p.policy.scheduledStartDate
    } else {
      // Earliest invite date will be the first of the most recently invoiced month
      // or first of the current month
      const earliestInviteDate = latestInvoiceDate
        ? firstOfFollowingMonth(latestInvoiceDate)
        : firstOfMonth()
      // If no invoice exists, set as the latest between the earliest invite date and start date
      minDateOfChange = max([earliestInviteDate, p.policy.scheduledStartDate])
      // The default should, however, be no earlier than the beginning of this month.
      defaultDateOfChange = max([firstOfMonth(), minDateOfChange])
    }

    return {
      id: p.id,
      dateOfChange: defaultDateOfChange,
      enabled,
      minDateOfChange
    }
  })
}

export const useUpdateUserProgram = (
  programs: EmployerBenefitProgram[],
  countryFilter?: CountryCode,
  userPrograms?: UserProgram[]
) => {
  const {
    state: { invoices }
  } = useEmployerInfoContext()

  const latestInvoiceDate = useMemo(() => findLatestInvoiceDate(invoices), [invoices])

  const filteredPrograms = useMemo(() => {
    if (!countryFilter) return programs

    return programs.filter((program) => program.countries.includes(countryFilter))
  }, [countryFilter, programs])

  const programChangeDetails = useMemo(
    () => convertToProgramChangeDetails(filteredPrograms, latestInvoiceDate, userPrograms),
    [filteredPrograms, userPrograms, latestInvoiceDate]
  )

  const [programChanges, setProgramChanges] = useState(programChangeDetails)

  useEffect(() => {
    setProgramChanges(programChangeDetails)
  }, [programChangeDetails])

  const getAssignedProgram = useCallback(
    (programId: number) => {
      const updatedAssignedPrograms = [...programChanges]
      const programIndex = updatedAssignedPrograms.findIndex((program) => program.id === programId)
      return { program: updatedAssignedPrograms[programIndex], updatedAssignedPrograms }
    },
    [programChanges]
  )

  const toggleProgram = useCallback(
    (programId: number) => {
      const { program, updatedAssignedPrograms } = getAssignedProgram(programId)

      if (program) {
        program.enabled = !program.enabled
        setProgramChanges(updatedAssignedPrograms)
      }
    },
    [getAssignedProgram]
  )

  const setProgramChangeDate = useCallback(
    (programId: number, date: Date) => {
      const { program, updatedAssignedPrograms } = getAssignedProgram(programId)

      if (program) {
        program.dateOfChange = date
        setProgramChanges(updatedAssignedPrograms)
      }
    },
    [getAssignedProgram]
  )

  const programStartDate = useCallback(
    (programId: number) => {
      const { program } = getAssignedProgram(programId)

      return program?.dateOfChange
    },
    [getAssignedProgram]
  )

  const programMinStartDate = useCallback(
    (programId: number) => {
      const { program } = getAssignedProgram(programId)

      return program?.minDateOfChange
    },
    [getAssignedProgram]
  )

  const isSelected = useCallback(
    (programId: number) => {
      const { program } = getAssignedProgram(programId)

      return program?.enabled
    },
    [getAssignedProgram]
  )

  return {
    toggleProgram,
    setProgramChangeDate,
    programStartDate,
    programMinStartDate,
    isSelected,
    programChanges,
    filteredPrograms
  }
}
