import { CategoryChips } from 'Components/Chip/CategoryChips'
import { CountryChips } from 'Components/Chip/CountryChips'
import { LinkButton } from 'Components/ExternalLink/LinkButton'
import { Modal } from 'Components/Modal/Modal'
import { TextSpan } from 'Components/TextSpan'
import { ModalHeader } from './ModalHeader'

import {
  UserBenefitProgram,
  EmployerBenefitProgram,
  isEmployerBenefitProgram
} from 'Utils/types/benefitProgram'
import { fontSizeSM, fontWeightThin } from 'Utils/styles/text'
import { FlexColumn, spacingLG, spacingXXS } from 'Utils/styles/spacing'
import { useCountries } from 'Hooks/useCountries'

export const ProgramDetailsModal = ({
  showModal,
  setShowModal,
  program
}: {
  showModal: boolean
  setShowModal: (a: boolean) => void
  program: UserBenefitProgram | EmployerBenefitProgram
}) => {
  const { hasMultipleCountries } = useCountries()

  return (
    <Modal showModal={showModal} setShowModal={setShowModal} maxWidth={500}>
      <FlexColumn gap={spacingLG}>
        <ModalHeader>{program.name}</ModalHeader>
        <FlexColumn gap={spacingXXS}>
          <TextSpan fontWeight={fontWeightThin} fontSize={fontSizeSM} lineHeight={160}>
            All eligible subcategories:
          </TextSpan>
          <CategoryChips program={program} showAll />
        </FlexColumn>
        {isEmployerBenefitProgram(program) && hasMultipleCountries && (
          <FlexColumn>
            <TextSpan fontWeight={fontWeightThin} fontSize={fontSizeSM} lineHeight={160}>
              All eligible countries:
            </TextSpan>
            <CountryChips id={program.id} countries={program.countries} showAll />
          </FlexColumn>
        )}
        <LinkButton text={'See guide'} href={program.programDescriptionLink} />
      </FlexColumn>
    </Modal>
  )
}
