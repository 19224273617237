import { SelectOption } from 'Components/Inputs/StyledSelect'
import subMonths from 'date-fns/subMonths'
import { firstOfMonth, lastOfMonth, formatDateForServer } from './dateUtils'
import { createMonthYearOption } from './reports'

// This will be obsolete after January 2025
const CUTOFF_DATE = new Date(2023, 0)

export const getBenefitProgramReceiptDownloadOptions = (policyStartDate: Date): SelectOption[] => {
  const latestReportableDate = policyStartDate < CUTOFF_DATE ? CUTOFF_DATE : policyStartDate
  const options: SelectOption[] = []

  let reportDate = new Date()

  // Only provide up to the trailing 12 months
  for (let i = 0; i < 12; i++) {
    if (reportDate < latestReportableDate) break

    options.push(createMonthYearOption(reportDate, i === 0))
    reportDate = subMonths(reportDate, 1)
  }

  return options
}

// Requires startDate && endDate of month in string format
// Ex. startDate: "2023-09-01" && endDate: "2023-09-30"
export const parseBenefitProgramDownloadOption = (selectedOption: string) => {
  const [month, year] = selectedOption.split('-')
  const selectedDate = new Date(parseInt(year), parseInt(month))

  const startDate = formatDateForServer(firstOfMonth(selectedDate))
  const endDate = formatDateForServer(lastOfMonth(selectedDate))

  return { startDate, endDate }
}
