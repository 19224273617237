import { ChangeEvent, useCallback, useMemo, useState } from 'react'
import * as React from 'react'

export const usePagination = (rowsPerPageDefault = 10) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageDefault)

  const handleChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
      setPage(page)
    },
    []
  )

  const handleChangeRowsPerPage = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value))
    setPage(0)
  }, [])

  return useMemo(
    () => ({ page, rowsPerPage, handleChangePage, handleChangeRowsPerPage }),
    [handleChangePage, handleChangeRowsPerPage, page, rowsPerPage]
  )
}
