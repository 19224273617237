import { useMutation, useQueryClient } from 'react-query'
import { useErrorReporter } from 'Hooks/useErrorReporter'
import { QueryNames } from 'Hooks/api/queryNames'
import { bulkUpdateUsersMetaData } from 'Services/users/userService'
import { toast } from 'react-toastify'
import { AppNotification } from 'Utils/types/appNotification'

export const useCsvUpdateQuery = () => {
  const reportError = useErrorReporter()
  const queryClient = useQueryClient()

  return useMutation([QueryNames.BulkUpdate], bulkUpdateUsersMetaData, {
    onSuccess: (response) => {
      if (response.success) {
        toast.success(AppNotification.CsvUserUpdateMetaDataSuccess)
      } else {
        toast.error(
          `${AppNotification.CsvUserUpdateMetaDataFailure} ${
            response.message ? response.message + '.' : ''
          }`
        )
      }
    },
    onError: (err: string) => {
      reportError(`useCsvUpdateQuery error: ${err}`)
    },
    onSettled: () => {
      queryClient.invalidateQueries([QueryNames.UsersForEmployer])
    }
  })
}
