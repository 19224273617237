import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useEmployerProgramsContext } from 'Contexts/EmployerProgramsContext'
import { FlexColumn, FlexRow, spacingSM } from 'Utils/styles/spacing'
import { JoonAdminProgramDetailsCard } from 'Pages/JOONAdmin/EmployerDetailsPage/Views/BenefitPrograms/JoonAdminProgramDetailsCard'
import { TableLabel } from 'Utils/styles/text'
import { StyledButton } from 'Components/Buttons/StyledButton'
import { CreateBenefitProgramModal } from 'Pages/JOONAdmin/EmployerDetailsPage/Views/BenefitPrograms/CreateBenefitProgramModal/CreateBenefitProgramModal'
import { EmployerBenefitProgram } from 'Utils/types/benefitProgram'

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); // Three equally spaced columns
  gap: ${spacingSM};
`

export const JoonAdminBenefitProgramsGrid: FC = () => {
  const {
    state: { allPrograms }
  } = useEmployerProgramsContext()

  const [selectedProgram, setSelectedProgram] = useState<EmployerBenefitProgram | undefined>(
    undefined
  )

  const [showCreateProgramModal, setShowCreateProgramModal] = useState(false)

  useEffect(() => {
    // Clear selected program when closing modal
    if (!showCreateProgramModal) {
      setSelectedProgram(undefined)
    }
  }, [showCreateProgramModal])

  return (
    <>
      <FlexColumn gap={spacingSM}>
        <FlexRow style={{ justifyContent: 'space-between' }}>
          <TableLabel>Benefit Programs</TableLabel>
          <StyledButton
            id="createBenefitProgram.button.label"
            primary
            onClick={() => setShowCreateProgramModal(true)}
          />
        </FlexRow>
        <GridContainer>
          {allPrograms.map((program) => (
            <JoonAdminProgramDetailsCard
              key={program.id}
              program={program}
              onClick={() => {
                setSelectedProgram(program)
                setShowCreateProgramModal(true)
              }}
            />
          ))}
        </GridContainer>
      </FlexColumn>
      <CreateBenefitProgramModal
        showModal={showCreateProgramModal}
        setShowModal={setShowCreateProgramModal}
        selectedProgram={selectedProgram}
      />
    </>
  )
}
