import { FC } from 'react'
import styled from 'styled-components'
import { TableRow, IconButton } from '@mui/material'

import { PeopleDetailsRow } from 'Pages/JOONAdmin/PeoplePage/PeopleDetailsRow'
import { StyledTableCell } from 'Components/Table/StyledTableCell'
import { CountryFlagIcon } from 'Components/Icons/CountryFlagIcon'
import { ProgramsList } from './ProgramsList'
import { PermissionsTags } from './PermissionsTags'
import { UserStatusLabel } from './UserStatusLabel'
import { PeopleActionsTableCell } from './PeopleActionsTableCell'
import { CollapsibleIcon } from 'Components/Icons/CollapsibleIcon'

import { useCountries } from 'Hooks/useCountries'
import { useToggled } from 'Hooks/useToggled'
import { useSwitchActiveUser } from 'Hooks/useSwitchActiveUser'

import { PeopleAction, getActionIcon } from 'Components/Label/ActionLabel'
import { PeopleTableRow } from 'Utils/types/user'
import { FlexRow } from 'Utils/styles/spacing'

const ActionItems = styled(FlexRow)`
  align-items: center;
  justify-content: space-evenly;
`

type PeopleRowProps = {
  row: PeopleTableRow
  isJoonAdmin?: boolean
}

export const PeopleRow: FC<PeopleRowProps> = ({ row, isJoonAdmin = false }) => {
  const { showCountries } = useCountries(isJoonAdmin)
  const { toggled, handleToggle } = useToggled()
  const { enterGhostMode } = useSwitchActiveUser()

  return (
    <>
      <TableRow>
        {isJoonAdmin && <StyledTableCell>{row.id}</StyledTableCell>}
        <StyledTableCell>{row.firstName}</StyledTableCell>
        <StyledTableCell>{row.lastName}</StyledTableCell>
        <StyledTableCell>{row.email}</StyledTableCell>
        <StyledTableCell>
          <ProgramsList programNames={row.programs} />
        </StyledTableCell>
        <StyledTableCell>
          <PermissionsTags userRoles={row.roles ?? []} />
        </StyledTableCell>
        <StyledTableCell>
          <UserStatusLabel user={row} />
        </StyledTableCell>
        {showCountries && (
          <StyledTableCell>
            <CountryFlagIcon text={row.countryCode} countryCode={row.countryCode} />
          </StyledTableCell>
        )}
        {isJoonAdmin ? (
          // Only Joon admins need to access to collapsible
          <StyledTableCell>
            <ActionItems>
              <CollapsibleIcon
                toggle={handleToggle}
                toggled={toggled}
                label="expand user details"
              />
              <IconButton
                aria-label={'ghost mode'}
                size="medium"
                onClick={() => {
                  enterGhostMode(row.id)
                }}
              >
                {getActionIcon(PeopleAction.ImpersonateUser)}
              </IconButton>
            </ActionItems>
          </StyledTableCell>
        ) : (
          <PeopleActionsTableCell
            userId={row.id}
            inviteId={row.inviteId}
            userStatus={row.status}
            roles={row.roles}
          />
        )}
      </TableRow>
      {/* Collapsible */}
      {isJoonAdmin && (
        <PeopleDetailsRow
          userId={row.id}
          userStatus={row.status}
          userPrograms={row.programs}
          open={toggled}
        />
      )}
    </>
  )
}
