import { createContext, useContext, useReducer } from 'react'
import { UserBenefitProgram } from 'Utils/types/benefitProgram'
import { ContextProviderProps } from 'Contexts/ContextProvider'

export enum UserProgramActionTypes {
  SetPrograms = 'SET_PROGRAM',
  ResetState = 'RESET_STATE'
}
type Action =
  | { type: UserProgramActionTypes; value: UserBenefitProgram[] }
  | { type: UserProgramActionTypes.ResetState }
type Dispatch = (action: Action) => void
type State = { programs: UserBenefitProgram[] }

export const USER_PROGRAM_DEFAULT_STATE: { programs: UserBenefitProgram[] } = { programs: [] }

const UserProgramStateContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(
  undefined
)

const userProgramReducer = (state: State, action: Action) => {
  switch (action.type) {
    case UserProgramActionTypes.SetPrograms: {
      return { programs: action.value }
    }
    case UserProgramActionTypes.ResetState: {
      return USER_PROGRAM_DEFAULT_STATE
    }
    default: {
      throw new Error('Invalid action type')
    }
  }
}

const UserProgramProvider = ({ children }: ContextProviderProps) => {
  const [state, dispatch] = useReducer(userProgramReducer, USER_PROGRAM_DEFAULT_STATE)
  const value = { state, dispatch }
  return (
    <UserProgramStateContext.Provider value={value}>{children}</UserProgramStateContext.Provider>
  )
}

const useUserProgramContext = () => {
  const context = useContext(UserProgramStateContext)
  if (context === undefined) {
    throw new Error('useUserProgram must be used within an UserProgramProvider')
  }
  return context
}

export { UserProgramProvider, useUserProgramContext }
