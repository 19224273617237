import { Allowance } from 'Utils/types/allowance'
import { parseServerDate, sameDay } from './dateUtils'

export enum AllowanceStatus {
  Active = 'Active',
  Exhausted = 'Exhausted',
  Expired = 'Expired',
  NotActiveYet = 'Not Active Yet'
}

export type UserAllowanceRow = {
  id: number
  initial: number
  remaining: number
  start: string
  end: string
  reason: string
  status: string
  allowance: Allowance
}

export const getAllowanceStatus = (allowance: Allowance): AllowanceStatus => {
  const start = parseServerDate(allowance.activeAt)
  let end: Date | undefined

  if (allowance.inactiveAt !== null) {
    end = parseServerDate(allowance.inactiveAt)
    end.setUTCHours(23, 59, 59, 999)
  }

  const now = new Date()

  if (now > start || sameDay(now, start)) {
    if (allowance.remainingAmount === 0) return AllowanceStatus.Exhausted
    if (!end || now <= end) return AllowanceStatus.Active

    return AllowanceStatus.Expired
  }

  return AllowanceStatus.NotActiveYet
}

export const convertAllowanceToTableRow = (allowance: Allowance): UserAllowanceRow => {
  const status = getAllowanceStatus(allowance)

  return {
    allowance,
    id: allowance.id,
    initial: allowance.initialAmount,
    remaining: allowance.remainingAmount,
    start: allowance.activeAt,
    end: allowance.inactiveAt,
    reason: allowance.reasonGranted,
    status
  }
}
