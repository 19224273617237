import styled from 'styled-components'
import { frostedGlass, mintGreen } from 'Utils/styles/colors'

export const StyledCircularProgress = styled.div`
  @keyframes dashboardBravoLoading {
    100% {
      transform: rotate(360deg);
    }
  }

  display: flex;
  align-items: center;
  justify-content: center;
  animation: dashboardBravoLoading 2s ease-in-out infinite;

  &:before {
    content: '';
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    border-top-right-radius: 20px;
    background: ${mintGreen};
  }

  &:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 15px;
    background: ${frostedGlass};
  }
`
