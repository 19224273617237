import { PeopleTableRow, UserDetailsForEmployer } from 'Utils/types/user'
import { UserStatus } from 'Utils/types/userStatus'
import { UserRole } from 'Utils/types/roles'
import { endOfToday } from 'date-fns'
import { createDate, sameDay } from './dateUtils'
import { Memberships } from 'Utils/types/benefitProgram'

export const convertUserToTableRow = (
  user: UserDetailsForEmployer,
  activeProgramIDs: Set<number>
): PeopleTableRow => {
  const lastDate = lastMembershipEndDate(user)
  const today = createDate()
  const inviteSent = user.invitedAt < today || sameDay(user.invitedAt, today)
  return {
    id: user.id,
    inviteId: user.inviteId,
    firstName: user.userInfo.firstName,
    lastName: user.userInfo.lastName,
    email: user.userInfo.email,
    status: modifiedStatus({ user, lastMembershipEndDate: lastDate, inviteSent }),
    programs: activeMembershipsInActivePrograms(user.activeMemberships, activeProgramIDs),
    roles: user.roles ?? [],
    inviteSent,
    inviteSendAt: user.inviteSendAt,
    lastMembershipEndDate: lastDate,
    countryCode: user.countryCode
  }
}

const today = endOfToday()

export const modifiedStatus = ({
  user,
  lastMembershipEndDate,
  inviteSent
}: {
  user: UserDetailsForEmployer
  lastMembershipEndDate?: Date
  inviteSent: boolean
}): UserStatus => {
  if (!user.status) return UserStatus.Enabled

  let modifiedStatus = user.status

  if (user.status === UserStatus.Invited && !inviteSent) modifiedStatus = UserStatus.ScheduledInvite
  if (user.status === UserStatus.Removed && lastMembershipEndDate && today < lastMembershipEndDate)
    modifiedStatus = UserStatus.ScheduledRemoval

  return modifiedStatus
}

// Filter the list of program memberships to the programs that have active policies
const activeMembershipsInActivePrograms = (
  activeMemberships: Memberships[] | undefined,
  activeProgramIDs: Set<number>
) => {
  if (!activeMemberships) return []

  return activeMemberships
    .filter((activeMembership) => {
      return activeProgramIDs.has(activeMembership.programId)
    })
    .map((activeMembership) => {
      return activeMembership.programName ?? ''
    })
}

const lastMembershipEndDate = (user: UserDetailsForEmployer) => {
  const futureMembershipEndDates = user.futureMemberships?.map((m) => m.membershipEndDate) || []
  const activeMembershipsEndDates = user.activeMemberships?.map((m) => m.membershipEndDate) || []
  let lastDate: Date | undefined = undefined
  for (const date of activeMembershipsEndDates.concat(futureMembershipEndDates)) {
    if (!date) {
      lastDate = undefined
      break
    }

    if (!lastDate || date > lastDate) lastDate = date
  }

  return lastDate
}

export const getGlobalAdminRole = (roles: UserRole[]) => {
  return roles.find((r) => r === UserRole.EMPLOYERADMIN || r === UserRole.JOONENGINEER)
}

export const getJOONAdminRole = (roles: UserRole[]) => {
  return roles.find((r) => r === UserRole.JOONENGINEER)
}

export const isEmployerAdminOnly = (roles: UserRole[] = []) => {
  return roles.includes(UserRole.EMPLOYERADMIN) && !roles.includes(UserRole.JOONENGINEER)
}
