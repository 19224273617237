import { SelectOption } from 'Components/Inputs/StyledSelect'
import { displayMonthAndYear } from './dateUtils'
import subMonths from 'date-fns/subMonths'
import subDays from 'date-fns/subDays'
import lastDayOfMonth from 'date-fns/lastDayOfMonth'

// This will be obsolete after January 2025
const CUTOFF_DATE = new Date(2023, 0)

export const createMonthYearOption = (date: Date, isLatestMonth: boolean) => {
  return {
    value: `${date.getMonth()}-${date.getFullYear()}`,
    label: isLatestMonth ? 'Current month to date' : displayMonthAndYear(date)
  }
}

export const getutilizationReportOptions = (earliestInvoiceDate: Date): SelectOption[] => {
  const latestReportableDate = earliestInvoiceDate < CUTOFF_DATE ? CUTOFF_DATE : earliestInvoiceDate
  const options: SelectOption[] = []

  let reportDate = new Date()

  // Only provide up to the trailing 24 months
  for (let i = 0; i < 24; i++) {
    if (reportDate < latestReportableDate) break

    options.push(createMonthYearOption(reportDate, i === 0))
    reportDate = subMonths(reportDate, 1)
  }

  return options
}

export const getBROReportRequestOptions = (selectedDate: string) => {
  const [month, year] = selectedDate.split('-')
  const now = new Date()
  const date = new Date(Date.UTC(Number(year), Number(month) + 1))

  const reportDate = date < now ? lastDayOfMonth(date) : subDays(now, 1)

  return {
    day: reportDate.getDate(),
    month: reportDate.getMonth() + 1,
    year: reportDate.getFullYear()
  }
}
