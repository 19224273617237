import { FC, ReactNode } from 'react'
import * as Accordion from '@radix-ui/react-accordion'
import styled from 'styled-components'

import { PlusIcon, MinusIcon } from '@radix-ui/react-icons'

import { spacingXS, spacingSM, FlexColumn } from 'Utils/styles/spacing'
import { mineralGreen, white, elfGreen, zirconGray, frostedGlass } from 'Utils/styles/colors'
import { fontSizeXS, fontWeightSlightlyBold } from 'Utils/styles/text'

export const AccordionActionItem = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  vertical-align: middle;
  cursor: pointer;
  border: none;

  width: 100%;
  height: 32px;
  gap: ${spacingSM};
  padding: 0 ${spacingXS};

  font-size: ${fontSizeXS};
  color: ${mineralGreen};
  background-color: ${white};

  &:hover:enabled {
    outline: none;
    background-color: ${elfGreen};
    color: ${white};
  }
  &:disabled {
    color: ${zirconGray};
    cursor: initial;
  }
`

export const AccordionHeader = styled(FlexColumn)`
  background-color: ${frostedGlass};
  padding: ${spacingXS};
  align-items: center;

  font-weight: ${fontWeightSlightlyBold};
`

const StyledAccordionTrigger = styled(Accordion.Trigger)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  cursor: pointer;
  border: none;
  padding-bottom: 1px;

  width: 100%;
  height: 32px;
  gap: ${spacingSM};

  font-size: ${fontSizeXS};
  color: ${mineralGreen};
  background-color: ${white};

  .accordion-plus,
  .accordion-minus {
    margin-left: auto;
  }

  &[data-state='open'] {
    padding-bottom: 0;
    border-bottom: 1px solid ${elfGreen};
    box-shadow: rgba(99, 99, 99, 0.2) 0 4px 2px 0;

    .accordion-plus {
      display: none;
    }
  }

  &[data-state='closed'] {
    .accordion-minus {
      display: none;
    }
  }

  &:hover {
    padding-bottom: 0;
    border-bottom: 1px solid ${elfGreen};
  }
`

export const AccordionTrigger: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <StyledAccordionTrigger>
      {children}
      <PlusIcon className="accordion-plus" />
      <MinusIcon className="accordion-minus" />
    </StyledAccordionTrigger>
  )
}

export const AccordionContent = styled(Accordion.Content)`
  &[date-state='open] {
    background-color: yellow;
  }
`

export const AccordionRoot = styled(Accordion.Root)`
  border-radius: 8px;
  border: 1px solid rgba(224, 224, 224, 1);
  box-shadow: none;
  width: 240px;
  overflow: hidden;
  height: fit-content;
`

export const AccordionItem = styled(Accordion.Item)`
  border-top: 1px solid rgba(224, 224, 224, 1);
`
