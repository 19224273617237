import lastDayOfMonth from 'date-fns/lastDayOfMonth'
import isSameMonth from 'date-fns/isSameMonth'
import format from 'date-fns-tz/format'
import addMonths from 'date-fns/addMonths'
import startOfMonth from 'date-fns/startOfMonth'
import startOfDay from 'date-fns/startOfDay'
import subMonths from 'date-fns/subMonths'
import endOfDay from 'date-fns/endOfDay'
import isSameDay from 'date-fns/isSameDay'
import add from 'date-fns/add'

/* Input/Output of dates */
/* Use this, so there's no confusion over how to parse dates */
// All dates generated from the server should be converted to local time
export const parseServerDate = (dateString: string) => {
  const date = new Date(dateString)

  return getLocalDate(date)
}

/* Display the date as a string, correcting the date to the UTC date */
// There's no scenario where we wouldn't show month, so, omitted.
export const displayDate = (date: Date, includeDay = true, includeYear = true): string => {
  let dateFormat = 'MMM'
  if (includeDay) {
    dateFormat += ' dd'
    if (includeYear) {
      dateFormat += ','
    }
  }
  if (includeYear) {
    dateFormat += ' yyyy'
  }

  return format(date, dateFormat)
}

export const displayMonthAndYear = (date: Date) => {
  return displayDate(date, false)
}

export const displayMonthAndDay = (date: Date) => {
  return displayDate(date, true, false)
}

/* When creating dates we must ensure they are set to midnight */
export const createDate = (date: Date = new Date()): Date => {
  return startOfDay(date)
}

/* Takes a date, converts it to UTC, and creates a datestring for the server */
export const formatDateForServer = (date: Date): string => {
  // Just send back the date to the server, ignoring time zone
  return format(date, 'yyyy-MM-dd')
}

// Converts a date based on UTC timezone and convert to local time
export const getLocalDate = (date: Date) => {
  // Get time offset in milliseconds
  const offset = date.getTimezoneOffset() * 60 * 1000

  // Create new date with offset
  return new Date(date.getTime() + offset)
}

export const firstOfPreviousMonth = (date = new Date()) => {
  const previousMonth = subMonths(date, 1)
  const firstOfPreviousMonth = startOfMonth(previousMonth)
  return createDate(firstOfPreviousMonth)
}

export const firstOfFollowingMonth = (date = new Date()) => {
  const followingMonth = addMonths(date, 1)
  const firstOfFollowingMonth = startOfMonth(followingMonth)
  return createDate(firstOfFollowingMonth)
}

export const firstOfMonth = (date = new Date()) => {
  const firstOfMonth = startOfMonth(date)
  return createDate(firstOfMonth)
}

export const lastOfMonth = (date = new Date()) => {
  return endOfDay(lastDayOfMonth(date))
}

/* Comparators */
export const sameMonth = (firstDate: Date, secondDate: Date) => {
  return isSameMonth(firstDate, secondDate)
}

export const sameDay = (firstDate: Date, secondDate: Date) => {
  return isSameDay(firstDate, secondDate)
}

export const withinNumDays = (firstDate: Date, numberOfDays = 10, secondDate = new Date()) => {
  const dateInFuture = add(new Date(secondDate), { days: numberOfDays })

  return dateInFuture >= firstDate
}

export const addDays = (numberOfDays: number, date = new Date()) => {
  return add(date, { days: numberOfDays })
}

// Old
export const safeDateString = (date?: Date) => {
  if (typeof date === 'undefined') return ''

  return formatDateForServer(date)
}
