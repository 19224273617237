import { datadogLogs } from '@datadog/browser-logs'
import {
  REACT_APP_APP_NAME,
  REACT_APP_DATADOG_CLIENT_TOKEN,
  REACT_APP_DATADOG_SITE,
  REACT_APP_SENTRY_DSN,
  REACT_APP_VERSION
} from 'Services/config'
import * as Sentry from '@sentry/react'
import { captureMessage } from '@sentry/react'
import { browserTracingIntegration } from '@sentry/browser'

export const initializeLogging = () => {
  if (REACT_APP_SENTRY_DSN) {
    Sentry.init({
      dsn: REACT_APP_SENTRY_DSN,
      integrations: [browserTracingIntegration()],
      tracesSampleRate: 1.0
    })
  }
  if (REACT_APP_DATADOG_CLIENT_TOKEN) {
    datadogLogs.init({
      clientToken: REACT_APP_DATADOG_CLIENT_TOKEN,
      site: REACT_APP_DATADOG_SITE,
      service: REACT_APP_APP_NAME,
      forwardErrorsToLogs: true,
      useSecureSessionCookie: true,
      sampleRate: 100,
      beforeSend: (log) => {
        // Even if running DD locally no need for webpack-dev-server errors
        if (log.message.includes('webpack-dev-server')) {
          return false
        }
      }
    })
  }
}

export const setLogContextUser = ({ id, employerId }: { id: number; employerId?: number }) => {
  if (REACT_APP_SENTRY_DSN) {
    Sentry.setUser({ id: id.toString(), employerId: employerId, release: REACT_APP_VERSION })
  }
  if (REACT_APP_DATADOG_CLIENT_TOKEN) {
    datadogLogs.setUser({ id: id.toString() })
    datadogLogs.setUserProperty('release', REACT_APP_VERSION)

    if (employerId) {
      datadogLogs.setUserProperty('employer', employerId.toString())
    }
  }
}

export enum LogLevel {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  DEBUG = 'debug'
}
type LogContext = { level?: LogLevel; extra?: Record<string, unknown> }

export const logMessage = (message: string, context?: LogContext | LogLevel) => {
  const level = (context && (typeof context === 'string' ? context : context.level)) || 'error'
  const extra = context && (typeof context === 'string' ? {} : context.extra)

  if (REACT_APP_SENTRY_DSN) {
    captureMessage(message, { level, extra })
  }
  if (REACT_APP_DATADOG_CLIENT_TOKEN) {
    ;({
      error: datadogLogs.logger.error.bind(datadogLogs.logger),
      warning: datadogLogs.logger.warn.bind(datadogLogs.logger),
      info: datadogLogs.logger.info.bind(datadogLogs.logger),
      debug: datadogLogs.logger.debug.bind(datadogLogs.logger)
    })[level](message, extra)
  }
}
