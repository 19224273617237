import { useQuery } from 'react-query'
import { getOngoingPurchases } from 'Services/purchases/purchasesService'
import { QueryNames } from 'Hooks/api/queryNames'

export const useGetOngoingPurchasesQuery = (userId?: number) => {
  return useQuery({
    queryKey: [QueryNames.GetUserOngoingPurchases, userId],
    queryFn: async () => {
      if (userId === undefined) return { ongoingPurchases: [] }

      const response = await getOngoingPurchases(userId)
      if (response.data) {
        return {
          ongoingPurchases: response.data.ongoingPurchases
        }
      }

      return { ongoingPurchases: [] }
    }
  })
}
