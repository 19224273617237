import { RequestResponse } from 'Services/apiResponseTypes'
import { postRequest } from 'Services/baseRequests/postRequest'
import { Employer } from 'Utils/types/JOONAdmin/employer'
import { deleteRequest } from 'Services/baseRequests/deleteRequest'
import { putRequest } from 'Services/baseRequests/putRequest'

type AddDomainPayload = {
  employerId: Employer['id']
  domainName: string
}
export const addDomain = async ({
  employerId,
  domainName
}: AddDomainPayload): Promise<RequestResponse> => {
  const response = await postRequest<RequestResponse, { domain_name: string }>(
    `/employers/${employerId}/domain`,
    { domain_name: domainName }
  )

  return {
    success: response?.success ?? false,
    message: response?.message ?? ''
  }
}

type DeleteDomainPayload = {
  employerId: Employer['id']
  domainId: number
}
export const deleteDomain = async ({
  employerId,
  domainId
}: DeleteDomainPayload): Promise<RequestResponse> => {
  const response = await deleteRequest<RequestResponse, { domain_id: number }>(
    `/employers/${employerId}/domain`,
    { domain_id: domainId }
  )

  return {
    success: response?.success ?? false,
    message: response?.message ?? ''
  }
}

type ToggleSSOPayload = {
  employerId: Employer['id']
  domainId: number
  enable: boolean
}
export const toggleSSOForDomain = async ({
  employerId,
  domainId,
  enable
}: ToggleSSOPayload): Promise<RequestResponse> => {
  const response = await putRequest<RequestResponse, { domain_id: number; enable_sso: boolean }>(
    `/employers/${employerId}/domain`,
    { domain_id: domainId, enable_sso: enable }
  )

  return {
    success: response?.success ?? false,
    message: response?.message ?? ''
  }
}

type CreateOrganizationPayload = {
  employerId: Employer['id']
}
export const createOrganization = async ({
  employerId
}: CreateOrganizationPayload): Promise<RequestResponse> => {
  const response = await postRequest<RequestResponse>(`/employers/${employerId}/create_org`)

  return {
    success: response?.success ?? false,
    message: response?.message ?? ''
  }
}
