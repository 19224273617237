import { useMemo } from 'react'
import styled from 'styled-components'
import { Modal } from '../Modal'

import { ModalHeader } from 'Components/Modal/ModalHeader'
import { UpdateUserCountryForm } from './UpdateUserCountryForm'

import { ModalType, usePeoplePageContext } from 'Contexts/PeoplePageContext'
import { useEmployerUsersContext } from 'Contexts/EmployerUsersContext'

import { red } from 'Utils/styles/colors'

const InfoContainer = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`

export const UpdateUserCountryModal = () => {
  const {
    state: { modal, actionableUserId },
    setModal
  } = usePeoplePageContext()
  const {
    state: { users }
  } = useEmployerUsersContext()

  const setShowModal = (show: boolean) => setModal(show ? ModalType.UpdateUserCountry : undefined)

  const user = useMemo(() => {
    return users.find((user) => user.id == actionableUserId)
  }, [users, actionableUserId])

  return (
    <Modal
      showModal={modal === ModalType.UpdateUserCountry}
      setShowModal={setShowModal}
      maxWidth={500}
    >
      <ModalHeader>Update User Country</ModalHeader>
      <InfoContainer>
        <li style={{ color: red }}>WARNING! This action is irreversible!</li>
        <li>Requirements and result of updating a user country:</li>
        <li>1. They must have no active or future program memberships.</li>
        <li>2. They must have no incomplete benefit transfers.</li>
        <li>3. Their current Stripe account will be deleted entirely.</li>
        <li>
          4. A new Stripe account will be created for the user and they must set up a new deposit
          account.
        </li>
      </InfoContainer>
      <UpdateUserCountryForm user={user} />
    </Modal>
  )
}
