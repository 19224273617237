import { useMemo } from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

import { StyledSelect } from 'Components/Inputs/StyledSelect'
import { PolicyPeriodDuration } from 'Utils/types/benefitProgramPolicy'
import { FlexColumn, spacingXXS } from 'Utils/styles/spacing'

import { getSelectOptions } from 'Utils/helpers/dateSelection'
import { parseServerDate } from 'Utils/helpers/dateUtils'

const DateSelectorContainer = styled(FlexColumn)`
  width: 50%;
  gap: ${spacingXXS};
`

type BenefitProgramStartDateSelectorProps = {
  policyDuration: PolicyPeriodDuration
  onChange: (date: Date) => void
}

export const BenefitProgramStartDateSelector = ({
  policyDuration,
  onChange
}: BenefitProgramStartDateSelectorProps) => {
  const placeholderText =
    policyDuration === PolicyPeriodDuration.Monthly
      ? 'Select a month'
      : policyDuration === PolicyPeriodDuration.Quarterly
      ? 'Select a quarter'
      : policyDuration === PolicyPeriodDuration.Yearly
      ? 'Select a year'
      : 'Select one...'

  const updateSelectedStartDate = (selectedOption: string) => {
    const selectedDate = parseServerDate(selectedOption)

    onChange(selectedDate)
  }

  const selectOptions = useMemo(() => getSelectOptions(policyDuration), [policyDuration])

  return (
    <DateSelectorContainer>
      <FormattedMessage id="createBenefitProgram.scheduledStartDate.label" />
      <StyledSelect
        options={selectOptions}
        handleChange={updateSelectedStartDate}
        placeholderText={placeholderText}
      />
    </DateSelectorContainer>
  )
}
