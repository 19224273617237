import React, { FC } from 'react'
import styled from 'styled-components'

import { CategoryChips } from 'Components/Chip/CategoryChips'
import { CountryChips } from 'Components/Chip/CountryChips'
import { ProgramAmount } from 'Components/ProgramAmount'
import { ProgramDetailsModal } from 'Components/Modal/ProgramDetailsModal'
import { fontSizeMD, fontWeightBold } from 'Utils/styles/text'
import { EmployerBenefitProgram } from 'Utils/types/benefitProgram'
import {
  FlexColumn,
  FlexRow,
  noPadding,
  spacingMD,
  spacingSM,
  spacingXXS
} from 'Utils/styles/spacing'
import { zirconGray } from 'Utils/styles/colors'

import { useCountries } from 'Hooks/useCountries'
import { useToggled } from 'Hooks/useToggled'
import { Card } from 'Components/Card/Card'
import { AppIconMap, AppIcons } from 'Utils/types/appIcons'
import { HoverIconCard } from 'Components/HoverIconCard'
import { hasRollover } from 'Utils/types/benefitProgramPolicy'
import { RolloverChip } from 'Components/Chip/RolloverChip'
import { OngoingPurchasesChip } from 'Components/Chip/OngoingPurchasesChip'
import { DownloadReceiptsButton } from './DownloadReceiptsButton'

export const HeaderSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${zirconGray};
  padding: ${spacingMD} ${spacingSM};
  gap: ${spacingXXS};
`

export const ProgramName = styled.span`
  font-size: ${fontSizeMD};
  font-weight: ${fontWeightBold};
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const InfoSection = styled(FlexColumn)`
  padding: ${spacingSM} ${spacingMD};
`

export const ButtonSection = styled(FlexColumn)`
  border-top: 1px solid ${zirconGray};
  align-items: center;
  justify-content: center;
  padding: ${spacingSM} 0;
  z-index: 2;
`

const ProgramInfoHover = ({ program }: JoonAdminProgramDetailsCardProps) => (
  <HoverIconCard iconSrc={AppIconMap[AppIcons.InfoCircle]} altText="Program info">
    <span>{program.name}</span>
  </HoverIconCard>
)

type JoonAdminProgramDetailsCardProps = {
  program: EmployerBenefitProgram
  onClick?: () => void
}

export const JoonAdminProgramDetailsCard: FC<JoonAdminProgramDetailsCardProps> = ({
  program,
  onClick
}) => {
  const { toggled, handleToggle } = useToggled()
  const { hasMultipleCountries } = useCountries()

  return (
    <Card style={{ minHeight: 250 }} padding={noPadding} onClick={onClick}>
      <HeaderSection>
        <ProgramName>
          ({program.id}) {program.name}
        </ProgramName>
        <ProgramInfoHover program={program} />
      </HeaderSection>
      <InfoSection gap={spacingSM}>
        <ProgramAmount program={program} />
        <CategoryChips program={program} handleToggle={handleToggle} showAll />
        {hasMultipleCountries && (
          <CountryChips id={program.id} countries={program.countries} handleToggle={handleToggle} />
        )}
        <FlexRow gap={spacingXXS}>
          {hasRollover(program.policy) && <RolloverChip />}
          {program.policy.ongoingPurchases && <OngoingPurchasesChip />}
        </FlexRow>
      </InfoSection>
      {program.policy && (
        <ButtonSection>
          <DownloadReceiptsButton program={program} />
        </ButtonSection>
      )}
      <ProgramDetailsModal program={program} showModal={toggled} setShowModal={handleToggle} />
    </Card>
  )
}
