import { FC, useMemo } from 'react'
import { Collapse, TableCell, TableRow } from '@mui/material'
import styled from 'styled-components'
import { PeopleAdminActions } from './PeopleAdminActions'
import { StripeAccountInfo } from './StripeAccountInfo'
import { UserDetailsInfo } from './UserDetailsInfo'

import { spacingMD, FlexRow } from 'Utils/styles/spacing'
import { UserStatus } from 'Utils/types/userStatus'

import { useEmployerUsersContext } from 'Contexts/EmployerUsersContext'

const Content = styled.div`
  padding: ${spacingMD};
`

const LeftContainer = styled(FlexRow)`
  flex-grow: 5;
`

const RightContainer = styled(FlexRow)`
  flex-grow: 1;
  justify-content: flex-end;
`

type PeopleDetailsRowProps = {
  open: boolean
  userId: number
  userStatus: UserStatus
  userPrograms: string[]
}
export const PeopleDetailsRow: FC<PeopleDetailsRowProps> = ({
  open,
  userId,
  userStatus,
  userPrograms
}) => {
  const {
    state: { users }
  } = useEmployerUsersContext()

  const user = useMemo(() => {
    return users.find((user) => user.id == userId)
  }, [users, userId])

  return (
    <TableRow
      style={{
        borderBottom: open ? '1px solid rgba(224, 224, 224, 1)' : 'none'
      }}
    >
      <TableCell
        style={{
          padding: 0,
          borderBottom: 'none'
        }}
        colSpan={10}
      >
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Content>
            <FlexRow>
              <LeftContainer>
                <UserDetailsInfo user={user} userPrograms={userPrograms} />
                <StripeAccountInfo stripeAccountInfo={user?.stripeAccount} />
              </LeftContainer>
              <RightContainer>
                {user && (
                  <PeopleAdminActions
                    userId={user.id}
                    userRoles={user.roles}
                    userStatus={userStatus}
                    hasStripeAccount={!!user.stripeAccount}
                    hasPurchaseAccount={user.hasPurchaseAccount}
                    userLocked={user.failedLoginCount >= 5}
                  />
                )}
              </RightContainer>
            </FlexRow>
          </Content>
        </Collapse>
      </TableCell>
    </TableRow>
  )
}
