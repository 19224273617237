import { FC } from 'react'
import styled from 'styled-components'
import { spacingLG, spacingSM } from 'Utils/styles/spacing'

const BackgroundImage = styled.div<{
  imageUrl: string
}>`
  justify-self: stretch;
  height: 100%;
  width: 100%;
  background-image: url(${(props) => props.imageUrl});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`

const PlaceholderTextContainer = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
`
const EmptyContainer = styled.div`
  padding: ${spacingLG} 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  gap: ${spacingSM};
`

type PlaceHolderProps = {
  imageUrl?: string
  subtext: FC
}

export const Placeholder = ({ imageUrl, subtext: Subtext }: PlaceHolderProps) => {
  return (
    <EmptyContainer>
      {imageUrl && <BackgroundImage imageUrl={imageUrl} />}
      <PlaceholderTextContainer>
        <Subtext />
      </PlaceholderTextContainer>
    </EmptyContainer>
  )
}
